import React from 'react';
import {Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {useSelector} from 'react-redux';
import {statsSummaryGet} from '../../redux/reducers/stats.js';

function TopBar() {
    const classes = useStyles();

    const summary = useSelector(statsSummaryGet)?.toJS();

    const SmallBtn = ({icon, text}) => (
        <div className={classes.toplineBtn}>
            <div className={classes.toplineBtnIcon}>
                {icon}
            </div>
            <Typography className={classes.smallBtnText}>{text}</Typography>
        </div>
    );

    const BigBtn = ({icon}) => (
        <div className={classes.toplineBigBtn}>{icon}</div>
    );

    return (
        <div className={classes.topbar}>
            <div className={classes.pageTitle}>
                <Typography variant="h1">Статистика</Typography>
            </div>
            <div className={classes.topbarButtons}>
                {/*<TopBarIcons summary={summary} />*/}
            </div>
            {/*<div className={classNames(classes.topbarButtons, classes.bigButtons)}>
                <BigBtn icon={<SearchIcon />} />
                <BigBtn icon={<SettingsIcon />} />
            </div>*/}
        </div>
    );
}
export default TopBar;

const styles = (theme) => ({
    topbar: {
        display: 'flex',
        marginBottom: 18,


        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    },
    pageTitle: {
        flex: 1,
    },
    topbarButtons: {
        display: 'flex',
        alignItems: 'center',
    },

    toplineBtn: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
    toplineBtnIcon: {
        width: 24,
        height: 24,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        marginRight: theme.spacing(2),
    },
    smallBtnText: {
        color: theme.colors.grey.c100,
    },

    bigButtons:{
        marginLeft: theme.spacing(8),
    },
    toplineBigBtn: {
        width: 24,
        height: 24,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        marginRight: theme.spacing(4),
    }
});
const useStyles = makeStyles(styles);
