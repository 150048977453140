import {fromJS, Map} from 'immutable';
import {post, post2} from '../../app/rest';
import {userGetInfo} from './user';
import * as jwtDecode from 'jwt-decode';
import {getLoadedLanguage} from './locale.js';

const initialState = new Map({
    access: null,
    bearer: null,
    refresh: null,
    permissions: [],
    isFetching: false,
    isError: null,
});

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'TOKENS_FETCH_REQUEST':
            return state.set('isFetching', true)
                .set('isError', false);
        case 'TOKENS_FETCH_SUCCESS':
            const perms = action.permissions ? fromJS(Object.keys(action.permissions)) : [];
            return state.set('isFetching', false)
                .set('access', action.access)
                .set('bearer', action.bearer)
                .set('permissions', perms)
                .set('isError', false);
        case 'TOKENS_FETCH_ERROR':
            return state.set('isFetching', false)
                .set('isError', action.error);
        case 'TOKENS_ACCESS_BAD':
            return state.set('access', null)
                .set('refresh', null)
                .set('isError', false);
        case 'TOKENS_REFRESH_REQUEST':
            return state.set('isFetching', true)
                .set('isError', false);
        case 'TOKENS_REFRESH_ERROR':
            return state.set('refresh', null)
                .set('isError', false);
        case 'TOKENS_LOGOUT':
            return state.set('refresh', null)
                .set('access', null)
                .set('isError', false);

/*
        case 'USER_PERM_UPDATE': {
            const permissions = Object.keys(action.permissions) || [];
            return state.set('permissions', permissions);
        }
 */
        default:
            return state;
    }
};
export default reducer;

export const tokensGetAccessToken = (state) => state.getIn(['tokens', 'access']);
export const tokensGetBearerToken = (state) => state.getIn(['tokens', 'bearer']);

export const tokensError = (state) => state.getIn(['tokens', 'isError']);

export const tokensLogout = () => (dispatch, getState) => {
    const state = getState();
    const code = getLoadedLanguage(state);

    dispatch({type: 'TOKENS_LOGOUT'});
    localStorage.setItem('tokens', undefined);
    localStorage.setItem('activeSessions', undefined);
    //localStorage.setItem('user', undefined);
    sessionStorage.clear();
    window.location.href=`/`;
}

export const tokensSingIn = (username, password) => async dispatch => {
    dispatch({type: 'TOKENS_FETCH_REQUEST'});
    try {
        let resp = null;
        try{
            resp = await post('/api/token/', {username, password});
        } catch (e) {
            console.log(e);

            if (e.status === 401) {
                throw 'API1';
            } else {
                throw 'API1err'
            }
        }

        let resp2 = null;
        try {
            resp2 = await post2('/v2/api/auth/token', {username, password, grant_type: 'password'});
        } catch (e) {
            if (e.status === 401) {
                throw 'API2';
            } else {
                throw 'API2err'
            }
        }
        dispatch({type: 'TOKENS_FETCH_SUCCESS', access: resp.access, bearer: resp2?.access_token, permissions: resp.permissions});

        const decoded = jwtDecode(resp2.access_token);
        console.log(decoded, decoded.user?.id);
        dispatch(userGetInfo(decoded.user?.id))
    } catch (err) {
        console.error(err);
        dispatch({type: 'TOKENS_FETCH_ERROR', error: err});
    }
}

export const tokensRefresh = (refreshToken) => async dispatch => {
    dispatch({type: 'TOKENS_REFRESH_REQUEST'});

    try {
        const resp = await post('/api/token/refresh/', {refresh: refreshToken});
        dispatch({type: 'TOKENS_FETCH_SUCCESS', access: resp.access, refresh: resp.refresh, permissions: resp.permissions});

        const decoded = jwtDecode(resp.access);
        dispatch(userGetInfo(decoded.user_id));
    } catch (err) {
        console.error(err);
    }

}

export const userIsSuper = (state) => state.getIn(['user', 'info', 'is_superuser']);

export const checkPermission = (perm) => (state) => state.getIn(['user', 'info', 'permissions'])?.includes(perm) || userIsSuper(state);
