import React from 'react';
import {makeStyles} from '@material-ui/styles';
import {Paper} from '@material-ui/core';
import classNames from 'classnames';

const StackPaper = props => {
    const classes = useStyles();

    const {children, className, ...paperProps} = props;

    return (
        <Paper className={classNames([classes.paper, className])} {...paperProps}>
            {children}
        </Paper>
    );
}

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(-2),
        marginBottom: 0,
        padding: theme.spacing(3, 1, 1),
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        position: "relative",
    }
}));

export default StackPaper;