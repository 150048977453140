import React, {useContext, useEffect} from 'react';
import {IconButton, Paper, Tab, Tabs} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {ReactComponent as FilterIcon} from '../StoresListPage/svg/FilterIcon.svg';
import classNames from 'classnames';
import {setTitle} from '../StoresListPage/StoresPage.js';
import {LocaleContext} from '../../i18n/LocaleContext.js';

const Topbar = ({filters, setFilters, type, setType, ...props}) => {
    const classes = useStyles();
    const BigBtn2 = ({icon,  ...props}) => (
        <IconButton {...props} className={classNames(classes.toplineBigBtn2, {'pressed':  filters})}>{icon}</IconButton>
    );


    const locale = useContext(LocaleContext);
    const lang = locale?.code;
    const dispPlink = `/${lang}/new_reports/dispensing#filters`;
    const idleLink = `/${lang}/new_reports/idle#filters`;

    useEffect(()=>{
        if (type === 0) {
            setTitle('Отчёт по наливам');
        } else {
            setTitle('Отчет по простоям');
        }
    }, [type])


    return (
        <Paper square className={classes.root}>
            <Tabs value={type} color="primary" className={classes.tabs} classes={{indicator: classes.indicator}}>
                <Tab label="Отчет по наливам" classes={{wrapper: classes.tab}} href={dispPlink}/>
                <Tab label="Отчет по простоям" classes={{wrapper: classes.tab}}
                     href={idleLink} />
            </Tabs>

            <BigBtn2 icon={<FilterIcon />} onClick={() => {
                setFilters(!filters);
            }}/>
        </Paper>
    )
};

export default Topbar;

const styles = (theme) => ({
    root: {
        padding: 0,
        boxShadow: '0px 3px 12px rgba(12, 37, 86, 0.06)',
        zIndex: 500,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    tabs: {
        margin: '0 24px',
    },
    indicator: {
        backgroundColor: theme.colors.blue.default,
    },
    tab: {
        fontSize: 17,
        textTransform: 'initial',
        fontWeight: 'normal',
        color: '#1d1d1d',
    },

    toplineBigBtn2: {
        width: 36,
        height: 36,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        marginRight: theme.spacing(2),
        '&.lastChild': {
            marginRight: 0,
        },
        padding: 0,

        '&.pressed': {
            background: '#007AFF1A',
            color: '#007AFF',

            '&.search': {
                //display: 'none',
                visibility: 'hidden',
            }
        },
    },

});
const useStyles = makeStyles(styles);

