import {Autocomplete} from '@material-ui/lab';
import {Checkbox, makeStyles, Popper, TextField} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';
import {t} from '../../i18n/utils.js';

const Select = React.memo(SelectRaw);

function SelectRaw({placeholder, multiple, ...props}) {
    const classes = useStyles();

    const uncheckedIcon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const arrowDownIcon = <ExpandMoreIcon className={classes.arrowDown} />;

    return (
        <Autocomplete
            getOptionLabel={option => option?.name || ''}
            getOptionSelected={(option, value) => option?.id === value?.id}
            renderInput={params => (
                <TextField
                    {...params}
                    placeholder={placeholder}
                    variant="outlined"
                    size="small"
                    fullWidth
                />
            )}
            multiple={multiple}
            renderTags={(tags) => {
                return (
                    <div className={classes.counter}>
                        {props.tagLabelI18n ? t(props.tagLabelI18n, {count: tags.length}) :
                            <>Выбрано: {tags.length}</>
                        }
                    </div>
                );
            }}
            disableCloseOnSelect={multiple}
            renderOption={(option, { selected }) => (
                <>
                    { multiple &&
                    <Checkbox
                        icon={uncheckedIcon}
                        checkedIcon={checkedIcon}
                        style={{marginRight: 8}}
                        className={classes.checkbox}
                        checked={selected}
                    />
                    }
                    {/*JSON.stringify(option)*/}
                    {props.getOptionLabel ? props.getOptionLabel(option) : option?.name}
                </>
            )}
            classes={{
                inputRoot: classes.inputRoot,
                listbox: classes.listbox,
                option: classes.option,
            }}
            popupIcon={arrowDownIcon}
            PopperComponent={(props)=>(<Popper {...props} style={{
                width: 'fit-content',
                maxWidth: '360px',
            }} placement="bottom-start" />)}
            {...props}
            noOptionsText={t('noOptions')}
            loadingText={t('loadingFilter')}
        />
    );
}
export default Select;

const styles = theme => ({
    inputRoot: {
        backgroundColor: theme.colors.main.white,
        borderRadius: theme.spacing(2),
        borderColor: '#DFE3E9',

        height: 36,
        minWidth: 196,

        '& input::placeholder': {
            color: theme.colors.grey.c100,
            opacity: 1,
        },
    },

    arrowDown: {
        fontSize: '18px',
        color: theme.colors.grey.c100,
    },
    checkbox: {
        color: "rgba(134, 138, 143, 1)",
        padding: 8,

        "&.Mui-checked": {
            color: "rgba(0, 122, 255, 1)",
        }
    },
    listbox: {
        padding: 0,
    },
    option: {
        padding: theme.spacing(2, 4),
    },
    counter: {
        padding: '4.5px 4px',
        paddingLeft: '6px',

        "& + input::placeholder": {
            opacity: 0,
        }
    },
});
const useStyles = makeStyles(styles);
