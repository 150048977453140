import React from 'react';
import {Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

function TopBarIcons({summary}) {
    const classes = useStyles();

    const SmallBtn = ({icon, text}) => (
        <div className={classes.toplineBtn}>
            <div className={classes.toplineBtnIcon}>
                {icon}
            </div>
            <Typography className={classes.smallBtnText}>{text}</Typography>
        </div>
    );


    return (
        null
    );
}

export default TopBarIcons;


const styles = (theme) => ({
    searchField: {
        position: "absolute",
        zIndex: 800,
        background: "#fff",
        top: 0,
        left: 0, // 220
        bottom: 0,
        right: 0,
        borderRadius: 16,
        display: 'none',

        '&.visible': {
            display: 'inherit',
        },
    },
    topbar: {
        display: 'flex',
        marginBottom: 18,
        position: 'relative',

        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    pageTitle: {
        flex: 1,
    },
    topbarButtons: {
        display: 'flex',
        alignItems: 'center',
    },

    toplineBtn: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: theme.spacing(3),
        marginLeft: theme.spacing(0),
    },
    toplineBtnIcon: {
        width: 24,
        height: 24,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        marginRight: theme.spacing(2),
    },
    smallBtnText: {
        color: theme.colors.grey.c100,
    },

    bigButtons: {
        marginLeft: theme.spacing(6),
    },
    toplineBigBtn: {
        width: 24,
        height: 24,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        marginRight: theme.spacing(4),
    },

    toplineBigBtn2: {
        width: 36,
        height: 36,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        marginRight: theme.spacing(2),
        '&.lastChild': {
            marginRight: 0,
        },
        padding: 0,

        '&.pressed': {
            background: '#007AFF1A',
            color: '#007AFF',

            '&.search': {
                display: 'none',
            }
        }
    },

});
const useStyles = makeStyles(styles);
