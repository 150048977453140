import React, {useContext} from 'react';
import NotificationWidget from './NotificationWidget';
import {DeviceContext} from '../DeviceLoader';
import {formatDate, getIcon} from '../../app/utils';
import {t} from '../../i18n/utils.js';
import {LocaleContext} from '../../i18n/LocaleContext.js';
import {useParams} from 'react-router-dom';

/**
 * Renders a given notification
 */
function Notification({noti, ...props}) {

    const params = useParams();
    const notificationType = noti.get('type');
    const icon = getIcon(notificationType);
    const dateSrc = noti.get('date');
    const localeObj = useContext(LocaleContext);
    const date = formatDate(dateSrc, localeObj.dateTimeFormat);

    const moduleInNoti = noti.get('module');
    const moduleId = moduleInNoti?.get('id');
    const type = moduleInNoti?.get('type');

    const device = useContext(DeviceContext);
    if(!device) {
        return null;
    }

    let module;
    module = device.get('modules').find(m => m.get('id') === moduleId);

    const storeId = params.storeId;

    let link;
    if (type === 'tap' || type === 'printer') {
        // it's one of the visible modules
        const deviceId = device.get('id');
        link = `/stores/${storeId}/groups/${deviceId}/modules/${moduleId}/`;
    } else if (type === 'takeHead') {
        // it's a keg
        const taps = device.get('modules')
            .filter(m => m.get('type') === 'head');

        const kegs = taps.flatMap(m => m.get('kegs').map(keg => keg.set('tapId', m.get('id'))));

        module = kegs.find(m => m.get('id') === moduleId);

        if (module) {
            const deviceId = device.get('id');
            const tapId = module.get('tapId');
            const kegId = moduleId;
            link = `/stores/${storeId}/groups/${deviceId}/modules/${moduleId}/`;
        }
    }

    let notiSrcModuleTitle;
    if (module) {
        notiSrcModuleTitle = module.get('title')
    }

    let blockedModules = noti.get('blocked_modules');

    let blockedModulesTitles = [];
    if(blockedModules) {
        blockedModulesTitles = blockedModules.map(bl => {
            const blId = bl.get('id');
            const blMod = device.get('modules').find(m => m.get('id') === blId);
            const blTitle = blMod.get('title');
            if (blTitle) {
                return blTitle;
            } else {
                return null;
            }
        }).filter(blText => blText)?.toJS();
    }

    return (
        <NotificationWidget
            icon={icon}
            date={date}
            color={notificationType === "Error" ? "red" : "orange"}
            link={link}
        >
            {
                notiSrcModuleTitle &&
                notiSrcModuleTitle + ': '
            }
            {noti.get('text')}
            {
                blockedModulesTitles.length > 0 &&
                ': ' + blockedModulesTitles.join(', ') + ' ' +
                    t('blocked', {count: blockedModulesTitles.length})
            }
        </NotificationWidget>
    );
}

export default Notification;
