import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import InfoIcon from '@material-ui/icons/Info';
import React from 'react';
import moment from 'moment';
import {localizeAdminUrls} from '../localization/config';
import {t} from '../i18n/utils.js';
import 'moment/locale/ru';

export const WEIGHT_OK = 200;
export const WEIGHT_WARN = 300;
export const WEIGHT_ERROR = 500;
export const WEIGHT_UNDEFINED = 500;

export function typeToWeight(type) {
    switch (type) {
        case 'Warn':
            return WEIGHT_WARN;
        case 'Error':
            return WEIGHT_ERROR;
        default:
            return WEIGHT_UNDEFINED;
    }
}

export function getMostBadNotification(list) {
    return list?.reduce((acc, cur) => {
        const curType = cur.get('type');
        const curWeight = typeToWeight(curType);

        const accType = acc.first?.get('type');
        const accWeight = accType ? typeToWeight(accType) : WEIGHT_OK;

        return curWeight >= accWeight ? [cur, curType] : acc;
    }, [null, null]);
}

export function getIcon(type) {
    return type === 'Error' ? (<ErrorIcon/>) :
        type === 'Warn'  ? (<WarningIcon/>) :
            (<InfoIcon/>);
}

export function plural(n, f1, f2, f5) {
    n = Math.abs(n) % 100; var n1 = n % 10;
    if (n > 10 && n < 20) { return f5; }
    if (n1 > 1 && n1 < 5) { return f2; }
    if (n1 === 1) { return f1; }
    return f5;
}

export function getWorstStatus(notifs) {
    //                   0     1          2       3
    const statusTypes = ["Ok", "Unknown", "Warn", "Error"];
    let worstLvl = 0; // Ok
    for (const n of notifs) {
        const nStatus = n.get('type');
        let nLvl = statusTypes.findIndex(st => st === nStatus);
        if (nLvl === -1) {
            nLvl = 1; // Unknown
        }

        if (nLvl === statusTypes.length - 1) {
            // it is worst of possibles
            worstLvl = nLvl;
            break;
        }

        if (worstLvl < nLvl) {
            worstLvl = nLvl;
        }
    }
    const worstStatus = statusTypes[worstLvl];

    return [worstStatus, worstLvl];
}

export function toUnits(objIm) {
    //console.log(objIm);
    return 'UNITS';
}

export const emptyVol = '--.-'

export function getVolume(volObj) {
    let vol = getUnitsValue(volObj);
    if (vol === null) {
        return emptyVol;
    }

    return parseFloat(vol).toFixed(2);
}

export function formatVolume(vol, units) {
    if (units === 'liters') {
        return t('litres', {litres: vol});
    }
    if (units === 'oz') {
        return t('oz', {litres: vol});
    }
    return vol
}

export function getUnits(obj) {
    if (obj && typeof obj === "object") {
        return obj.unit;
    }

    return "none";
}

export function getUnitsValue(obj) {
    if (obj && typeof obj === "object") {
        return obj.value;
    }

    return null;
}

export function formatVolumeStr(immObj) {
    if (!immObj) return '--.-';
    if (typeof immObj !== 'object') return immObj;

    const jsObj = immObj?.toJS();
    const val = getVolume(jsObj);
    const units = getUnits(jsObj);
    return formatVolume(val, units);
}

export function check401(err, dispatch) {
    if (err.status === 401) {
        console.error('Bad access token');
        dispatch({type: 'TOKENS_ACCESS_BAD'});
    }
}

export function getResults(resp) {
    if (!resp?.results) throw new Error('No results');
    return resp.results;
}

export function prefixPath (path, prefix) {
    return `/${prefix}${path}`
}

export function adminLink(token, next, locale) {
    let localizedNext = localizeAdminUrls ? prefixPath(next, locale) : next;
    let url = `/admin/jwtlogin/?token=${token}&next=${localizedNext}&backurl=${encodeURI(window.location.pathname)}`
    let result = localizeAdminUrls ? prefixPath(url, locale) : url;

    return result;
}

export function formatDate(date, format, language) {
    const dateMoment = moment(date);

    if (language) {
        dateMoment.locale(language);
    }
    const result = dateMoment.isValid() ? dateMoment.parseZone().format(format) : '-';

    return result;
}

export function formatDateUtc(date, format, language) {
    const dateMomentUtc = moment.utc(date);
    const dateMoment = moment(dateMomentUtc).local();

    if (language) {
        dateMoment.locale(language);
    }
    const result = dateMoment.isValid() ? dateMoment.parseZone().format(format) : '-';

    return result;
}



export function normalizeBool(src) {
    function num2bool(num) {
        // 0 is false, otherwise is true
        return num !== 0;
    }

    // it's already bool
    if (typeof src === 'boolean') {
        return src;
    }
    // 0 is false, otherwise is true
    if (typeof src === 'number') {
        return num2bool(src);
    }
    // try to convert string to bool
    if (typeof src === 'string') {
        const lowercaseSrc = src.toLowerCase();

        if (lowercaseSrc === 'true') {
            return true;
        }
        if (lowercaseSrc === 'false') {
            return false;
        }

        if (Array.isArray(lowercaseSrc.match(/^\d+$/))) {
            const num = parseInt(lowercaseSrc, 10);
            return num2bool(num);
        }
    }
    // else unsupported value
    return null;
}

export const days = (n) => {
    if (n === '-') return '-';

    if (n === 0) {
        return t('today');
    }

    if (n === 1) {
        return t('yesterday');
    }

    return t('d', {count: n});
}

export const daysAgo = n => {
    if (n === '-') return '- ' + t('dnull') + ' ' + t('ago');

    if (n <= 1) return days(n);

    return days(n) + ' ' + t('ago');
}
