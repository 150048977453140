import React from 'react';
import {makeStyles} from '@material-ui/styles';
import {Button} from '@material-ui/core';

function IconButton({children, frRef, icon, ...props}) {
    const classes = useStyles();

    return (
        <Button ref={frRef} {...props} classes={{root: classes.root, label: classes.wrapper}}>
            {icon}
            {children}
        </Button>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: "6px 12px",
        paddingTop: 9,
        minHeight: 72,
        minWidth: 98,
    },
    wrapper: {
        width: "100%",
        display: "inline-flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        fontWeight: "normal",
        fontSize: 14,
        lineHeight: "16px",
        textTransform: "none",

        "& > *:first-child": {
            marginBottom: 6,
        }
    }
}));

export default IconButton;