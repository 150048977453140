import React from 'react';
import {Avatar} from '@material-ui/core';

/**
 * Хеш-функция для генерации цвета
 * @param str
 * @returns {number}
 */
function hashCode(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
}

/**
 * Генерирует цвет ждя иконки пользователя, основываясь на его имени
 * @param str
 * @returns {string}
 */
function pickColor(str) {
    return `hsl(${hashCode(str) % 360}, 75%, 35%)`;
}

/**
 * Возвращает иконку пользователя
 * @returns {JSX.Element}
 * @constructor
 */
function ProfileIcon({username}) {
    const uniqColor = pickColor(username);

    return (
        <Avatar style={{backgroundColor: uniqColor}}>
            {username[0]}
        </Avatar>
    );
}

export default ProfileIcon;