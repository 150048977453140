import React from 'react';

import {ReactComponent as UpIcon} from './svg/arrowUp.svg';
import {ReactComponent as DownIcon} from './svg/arrowDown.svg';
import {Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import classNames from 'classnames';
import {Remove as RemoveIcon} from '@material-ui/icons';

function Badge({t, v, reverse=false, ...props}) {
    const classes = useStyles();

    let sign = t > 0 || isNaN(t) ? 'up' :
        t < 0 ? 'down' : 'null'

    if (isNaN(t)) {
        sign = 'null';
    }

    const icon = sign === 'up' ? <UpIcon /> :
        sign === 'down' ? <DownIcon /> :
            <RemoveIcon className={classes.neutIcon}/>;

    if (sign === 'null') {
        return (
            <div className={classNames(classes.root, 'neut')}>
                <div className={classes.icon}>
                    {icon}
                </div>
            </div>
        );
    }

    return (
        <div className={classNames(classes.root, {'red': (sign === 'down' && !reverse) || (sign === 'up' && reverse)}, {'neut': (sign === 'null')})}>
            <div className={classes.icon}>
                {icon}
            </div>
            <Typography variant="subtitle1">{v}</Typography>
        </div>
    );
}

export default Badge;


const styles = (theme) => ({
    neutIcon: {
        width: 16,
        height: 16,
    },
    root: {
        display: 'inline-flex',
        alignItems: 'center',
        padding: 4,
        height: 24,
        background: theme.colors.colors.successBg,
        color: theme.colors.colors.success,
        borderRadius: 4,

        "&.red": {
            background: theme.colors.colors.errorBg,
            color: theme.colors.colors.error,
        },

        "&.neut": {
            background: theme.colors.lightGrey.c100,
            color: theme.colors.grey.c100,
        }
    },
    icon: {
        width: 16,
        height: 16,
        marginRight: 4,

        '.neut &': {
            marginRight: 0,
        }
    }
});
const useStyles = makeStyles(styles);
