import React, {useState} from 'react';
import {t} from '../../i18n/utils.js';
import {InputAdornment, TextField} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

function SearchField({className, setSearch, query, setQuery, ...props}) {
    const [innerQ, setInnerQ] = useState(query);

    return (
        <TextField size="small" variant="outlined" placeholder={t('searchPlaceholder')}
                   className={className} value={innerQ} onChange={(e) => {
                       const v = e.target.value;
                       setInnerQ(v);
        }} autoFocus={true}
                   onKeyPress={(e) => {
                       if (e.key == 'Enter') {
                           setQuery(innerQ);
                           //setSearch(false);
                       }
                   }}

                   InputProps={{
                       endAdornment:
                           <InputAdornment position="end">
                               <div onClick={() => {
                                   setInnerQ('');
                                   setQuery('');
                               setSearch(false);
                               }
                               }
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContentL: 'center',
                                    }}
                               >
                                    <CloseIcon style={{
                                        color: '#868A8F',
                                        fill: '#868A8F',
                                        fontSize: '20px',
                                        alignSelf: 'center',
                                        position: 'relative',
                                        //top: 1,
                                        left: 5,
                                        cursor: 'pointer',
                                    }}/>
                               </div>
                           </InputAdornment>

                   }}
                   {...props}
        />
    );
}
export default SearchField;
