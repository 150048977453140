import React, {useContext, useEffect, useRef, useState} from 'react';
import {Button, ClickAwayListener, Fab, IconButton, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

import {ReactComponent as SearchIcon} from './svg/search.svg';
import {ReactComponent as FilterIcon} from './svg/FilterIcon.svg';
import {ReactComponent as SettingsIcon} from './svg/settings.svg';

import {ReactComponent as MenuIcon} from './svg/menu.svg';

import {ReactComponent as LogoBig} from './svg/logoBig.svg';
import lodash from 'lodash';

import classNames from 'classnames';
import ProfileMenu from './ProfileMenu.js';
import {useSelector} from 'react-redux';
import {userInfo} from '../../redux/reducers/user.js';
import {t} from '../../i18n/utils.js';
import ProfileIcon from './ProfileIcon.js';
import {statsSummaryGet} from '../../redux/reducers/stats.js';
import SearchField from './SearchField.js';
import {LocaleContext} from '../../i18n/LocaleContext.js';
import SettingsMenu from './SettingsMenu.js';
import {checkPermission, tokensGetAccessToken} from '../../redux/reducers/tokens.js';
import {ReactComponent as AddIcon} from './svg//add.svg';
import TopBarIcons from './components/TopBarIcons.js';
import MobileMenu from '../../componets/Layout2/MobileMenu.js';

function MobileTopBar({filters, fil, setFilters, search, setSearch, query, setQuery, ...props}) {
    const classes = useStyles();

    const [open, setOpen] = useState(false);

    const summary = useSelector(statsSummaryGet)?.toJS();

    const [settings, setSettings] = useState(false);
    const ref = useRef(null);

    const SmallBtn = ({icon, text}) => (
        <div className={classes.toplineBtn}>
            <div className={classes.toplineBtnIcon}>
                {icon}
            </div>
            <Typography className={classes.smallBtnText}>{text}</Typography>
        </div>
    );

    const BigBtn = ({icon}) => (
        <div className={classes.toplineBigBtn2}>{icon}</div>
    );
    const BigBtn2 = ({icon, ...props}) => (
        <IconButton {...props} className={classNames(classes.toplineBigBtn2, {'pressed':  fil})}>{icon}</IconButton>
    );

    const handleClickAway = () => {
        setOpen(false);
    };

    const handleClickAway2 = () => {
        setSearch(false);
    };

    const [profileMenu, setProfileMenu] = useState(false);
    const profileTabRef = React.useRef(null);

    const user = useSelector(userInfo);
    const username = user?.get('first_name') || user?.get('username');
    const profileBtnLabel = username || t('mainMenu_profile');

    const count = summary?.count_errors + summary?.count_warnings;
    const color = summary?.count_errors === 0 ? 'yellow' : 'red';


    const locale = useContext(LocaleContext);
    const token = useSelector(tokensGetAccessToken);
    //const addStoreLink = adminLink(token, `/admin/sellers/store/add/`, locale.code);
    const addStoreLink = `/${locale.code}/adm/sellers/store/add/`;

    const hasAccessToAdmin = useSelector(checkPermission('section_service'));

    const addBtnRef = useRef(null);

    const [rightOffset, setRightOffset] = useState(0);

    function onResize() {
        if (addBtnRef.current) {
            const rect = addBtnRef.current.getBoundingClientRect();
            //setRightOffset(window.innerWidth - addBtnRef.current.offsetLeft - 154);
            //console.log(rect.x)
            setRightOffset(window.innerWidth - rect.x - 42);
        }
    }

    useEffect(() => {
        onResize();
    }, [addBtnRef.current]);

    window.onresize = lodash.throttle( onResize, 400);

    return (
        <div className={classes.wrap}>
            <Fab color="primary" className={classes.fab} href={addStoreLink}>
                <AddIcon />
            </Fab>

            <div className={classes.topbar}>
                <IconButton id="menu-btn" className={classes.menuBtn} onClick={() => {
                    setOpen(true);
                }}>
                    <MenuIcon />
                </IconButton>

                <div className={classes.spacer} />
                <div className={classes.topbarButtons}>
                    <TopBarIcons summary={summary} />
                </div>

                <div className={classNames(classes.topbarButtons, classes.bigButtons)}>
                    {search &&
                    <SearchField query={query} setQuery={setQuery}
                                 className={classNames(classes.searchField, {'visible': search})}
                                setSearch={setSearch} style={{right: rightOffset}}
                    />
                    }
                    <IconButton className={classNames(classes.toplineBigBtn2, {'pressed':  search}, 'search')}
                                onClick={() => {
                                    console.log(search);
                                    setSearch(true);
                                }}
                                ref={addBtnRef}
                    ><SearchIcon /></IconButton>

                    <ClickAwayListener onClickAway={handleClickAway2}>
                        <>
                    <BigBtn2 icon={<FilterIcon />} onClick={() => {
                        setFilters(!fil);
                    }}/>
                    {hasAccessToAdmin &&
                    <Button className={classes.toplineBtn} variant="contained" color="primary" href={addStoreLink}>
                        {t('addStore')}</Button>}
                    <IconButton className={classNames(classes.toplineBigBtn2, {'pressed':  settings}, 'lastChild')}
                                onClick={() => {
                                    setSettings(true);
                                }}  ref={ref}
                    ><SettingsIcon/></IconButton>
                    <SettingsMenu open={settings} anchorRef={ref} {...props} onClose={()=>setSettings(false)} />
                            </>
                    </ClickAwayListener>
                </div>


            </div>

            {open &&
                <MobileMenu onClose={handleClickAway} />
            }
        </div>
    );
}
export default MobileTopBar;

const topbarHeight = 56;

const styles = (theme) => ({
    searchField: {
        position: "absolute",
        zIndex: 800,
        background: "#F2F4F6",
        top: 10,
        left: 64,
        bottom: 8,
        borderRadius: 4,
        right: 8,
        display: 'none',
        border: 'none !important',
        outline: 'none !important',
        boxShadow: 'none !important',
        height: 36,

        "& fieldset": {
            display: 'none',
        },

        '&.visible': {
            display: 'inherit',
        },
    },
    menuItem: {
        display: 'flex',
        padding: '10px 20px',
        gap: 18,
        alignItems: 'center',
        cursor: 'pointer',
        color: 'rgba(114, 142, 192, 1)',
        transition: 'all .3s ease',

        "&:hover": {
            background: 'rgba(11, 46, 109, 1)',
            color: theme.colors.main.white,
        },

        "&.active": {
            background: 'rgba(11, 46, 109, 1)',
            color: theme.colors.main.white,
        },
    },
    menuItemLabel: {
        color: theme.colors.main.white,
    },
    menuItemIcon: {
        width: 24,
        height: 24,
        overflow: 'hidden',
    },

    logo: {
        padding: '16px 20px',
    },

    leftMenu: {
        background: theme.colors.main.secondaryBg,
        color: theme.colors.main.white,
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        zIndex: 500,
        width: 264,

        display: 'flex',
        flexDirection: 'column',
    },

    toplineBtn: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: theme.spacing(3),
        marginLeft: theme.spacing(0),
        fontWeight: 'normal',

        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },

    wrap: {
        //paddingTop: topbarHeight,

        [theme.breakpoints.up('lg')]: {
            display: 'none'
        }
    },
    spacer: {
        flex: 1,
    },

    menuBtn: {
        width: 24,
        height: 24,
        padding: 0,
    },

    topbar: {
        display: 'flex',
        background: theme.colors.main.white,
        height: topbarHeight,
        padding: theme.spacing(4, 6),
        boxShadow: theme.shadows[1],
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 100,
    },
    topbarButtons: {
        display: 'flex',
        alignItems: 'center',
    },

    toplineBtnIcon: {
        width: 24,
        height: 24,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        marginRight: theme.spacing(2),

        '&:last-child': {
            marginRight: 0,
        },
    },
    smallBtnText: {
        color: theme.colors.grey.c100,
    },

    bigButtons:{
        marginLeft: theme.spacing(1),
    },
    toplineBigBtn: {
        width: 24,
        height: 24,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        marginRight: theme.spacing(4),
        '&:last-child': {
            marginRight: 0,
        },
        padding: 0,
    },

    toplineBigBtn2: {
        width: 36,
        height: 36,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        marginRight: theme.spacing(2),
        '&.lastChild': {
            marginRight: 0,
        },
        padding: 0,

        '&.pressed': {
            background: '#007AFF1A',
            color: '#007AFF',

            '&.search': {
                //display: 'none',
                visibility: 'hidden',
            }
        },
    },

    profileBtn: {
        display: 'flex',
        padding: '10px 20px',
        gap: 12,
        alignItems: 'center',
        cursor: 'pointer',
        color: theme.colors.main.white1,
    },

    profileBtnIcon: {
        width: 40,
        height: 40,
        overflow: 'hidden',
    },

    fab: {
        position: 'fixed',
        bottom: 24,
        right: 24,
        width: 44,
        height: 44,
        display: 'none',

        [theme.breakpoints.down('sm')]: {
            display: 'flex',
        }
    },
});
const useStyles = makeStyles(styles);
