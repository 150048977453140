import React from 'react';
import {FormControl, MenuItem, Select} from '@material-ui/core';
import classNames from 'classnames';
import {ReactComponent as ExpandMore} from './svg/expand_more.svg';
import {makeStyles} from '@material-ui/core/styles';

function Dropdown({className, value, onChange}) {
    const classes = useStyles();

    return (
        <FormControl variant="outlined" className={classNames((className))}>
            <Select
                value={value}
                onChange={()=>{}}
                IconComponent={ExpandMore}
                classes={{
                    select: classes.select,
                }}
                onChange={(event => {
                    const v = event.target.value;
                    onChange(v);
                    //console.log(event);
                })}
            >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={100}>100</MenuItem>
            </Select>
        </FormControl>
    );
}
export default Dropdown;

const styles = theme => ({
    select: {
        color: theme.colors.main.secondaryText,
        paddingRight: '42px !important',

        fontSize: '16px',
        lineHeight: '20px',
    },
});
const useStyles = makeStyles(styles);
