import React from 'react';
import {
    Dialog,
    DialogContent,
    DialogTitle as MuiDialogTitle,
    IconButton,
    Typography,
    withStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {makeStyles} from '@material-ui/core/styles';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    storename: {
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 500,
    },
    storeaddress: {
        color: "#7E858E",
        fontSize: '12px',
        lineHeight: '16px',
    },
    notifType: {
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '0.002em',
    },
    dialogContent: {
        padding: 8,
        marginTop: 8,
    },
    notiHeader: {
        display: 'flex',
        gap: '6px',
    },
    notiBlock: {
        padding: '8px 0',
    },
    paper: {
        padding: 12,
        maxWidth: 988,
    },
    notiIcon: {
        width: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    notifItem: {
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0.002em',

        color: '#7E858E',
    },
    h: {
        color: '#1D1D1D',
        fontSize: 19,
        fontWeight: 500,
        lineHeight: '22px',
        letterSpacing: '0em',
        marginBottom: 16,
        padding: 8,
    },
    c: {
        fontSize: 15,
        fontWeight: 400,
        lineHeight: '20px',
        marginBottom: 18,
        textAlign: 'center',

        '&:last-child': {
            marginBottom: 20,
        }
    },

    table: {
        borderCollapse: 'collapse',
        textAlign: 'left',
        '& td': {
            padding: '12px 12px 12px 0px',
            color: '#1d1d1d',
            borderTop: '1px solid #DFE3E9',
            fontSize: 15,
            verticalAlign: 'top',
        },
        '& ul': {
            padding: '0 0 0 2ch',
            margin: 0,
        },
        '& th': {
            padding: '12px 12px 12px 0px',
            color: '#1d1d1d',
            fontWeight: 500,
            fontSize: 15,
            lineHeight: '20px',
            verticalAlign: 'top',
        },
    }
});

const useStyles = makeStyles(styles);

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

function MyDialog({open, store, type, onclose, isStore}) {
    const classes = useStyles();

    return (
        <Dialog open={open} onClose={onclose} fullWidth={true} classes={{
            paper: classes.paper,
        }}>
            <DialogTitle onClose={onclose} className={classes.h}>
                Сравнение тарифов
            </DialogTitle>

            <DialogContent className={classes.dialogContent}>
                <table className={classes.table}>
                    <tr>
                        <th>Возможности</th>
                        <th>"Базовый"</th>
                        <th>"PRO"</th>
                    </tr>
                    <tr>
                        <td>Дашборд</td>
                        <td><ul>
                            <li>Сводная информация о техническом состоянии устройств</li>
                                <li>Данные по проливам и простоям по сети или магазину</li>
                            <li>Данные за сутки</li></ul></td>
                        <td>
                            <ul>
                            <li>Всё, что входит в “Базовый”</li>
                            <li>Динамика по проливам и простоям по часам и дням</li>
                                <li>Данные за любой период</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>Мониторинг и настройка
                            оборудования</td>
                        <td colSpan={2}><ul><li>Мониторить состояние магазинов и оборудования</li>
                            <li>Проводить настройки и изменения на оборудовании в режиме онлайн</li>
                        </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>Журнал событий</td>
                        <td><ul>
                            <li>Информация о событиях с устройством и совершенных действиях сотрудника с устройством</li>
                            <li>Вы узнаете когда была сделана мойка крана, замены кег и устранены критические ситуации</li>
                        </ul>
                        </td>
                        <td><ul><li>Всё, что входит в “Базовый”</li>
                            <li>Данные за любой период</li></ul>
                        </td>
                    </tr>
                    <tr>
                        <td>Отчеты</td>
                        <td><ul>
                            <li>Формировать отчёты по наливам и простою устройств в рамках города, торговой сети, магазина и напитка</li>
                            <li>Только за сутки, без возможности выбора фильтров</li>
                        </ul>
                        </td>
                        <td><ul>
                            <li>Наглядные графики и вывод динамики по прошлому периоду помогут в принятии решений и понимание текущей ситуации</li>
                            <li>Сохраняйте шаблоны отчётов для быстрого формирования</li>
                            <li>Скачивайте отчёты в эксель</li>
                            <li>Добавляйте в фильтры свои поля для вывода данных</li>
                            <li>Формирование за любой период времени</li></ul></td>
                    </tr>
                    <tr>
                        <td>Конструктор этикеток</td>
                        <td>
                            <ul><li>Этикетка содержит необходимые поля по законодательству</li>
                                <li>Без возможности редактировать этикетку</li>
                            </ul>
                        </td>
                        <td>
                            <ul><li>Добавляйте на этикетку свой логотип и другие поля</li>
                                <li>Возможность создавать этикетки под каждую торговую сеть</li>
                            </ul></td>
                    </tr>
                    <tr>
                        <td>Push-уведомления</td>
                        <td><ul><li>Недоступно</li></ul></td>
                        <td><ul><li>Узнавайте и решайте ситуации о критический ситуациях с устройств получив push-уведомления на смартфоны</li></ul></td>
                    </tr>
                </table>
            </DialogContent>
        </Dialog>
    );
}

export default MyDialog;
