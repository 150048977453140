import React from 'react';

function InfoIcon() {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.9999 7.61536C10.6652 7.61536 10.338 7.51611 10.0597 7.33015C9.78143 7.1442 9.56452 6.8799 9.43644 6.57067C9.30835 6.26144 9.27484 5.92117 9.34014 5.5929C9.40543 5.26462 9.56661 4.96308 9.80328 4.72641C10.04 4.48974 10.3415 4.32856 10.6698 4.26326C10.998 4.19796 11.3383 4.23148 11.6475 4.35956C11.9568 4.48765 12.2211 4.70456 12.407 4.98286C12.593 5.26115 12.6922 5.58835 12.6922 5.92305C12.6922 6.37188 12.5139 6.80232 12.1966 7.11969C11.8792 7.43706 11.4488 7.61536 10.9999 7.61536Z" fill="currentColor"/>
            <path d="M11 22C8.82441 22 6.69767 21.3549 4.88873 20.1462C3.07979 18.9375 1.66989 17.2195 0.83733 15.2095C0.00476611 13.1995 -0.213071 10.9878 0.211367 8.85401C0.635804 6.72022 1.68345 4.76021 3.22183 3.22183C4.76021 1.68345 6.72022 0.635804 8.85401 0.211367C10.9878 -0.213071 13.1995 0.00476611 15.2095 0.83733C17.2195 1.66989 18.9375 3.07979 20.1462 4.88873C21.3549 6.69767 22 8.82441 22 11C22 13.9174 20.8411 16.7153 18.7782 18.7782C16.7153 20.8411 13.9174 22 11 22ZM11 1.69231C9.15912 1.69231 7.35957 2.2382 5.82893 3.26094C4.29828 4.28368 3.10529 5.73735 2.40082 7.4381C1.69634 9.13886 1.51202 11.0103 1.87116 12.8158C2.2303 14.6214 3.11677 16.2798 4.41847 17.5815C5.72018 18.8832 7.37865 19.7697 9.18416 20.1288C10.9897 20.488 12.8611 20.3037 14.5619 19.5992C16.2627 18.8947 17.7163 17.7017 18.7391 16.1711C19.7618 14.6404 20.3077 12.8409 20.3077 11C20.3077 8.53145 19.3271 6.164 17.5815 4.41847C15.836 2.67294 13.4686 1.69231 11 1.69231Z" fill="currentColor"/>
            <path d="M10.9999 17.7692C10.5511 17.7692 10.1207 17.5909 9.80328 17.2735C9.48591 16.9562 9.30762 16.5257 9.30762 16.0769V11C9.30762 10.5512 9.48591 10.1207 9.80328 9.80334C10.1207 9.48597 10.5511 9.30768 10.9999 9.30768C11.4488 9.30768 11.8792 9.48597 12.1966 9.80334C12.5139 10.1207 12.6922 10.5512 12.6922 11V16.0769C12.6922 16.5257 12.5139 16.9562 12.1966 17.2735C11.8792 17.5909 11.4488 17.7692 10.9999 17.7692Z" fill="currentColor"/>
        </svg>
    );
}

export default InfoIcon;