import React from 'react';
import {Tab, Tabs} from '@material-ui/core';
import classNames from 'classnames';
import {t} from '../../../i18n/utils.js';

function TabsFilter({className, devices, value, onChange, ...props}) {
    //console.log(devices);

    const types = {
        all: 0,
        temperature: 0,
        tap: 0,
        printer: 0,
        manager: 0,
    };

    for (const d of devices) {
        const dInfo = d[1];
        const t = dInfo.type;


        if (types !== 'all' && types.hasOwnProperty(t)) {
            types[t] += 1;
            types.all += 1;
        }
    }

    return (
        <Tabs
            value={value}
            indicatorColor="primary"
            textColor="primary"
            onChange={(ev, v) => {
                onChange(v);
            }}
            className={classNames(className)}
            variant="scrollable"
            scrollButtons="auto"
        >
            <Tab label={`${t('tabs_ALLDEVICES')} (${types.all})`} />
            <Tab label={`${t('tabs_TAPS')} (${types.tap})`}/>
            <Tab label={`${t('tabs_HUBS')} (${types.manager})`} />
            <Tab label={`${t('tabs_PRINTERS')} (${types.printer})`} />
            <Tab label={`${t('tabs_FREEZER')} (${types.temperature})`} />
        </Tabs>
    );
}

export default TabsFilter;
