import React, {useContext} from 'react';
import {Typography} from '@material-ui/core';
import {ReactComponent as DotStatus} from '../../../svg/dot-status.svg';
import {ReactComponent as TapIcon} from '../../../svg/tap.svg';
import {makeStyles} from '@material-ui/core/styles';
import {STATE_ERROR, STATE_OK, STATE_WARN} from '../../../app/constants';
import NotificationsCounter from '../../../componets/Notifications/NotificationsCounter';
import Immutable from 'immutable';
import {formatDate} from '../../../app/utils';
import {t} from '../../../i18n/utils.js';
import {LocaleContext} from '../../../i18n/LocaleContext.js';

const styles = theme => ({
    header: {
        display: "inline-flex",
        width: "100%",
        boxSizing: "border-box",
        paddingRight: 13,
        flexDirection: "column",
    },
    title: {
        marginTop: 6,
        flexGrow: 1,
        flexShrink: 1,
        height: 28,
        lineHeight: "28px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        color: params => !params.isActive ? theme.palette.disabled.main : "inherit",
    },
    iconWrap: {
        width: 32,
        height: 32,
        flexGrow: 0,
        flexShrink: 0,
        overflow: "hidden",
        marginRight: 6,
        marginTop: 3,
    },
    icon: {
        color: params => !params.isActive ? theme.palette.disabled.main : "inherit",
    },
    statusWrap: {
        width: 20,
        height: 20,
        flexGrow: 0,
        flexShrink: 0,
        overflow: "hidden",
        marginTop: 9,
    },
    status: {
        color: params => params.state === STATE_ERROR ? theme.palette.red :
            params.state === STATE_WARN ? theme.palette.orange :
                params.state === STATE_OK && params.isActive ? theme.palette.green :
                    theme.palette.disabled.main,
    },
    dateWashed: {
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: 13,
        lineHeight: "16px",
        flexGrow: 0,
        flexShrink: 0,
        marginTop: 16,
        marginLeft: theme.spacing(1),
        height: 30,
        alignItems: "center",
        display: "flex",
    },
    headRow: {
        display: "flex",
    }
});

const useStyles = makeStyles(styles);

function TapHeader2(props) {
    const {title, isActive, notifications, component, variant, dateWashed, id, allNotifs} = props;

    const mostBadStatus = !notifications ? STATE_OK : notifications.reduce((acc, cur) => {
        const curType = cur.get("type");
        const curStatus = curType === 'Warn' ? STATE_WARN : STATE_ERROR;

        return curStatus > acc ? curStatus : acc;
    }, STATE_OK);

    const classes = useStyles({
        isActive,
        state: mostBadStatus,
    });

    const localeObj = useContext(LocaleContext);
    const dateWashedFormatted = formatDate(dateWashed, localeObj.dateFormat);

    const moduleId = id;
    const tapAnalyticalNotifs = notifications ? notifications.filter(n => n.getIn(['module', 'id']) === moduleId) : Immutable.List();
    const tapSynteticalNotifs = allNotifs?.map(n => {
        const blockedByN = n.get('blocked_modules');

        const thisBlockedByN = blockedByN?.find(bln => bln.get('id') === moduleId);

        if (thisBlockedByN) {
            return n.set('blocked_modules', Immutable.List([thisBlockedByN]));
        } else {
            return null;
        }
    }).filter(n => !!n);

    const tapNotifications = tapAnalyticalNotifs.merge(tapSynteticalNotifs);

    return (
        <header className={classes.header}>
            <div className={classes.headRow}>
                <div className={classes.statusWrap}>
                    <DotStatus className={classes.status}/>
                </div>
                <div className={classes.iconWrap}>
                    <TapIcon className={classes.icon}/>
                </div>
                <Typography variant={ variant || 'h2'} component={component} className={classes.title}>{title}</Typography>
            </div>
            { notifications.size === 0 &&
                <div className={classes.dateWashed}>
                    {t('lastWashed')}<br/>
                    {dateWashedFormatted}
                </div>
            }
            { tapNotifications.size> 0 &&
            <div className={classes.dateWashed}>
                <NotificationsCounter notifs={tapNotifications} />
            </div>
            }
        </header>
    );
}

export default TapHeader2;
