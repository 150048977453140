import {enUS, ru} from 'date-fns/locale';

export const locales = [
    {
        code: "ru",
        name: "Russian",
        dateFnsLocale: ru,
        dateFormat: 'DD.MM.YYYY',
        dateTimeFormat: 'DD.MM.YYYY HH:mm',
        dateTimeFormatSeconds: 'DD.MM.YYYY HH:mm:ss',
        dateFormatForPicker: 'dd.MM.yyyy',
        timeFormat: 'HH:mm',
        timeAmPm: false,
    },
    {
        code: "en",
        name: "English",
        dateFnsLocale: enUS,
        dateFormat: 'MM/DD/YYYY',
        dateTimeFormat: 'MM/DD/YYYY hh:mm A',
        dateTimeFormatSeconds: 'MM/DD/YYYY hh:mm:ss A',
        dateFormatForPicker: 'MM/dd/yyyy',
        timeFormat: 'hh:mm A',
        timeAmPm: true,
    }
];

export const defaultLocale = "en";
