import React, {useEffect, useRef, useState} from 'react';
import Select from '../Dashboard/Select.js';
import {useDispatch, useSelector} from 'react-redux';
import {citiesFetchList, getCitiesList} from '../../redux/reducers/cities.js';
import {chainsFetchList, getChainsList} from '../../redux/reducers/chains.js';
import {getWithToken} from '../../app/rest.js';
import {APIPREFIX} from './Metrics.js';
import {tokensGetBearerToken} from '../../redux/reducers/tokens.js';

const StoresFilter = ({
    selCities, selChains,
                         selStores, setSelStores,
    ...props
}) => {

    const [stores, setStores] = useState([]);
    const [loading, setLoading] = useState(true);

    const [search, setSearch] = useState('');

    const token = useSelector(tokensGetBearerToken);

    async function fetchStores() {
        setLoading(true);

        let query = `?limit=100`;
        const cityIds = selCities.map(c => c.id);
        let t;
        t = cityIds?.map(c => {
            query += `&city_ids=${c}`;
        });
        const chainIds = selChains.map(c => c.id);
        t = chainIds?.map(c => {
            query += `&chain_ids=${c}`;
        });

        if(search) {
            query += `&search=${search}`;
        }

        const resp = await getWithToken(APIPREFIX + '/stores/full' + query, token, 'Bearer');

        setStores(resp.items);

        setLoading(false);
    }

    useEffect(() => {
        console.log('STORES FILTER', selCities, selCities, selStores);

        fetchStores();
    }, [selCities, selChains, search])

    return (
        <Select
            options={stores}
            placeholder="Все магазины"
            value={selStores}
            onChange={(e, v, reason)=>{
                //console.log('VALUE', v);
                setSelStores(v);
            }}
            multiple
            loading={loading}
            getOptionLabel={option => option?.address || ''}
            tagLabelI18n="stores"
            onInputChange={(e, v, r) => {
                console.log(v);
                setSearch(v);
            }}
        />
    );
}

export default StoresFilter;
