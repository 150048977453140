import {fromJS, Map} from 'immutable';
import {getWithToken} from '../../app/rest';
import {tokensGetBearerToken} from './tokens';
import {check401} from '../../app/utils.js';

const initialState = new Map({
    list: null,
    isFetching: false,
    isError: false,
});

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'CITIES_FETCH_REQUEST':
            return state.set('isFetching', true)
                .set('isError', false);
        case 'CITIES_FETCH_SUCCESS':
            return state.set('isFetching', false)
                .set('list', fromJS(action.list));
        case 'CITIES_FETCH_ERROR':
            return state.set('isFetching', false)
                .set('isError', true);
        default:
            return state;
    }
};
export default reducer;

export const getCitiesList = (state) => state.getIn(['cities', 'list']);
export const getCitiesListState = (state) => state.getIn(['cities', 'isFetching']);

export const citiesFetchList = () => async (dispatch, getState) => {
    dispatch({type: 'CITIES_FETCH_REQUEST'});

    const state = getState();
    const token = tokensGetBearerToken(state);

    try {
        const resp = await getWithToken(`/v2/api/cities?limit=9999`, token, 'Bearer');

        const list = resp.items;

        dispatch({type: 'CITIES_FETCH_SUCCESS', list: list});
    } catch (err) {
        check401(err, dispatch)

        dispatch({type: 'CITIES_FETCH_ERROR'});
    }
}
