import React, {useContext, useEffect, useState} from 'react';
import {Button, Paper, Typography} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';
import {getWithToken, postWithToken} from '../../../app/rest';
import {useSelector} from 'react-redux';
import {tokensGetBearerToken} from '../../../redux/reducers/tokens';
import {t} from '../../../i18n/utils.js';
import {LocaleContext} from '../../../i18n/LocaleContext.js';
import PromptDialog from './PromptDialog.js';
import ConfirmDialog from './ConfirmDialog.js';

function BackupGroup({device, isDisconnected, ...props}) {
    const classes = useStyles();

    const deviceId = device.get('id');
    const gname = device.get('device_number');
    const token = useSelector(tokensGetBearerToken);
    const localeObj = useContext(LocaleContext);

    const [propmtSaveDialog, setPromptSaveDialog] = useState(false);
    const [propmtRestoreDialog, setPromptRestoreDialog] = useState(false);

    const [confirmDialog, setConfirmDialog] = useState('');

    const [hasSavedSettings, setHasSavedSettings] = useState(false);

    async function checkSaved() {
        const list = await getWithToken(`/v2/api/devices/${deviceId}/settings?group_name=${gname}`, token, 'Bearer');
        if (list?.length > 0) {
            setHasSavedSettings(true);
        }
    }

    useEffect(()=>{
        checkSaved();
    }, []);

    return (
        <Paper className={classes.group}>
            <div className={classes.groupHeader}>
                <div className={classes.groupTitle}>
                    {t('fetchParams')}
                </div>
            </div>
            <div className={classes.items}>
                <div className={classNames([classes.item])}>
                    <div className={classes.itemTitle}>
                        <Typography className={classes.itemText}>
                            {t('fetchParamsText')}
                        </Typography>


                        <Button variant="outlined" color="primary" className={classes.btn}
                                onClick={()=>{
                                    getWithToken('/v2/api/groups/get_values?group_id='+ deviceId, token, 'Bearer').then(
                                        r => {
                                            console.log(r);

                                            if (r === 'Ok') {
                                                setConfirmDialog(t('fetchOk1'));
                                            } else {
                                                setConfirmDialog('fetchErr1');
                                            }
                                        }
                                    )
                                }}
                        >{t('fetchParamsBtn')}</Button>
                    </div>
                </div>

            </div>

            <div className={classes.sep} />

            <div className={classes.groupHeader}>
                <div className={classes.groupTitle}>
                    {t('saveParams')}
                </div>
            </div>
            <div className={classes.items}>
                <div className={classNames([classes.item])}>
                    <div className={classes.itemTitle}>
                        <Typography className={classes.itemText}>
                            {t('saveParamsText')}
                        </Typography>


                        <Button variant="outlined" color="primary" className={classes.btn}
                            onClick={()=>{
                                setPromptSaveDialog(true);
                            }}
                        >{t('saveParamsBtn')}</Button>
                    </div>
                </div>

            </div>

            <div className={classes.sep} />

            <div className={classes.groupHeader}>
                <div className={classes.groupTitle}>
                    {t('restoreParams')}
                </div>
            </div>
            <div className={classes.items}>
                <div className={classNames([classes.item])}>
                    <div className={classes.itemTitle}>
                        <Typography className={classes.itemText}>
                            {t('restoreParamsText')}
                        </Typography>

                        <Button variant="outlined" color="primary"
                                className={classes.btn}
                                onClick={()=>{
                                    setPromptRestoreDialog(true);
                                }}
                                disabled={!hasSavedSettings}
                        >{t('restoreParamsBtn')}</Button>
                    </div>
                </div>

            </div>

            {propmtSaveDialog &&
            <PromptDialog open={propmtSaveDialog} text={t('savePromptText')} btnText={t('saveParamsBtn')}
                          onClose={() => {
                              setPromptSaveDialog(false);
                          }} succText={t('successSaveText')} errText={t('errorSaveText')} apiCall={async () => {
                try {
                    const json = await postWithToken(`/v2/api/devices/save_settings?group_id=${deviceId}&group_name=${gname}`, {}, 'POST', token, 'Bearer');

                    if (json.status && json.status === 'All settings saved') {
                        checkSaved();
                        return 'ok';
                    } else {
                        // noinspection ExceptionCaughtLocallyJS
                        throw json;
                    }
                } catch (err) {
                    console.error(err);
                    throw err;
                }
            }}
            />
            }

            {propmtRestoreDialog &&
            <PromptDialog open={propmtRestoreDialog} text={t('restorePromptText')} btnText={t('restoreParamsBtn')}
                          onClose={() => {
                              setPromptRestoreDialog(false);
                          }} succText={t('successRestoreText')} errText={t('errorRestoreText')}
                          apiCall={async () => {
                              try {
                                  const json = await postWithToken(`/v2/api/devices/restore_settings?group_id=${deviceId}&group_name=${gname}`, {}, 'POST', token, 'Bearer');

                                  if (json.status && json.status === 'All settings restored') {
                                      return 'ok';
                                  } else {
                                      // noinspection ExceptionCaughtLocallyJS
                                      throw json;
                                  }
                              } catch (err) {
                                  throw err;
                              }
                          }}
            />
            }

            {confirmDialog !== '' &&
            (
                <ConfirmDialog open={true} text={confirmDialog} onClose={()=>{
                    setConfirmDialog('');
                }}/>
            )
            }
        </Paper>
    );
}

export default React.memo(BackupGroup);


const useStyles = makeStyles(theme => ({
    itemText: {
      fontSize: 14,
      lineHeight: "16px",
        marginBottom: 16,
    },
    sep: {
      marginBottom: 26,
    },
    root: {
        margin: 8,
        padding: 25,
    },
    section: {
        padding: 4,
        //marginLeft: -4,
    },
    sectionTitle: {
        fontSize: '21px',
        marginBottom: 16,
    },
    groups: {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
    },
    group: {
        display: "inline-block",
        //background: "#6a9d80",
        padding: 16,
        //color: "#fff",
        borderRadius: 8,
        maxWidth: 350,
        minWidth: 270,
        width: "100vw",
    },
    header: {
        display: "flex",
        alignItems: "center",
        gap: "16px",
    },
    groupLink: {
        textDecoration: 'underline',
        cursor: "pointer",
        padding: "4px",

        "&:hover": {
            color: "#fff",
            background: 'rgba(0,0,0,.26)!important',
            borderRadius: "8px",
        },
    },
    groupTitle: {

    },
    groupHeader: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        fontSize: '16px',
        marginBottom: '1em',
    },
    items: {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px 0',
    },
    indic: {
        //boxShadow: '0px 0px 6px 1px #0c7715',
        borderRadius: '100%',
    },
    item: {
        display: 'flex',
        alignItems: 'center',

        "&.no-value": {
            opacity: 0.5,
        }
    },
    itemTitle: {
        flex: 1,
    },
    itemValue: {
        flex: 1,
        textAlign: 'center',
    },
    btn: {
        minWidth: 128,
    },
    itemEditable: {
        border: 0,
        background: "transparent",
        color: "#646464",
        cursor: "pointer",
        minHeight: '1em',
        textDecoration: 'underline',

        "&:not(.disabled):hover": {
            color: "#fff",
            background: 'rgba(0,0,0,.26)!important',
            borderRadius: "8px",
        },

        "&.disabled": {
            cursor: "default",
        }
    },
}));

