import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import SectionSwitch from './SectionSwitch';
import {useDispatch} from 'react-redux';

function SectionsListWidget({sections, activeSections, setActiveSections, ...props}) {
    const classes = useStyles();

    const dispatch = useDispatch();

    return (
        <div className={classes.sectionsSelects}>
            {sections.map((sec, index) => {
                const isActive = activeSections.includes(sec.id);

                return (
                    <SectionSwitch key={index} title={sec.name} active={isActive} onClick={(event)=>{

                        console.log(sections, activeSections, isActive);

                        if(isActive) {
                            // unset
                            const newArr = activeSections.filter(secId => {
                                return secId !== sec.id
                            });
                            setActiveSections(newArr);
                        } else {
                            // set
                            const newArr = [...activeSections];
                            newArr.push(sec.id);
                            setActiveSections(newArr);

                            //dispatch(sectionDetailsFetch(sec.id));
                        }
                    }} />
                );
            })}
        </div>
    );
}

export default SectionsListWidget;

const useStyles = makeStyles(theme => ({
    sectionsSelects: {
        marginTop: "1em",
        display: 'flex',
        gap: '8px 4px',
        marginBottom: '1em',
        flexWrap: 'wrap',
    },
}));
