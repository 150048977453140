import {fromJS, Map} from 'immutable';
import {getWithToken} from '../../app/rest';
import {tokensGetAccessToken, tokensGetBearerToken} from './tokens';
import {apiChangeItem} from '../../app/api';
import {getLoadedLanguage as getSavedLocale} from './locale';
import {check401} from '../../app/utils.js';

const initialState = new Map({
    active: [],
    list: null,
    id: null,
    isFetching: false,
    isError: false,
    index: null,
});

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SECTIONS_FETCH_REQUEST':
            return state.set('isFetching', true)
                .set('isError', false);
        case 'SECTIONS_FETCH_SUCCESS':
            return state.set('isFetching', false)
                .set('list', fromJS(action.list))
                .set('id', fromJS(action.id));
        case 'SECTIONS_FETCH_ERROR':
            return state.set('isFetching', false)
                .set('isError', true);
        case 'SECTIONS_DETAIL_FETCH_REQUEST': {
            const index = getSecIndex(state, action.sectionId)

            return state.setIn(['list', index, 'isFetching'], true);
        }
        case 'SECTIONS_DETAIL_FETCH_ERROR': {
            const index = getSecIndex(state, action.sectionId)

            return state.setIn(['list', index, 'isFetching'], false);
        }
        case 'SECTIONS_DETAIL_FETCH_SUCCESS': {
            const index = getSecIndex(state, action.sectionId)

            const hmap = {};

            const grpIndex = 0;
            action.payload.items.map( (item, itemIndex) => hmap[item.key] = ['list', index, 'data', 'items', itemIndex]);
            action.payload.params.map( (param, paramIndex) => hmap[param.key] = ['list', index, 'data', 'params', paramIndex] );


            const oldHmap = state.get('index')?.toJS();
            const newHmap = {...oldHmap, ...hmap};

            //console.log(newHmap);

            return state.setIn(['list', index, 'isFetching'], false)
                .setIn(['list', index, 'data'], fromJS(action.payload))
                .set('index', fromJS(newHmap));
        }
        case 'SECTIONS_DETAIL_SET': {
            const key = action.key;
            const val = action.val;

            const keyPath = state.getIn(['index', key])?.toJS();

            if (keyPath) {
                //console.log('WS CHANGE', key, keyPath, val);
                return state.setIn([...keyPath, 'value'], val);
            } else {
                //console.error('WS_CHANGE', key, 'UNKNOWN', val);
                return state;
            }
        }
        case 'SET_ACTIVE_SECTIONS': {

            localStorage.setItem('activeSections', JSON.stringify(action.val));
            return state.setIn(['active'], fromJS(action.val));
            //return state;
        }
        default:
            return state;
    }
};
export default reducer;

export const wsChange = (key, val) => async (dispatch, getState) => {
    //const state = getState();
    dispatch({type: 'SECTIONS_DETAIL_SET', key, val});
}


const getSecIndex = (subtree, sectionId) => {
    const index = subtree.get('list').findIndex(el => {
        return el.get('id') === sectionId
    });

    return index;
}

export const getActiveSections = (state) => fromJS(JSON.parse(localStorage.getItem('activeSections')));

export const getSectionsList = (state) => state.getIn(['sections', 'list']);

export const sectionDetailsFetch = (groupId, sectionId) => async (dispatch, getState) => {
    const state = getState();

    const index = getSecIndex(state.get('sections'), sectionId);

    if (state.getIn(['sections', 'list', index, 'isFetching'])) {
        return;
    }
    dispatch({type: 'SECTIONS_DETAIL_FETCH_REQUEST', sectionId});

    const token = tokensGetAccessToken(state);
    const bearerToken = tokensGetBearerToken(state);
    const lang = getSavedLocale(state);

    const deviceId = state.getIn(['sections', 'id']);
    //   api/sections/1/?device=58
    try {
        const resp = await getWithToken(`/v2/api/groups/${groupId}/elements/${sectionId}?lang=${lang}`, bearerToken, 'Bearer');

        const payload = resp;

        dispatch({type: 'SECTIONS_DETAIL_FETCH_SUCCESS', payload, sectionId});
    } catch (err) {
        check401(err, dispatch)

        console.error('SECTIONS_DETAIL_FETCH_ERROR', err);
        dispatch({type: 'SECTIONS_DETAIL_FETCH_ERROR'});
    }
}

export const sectionsFetchList = (storeId, groupId) => async (dispatch, getState) => {
    const state = getState();

    if (state.getIn(['sections', 'isFetching'])) {
        return;
    }
    dispatch({type: 'SECTIONS_FETCH_REQUEST'});

    const token = tokensGetAccessToken(state);
    const bearerToken = tokensGetBearerToken(state);
    const lang = getSavedLocale(state);

    try {
        const resp = await getWithToken(`/v2/api/groups/${groupId}/sections?lang=${lang}`, bearerToken, 'Bearer');

        console.log(resp);
        const list = resp;

        console.log(list);

        dispatch({type: 'SECTIONS_FETCH_SUCCESS', list: list, id: groupId});
    } catch (err) {
        check401(err, dispatch)

        dispatch({type: 'SECTIONS_FETCH_ERROR'});
    }
}

export const changeItemValue = (itemId, value, key, secId) => async (dispatch, getState) => {
    const state = getState();
    const tokenv1 = tokensGetAccessToken(state);
    const token = tokensGetBearerToken(state);

    const deviceId = state.getIn(['sections', 'id']);

    //console.log('CHANGE ITEM VALUE', deviceId, itemId, value);
    apiChangeItem(token, tokenv1, secId, itemId, value).then(r => {
        console.log('RESP', r);

        if (r === "Ok") {
            dispatch(wsChange(key, value));
            dispatch(wsChange(key, value));
        }
    });
}
