import React from 'react';
import {makeStyles} from '@material-ui/styles';

function StatusIndicator({className, ...props}) {
    const classes = useStyles(props);
    return (
        <svg {...props} viewBox="0 0 80 80" className={[classes.root, className].join(' ')} version="1.1" xmlns="http://www.w3.org/2000/svg">
            <circle cx="40" cy="40" r="30" />
        </svg>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        width: 8,
        height: 8,
        fill: props => props.lvl === 3 ? theme.palette.red :
                       props.lvl === 2 ? theme.palette.orange :
                       props.lvl === 1 ? theme.palette.green :
                           props.lvl === 0 ? theme.palette.green :
                           theme.palette.disabled.main,
        flexShrink: 0,

        ".disconnected & ": {
            fill: "#c1c1c1" + " !important",
        },
    },
}));

export default StatusIndicator;
