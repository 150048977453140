import React, {useEffect, useState} from 'react';
import PageLayout from '../../componets/PageLayout/PageLayout.js';
import {Button, Checkbox, FormControlLabel, Paper, TextField, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import classNames from 'classnames';
import {useSelector} from 'react-redux';
import {tokensGetBearerToken} from '../../redux/reducers/tokens.js';
import {getWithToken, postWithToken} from '../../app/rest.js';
import {useHistory, useLocation} from 'react-router-dom';
import BackButton from '../TapPage/BackButton.js';
import Dropdown from '../DeviceList/search/Dropdown.js';
import Autocomplete from '@material-ui/lab/Autocomplete';
import throttle from 'lodash.throttle';
import MyDialog1 from './MyDialog1.js';
import MyDialogplans from './MyDialogplans.js';
import {setTitle} from '../Dashboard/Dashboard.js';
import {t} from '../../i18n/utils.js';
import {ThemeProvider} from '@emotion/react';

const styles = () => ({
    h1: {
        //styleName: Text ∙ 23|32 / Regular;
        fontSize: '23px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '32px',
        letterSpacing: '0em',
        textAlign: 'left',
        marginBottom: 16,
        padding: 0,
        color: '#1D1D1D',
        marginTop: 12,
    },
    h2: {
        //styleName: Text ∙ 23|32 / Regular;
        fontSize: '17px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '20px',
        letterSpacing: '0em',
        textAlign: 'left',
        marginBottom: 12,
        padding: 0,
        color: '#1D1D1D',
    },
    p: {
        //styleName: Text ∙ 15|20 / Regular;
        fontSize: '15px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '20px',
        letterSpacing: '0em',
        textAlign: 'left',
        color: '#1D1D1D',
        width: 844,
    },
    paper1: {
        boxShadow: '0px 3px 12px rgba(12, 37, 86, 0.06)',
        padding: 20,
    },
    numInput: {
        boxSizing: 'border-box',
        width: 80,
        height: 36,
    },
    inputRow: {
        display: 'flex',
        color: '#666',
        fontSize: 13,
        lineHeight: '16px',
        alignItems: 'flex-start',
        marginBottom: 24,
    },
    smallText: {
        color: '#666',
        fontSize: 13,
        lineHeight: '16px',
    },
    spacing: {
        marginLeft: 16,
    },
    vertSpacing: {
        marginBottom: 8,
    },
    diffTable: {
        width: '100%',
        textAlign: 'left',
        color: '#1D1D1D',
        fontSize: 15,
        lineHeight: '20px',

        '& th': {
            fontWeight: 500,
            padding: '12px 0',
            width: '33%',
        },

        '& td': {
            borderTop: '1px solid #E9ECF0',
            padding: '12px 0',
            verticalAlign: 'bottom',
        }
    },
    bigText: {
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 17,
        lineHeight: '20px',
        /* Main / MainText */
        color: '#1D1D1D',
    },
    layout: {
        display: 'flex',
        marginTop: 32,
        alignItems: 'flex-start',
    },
    paper2: {
        marginLeft: 32,
        width: 400,
        minWidth: 400,
        padding: 20,
        boxShadow: '0px 3px 12px rgba(12, 37, 86, 0.06)',
    },
    bold: {
        fontWeight: 500,
    },
    switch: {
        display: 'inline-flex',
        boxShadow: '0px 3px 12px rgba(12, 37, 86, 0.06)',
        marginLeft: 24,
    },
    switchBtn: {
        cursor: 'pointer',
        border: '1px solid #DFE3E9',
        borderRadius: 4,
        color: '#666666',
        padding: '8px 16px',
        fontSize: 15,

        "&:first-child": {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            borderRight: 0,
        },

        "&:last-child": {
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderLeft: 0,
        },

        '&.active': {
            border: '1px solid #007AFF',
            color: '#1d1d1d',
            background: 'rgba(0, 122, 255, 0.1)',
        }
    },
    inputRow2: {
        display: 'flex',
        color: '#666',
        fontSize: 13,
        lineHeight: '16px',
        alignItems: 'baseline',
        marginBottom: 16,
    },
    line: {
        borderBottom: '1px solid #DFE3E9',
        padding: '8px 0px',
        marginBottom: 14,
    },
    totalTable: {
        width: '100%',

        '& td': {
            fontSize: 15,
            lineHeight: '20px',
            color: '#1D1D1D',
            paddingBottom: 16,

            '&:last-child': {
                textAlign: 'right',
            }
        }
    },
    grandTotal: {
        fontSize: 23,
        fontWeight: 500,
        color: '#1d1d1d',
        marginBottom: 16,
    },
    buyBtn: {
        width: '100%',
        height: 44,
        background: '#007AFF',
        borderRadius: 6,
        fontSize: 15,
        boxShadow: 'none',
    },
    col: {
      display: 'flex',
      flexDirection: 'column',
        flex: 1,
        gap: '12px',
    },
    step2inouts: {
        display: 'flex',
        gap: '24px',
        marginTop: 32,
    },
    back: {
      width: 32,
      height: 32,
        marginRight: 16,
    },
    checkboxlabel: {
        fontSize: 13,
    },
    link: {
        textDecoration: 'underline',
        color: 'rgb(0, 122, 255)',
        cursor: 'pointer',
    },
    col1: {
        flex: 1,
        maxWidth: 884,
        minWidth: 550,
    }
});

const useStyles = makeStyles(styles);

const TariffPage = () => {
    const classes = useStyles();
    const token = useSelector(tokensGetBearerToken);
    const lang = 'ru';

    const companyId = useSelector(state => state.getIn(['user', 'info', 'company_id']));

    async function getCompanyInfo(companyId) {
        const resp = getWithToken(`/v2/api/companies/${companyId}`, token, 'Bearer');
        return resp;
    }

    function formatSumm(summ) {
        if (isNaN(summ)) {
            return '-';
        }

        const formattedValue = summ.toLocaleString(lang, {maximumFractionDigits: 2})
        const units = '₽';

        return formattedValue + ' ' + units
    }

    const loc = useLocation();
    const history = useHistory();
    const search = loc.search;
    const params = new URLSearchParams(search);
    let qPeriod = params.get('period');
    let defPeriod = 12;
    if (qPeriod === '3') {
        // 3 mo
        defPeriod = 3;
    }


    const [shName, setshName] = useState('');
    const [fullName, setfullName] = useState('');
    const [inn, setinn] = useState('');
    const [kpp, setkpp] = useState('');
    const [urAdd, seturAdd] = useState('');
    const [postAdd, setpostAdd] = useState('');
    const [email, setemail] = useState('');

    console.log('QPERIOD', qPeriod);

    const [period, setPeriod] = useState(defPeriod);
    const [hasSale, setHasSale] = useState(defPeriod === 12);

    const [options, setOptions] = useState([]);

    let qCount = params.get('count');
    let qCountNum = parseInt(qCount, 10);

    let defCountNum = 0;
    if (!isNaN(qCountNum) && qCountNum > 0) {
        defCountNum = qCountNum;
    }
    if (qCountNum > 10000) {
        defCountNum = 10000;
    }

    let qStep = params.get('step');
    let qStepNum = parseInt(qStep, 10);
    if (isNaN(qStepNum) ||  qStepNum > 2 || qStepNum < 1) {
        qStepNum = 1;
    }

    const [step, setStep] = useState(qStepNum);

    const [tapCount, setTapCount] = useState(defCountNum);

    const [hasSavedComp, setHasSavedComp] = useState(false);
    const [editComp, setEditComp] = useState(true);

    const [agree, setAgree] = useState(false);

    const [succOpen, setSuccOpen] = useState(false);
    const [plans, setPlans] = useState(false);

    const salePercent = 20;
    const oneTapPrice = 120;

    const oneTapPriceWithSale = hasSale ? oneTapPrice - oneTapPrice * salePercent / 100 : oneTapPrice;
    const fullPrice = oneTapPrice * period * tapCount;
    const saleRub = hasSale ? fullPrice / 100 * salePercent : 0;
    const totalPrice = fullPrice - saleRub;
    const vatRub = totalPrice / 100 * 20; // 20% VAT


    useEffect(() => {
        // disable zoom
        const meta = document.getElementsByName('viewport')[0];
        if (meta) {
            meta.setAttribute('content', 'width=1024');
        }

        // get company info
        if (companyId) {
            console.log('COMPANY ID: ', companyId);

            getCompanyInfo(companyId).then(company => {
                console.log('COMPANY: ', company);
                if (defCountNum === 0) {
                    setTapCount(company?.taps_count);
                }

                setkpp(company?.kpp);
                setinn(company?.inn);
                setpostAdd(company?.postal_address);
                seturAdd(company?.legal_address);
                setshName(company?.name)
                setfullName(company?.full_name)
                setemail(company?.email);

                setHasSavedComp(true);
                setEditComp(false);
            });
        }
    }, []);

    function serializeState() {
        return `?count=${tapCount}&period=${period}&step=${step}`;
    }

    useEffect(() => {
        history.replace(loc.pathname + serializeState());
    }, [tapCount, period, step]);

    function dadata(query) {
        var url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/party";
        var token = "080f4617a3adce038e10aeb3ad21af8ef81f387d";

        var options = {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Token " + token
            },
            body: JSON.stringify({query: query})
        }

        fetch(url, options)
            .then(response => response.text())
            .then(result => {
                console.log('result', result);
                const arr = JSON.parse(result);
                const sugs = arr?.suggestions;
                setOptions(sugs);
            })
            .catch(error => console.log("error", error));
    }
    const th = throttle(dadata, 1000);


    async function doSubmit() {
        let r;
        try {
            const body = {
                "name": shName,
                "full_name": fullName || '-',
                "inn": inn,
                "kpp": kpp || '-',
                "legal_address": urAdd || '-',
                "postal_address": postAdd || '-',
                "email": email || '-',
            }
            r = await postWithToken(`/v2/api/companies/${companyId}`, body, 'PUT', token, 'Bearer');
        } catch (err) {
            alert('Произошла ошибка сохранения!')
        }

        setSuccOpen(true);

        try {
            const body2 = {
                "company_id": companyId,
                "name": shName,
                "full_name": fullName,
                "inn": inn,
                "kpp": kpp,
                "legal_address": urAdd,
                "postal_address": postAdd,
                "email": email,
                "taps_count": tapCount,
                "amount": totalPrice,
                "period": period
            };

            r = await postWithToken(`/v2/api/payments/send_order`, body2, 'POST', token, 'Bearer');


            if (r.status === 'Mail has been sent') {
                //
            } else {
                throw r;
            }
        } catch (err) {
            alert('Произошла ошибка отправки!')
        }
    }

    function validate(email) {
        if (!email) { return true;}

        if (email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )) {
            return true
        } else {
            return false;
        }
    }

    setTitle('Тарифы');

    return (
        <PageLayout noContainer={true}>
            <div style={{padding: '0 24px'}}>
                <Typography variant="h1" className={classes.h1}>Тарифы</Typography>
                <Typography className={classes.p}>
                    Вы получаете не просто систему мониторинга и дополнительные настройки, а полноценного помощника,<br />
                    способного сэкономить на услугах техника, оптимизировать поставки напитков и повысить продажи.<br />
                    Всего за {oneTapPrice} руб в месяц в расчете за 1 подключённый кран на тарифе “PRO”.
                </Typography>

                <div className={classes.layout}>
                    <div className={classes.col1}>
                        {step === 1 ?
                        <Paper className={classes.paper1}>
                            <Typography variant="h2" className={classes.h2}>Сколько у вас установлено кранов?</Typography>
                            <div className={classes.inputRow}>
                                <TextField variant="outlined" type="number" value={tapCount} InputProps={{
                                    classes: {
                                        input: classes.numInput,
                                    },
                                    inputProps: {
                                        min: 0,
                                        max: 10000,
                                    }
                                }} onChange={(e) => {
                                    let val = e.target.value;
                                    let num = parseInt(val, 10);

                                    if (num < 0) {
                                        num = 0;
                                    }

                                    if (num > 10000) {
                                        num = 10000;
                                    }


                                    setTapCount(num);
                                }}
                                />
                                <Typography variant="subtitle1" className={classNames(classes.smallText, classes.spacing)}>
                                    Количество высчитано автоматически.<br />
                                    Если хотите приобрести на другое - можете исправить
                                </Typography>
                            </div>
                            <div className={classes.inputRow2}>
                                <Typography variant="h2" className={classNames(classes.h2, classes.bold)}>Период</Typography>

                                <div className={classes.switch}>
                                    <div className={classNames(classes.switchBtn, {'active': period === 12})}
                                        onClick={()=> {
                                            setHasSale(true);
                                            setPeriod(12);
                                        }}
                                    >1 год ({salePercent}% скидка)</div>
                                    <div className={classNames(classes.switchBtn,{'active': period === 3})}
                                         onClick={()=> {
                                             setHasSale(false);
                                             setPeriod(3);
                                         }}
                                    >3 месяца</div>
                                </div>

                                <div style={{flex: 1}} />
                                <div style={{
                                    textDecoration: 'underline',
                                    color: '#007AFF',
                                    cursor: 'pointer',
                                    fontSize: 15,
                                }} onClick={()=>{setPlans(true)}}>Сравнение тарифов</div>

                            </div>

                            <table className={classes.diffTable}>
                                <tr>
                                    <th>Возможности</th>
                                    <th>"Базовый"</th>
                                    <th>"PRO"</th>
                                </tr>
                                <tr>
                                    <td>Дашборд</td>
                                    <td>За сутки</td>
                                    <td>За любой период</td>
                                </tr>
                                <tr>
                                    <td>Журнал событий</td>
                                    <td>За сутки</td>
                                    <td>За любой период</td>
                                </tr>
                                <tr>
                                    <td>Формирование отчетов</td>
                                    <td>За сутки</td>
                                    <td>За любой период, кастомные</td>
                                </tr>
                                <tr>
                                    <td>Конструктор этикеток</td>
                                    <td>Стандартный (ГОСТ)</td>
                                    <td>Расширенный</td>
                                </tr>
                                <tr>
                                    <td>Push-уведомления</td>
                                    <td>&nbsp;</td>
                                    <td>Есть</td>
                                </tr>
                                <tr>
                                    <td>Дайджест</td>
                                    <td>Только онлайн</td>
                                    <td>Онлайн, mail рассылка</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className={classes.bigText}>Стоимость</div>
                                    </td>
                                    <td>
                                        <div className={classes.bigText}>Бесплатно</div>
                                    </td>
                                    <td>
                                        <div className={classNames(classes.smallText, classes.vertSpacing)}>{isNaN(tapCount) ? '-' : tapCount} {t('tap', {count: tapCount}).toLowerCase()} по {formatSumm(oneTapPriceWithSale)}/месяц
                                            {hasSale ? <> (скидка {salePercent}%)</> : <></>} </div>
                                        <div className={classes.bigText}>{formatSumm(totalPrice)}</div>
                                    </td>
                                </tr>
                            </table>
                        </Paper>

                        :

                        <Paper className={classes.paper1}>
                            <div  style={{
                                fontSize: 15,
                                marginBottom: 24,
                                color: '#868A8F',
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                <BackButton className={classes.back} onClick={e => {
                                    setStep(1);
                                }} />

                                <div>Назад к выбору тарифа</div>
                            </div>
                            <div style={{
                                fontSize: 17,
                                marginBottom: 24,
                                fontWeight: 500,
                                color: '#1d1d1d',
                            }}>
                                Данные о компании
                            </div>
                            {editComp &&
                                <div>
                                    <Autocomplete
                                        options={options}
                                        getOptionLabel={(options) => options?.value || ''}
                                        filterOptions={options => options}
                                        disableClearable
                                        renderInput={params =>
                                            <TextField {...params} label={"Юр.название или ИНН"} variant="outlined"
                                                       onChange={(e) => {
                                                           const val = e.target.value;

                                                           th(val);
                                                       }
                                                       }
                                            />
                                        }
                                        renderOption={(option, {selected}) => (
                                            <div>
                                                <Typography>{option?.value}</Typography>
                                                <Typography variant={"subtitle1"}
                                                            className={classes.smallText}>ИНН:{option?.data?.inn}</Typography>
                                            </div>
                                        )}
                                        style={{maxWidth: 256}}
                                        onChange={(e, v) => {
                                            console.log(v);
                                            setkpp(v?.data?.kpp || '');
                                            setinn(v?.data?.inn || '');
                                            setpostAdd(v?.data?.address?.value || '');
                                            seturAdd(v?.data?.address?.value || '');
                                            setshName(v?.data?.name?.short_with_opf || '')
                                            setfullName(v?.data?.name?.full_with_opf || '')
                                        }}
                                    />
                                </div>
                            }
                            <div className={classes.step2inouts}>
                                <div className={classes.col}>
                                    <TextField variant="outlined" label="Краткое название" value={shName} onChange={(e) => {
                                        const val = e.target.value;
                                        setshName(val);
                                    }} disabled={!editComp} />
                                    <TextField variant="outlined" label="ИНН"  value={inn} onChange={(e) => {
                                        const val = e.target.value;
                                        setinn(val);
                                    }} disabled={!editComp} />
                                    <TextField variant="outlined" label="Юридический адрес"  value={urAdd} onChange={(e) => {
                                        const val = e.target.value;
                                        seturAdd(val);
                                    }} disabled={!editComp} />
                                </div>
                                <div className={classes.col}>
                                    <TextField variant="outlined" label="Полное название"  value={fullName} onChange={(e) => {
                                        const val = e.target.value;
                                        setfullName(val);
                                    }}  disabled={!editComp}/>
                                    <TextField variant="outlined" label="КПП"  value={kpp} onChange={(e) => {
                                        const val = e.target.value;
                                        setkpp(val);
                                    }}  disabled={!editComp}/>
                                    <TextField variant="outlined" label="Почтовый адрес"  value={postAdd} onChange={(e) => {
                                        const val = e.target.value;
                                        setpostAdd(val);
                                    }}  disabled={!editComp}/>
                                </div>
                            </div>
                            {hasSavedComp && !editComp &&
                            <div style={{marginTop: 23,}}>
                                <a href="#" onClick={(e) => {
                                    e.preventDefault();
                                    setEditComp(true);
                                }
                                } className={classes.link}>Изменить реквизиты</a>
                            </div>
                            }
                        </Paper>
                        }
                    </div>

                    <Paper className={classes.paper2}>
                        <Typography variant="h2" className={classNames(classes.h2, classes.bold, classes.line)}>Ваш заказ</Typography>
                        <table className={classes.totalTable}>
                            {hasSale ?
                                <>
                                <tr>
                                    <td>
                                        Тариф "PRO" на 1 год ({isNaN(tapCount) ? '-' : tapCount} {t('tap', {count: tapCount}).toLowerCase()})
                                        <div className={classes.smallText}>(включая НДС {formatSumm(vatRub)})</div>
                                    </td>
                                    <td>{formatSumm(fullPrice)}</td>
                                </tr>

                                <tr>
                                    <td>
                                        Скидка 20%
                                    </td>
                                    <td>{!isNaN(saleRub) && <>&minus;</>} {formatSumm(saleRub)}</td>
                                </tr>
                                </>
                                :
                                <>
                                    <tr>
                                        <td>
                                            Тариф "PRO" на {period === 12 ? '1 год' : '3 месяца'} ({isNaN(tapCount) ? '-' : tapCount} {t('tap', {count: tapCount}).toLowerCase()})
                                            <div className={classes.smallText}>(включая НДС {formatSumm(vatRub)})</div>
                                        </td>
                                        <td />
                                    </tr>
                                </>
                            }
                        </table>

                        <div className={classes.grandTotal}>{formatSumm(totalPrice)}</div>


                        {step === 1 ?
                            <>
                                <Button variant="contained" color="primary" className={classes.buyBtn}
                                        disabled={tapCount <= 0}
                                        onClick={(e) => {
                                            setStep(2);
                                        }}
                                >Перейти к оформлению</Button>
                            </>
                            :
                            <div style={{
                            paddingTop: 16,
                                borderTop: '1px solid #DFE3E9',
                            }}>
                                <TextField fullWidth variant="outlined" label="Введите email" type="email" value={email} onChange={(e) => {
                                    const val = e.target.value;
                                    setemail(val);
                                }}
                                error={!validate(email)}
                                />

                                <div style={{
                                    marginTop: 16,
                                }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={agree} onChange={()=>{setAgree(!agree)}} name="agree" color="primary" />
                                        }
                                        classes={{
                                            label: classes.checkboxlabel,
                                        }}
                                        label={<>Нажимая кнопку «Получить счет», я соглашаюсь с условиями <a className={classes.link} href="/v2/api/oferta.pdf">договора оферты</a></>}
                                    />

                                    <Button variant="contained" color="primary" className={classes.buyBtn}
                                            disabled={!agree || !email || !shName || !inn || !validate(email)}
                                            onClick={(e) => {
                                                //alert('!');
                                                doSubmit();
                                            }}
                                            style={{
                                                marginTop: 16,
                                            }}
                                    >Получить счёт</Button>
                                </div>
                            </div>
                        }
                    </Paper>
                </div>
            </div>
            <MyDialog1 onclose={()=>{setSuccOpen(false)}} open={succOpen} />

            <MyDialogplans onclose={()=>{setPlans(false)}} open={plans} />
        </PageLayout>
    );
};

export default TariffPage;
