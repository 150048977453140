import React from 'react';
import {Button, Dialog, DialogActions, DialogContent} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {t} from '../../../../i18n/utils.js';

function ConfirmDialog({open, onClose}) {
    const classes = useStyles();

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogContent>
                <div>
                    {t('kegReplaceAfter')}
                </div>
            </DialogContent>
            <DialogActions className={classes.confirmActions}>
                <Button variant={"contained"} onClick={onClose} color="primary" autoFocus className={classes.actionBtn + ' accept'}>
                    {t('kegReplaceAfterBtnConfirm')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmDialog;

const useStyles = makeStyles(theme => ({
    confirmActions: {
        justifyContent: "center",
        "& > *": {
            flex: 0,
        },
    },
    actionBtn: {
        minWidth: 120,
    },
}));
