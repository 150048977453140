import React, {useContext, useRef, useState} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import StackPaper from '../../../componets/StackPaper';
import BackBtn from '../BackBtn';
import DeviceNumber from './DeviceNumber';
import Indicators from '../Indicators';
import {useHistory} from 'react-router-dom';
import NotificationsArea from '../../../componets/Notifications/NotificationsArea';
import RefreshIcon from '@material-ui/icons/Refresh';
import {IconButton} from '@material-ui/core';
import LocalizedLink from '../../../localization/LocalizedLink';
import RebootDialog from './RebootDialog';
import {useSelector} from 'react-redux';
import {checkPermission, userIsSuper} from '../../../redux/reducers/tokens';
import DevActions from './DevActions';

import CreateIcon from '@material-ui/icons/Create';
import {t} from '../../../i18n/utils.js';
import moment from 'moment';
import {LocaleContext} from '../../../i18n/LocaleContext.js';

function DetailHeaderDesktop({device, className, linkBack, ...props}) {
    const classes = useStyles();
    const devActionsRef = useRef(null);
    const [devActions, setDevActions] = useState(false);
    const [reboot, setReboot] = useState(false);

    const canReboot = useSelector(checkPermission('access_to_reload_device'));
    const canChangeStore = useSelector(checkPermission('access_to_change_store'));

    const store = device.get('store');
    const storeName = store?.get('name');
    const storeAddress = store?.get('address')?.concat(', ');
    let storeHours = ''
    if (store?.get('work_hours')?.toJS instanceof Function) {
        const wh = store?.get('work_hours')?.toJS();
        storeHours = wh?.start && wh?.end ? `${wh.start} – ${wh.end}` : '-';
    } else {
        storeHours = store?.get('work_hours') || '-';
    }

    const storeCity = store?.getIn(['city']);
    const storeCityName = storeCity?.get('name')?.concat(', ');
    const chainName = store?.get('chain')?.get('name')?.concat(', ');

    const notiAll = device.get('notifications');
    const notiWarns = notiAll.filter(n => n.get('type') === 'Warn');
    const notiErrors = notiAll.filter(n => n.get('type') === 'Error');

    let history = useHistory()

    const locale = useContext(LocaleContext);

    const isSuper = useSelector(userIsSuper);

    const storeStr = device.get('store') ? `${storeCityName} ${storeAddress} ${storeHours}` : t('undefinedStore');

    const dateWarrancy = moment(device.get('warranty_date'));
    const dateWarrancyP1 = dateWarrancy.add(1, 'day');
    const now = moment();

    const hasWarrancy = dateWarrancy?.isValid() === true;
    console.log(dateWarrancy.isValid());

    const onWarrancy = dateWarrancy.diff(now) > 0;

    return (
        <div {...props} className={[classes.root, className].join(' ')}>
            <StackPaper className={classes.header}>
                <div className={classes.grid}>
                    <div className={[classes.g, classes.g1].join(' ')}>
                        <LocalizedLink to={linkBack}>
                            <BackBtn/>
                        </LocalizedLink>
                    </div>
                    <div className={[classes.g, classes.g2, classes.gmarginStart].join(' ')}>
                        <DeviceNumber number={device.get('device_number')}/>
                    </div>
                    <div className={[classes.g, classes.g3, classes.gmargin].join(' ')}>
                        <Indicators data={device.get('indicators')} />
                    </div>
                    <div className={[classes.g, classes.separator, classes.g4, classes.gmargin].join(' ')}/>
                    <div className={[classes.g, classes.g7, classes.gmarginEnd, classes.storeCity].join(' ')}>
                        <div className={classes.storeInfo}>
                            <div>
                            {storeStr}
                            {canChangeStore &&
                            <IconButton title={t('changeStore')} onClick={() => {
                                props.setStoreChange(true);
                            }} disabled={!canChangeStore}>
                                <CreateIcon style={{width: 21}}/>
                            </IconButton>
                            }
                            </div>
                            {hasWarrancy &&
                                <>
                                    {
                                        onWarrancy ? (
                                            <div>
                                                {t('onWarrant', {date: dateWarrancy.format(locale.dateFormat)})}
                                            </div>
                                        ) : (
                                            <div>
                                                {t('offWarrant', {date: dateWarrancyP1.format(locale.dateFormat)})}
                                            </div>
                                        )
                                    }
                                </>
                            }
                        </div>
                        <div className={classes.devActions}>
                            {canReboot &&
                            <IconButton onClick={() => {
                                setReboot(true);
                            }} title={t('rebootTitle')} disabled={!canReboot} id={'device-reboot-btn'}>
                                <RefreshIcon/>
                            </IconButton>
                            }

                            <RebootDialog disbaled={true} open={reboot} onClose={()=>{
                                setReboot(false);
                            }} />
                            <DevActions device={device} />
                        </div>
                    </div>
                    <div className={[classes.g8, classes.notifications].join(' ')}>
                        {notiErrors.size + notiWarns.size > 0 &&
                            <div className={classes.sep} />
                        }
                        <NotificationsArea list={notiAll} superArea={true}/>
                        {notiErrors.size + notiWarns.size > 0 &&
                            <div className={classes.sep2} />
                        }
                    </div>
                </div>
            </StackPaper>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    storeInfo: {
        flex: 1,
    },
    devActions: {},

    header: {
        marginLeft: 0,
        marginRight: 0,
        paddingRight: 32,
        paddingTop: 40,
    },
    grid: {
        display: "grid",
        gridTemplateColumns: "min-content max-content 2fr max-content 1fr 1fr 1fr",
        gridTemplateAreas: '"g1 g2 g3 g4 g7 g7 g7" ". g8 g8 g8 g8 g8 g8"',
    },
    separator: {
        display: "inline-block",
        width: 1,
        alignSelf: "stretch",
        background: theme.palette.border.main,
    },

    g: {
        display: "flex",
        alignItems: "center",
    },
    g1: {
        gridArea: "g1",
        marginRight: 18,
    },
    g2: { gridArea: "g2" },
    g3: { gridArea: "g3" },
    g4: { gridArea: "g4" },
    g5: { gridArea: "g5" },
    g6: { gridArea: "g6" },
    g7: { gridArea: "g7" },
    g8: { gridArea: "g8" },
    gmarginStart: {
        marginRight: 20,
    },
    gmargin: {
        margin: "0 20px",
    },
    gmarginEnd: {
        marginLeft: 20,
    },

    storeName: {
        fontWeight: 500,
        lineHeight: "16px",
    },
    storeAddressHours: {
        display: "flex",
        flexDirection: "column",
    },
    storeHours: {
        fontSize: 12,
        fontWeight: 500,
        lineHeight: "14px",
    },
    storeCity: {
        fontSize: 14,
        lineHeight: "14px",
    },

    notifications: {
        marginTop: 12,
    },
    sep: {
        background: "#c1c1c1",
        width: "100%",
        height: 1,
    },
    sep2: {
        width: "100%",
    },
}));

export default DetailHeaderDesktop;
