import React, {useEffect, useState} from 'react';
import PageLayout from '../../componets/PageLayout/PageLayout.js';
import {Paper, ThemeProvider} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import Filters from './Filters.js';
import Topbar from './Topbar.js';
import Metrics from './Metrics.js';
import Dynamics from './Dynamics.js';
import Table from './Table.js';
import {addDays, differenceInCalendarDays} from 'date-fns';
import {theme} from './../StoresListPage/theme.js';
import {setTitle} from '../StoresListPage/StoresPage.js';
import MetricsIdle from './MetricsIdle.js';
import IdleLoader from './IdleLoader.js';
import TableIdle from './TableIdle.js';
import {useLocation} from 'react-router-dom';

const IdleReport = (props) => {
    const classes = useStyles();

    const loc = useLocation();
    console.log(props, loc);

    const [range, setRange] = useState(  loc?.state?.range ||
        {
            startDate: addDays(new Date(), -1),
            endDate: addDays(new Date(), -1),
            key: 'selection',
        }
    );



    const [type, setType] = useState(1);

    const [selCities, setSelCities] = useState(loc?.state?.cities || []);

    const [selChains, setSelChains] = useState(loc?.state?.chains || []);
    const [selStores, setSelStores] = useState(loc?.state?.stores || []);
    console.log(loc?.state?.stores);

    const [filters, setFilters] = useState(true);

    const location = useLocation();
    useEffect(()=>{
        //console.log('LOCATION', location);
        const hash = location?.hash;

        if (hash === '#filters') {
            try {
                const load = sessionStorage.getItem('filters');
                const parsed = JSON.parse(load);
                setSelCities(parsed.cities);
                setSelChains(parsed.chains);
                setSelStores(parsed.stores);
                setRange({
                    startDate: new Date(parsed.range.startDate),
                    endDate: new Date(parsed.range.endDate),
                    key: 'selection',
                });
            } catch (e) {
                //
            }
        }
    }, []);

    useEffect(()=>{
        //console.log('FILTERS', range, selCities, selChains);
        const save = {
            cities: selCities,
            chains: selChains,
            stores: selStores,
            range: range,
        }
        //console.log(save);
        const encoded = JSON.stringify(save, null, 0);
        sessionStorage.setItem('filters', encoded);
        window.location.hash = '#filters';
    }, [range, selCities, selChains, selStores])


    const diff = differenceInCalendarDays(range.endDate, range.startDate) + 1;

    return (
        <ThemeProvider theme={theme}>
            <PageLayout noContainer={true} customMobileTopBar={null}>
                <Topbar filters={filters} setFilters={setFilters} type={type} setType={setType} />
                {filters &&
                    <Filters selectionRange={range} setSelectionRange={setRange}
                             selCities={selCities} setSelCities={setSelCities}
                             selChains={selChains} setSelChains={setSelChains}
                             selStores={selStores} setSelStores={setSelStores}
                             filters={filters} setFilters={setFilters}
                    />
                }

                <div className={classes.content}>
                    {type === 0 ?
                        (
                            <>
                                <Metrics dateFrom={range.startDate} dateTo={range.endDate}
                                         selCities={selCities} selChains={selChains}
                                         selStores={selStores} setSelStores={setSelStores}
                                />
                                <Dynamics dateFrom={range.startDate} dateTo={range.endDate}
                                          selCities={selCities} selChains={selChains}
                                          selStores={selStores} setSelStores={setSelStores}
                                          withTime={diff < 5}
                                />
                            </>
                        )
                        :
                        <IdleLoader  dateFrom={range.startDate} dateTo={range.endDate}
                                     selCities={selCities} selChains={selChains}
                                     selStores={selStores} setSelStores={setSelStores}
                                     withTime={diff < 5} />
                    }


                    {type === 0 ?
                        (
                            <Table dateFrom={range.startDate} dateTo={range.endDate}
                                   selCities={selCities} selChains={selChains}
                                   selStores={selStores} setSelStores={setSelStores}
                            />
                        ) : (
                            <>
                                <TableIdle dateFrom={range.startDate} dateTo={range.endDate}
                                       selCities={selCities} selChains={selChains}
                                       selStores={selStores} setSelStores={setSelStores}
                                />
                            </>
                        )
                    }
                </div>
            </PageLayout>
        </ThemeProvider>
    );
};

export default IdleReport;

const styles = {
    content: {
      display: 'flex',
      padding: 24,
      gap: 24,
      flexDirection: 'column',
    },
};
const useStyles = makeStyles(styles);
