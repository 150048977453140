import React from 'react';
import {makeStyles} from '@material-ui/styles';
import classNames from 'classnames';

function ProgressBar({className, ...props}) {
    const classes = useStyles(props);

    return (
        <div className={classNames(classes.root, className)}>
            <div className={classes.bar} />
        </div>
    );
}


const useStyles = makeStyles(theme => ({
    root: {
        height: 5,
        background: theme.palette.background.progressBar,
        borderRadius: 3,
        width: "100%",
        overflow: "hidden",
    },
    bar: {
        height: "100%",
        width: props => `${props.value}%`,
        background: props => props.disabled ? "#818C99" : theme.palette.green,
        borderRadius: 3,

        ".disconnected & ": {
            background: theme.palette.disabled.main + " !important",
        },
    }
}));

export default ProgressBar;
