import React, {useRef, useState} from 'react';
import {IconButton, Typography} from '@material-ui/core';
import classNames from 'classnames';
import {ReactComponent as SettingsIcon} from '../StoresListPage/svg/settings.svg';
import {makeStyles} from '@material-ui/core/styles';
import {t} from '../../i18n/utils.js';
import BackButton from '../StorePage/components/BackButton.js';
import SettingsMenu from './SettingsMenu.js';
import {ReactComponent as FreezerIcon} from '../StorePage/svg/freezer.svg';
import {ReactComponent as GasIcon} from '../StorePage/svg/gas.svg';
import {ifnn} from '../Dashboard/DailyStats.js';


function MobileTopbar({name, backUrl, temp, co2, ...props}) {
    const classes = useStyles();

    const [settings, setSettings] = useState(false);
    const ref = useRef(null);

    let tempVal = ifnn(temp?.info.termo_cool);
    let co2Val = ifnn(co2?.info.pressure_co2_value);

    let tempClr = classes.green;
    if (temp?.notifications?.errors?.length > 0) {
        tempClr = classes.red;
    } else if (temp?.notifications?.warnings?.length > 0) {
        tempClr = classes.yellow;
    }

    let co2Clr = classes.green;
    if (co2?.notifications?.errors?.length > 0) {
        co2Clr = classes.red;
    } else if (co2?.notifications?.warnings?.length > 0) {
        co2Clr = classes.yellow;
    }

    return (
        <div className={classes.topbar}>
            <BackButton href={backUrl} />

            <div className={classes.spacer} />

            <div className={classes.indic}>
                <FreezerIcon className={tempClr}  />
                <Typography className={classes.indicText}>{tempVal}°C</Typography>
            </div>

            <div className={classes.indic}>
                <GasIcon className={co2Clr}  />
                <Typography className={classes.indicText}>{co2Val} {t('bar')}</Typography>
            </div>

                <div className={classNames(classes.topbarButtons, classes.bigButtons)}>
                    <IconButton className={classNames(classes.toplineBigBtn2, {'pressed':  settings}, 'lastChild')}
                                onClick={() => {
                                    setSettings(true);
                                }}  ref={ref}
                    ><SettingsIcon/></IconButton>
                    <SettingsMenu open={settings} showStats={props.showStats} setShowStats={props.setShowStats}  anchorRef={ref} {...props} onClose={()=>setSettings(false)} />
                </div>

        </div>
    );
}

export default MobileTopbar;


const topbarHeight = 56;

const styles = (theme) => ({
    green: {
       color: theme.colors.colors.success,
    },
    yellow: {
        color: theme.colors.colors.warning,
    },
    red: {
        color: theme.colors.colors.error,
    },
    indic: {
        display: 'flex',
        gap: 6,
        //marginLeft: ,
        alignItems: 'center',

        [theme.breakpoints.up('lg')]: {
            display: 'none',
        },
    },
    indicText: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    iconWrap: {
        '&.red': {
            color: '#FF6370',
        },
        '&.yellow': {
            color: '#FFAE17',
        }
    },
    moduleName: {
      display: 'flex',
      gap: '10px',
        alignItems: 'baseline',
        color: theme.colors.main.mainText,

    },
    moduleNameText: {
        fontSize: 23,
        lineHeight: '32px',
    },
    searchField: {
        position: "absolute",
        zIndex: 800,
        background: "#F2F4F6",
        top: 10,
        left: 64,
        bottom: 8,
        borderRadius: 4,
        right: 8,
        display: 'none',
        border: 'none !important',
        outline: 'none !important',
        boxShadow: 'none !important',
        height: 36,

        "& fieldset": {
            display: 'none',
        },

        '&.visible': {
            display: 'inherit',
        },
    },
    menuItem: {
        display: 'flex',
        padding: '10px 20px',
        gap: 18,
        alignItems: 'center',
        cursor: 'pointer',
        color: 'rgba(114, 142, 192, 1)',
        transition: 'all .3s ease',

        "&:hover": {
            background: 'rgba(11, 46, 109, 1)',
            color: theme.colors.main.white,
        },

        "&.active": {
            background: 'rgba(11, 46, 109, 1)',
            color: theme.colors.main.white,
        },
    },
    menuItemLabel: {
        color: theme.colors.main.white,
    },
    menuItemIcon: {
        width: 24,
        height: 24,
        overflow: 'hidden',
    },

    logo: {
        padding: '16px 20px',
    },

    leftMenu: {
        background: theme.colors.main.secondaryBg,
        color: theme.colors.main.white,
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        zIndex: 500,
        width: 264,

        display: 'flex',
        flexDirection: 'column',
    },

    toplineBtn: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: theme.spacing(3),
        marginLeft: theme.spacing(0),
        fontWeight: 'normal',

        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },

    wrap: {
        //paddingTop: topbarHeight,

        [theme.breakpoints.up('lg')]: {
            display: 'none'
        }


    },
    spacer: {
        flex: 1,
    },

    menuBtn: {
        width: 24,
        height: 24,
        padding: 0,
    },

    back: {
        marginRight: 22,
    },

    topbar: {
        display: 'flex',
        background: theme.colors.main.white,
        height: topbarHeight,
        padding: '12px 20px',
        boxShadow: theme.shadows[1],
        marginBottom: 16,
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        gap: 14,

        [theme.breakpoints.down('md')]: {
           //padding: 22,
        },

            [theme.breakpoints.down('sm')]: {
                //padding: 22,
                boxShadow: theme.shadows[0],
            }
    },
    topbarButtons: {
        display: 'flex',
        alignItems: 'center',
    },

    toplineBtnIcon: {
        width: 24,
        height: 24,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        marginRight: theme.spacing(2),

        '&:last-child': {
            marginRight: 0,
        },
    },
    smallBtnText: {
        color: theme.colors.grey.c100,
    },

    bigButtons:{
        //marginLeft: theme.spacing(6),
    },
    toplineBigBtn: {
        width: 24,
        height: 24,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        marginRight: theme.spacing(4),
        '&:last-child': {
            marginRight: 0,
        },
        padding: 0,
    },

    toplineBigBtn2: {
        width: 36,
        height: 36,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        marginRight: theme.spacing(2),
        '&.lastChild': {
            marginRight: 0,
        },
        padding: 0,

        '&.pressed': {
            background: '#007AFF1A',
            color: '#007AFF',

            '&.search': {
                display: 'none',
            }
        },
    },

    profileBtn: {
        display: 'flex',
        padding: '10px 20px',
        gap: 12,
        alignItems: 'center',
        cursor: 'pointer',
        color: theme.colors.main.white1,
    },

    profileBtnIcon: {
        width: 40,
        height: 40,
        overflow: 'hidden',
    },

    fab: {
        position: 'fixed',
        bottom: 24,
        right: 24,
        width: 44,
        height: 44,
        display: 'none',

        [theme.breakpoints.down('sm')]: {
            display: 'flex',
        }
    },
});
const useStyles = makeStyles(styles);
