import React, {useState} from 'react';
import {connect} from 'react-redux';
import {tokensError, tokensSingIn} from '../../redux/reducers/tokens';
import {Button, FormControl, IconButton, InputAdornment, OutlinedInput, Paper, TextField} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Logo from '../../componets/PageLayout/Logo';
import {ReactComponent as NpmLogo} from '../../svg/npm.svg';
import classNames from 'classnames';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import {t} from '../../i18n/utils.js';

const styles = theme => ({
    root: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
    },
    paper: {
        marginTop: 37,
        maxWidth: 544,
        display: "flex",
        justifyContent: "center",
        width: "100%",
    },
    content: {
        maxWidth: 372,
        paddingLeft: 8,
        paddingRight: 8,
        width: "100%",
    },
    logoWrap: {
        display: "flex",
        justifyContent: "center",
        marginTop: 42,
        marginBottom: 55,
    },
    loginBtn: {
        width: "100%",
        marginBottom: 32,
        height: 36,
        marginTop: 53,
    },
    label: {
        fontSize: "12px",
        marginBottom: 4,
        lineHeight: "16px",
        letterSpacing: "0.4px",
        display: "block",
        width: "100%",
        paddingLeft: 16,
    },
    field: {
        width: "100%",
        marginBottom: 24,

        "&:last-child": {
            marginBottom: 0,
        },

        "& > .MuiInputBase-root": {
            borderRadius: 8,
        },

        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: props => props.isError ? "#FF3B30" : "#c1c1c1",
            background: props => props.isError ? "rgba(255, 59, 48, 0.1)" : "inherit",
        },
    }
    ,
    npmLogoWrap: {
        marginTop: 32,
    },
    errorMsg: {
        color: "#FF3B30",
    },
});

const useStyles = makeStyles(styles);

function SingInPage(props) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const classes = useStyles(props);
    const [showPassword, setShowPassword] = useState(false);

    return (
        <div className={classes.root}>
            <Paper elevation={0} className={classes.paper}>
                <div className={classes.content}>
                    <div className={classes.logoWrap}>
                        <Logo />
                    </div>
                    <form>
                        <label htmlFor="username" className={classes.label}>{t('login')}</label>
                        <TextField  variant="outlined" type="text" name="username" id="username"
                                    className={classes.field}
                                    value={username}
                                    onChange={event => setUsername(event.target.value)}/>

                        <label htmlFor="password" className={classes.label}>{t('password')}</label>
                        <FormControl className={classNames(classes.margin, classes.field)}>
                            <OutlinedInput
                                id="standard-adornment-password"
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                name="password"
                                variant="outlined"
                                onChange={event => setPassword(event.target.value)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={()=>{setShowPassword(!showPassword);}}
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>

                        {
                            props.isError === 'API1' &&
                            <div className={classes.errorMsg}>
                                {t('wrongAuth')}
                            </div>
                        }
                        {
                            props.isError === 'API2' &&
                            <div className={classes.errorMsg}>
                                {t('wrongAuth2')}
                            </div>
                        }
                        {
                            props.isError === 'API1err' &&
                            <div className={classes.errorMsg}>
                                {t('authServErr1')}
                            </div>
                        }
                        {
                            props.isError === 'API2err' &&
                            <div className={classes.errorMsg}>
                                {t('authServErr1')}
                            </div>
                        }

                        <Button type="submit" variant="outlined" color="primary" onClick={event => {
                            event.preventDefault();
                            props.singIn(username.trim(), password.trim())}
                        } className={classes.loginBtn}>{t('signInBtn')}</Button>
                    </form>
                </div>
            </Paper>
            <div className={classes.npmLogoWrap}>
                <NpmLogo />
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        isError: tokensError(state),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        singIn: (username, password) => dispatch(tokensSingIn(username, password)),
    };
}

export default SingInPage |> connect(mapStateToProps, mapDispatchToProps);
