import React from 'react';
import {
    Dialog,
    DialogContent,
    DialogTitle as MuiDialogTitle,
    IconButton,
    Typography,
    withStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {makeStyles} from '@material-ui/core/styles';
import {ReactComponent as KegIcon} from './svg/keg3.svg';
import {ReactComponent as WarnIcon} from './svg/warnIcon.svg';
import {ReactComponent as ErrorIcon} from './svg/errorIcon.svg';
import NotifDate from '../StorePage/components/NotifDate.js';
import {t} from '../../i18n/utils.js';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        marginBottom: 16,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    storename: {
      fontSize: '16px',
        lineHeight: '24px',
      fontWeight: 500,
    },
    storeaddress: {
        color: "#7E858E",
        fontSize: '12px',
        lineHeight: '16px',
    },
    notifType: {
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '0.002em',
    },
    dialogContent: {
        padding: 8,
        display: 'flex',
        gap: '12px',
        flexDirection: 'column',
    },
    notiHeader: {
        display: 'flex',
        gap: '6px',
    },
    notiBlock: {
        padding: '8px 0',
    },
    paper: {
        padding: 12,
        maxWidth: 500,
    },
    notiIcon: {
        width: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    notifItem: {
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0.002em',

        color: '#7E858E',
    }
});

const useStyles = makeStyles(styles);

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

function NotifDialog({open, store, type, onclose, isStore}) {
    const classes = useStyles();


    let notifs;

    notifs = type === 'all' ? store.notifications : store.notifications?.filter(n => n.module?.type === type);

    const notifsByGroup = {};

    for (const n of notifs) {
        console.log(n);
        const uuid = n.status_uuid;
        const split = uuid.split('/');
        const deviceNum = Array.isArray(split) ? split[0] : split;
        const deviceNumStr = deviceNum ? deviceNum.toString() : '(none)';

        if (!Array.isArray(notifsByGroup[deviceNumStr])) {
            notifsByGroup[deviceNumStr] = [];
        }

        notifsByGroup[deviceNumStr].push(n);
    }

    console.log(notifsByGroup);

    function renderGroup(g, index, title) {
        let kegNotifs = g.filter(n => n.module?.type === 'keg') || [];
        const notKegs = g.filter(n => !kegNotifs.includes(n)) || [];
        let errNotifs = notKegs?.filter(n => n.type === 'error') || [];
        let warnNotifs = notKegs?.filter(n => n.type === 'warning') || [];

        const kegNotifsS = kegNotifs.sort((a, b) => {
            return a.module?.name > b.module?.name ? 1 : -1;
        });

        const errNotifsS = errNotifs.sort((a, b) => {
            return a.module?.name > b.module?.name ? 1 : -1;
        });

        const warnNotifsS = warnNotifs.sort((a, b) => {
            return a.module?.name > b.module?.name ? 1 : -1;
        });

        return (
                <div key={index}>
                    <Typography className={classes.storename}>{title}</Typography>

                    {kegNotifs?.length > 0 &&
                    <div className={classes.notiBlock}>
                        <div className={classes.notiHeader}>
                            <div className={classes.notiIcon} style={{color: '#868A8F'}}>
                                <KegIcon/>
                            </div>
                            <Typography className={classes.notifType}>Кончился напиток (кеги пустые)</Typography>
                        </div>
                        <div className={classes.notifs}>
                            {kegNotifsS.map((n, i) => {
                                return (
                                    <Typography key={i}
                                                className={classes.notifItem}>{n.module?.name + ': ' + n.text}, <NotifDate
                                        notif={n}/>
                                    </Typography>
                                );
                            })}
                        </div>
                    </div>
                    }
                    {errNotifs?.length > 0 &&
                    <div className={classes.notiBlock}>
                        <div className={classes.notiHeader}>
                            <div className={classes.notiIcon}>
                                <ErrorIcon/>
                            </div>
                            <Typography className={classes.notifType}>Критические ошибки</Typography>
                        </div>
                        <div className={classes.notifs}>
                            {errNotifsS.map((n, i) => {
                                return (
                                    <Typography key={i}
                                                className={classes.notifItem}>{t(n.module?.name) + ': ' + n.text}, <NotifDate
                                        notif={n}/>
                                    </Typography>
                                );
                            })}
                        </div>
                    </div>
                    }
                    {warnNotifs?.length > 0 &&
                    <div className={classes.notiBlock}>
                        <div className={classes.notiHeader}>
                            <div className={classes.notiIcon}>
                                <WarnIcon/>
                            </div>
                            <Typography className={classes.notifType}>Требует реакции</Typography>
                        </div>
                        <div className={classes.notifs}>
                            {warnNotifsS.map((n, i) => {
                                return (
                                    <Typography key={i}
                                                className={classes.notifItem}>{t(n.module?.name) + ': ' + n.text}, <NotifDate
                                        notif={n}/></Typography>
                                );
                            })}
                        </div>
                    </div>
                    }
                    {!warnNotifs?.length && !kegNotifs?.length && !errNotifs?.length &&
                    <Typography className={classes.notifItem}>
                        {t('allRight')}
                    </Typography>
                    }
                </div>
        );
    }

    const keys =Object.keys(notifsByGroup).sort();

    return (
        <Dialog open={open} onClose={onclose} fullWidth={true} classes={{
            paper: classes.paper,
        }}>
            <DialogTitle onClose={onclose}>
                {isStore &&
                    <>
                        <Typography className={classes.storename}>{store?.name}</Typography>
                        <Typography className={classes.storeaddress}>{store?.address}</Typography>
                    </>
                }
            </DialogTitle>

            <DialogContent className={classes.dialogContent}>
                {keys.map((k, index) => {
                   const group = notifsByGroup[k];

                   return renderGroup(group, index, k);
                })}
            </DialogContent>
        </Dialog>
    );
}

export default NotifDialog;
