/* global firebase */

export function startWebPush(username, tokenAuth) {
// TODO: Replace the following with your app's Firebase project configuration
// For Firebase JavaScript SDK v7.20.0 and later, `measurementId` is an optional field
// Initialize Firebase
    const firebaseConfig = {
        apiKey: "AIzaSyDXryGbHFJpUX78qA74QQfxQ2ybOPwg4KU",
        authDomain: "smdev-7852a.firebaseapp.com",
        databaseURL: "https://smdev-7852a.firebaseio.com",
        projectId: "smdev-7852a",
        messagingSenderId: "640086124442",
        appId: "1:640086124442:web:5e4830e273137faeadfc23",
    };

    firebase.initializeApp(firebaseConfig);


    const messaging = firebase.messaging();
    messaging
        .requestPermission()
        .then(function () {
            //console.log("Notification permission granted.");

            // get the token in the form of promise
            return messaging.getToken()
        })
        .then(async function (token) {
            //console.log("token is : " + token);

            // Здесь дальше запрос на /api/push/device/fcm для создания/получения зарегистрированного устройства
            // GET /api/push/device/fcm/{token}
            // в случае отсутствия - POST /api/push/device/fcm/

            var json;

            let response = await fetch(`/v2/api/fcm?token=${token}`, {method: 'GET',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                    'Authorization': `Bearer ${tokenAuth}`
                },
            });
            json = await response.json()

            if (!json || !json.id) {
                response = await fetch(
                    `/v2/api/fcm/subscribe`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json;charset=utf-8',
                            'Authorization': `Bearer ${tokenAuth}`
                        },
                        body: JSON.stringify({
                            type: 'web',
                            registration_id: token,
                            name: username,
                        })
                    });

                json = await response.json();
            }

            //console.log(json);
        })
        .catch(function (err) {
            console.error("Unable to get permission to notify.", err);
        });

    messaging.onMessage(function (payload) {
        //
    });
}
