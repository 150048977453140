import React from 'react';
import {Button} from '@material-ui/core';
import * as colors from '../StorePage/uiKit/colors.js';
import {ArrowBackIos} from '../StorePage/uiKit/icons';
import {makeStyles} from '@material-ui/core/styles';
import classNames from 'classnames';

const styles = ({
    button: {
        background: colors.lightgrey40,
        color: colors.grey100,
        borderRadius: 8,
        width: 30,
        height: 32,
        minWidth: 30,
        minHeight: 32,
    },
    icon: {
        width: 16,
        height: 16,
        position: 'relative',
        left: 3,
    },
    label: {
        color: colors.grey100,
        fontSize: 12,
        lineHeight: '16px',
    },
});
const useStyles = makeStyles(styles);

export default function BackButton({className, ...props}) {
    const classes = useStyles();

    return (
        <Button className={classNames(classes.button, className)} {...props}>
            <ArrowBackIos className={classes.icon} />
        </Button>
    );
}
