import React, {useContext, useEffect, useState} from 'react';
import PageLayout from '../../componets/PageLayout/PageLayout';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Switch from '../../componets/Switch';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {Checkbox, IconButton, InputAdornment} from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import {DatePicker} from '@material-ui/pickers';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {useDispatch, useSelector} from 'react-redux';
import {citiesFetchList, getCitiesList} from '../../redux/reducers/cities';
import {chainsFetchList, getChainsList} from '../../redux/reducers/chains';
import {companiesFetchList, getCompaniesList} from '../../redux/reducers/companies';
import {getWithToken} from '../../app/rest';
import {tokensGetAccessToken, userIsSuper} from '../../redux/reducers/tokens';
import ClearIcon from '@material-ui/icons/Clear';
import moment from 'moment';
import TablePagination from '@material-ui/core/TablePagination';
import {t} from '../../i18n/utils.js';
import {LocaleContext} from '../../i18n/LocaleContext.js';
import {setTitle} from '../Dashboard/Dashboard.js';

const ReportsPage = props => {
    const classes = useStyles();

    const today = moment();
    const firstDayOfMonth = moment().clone().startOf('month');

    // state for filters and report type
    const [dateFrom, setDateFrom] = useState(firstDayOfMonth);
    const [dateTo, setDateTo] = useState(today);
    const [chain, setChain] = useState([]);
    const [city, setCity] = useState([]);
    const [type, setType] = useState(1);
    const [company, setCompany] = useState(null);

    // state for pagination
    const [offset, setOffset] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(35);
    const pageNum = offset / rowsPerPage;

    // state for device list
    const [devices, setDevices] = useState([]);
    const [count, setCount] = useState(0);

    // selectors
    const isSuper = useSelector(userIsSuper);
    const token = useSelector(tokensGetAccessToken);
    const cities = useSelector(getCitiesList)?.toJS() || [];
    const chains = useSelector(getChainsList)?.toJS() || [];
    const companies = useSelector(getCompaniesList)?.toJS() || [];

    const dispatch = useDispatch();

    setTitle('Отчёты');

    // initial loading
    useEffect(() => {
        dispatch(citiesFetchList());
        dispatch(chainsFetchList());

        if (isSuper) {
            dispatch(companiesFetchList());
        }
    }, []);

    // update device list on filters change
    useEffect(()=>{
        let endpoint = `/api/devices/details/?limit=${rowsPerPage}&offset=${offset}`;
        if (chain.length > 0) {
            endpoint += '&' + chain.map(c => 'chain=' + c).join('&');
        }
        if (city.length > 0) {
            endpoint += '&' + city.map(c => 'city=' + c).join('&');
        }
        if (company) {
            endpoint += '&company=' + company;
        }

        getWithToken(endpoint, token).then(resp => {
            const devices = resp.results;
            setDevices(devices);
            setCount(resp.count);

            if (resp.count < offset) {
                setOffset(0);
            }
        })
    }, [chain, city, company, offset, rowsPerPage]);

    // do report
    function handleReport() {
        let endpoint;
        let reportFilename = 'report';

        const dtFrom = moment(dateFrom).format('YYYY.MM.DD');
        const dtTo = moment(dateTo).format('YYYY.MM.DD');

        const dtFrom2 = moment(dateFrom).format('DD.MM.YYYY');
        const dtTo2 = moment(dateTo).format('DD.MM.YYYY');

        switch (type) {
            case 1:
                endpoint = '/api/export/excel_pour';
                reportFilename = `Отчет_по_продажам_${dtFrom2}_${dtTo2}`;
                break;
            case 2:
                endpoint = '/api/export/excel_idle';
                reportFilename = `Отчет_по_простоям_${dtFrom2}_${dtTo2}`;
                break;
            default:
                alert(t('washDialogError'));
                return;
        }

        const body = {
            "ts_from": dtFrom,
            "ts_to": dtTo,
            "company": company,
            "cities": city,
            "chains": chain,
        };

        fetch(endpoint,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `JWT ${token}`
            },
            body: JSON.stringify(body),
        }).then(response => {
            if (response.ok)
                return response.blob();
            else
                throw Error('error');
        })
            .then(blob => {
                //console.log('BLOB', blob);

                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = reportFilename + ".xlsx";
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove();  //afterwards we remove the element again
            }, error => {
                alert(t('washDialogError'));
            });
    }

    return (
        <PageLayout url="reports" noContainer={true}>
            <div className={classes.toolbar}>
                <Switch options={[
                    {id: 1, title: t('saleReportSwitch'), disabled: false},
                    {id: 2, title: t('idleReportSwitch'), disabled: false},
                ]} value={type} className={classes.switch} setValue={setType} />
            </div>
            <div className={classes.content}>
                <div className={classes.filterArea}>
                    {
                        isSuper &&
                        <FormControl variant="outlined" className={classes.formControl}>
                            <label className={classes.datepickerLabel}>{t('companiesDropdown')}</label>
                            <Select
                                className={classes.select}
                                value={company}
                                onChange={(ev)=>{
                                    const newVal = ev.target.value;
                                    setCompany(newVal);
                                }}
                                renderValue={(selected) => selected === null ? t('anyCategory') : companies.find(c=>c.id === selected)?.name}
                                variant={"filled"}
                                displayEmpty
                                endAdornment={
                                    company ?
                                        <InputAdornment position="end" className={classes.clearAdornment} >
                                            <IconButton
                                                onClick={() => {setCompany(null)}}
                                                size="small"
                                            >
                                                <ClearIcon />
                                            </IconButton>
                                        </InputAdornment>
                                        : null
                                }

                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null
                                }}
                            >
                                {companies.map((option, index) => (
                                    <MenuItem value={option.id} key={index} className={classes.menuItem}>
                                        <div className={classes.menuItemText}>{option.name}</div>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    }
                    <CustomDatePicker
                        label={t('dateFrom')}
                        value={dateFrom}
                        onChange={setDateFrom}
                        maxDate={dateTo ? dateTo : undefined}
                    />
                    <CustomDatePicker
                        label={t('dateTo')}
                        value={dateTo}
                        onChange={setDateTo}
                        minDate={dateFrom ? dateFrom : undefined}
                    />
                    <SelectDropDown options={cities} value={city} setValue={setCity} placeholder={t('citiesDropdown')}
                        emptyLabel={t('anyType')}
                    />
                    <SelectDropDown options={chains} value={chain} setValue={setChain} placeholder={t('chainsDropdown')}
                        emptyLabel={t('anyCategory')}
                    />

                    <Button className={classes.toolbarBtn} color="primary" variant="contained" onClick={handleReport}
                            disabled={!dateFrom || !dateTo}>
                        {t('newReportBtn')}
                    </Button>
                </div>

                <div className={classes.resultArea}>
                    <p>{t('reportResultText')}</p>
                    <DevicesTable devices={devices} pagination={
                        <TablePagination
                            component="div"
                            count={count}
                            page={pageNum}
                            onChangePage={(ev, newPage)=>{
                                const newOffset = newPage * rowsPerPage;
                                setOffset(newOffset);
                            }}
                            labelRowsPerPage={t('rowsPerPage')}
                            rowsPerPage={rowsPerPage}
                            rowsPerPageOptions={[10, 35, 50, 100]}
                            onChangeRowsPerPage={(ev)=>{
                                const newVal = ev.target.value;
                                setRowsPerPage(newVal);
                            }}
                        />
                    } />
                </div>
            </div>
        </PageLayout>
    );
};

const CustomDatePicker = ({...props}) => {
    const classes = useStyles();

    const localeObj = useContext(LocaleContext);
    const pickerFormat = localeObj.dateFormatForPicker;

    const textFieldComponent = ({label, ...props}) => (
        <div className={classes.datepickerWrap}>
            <label className={classes.datepickerLabel}>{label}</label>
            <TextField {...props} placeholder={label} variant="filled" label={label}
                       InputProps={{
                           endAdornment: <InputAdornment position="end"><EventIcon /></InputAdornment>,
                       }}
            />
        </div>
    );

    return (
        <DatePicker
            className={classes.datePicker}
            autoOk
            format={pickerFormat}
            clearLabel={t('clearLabel')}
            cancelLabel={t('cancelLabel')}
            okLabel={t('okLabel')}
            disableFuture
            TextFieldComponent={textFieldComponent}
            {...props}
        />
    );
}

const SelectDropDown = ({value, setValue, placeholder, options, emptyLabel, ...props}) => {
    const classes = useStyles();

    return (
        <FormControl variant="outlined" className={classes.formControl}>
            <label className={classes.datepickerLabel}>{placeholder}</label>
            <Select
                className={classes.select}
                value={value}
                onChange={(ev)=>{
                    const newVal = ev.target.value;
                    setValue(newVal);
                }}
                multiple
                renderValue={(selected) => !selected.length ? emptyLabel : selected.map(idx=>options.find(c=>c.id === idx)?.name).join(', ')}
                variant={"filled"}
                displayEmpty
                endAdornment={
                    value.length > 0 ?
                        <InputAdornment position="end" className={classes.clearAdornment} >
                            <IconButton
                                onClick={() => {setValue([])}}
                                size="small"
                            >
                                <ClearIcon />
                            </IconButton>
                        </InputAdornment>
                        : null
                }

                MenuProps={{
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                    },
                    getContentAnchorEl: null
                }}
                {...props}
            >
                {options.map((option, index) => (
                    <MenuItem value={option.id} key={index} className={classes.menuItem}>
                        <Checkbox checked={value.indexOf(option.id) > -1} />
                        <div className={classes.menuItemText}>{option.name}</div>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

const DevicesTable = ({devices, pagination}) => {
    const classes = useStyles();

    return (
        <div>
            <div className={classes.devicesTable}>
                {devices.map((dev, idx) => {
                    return (
                        <DevicesTableRow device={dev} key={idx} />
                    );
                })}
            </div>
            {pagination}
        </div>
    );
};

const DevicesTableRow = ({device}) => {
    const classes = useStyles();

    return (
        <div className={classes.devicesTableRow}>
            <div>{device.name}</div>
            <div>{device.store?.city?.name}</div>
            <div>{device.store?.address}</div>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    datepickerLabel: {
        display: "block",
        fontSize: "12px",
    },

    filterArea: {
        padding: "18px",
        display: "flex",
        flexWrap: "wrap",
        gap: "0.65em",
        alignItems: "flex-end",
    },
    resultArea: {
        padding: "0 18px",
    },

    devicesTableRow: {
        display: 'flex',
        flexWrap: 'wrap',
        background: '#ddd',
        padding: 6,
        borderRadius: 8,
        gap: '1em',
    },

    devicesTable: {
        width: "100%",
        background: "#fff",
        display: "flex",
        flexDirection: "column",
        gap: '6px 6px',
        padding: 6,
    },

    switch: {
        width: 320,
    },
    toolbar: {
        margin: "19px 0",
        display: "flex",
        flexWrap: "wrap",
        gap: "16px",
        padding: "0 18px",
        justifyContent: "space-between",
    },

    reportBar: {
        margin: "19px 0",
        display: "flex",
        flexWrap: "wrap",
        gap: "16px",
        padding: "0 18px",
    },

    reportItem: {

    },

    menuItem: {
        maxWidth: 300,
    },

    menuItemText: {
        width: '100%',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },

    toolbarBtn: {
        padding: "0 24px",
        minHeight: 32,
    },

    datePicker: {
        minWidth: 200,
        maxWidth: 200,
    },

    select: {
        width: '100%',
        minWidth: 200,
        maxWidth: 200,

        "&.MuiFilledInput-adornedEnd":  {
            paddingRight: 0,
        },

        "& .MuiSelect-filled": {
            paddingRight: 60,
        }
    },

    clearAdornment: {
        //marginRight: theme.spacing(3),
        position: 'absolute',
        right: 30,
    },

    [theme.breakpoints.down('sm')]: {
        toolbar: {
            justifyContent: "space-around",
        },
    },
}));

export default ReportsPage;
