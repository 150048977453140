import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {t} from '../../i18n/utils.js';

function Indicators({data, className, ...props}) {
    const classes = useStyles();

    const termoCool = data.find(i => i.get('type') === 'termoCool')?.get('value');
    const pressurePipe = data.find(i => i.get('type') === 'pressurePipe')?.get('value');
    const signalLevel = data.find(i => i.get('type') === 'signalLevel')?.get('value');

    const arr = [];
    if (termoCool) arr.push(t('tempIndicator', {temp: termoCool}));
    if (pressurePipe) arr.push(t('gasIndicator', {pressure: pressurePipe }));
    if (signalLevel) arr.push(t('signalIndicator', {signalLevel: signalLevel}));

    return (
        <div {...props} className={[classes.root, className].join(' ')}>
            {arr.join(' | ')}
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        //fontSize: 12,
        //fontWeight: 300,
        lineHeight: "168%",
        letterSpacing: -0.363636,
    },
}));

export default Indicators;
