import React, {useEffect, useRef} from 'react';
import Select from '../Dashboard/Select.js';
import {useDispatch, useSelector} from 'react-redux';
import {citiesFetchList, getCitiesList} from '../../redux/reducers/cities.js';

const CitiesFilter = ({
                          selCities, setSelCities,
    ...props
}) => {
    const citiesImm = useSelector(getCitiesList);
    const cities = citiesImm?.toJS();
    const citiesLoaded = cities !== null;

    const localCitiesSel = useRef([]);

    useEffect(() => {
        localCitiesSel.current = selCities;
    }, [selCities])

    function updateCitiesFilter() {
        setSelCities(localCitiesSel.current);
    }

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(citiesFetchList());
    }, [])

    return (
        <Select
            options={cities || []}
            placeholder="Все города"
            value={selCities}
            onChange={(e, v, reason)=>{
                //console.log('VALUE', v);
                /*
                localCitiesSel.current = v;

                if (reason === 'clear') {
                    updateCitiesFilter();
                }*/
                setSelCities(v);
            }}
            multiple
            loading={!citiesLoaded}
            tagLabelI18n="city"
        />
    );
}

export default CitiesFilter;
