import React, {useContext, useEffect, useState} from 'react';
import {Paper, Typography, useTheme} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {formatDate} from '../../app/utils.js';
import {getWithToken} from '../../app/rest.js';
import {APIPREFIX} from './Metrics.js';
import {useSelector} from 'react-redux';
import {tokensGetBearerToken} from '../../redux/reducers/tokens.js';
import {
    Bar,
    CartesianGrid,
    ComposedChart,
    Label,
    Legend,
    Line,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import {LocaleContext} from '../../i18n/LocaleContext.js';
import moment from 'moment';
import {ifnn} from '../Dashboard/DailyStats.js';
import {ReactComponent as DownloadIcon} from './DownloadIcon.svg';


const CustomTooltip = ({ active, payload, label }) => {
    const classes = useStyles();

    const locale = useContext(LocaleContext);

    //console.log(payload);

    let pour = null;
    let idle = null;
    let idleRaw = null;

    function formatHours(time) {
        if (!time || !time.split) {
            return '-';
        }

        const split = time.split(':');

        return parseInt(split[0], 10)?.toLocaleString(locale.code, {maximumFractionDigits:2}) + ' ч ' + split[1] + ' м';
    }

    if (payload) {
        payload.forEach(p => {
            if (p.dataKey === 'value') {
                pour = p.payload.value_raw;
            }
        });
    }

    console.log(payload);
    const timeMoment = moment(label);
    const timeStr = timeMoment?.isValid() ? timeMoment.format(locale.dateFormat) : label;

    if (active && payload && payload.length) {
        return (
            <div className={classes.tooltip}>
                <Typography variant="subtitle1"><strong>{timeStr}</strong></Typography>
                <Typography variant="subtitle1">Простой: {formatHours(pour)}</Typography>
            </div>
        );
    }

    return null;
};

function CustomResponsiveContainer(props) {
    return (
        <div style={{ width: '100%', height: '100%', position: 'relative' }}>
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    top: 0,
                    left: 0
                }}
            >
                <ResponsiveContainer {...props} />
            </div>
        </div>
    );
}


const Dynamics = ({
                      dateFrom, dateTo, selCities, selChains, selStores, dynamics,
    ...props
}) => {
    const classes = useStyles();

    const [data, setData] = useState(null);

    const token = useSelector(tokensGetBearerToken);

    const theme = useTheme();

    const locale = useContext(LocaleContext);
    const lang = locale.code;

    const customTick = {
        fontSize: '13',
        fontFamily: 'Rubik',
        fill: theme.colors.grey.c100,
    };

    useEffect(() => {
        const parsed = dynamics?.map(v => {
            const split = v.value?.split(':');
            const hh = parseInt(split[0], 10);
            const mm = parseInt(split[1], 10);

            return ({
                    ...v,
                    value: hh + mm / 60,
                    value_raw: v.value,
                }
            );
        }).sort((a,b) => {
            if (a.date > b.date) {
                return 1
            } else if (a.date < b.date) {
                return -1
            } else {
                return 0
            }
        })

        setData(parsed);
    }, [dynamics]);

    const loading = data === null;

    function saveChart() {
        //get svg element.
        var list = document.getElementsByClassName('recharts-surface');
        var svg = list?.length > 0 ? list[0] : null;

        if (!svg) return;

        //get svg source.
        var serializer = new XMLSerializer();
        var source = serializer.serializeToString(svg);

        //add name spaces.
        if(!source.match(/^<svg[^>]+xmlns="http\:\/\/www\.w3\.org\/2000\/svg"/)){
            source = source.replace(/^<svg/, '<svg xmlns="http://www.w3.org/2000/svg"');
        }
        if(!source.match(/^<svg[^>]+"http\:\/\/www\.w3\.org\/1999\/xlink"/)){
            source = source.replace(/^<svg/, '<svg xmlns:xlink="http://www.w3.org/1999/xlink"');
        }

        //add xml declaration
        source = '<?xml version="1.0" standalone="no"?>\r\n' + source;

        //convert svg source to URI data scheme.
        var url = "data:image/svg+xml;charset=utf-8,"+encodeURIComponent(source);

        //set url value to a element's href attribute.
        var link = document.getElementById("link");
        link.href = url;
        link.click();
        //you can download svg file by right click menu.
    }

    const firstStore = selStores?.length === 1 ? selStores[0] : null;

    const chartXLabel = firstStore ? firstStore.address : 'Магазины';

    let first_d = data ? data[0] : null;

    const days = [first_d];

    for (let i = 1; i < data?.length; i++) {
        const d = data[i];

        const formated1 = formatDate(first_d.date,  'DD MMMM YYYY', lang);
        const formated2 = formatDate(d.date, 'DD MMMM YYYY', lang);

        //console.log(formated1, formated2);

        if (formated1 !== formated2) {
            days.push(d);
            first_d = d;

            //console.log(days);
        }
    }

    return (
        <Paper>
            <a href="#" download={'chart.svg'} id="link" className={classes.link} />
            <div className={classes.header}>
                <div className={classes.headerTitle}>Динамика по простою</div>

                <a className={classes.downloadBtn} onClick={(e) => {
                    e.preventDefault();
                    saveChart();
                }}>
                    <div className={classes.downloadBtnIcon}>
                        <DownloadIcon width={24} height={24} />
                    </div>
                    <div className={classes.downloadBtnLabel}>Скачать</div>
                </a>
            </div>

            <div className={classes.chartWrap}>
                <CustomResponsiveContainer width="99%" height="100%" className={classes.chart}>
                    {loading ?
                        <div>Загрузка...</div>
                        :
                        <ComposedChart
                            data={data}
                            margin={{ left: 30 }}
                        >
                            <CartesianGrid stroke="#f5f5f5"/>
                            <XAxis dataKey="date"
                                   scale="band"
                                   /*domain = {['auto', 'auto']}*/
                                   /*type="number"*/
                                   interval={0}
                                   tickLine={false}
                                   style={customTick}
                                   axisLine={false}
                                   tickFormatter={(val)=>{
                                       return formatDate(val, props.withTime ? 'HH:mm' : 'DD.MM', lang);
                                   }}
                            />

                            {props.withTime && days.map(d => {
                                if (!d?.date) return;
                                return (
                                    <ReferenceLine x={d.date} yAxisId="left" stroke="#aaaaaa" /*label={
                                formatDate(data[1].date, 'DD MMMM YYYY', lang)
                            } */ label={
                                        { angle: -90, position: 'right', offset: 10,  value: formatDate(d.date, 'DD MMM', lang), fill: 'black', fontSize: 14 }
                                    }/>);
                                })
                            }

                            <YAxis yAxisId="left" style={customTick}
                                   axisLine={false}
                                   tickLine={false}
                                   interval="preserveEnd"
                                   domain={[ 0, 'auto']}
                                   tickFormatter={(val)=>{
                                       return val.toLocaleString(locale.code, {maximumFractionDigits:2})
                                   }}
                            >
                                <Label angle={-90} dy={-30} position="left"
                                              offset={10}
                                       textAnchor="middle" fontSize={13}
                                       fill={theme.colors.main.secondaryText}
                                >
                                    Простой, часы
                                </Label>
                            </YAxis>
                            <Tooltip content={<CustomTooltip/>}/>
                            <Legend/>

                            <Line yAxisId="left"
                                  name={chartXLabel}
                                  type="monotone"
                                  dataKey="value"
                                  stroke={theme.colors.blue.default}
                                  dot={false}
                                  strokeWidth={2}
                                  isAnimationActive={false}
                                  connectNulls
                            />
                        </ComposedChart>
                    }
                </CustomResponsiveContainer>
            </div>
        </Paper>
    )
};

export default Dynamics;

const styles = (theme) => ({
    chartWrap: {
        height: 310,
    },
    tooltip: {
        background: 'rgba(22, 25, 56, 0.8)',
        color: theme.colors.main.white,
        padding: theme.spacing(2, 3),
        borderRadius: theme.spacing(4),

        '& strong': {
            fontWeight: 500,
        },
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 22,
        fontSize: 15,
    },
    headerTitle: {
        fontSize: 16,
        letterSpacing: '0.002em',
    },
    downloadBtnIcon: {
        width: 24,
        height: 24,
    },

    downloadBtn: {
        color: '#666',
        display: 'flex',
        gap: 4,
        cursor: 'pointer',

        '&:hover': {
            textDecoration: 'underline',
        }
    },
    link: {
        position: 'fixed',
        top: -9999,
        left: -9999,
        fontSize: 0,
    }
});
const useStyles = makeStyles(styles);
