import React from 'react';
import {useSelector} from 'react-redux';
import {t} from '../../i18n/utils.js';
import {tokensGetBearerToken} from '../../redux/reducers/tokens.js';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import {ClickAwayListener} from '@material-ui/core';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import {makeStyles} from '@material-ui/core/styles';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank.js';
import CheckBoxIcon from '@material-ui/icons/CheckBox.js';
import LocalizedLink from '../../localization/LocalizedLink.js';
import {postWithToken} from '../../app/rest.js';

function PrinterMenu(props) {
    const classes = useStyles();
    const { open, anchorRef, onClose, device, deviceId } = props;



    const token = useSelector(tokensGetBearerToken);

    const menuItems = [
        {
            title: t('testPrint'),
            href: ``,
            disabled: false,
            onClick: () => {
                postWithToken(`/v2/api/devices/${deviceId}/print_qr`, {device: deviceId}, 'PUT', token, 'Bearer');
                onClose();
            },
        },];

    const taps = device.modules.filter(m => m.type === 'tap');


    taps.map(m => {
        let keg_id = 0;
        let disabled = true;

        for (const k of m.kegs) {
            if (k.is_empty === false) {
                disabled = false;
                keg_id = k.id;
                break;
            }
        }

       menuItems.push({
           title: t('testPrint2') + m.title,
           href: ``,
           disabled,
           onClick: () => {
               postWithToken(`/v2/api/devices/${keg_id}/print_label`, {device: deviceId}, 'PUT', token, 'Bearer');
               onClose();
           }
       },);
    });
/*
        {
            title: t('testPrint2'),
            href: ``,
            disabled: false,
            onClick: () => {
                postWithToken(`/v2/api/devices/${keg_id}/print_label`, {device: deviceId}, 'PUT', token, 'Bearer');
            }
        },
        ];
 */


    const uncheckedIcon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    return (
        <Popper open={open} anchorEl={anchorRef.current} transition>
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin: placement === 'left bottom',
                    }}
                >
                    <Paper className={classes.paper}>
                        <ClickAwayListener onClickAway={onClose}>
                            <MenuList id="split-button-menu" className={classes.menuList}>
                                {menuItems.map((item, index) => (
                                    item.href ?

                                        (<LocalizedLink to={item.href} key={index}>
                                            <MenuItem
                                                className={classes.menuItem}
                                                disabled={item.disabled}
                                            >
                                                {item.title}
                                            </MenuItem>
                                        </LocalizedLink>)
                                        :
                                        (
                                            <MenuItem
                                                key={index}
                                                className={classes.menuItem}
                                                disabled={item.disabled}
                                                onClick={item.onClick}
                                            >
                                                {item.title}
                                            </MenuItem>
                                        )
                                ))}
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    );
}

export default PrinterMenu;


const styles = (theme) => ({
    paper: {
        zIndex: 200,
        border: '1px solid ' + theme.colors.lightGrey.c68,
        boxShadow: theme.shadows[3],
        borderRadius: 4,
        padding: 0,
        minWidth: 15
    },
    menuList: {
        padding: 8,
    },
    menuItem: {
        padding: 8,
    },
    checkbox: {
        padding: 0,
    }
});
const useStyles = makeStyles(styles);
