import React, {useEffect, useRef, useState} from 'react';
import {Button, Chip, Stack, Paper, Typography} from '@material-ui/core';
import {plural} from '../../app/utils.js';
import {ReactComponent as CalIcon} from '../Dashboard/svg/calIcon.svg';
import {makeStyles} from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import {DateRangePicker} from 'react-date-range';
import * as rdrLocales from 'react-date-range/dist/locale';
import {addDays, differenceInCalendarDays} from 'date-fns';
import {staticRanges} from '../Dashboard/CalendarStats.js';
import CalendarFilter from './CalendarFilter.js';
import Select from '../Dashboard/Select.js';
import {useDispatch, useSelector} from 'react-redux';
import {citiesFetchList, getCitiesList, getCitiesListState} from '../../redux/reducers/cities.js';
import CitiesFilter from './CitiesFilter.js';
import ChainsFilter from './ChainsFilter.js';
import {Clear} from '@material-ui/icons';
import StoresFilter from './StoresFilter.js';

StoresFilter.propTypes = {};
const Filters = ({
                     selectionRange, setSelectionRange,
    selCities, setSelCities,
                     selChains, setSelChains, filters, setFilters,
    selStores, setSelStores,
    ...props
                 }) => {
    const classes = useStyles();

    const handleDeleteCity = (id) => (e, v) => {
        setSelCities(selCities.filter(c => c.id !== id));
    }

    const handleDeleteChain = (id) => (e, v) => {
        setSelChains(selChains.filter(c => c.id !== id));
    }

    const handleDeleteStore = (id) => (e, v) => {
        setSelStores(selStores.filter(c => c.id !== id));
    }

    return (
        <Paper square className={classes.root}>
            <button className={classes.closeBtn} onClick={()=>{
                setFilters(false);
            }}>
                <Clear className={classes.closeIcon} />
            </button>
            <div className={classes.bar}>
                <CalendarFilter selectionRange={selectionRange} setSelectionRange={setSelectionRange} />

                <CitiesFilter selCities={selCities} setSelCities={setSelCities} />
                <ChainsFilter selChains={selChains} setSelChains={setSelChains} />
                <StoresFilter selStores={selStores} setSelStores={setSelStores} selChains={selChains} selCities={selCities}  />
            </div>
            {selChains.length + selCities.length + selStores.length > 0 &&
                <div className={classes.stack}>
                    {selCities.map((c, i) => {
                        const label = c.name;
                        return (
                            <Chip key={i} label={label} onDelete={handleDeleteCity(c.id)} classes={{root: classes.chip}}
                            />
                        );
                    })}
                    {selChains.map((c, i) => {
                        const label = c.name;
                        return (
                            <Chip key={i} label={label} onDelete={handleDeleteChain(c.id)} classes={{root: classes.chip}}
                            />
                        );
                    })}
                    {selStores.map((c, i) => {
                        const label = c.address;
                        return (
                            <Chip key={i} label={label} onDelete={handleDeleteStore(c.id)} classes={{root: classes.chip}}
                            />
                        );
                    })}


                    <a href={"#"} onClick={(e) => {
                        e.preventDefault();
                        setSelChains([]);
                        setSelCities([]);
                        setSelStores([]);
                    }} className={classes.clearLink}>Очистить всё</a>
                </div>
            }
        </Paper>
    )
};

export default Filters;

const styles = (theme) => ({
    root: {
      position: 'relative',
    },
    bar: {
        display: 'flex',
        gap: '8px',

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    stack: {
        marginTop: 16,
        marginLeft: -4,
        marginRight: -4,

        "& > *": {
            margin: 4,
        }
    },
    chip: {
        background: '#F2F4F6',
    },
    clearLink: {
      color: '#007AFF',
        cursor: 'pointer',
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline',
        }
    },
    closeIcon: {
        width: 16,
        color: theme.colors.grey.c100,
    },
    closeBtn: {
        border: 0,
        margin: 0,
        padding: 0,
        background: 'transparent',
        position: 'absolute',
        top: 14,
        right: 24,
        cursor: 'pointer',
    }
});
const useStyles = makeStyles(styles);
