import React from 'react';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import {ClickAwayListener} from '@material-ui/core';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Popper from '@material-ui/core/Popper';
import {useDispatch, useSelector} from 'react-redux';
import {tokensGetAccessToken, tokensGetBearerToken, tokensLogout} from '../../redux/reducers/tokens.js';
import {t} from '../../i18n/utils.js';
import {generatePath, useHistory, useLocation, useRouteMatch} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import {postWithToken} from '../../app/rest.js';
import {userInfo} from '../../redux/reducers/user.js';

const ProfileMenu = props => {
    const classes = useStyles();
    const { open, anchorRef, onClose } = props;

    const dispatch = useDispatch();

    const loc = useLocation();
    const match = useRouteMatch();
    const history = useHistory();

    const uInfoImm = useSelector(userInfo);
    const token = useSelector(tokensGetBearerToken);

    async function changeLanguage(lang) {
        //console.log(match);

        const uInfo = uInfoImm.toJS ? uInfoImm.toJS() : null;
        const user_id = uInfo.id;

        const resp = await postWithToken(`/v2/api/users/language?user_id=${user_id}&language=${lang}` ,
            {}, 'PUT', token, 'Bearer');

        console.log(resp);

        if (resp.language === lang) {
            /*
            const newPath = generatePath(match.path, {
                ...match.params,
                locale: lang,
            });

            const newLoc = {
                ...loc,
                pathname: newPath,
            }

            history.push(newLoc);
             */
            window.location.href = '';
        } else {
            alert('Произошла ошибка смены языка')
        }
    }

    // TODO: load languages from config
    const menuItems = [
        {
            title: 'Русский',
            action: () => {
                //dispatch(setLocale('ru', true, true));
                changeLanguage('ru');
            },
        },
        {
            title: 'English',
            action: () => {
                //dispatch(setLocale('en', true, true));
                changeLanguage('en');
            },
        },
        {
            title: t('profileMenu_logout'),
            action: () => {
                // logout
                dispatch(tokensLogout());
            },
        },
    ];

    const handleMenuItemClick = (event, index) => {
        menuItems[index].action();
        onClose();
    };

    return (
        <Popper open={open} anchorEl={anchorRef.current} transition disablePortal>
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                >
                    <Paper className={classes.paper}>
                        <ClickAwayListener onClickAway={onClose}>
                            <MenuList id="split-button-menu" className={classes.menuList}>
                                {menuItems.map((item, index) => (
                                    <MenuItem
                                        key={item.title}
                                        onClick={(event) => handleMenuItemClick(event, index)}
                                        className={classes.menuItem}
                                    >
                                        {item.title}
                                    </MenuItem>
                                ))}
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    );
}
export default ProfileMenu;

const styles = (theme) => ({
    paper: {
        zIndex: 200,
        border: '1px solid' + theme.colors.lightGrey.c68,
        boxShadow: theme.shadows[3],
        borderRadius: 4,
        padding: 0,
    },
    menuList: {
        padding: 0,
    },
    menuItem: {
        padding: 16,
    },
});
const useStyles = makeStyles(styles);
