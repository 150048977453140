import React from 'react';
import {ReactComponent as LogoSvg} from '../../svg/logo.svg';
import LocalizedLink from '../../localization/LocalizedLink';

/**
 * Site logo
 * @returns {JSX.Element}
 * @constructor
 */
function Logo() {
    return (
        <LocalizedLink to={'/'} rel="home">
            <LogoSvg />
        </LocalizedLink>
    );
}

export default Logo;