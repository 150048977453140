import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {ReactComponent as CheckCircle} from './svg/checkCircle.svg';

const NotificationCount = ({className, count, color, ...props}) => {
    const classes = useStyles();

    const showOk = count === 0;

    if (showOk) {
        return (
            <div className={classes.ok} {...props}>
                <CheckCircle/>
            </div>
        );
    }

    return (
        <div className={classNames(className, classes.circle, color)} {...props}>
            <Typography variant="subtitle1" className={classes.label}>
                {count}
            </Typography>
        </div>
    );
};

NotificationCount.propTypes = {
    count: PropTypes.number.isRequired,
    color: PropTypes.string,
};

export default NotificationCount;

const styles = (theme) => ({
    ok: {
        height: 20,
        width: 20,
        color: theme.colors.colors.success,
    },
    circle: {
        background: theme.colors.grey.c68,
        borderRadius: 30,
        height: 20,
        minWidth: 20,
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '2px 6px',

        '&.green': {
            background: theme.colors.colors.success,
        },
        '&.yellow': {
            background: theme.colors.colors.warning,
        },
        '&.red': {
            background: theme.colors.colors.error,
        },
    },
    label: {
        color: theme.colors.main.white,
        fontWeight: 500,
    },
});
const useStyles = makeStyles(styles);
