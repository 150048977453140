import React from 'react';
import {Button, Typography} from '@material-ui/core';
import Dropdown from './Dropdown.js';
import {makeStyles} from '@material-ui/core/styles';
import {PaginationItem, usePagination} from '@material-ui/lab';
import {ChevronLeft, ChevronRight} from '@material-ui/icons';
import {t} from '../../i18n/utils.js';

function Pagination({total, offset, limit, setLimit, setOffset}) {
    const classes = useStyles();

    const currentPage = Math.ceil(offset / limit) + 1;

    const { items } = usePagination({
        count: Math.ceil(total / limit),
        page: currentPage,
    });

    return (
        <div className={classes.pagination}>
            <div className={classes.paginationPageSize}>
                <Typography>{t('showed')}</Typography>
                <Dropdown className={classes.paginationPageSizeSelect} value={limit} onChange={setLimit} />
                <Typography>{t('from')} {total}</Typography>
            </div>
            <div className={classes.paginationPages}>
                <ul className={classes.ul}>
                    {items.map(({ page, type, selected, ...item }, index) => {
                        let children = null;

                        if (type === 'start-ellipsis' || type === 'end-ellipsis') {
                            children = (
                                <Typography className={classes.paginationItem}>
                                    ...
                                </Typography>
                            );
                        } else if (type === 'page') {
                            children = (
                                <PaginationItem type="page" page={page} shape="round" selected={selected}
                                    color="primary" classes={{root: classes.paginationItem}}
                                                onClick={()=>{
                                                    setOffset((page - 1) * limit);
                                                }}
                                />
                            );
                        } else if (type === 'previous') {
                            children = (
                                <Button classes={{root: classes.paginationItem}} {...item}
                                        onClick={()=>{
                                            if (page >= 1) {
                                                setOffset((page - 1) * limit);
                                            }
                                        }}
                                >
                                    <ChevronLeft />
                                    <Typography style={{marginLeft: 3}}>{t('prev')}</Typography>
                                </Button>
                            );
                        } else if (type === 'next') {
                            children = (
                                <Button classes={{root: classes.paginationItem}} {...item}
                                        onClick={()=>{
                                            if (page <= Math.ceil(total / limit)) {
                                                setOffset((page - 1) * limit);
                                            }
                                        }}
                                >
                                    <Typography style={{marginRight: 3}}>{t('next')}</Typography>
                                    <ChevronRight />
                                </Button>
                            );
                        }

                        return <li key={index}>{children}</li>;
                    })}
                </ul>
            </div>
        </div>
    );
}
export default Pagination;

const styles = theme => ({
    pagination: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'baseline',
        color: theme.colors.main.secondaryText,

        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            gap: '14px',
            justifyContent: 'center',
            alignItems: 'center',
        }
    },
    paginationPageSize: {
        display: 'flex',
        alignItems: 'baseline',
    },
    paginationPageSizeSelect: {
        margin: '0 12px',
        minWidth: 72,
    },
    ul: {
        listStyle: 'none',
        margin: 0,
        padding: 0,
        display: 'flex',

        [theme.breakpoints.down('md')]:
            {
                flexWrap: 'wrap',
                gap: '8px',
                alignItems: 'center',
                justifyContent: 'center',
            },
    },
    paginationItem: {
        minWidth: 28,
        minHeight: 28,
        height: 28,
        borderRadius: 16,
        fontSize: theme.typography.body1.fontSize,
        lineHeight: theme.typography.body1.lineHeight,
        boxSizing: 'border-box',
        padding: '4px 10px',
        color: theme.colors.main.secondaryText,
        fontWeight: theme.typography.body1.fontWeight,
    },
});
const useStyles = makeStyles(styles);
