import React, {useContext} from 'react';
import {Paper, Typography, useTheme} from '@material-ui/core';

import {
    Bar,
    CartesianGrid,
    ComposedChart,
    Label,
    Legend,
    Line,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import {makeStyles} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useSelector} from 'react-redux';
import {statsGraphWFGet, statsGraphWFGetState} from '../../redux/reducers/stats.js';
import {formatDate} from '../../app/utils.js';
import {ifnn} from './DailyStats.js';
import {timeToNum} from './CalendarStats.js';
import {LocaleContext} from '../../i18n/LocaleContext.js';
import moment from 'moment';

const CustomTooltip = ({ active, payload, label }) => {
    const classes = useStyles();

    const locale = useContext(LocaleContext);

    //console.log(payload);

    let pour = null;
    let idle = null;
    let idleRaw = null;

    function formatHours(time) {
        if (!time || !time.split) {
            return '-';
        }

        const split = time.split(':');

        return parseInt(split[0], 10)?.toLocaleString(locale.code, {maximumFractionDigits:2}) + ' ч ' + split[1] + ' м';
    }

    if (payload) {
        payload.forEach(p => {
            if (p.dataKey === 'idle') {
                idle = p.value;
                idleRaw = formatHours(p.payload.idleRaw);
            } else if (p.dataKey === 'pour') {
                pour = p.value;
            }
        });
    }

    //console.log(payload);
    const timeMoment = moment(label);
    const timeStr = timeMoment?.isValid() ? timeMoment.format(locale.dateFormat) : label;

    if (active && payload && payload.length) {
        return (
            <div className={classes.tooltip}>
                <Typography variant="subtitle1"><strong>{timeStr}</strong></Typography>
                <Typography variant="subtitle1">Простой: {ifnn(idleRaw)}</Typography>
                <Typography variant="subtitle1">Пролив: {ifnn(pour?.toLocaleString(locale.code, {maximumFractionDigits:2}))} л</Typography>
            </div>
        );
    }

    return null;
};

function CustomResponsiveContainer(props) {
    return (
        <div style={{ width: '100%', height: '100%', position: 'relative' }}>
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    top: 0,
                    left: 0
                }}
            >
                <ResponsiveContainer {...props} />
            </div>
        </div>
    );
}

function Charts(props) {
    const theme = useTheme();
    const classes = useStyles();

    const locale = useContext(LocaleContext);

    const lang = locale.code;

    const customTick = {
        fontSize: '13',
        fontFamily: 'Rubik',
        fill: theme.colors.grey.c100,
    };

    const dataRaw = useSelector(statsGraphWFGet)?.toJS();

    const gState = useSelector(statsGraphWFGetState);
    const loading = gState === 'loading';

    const dataPour = dataRaw?.pouring;
    const dataIdle = dataRaw?.idle;

    function formatPour(value) {
        return Number(Math.round(value));
    }

    const dataPourX = dataPour?.map(a => ({date: a.date, pour: formatPour(a.value)})) || [];

    function formatIdle(value) {
        return Number(timeToNum(value).toFixed(2));
    }

    const dataIdleX = dataIdle?.map(a => ({date: a.date, idle: formatIdle(a.value), idleRaw: a.value})) || [];

    function merge(idleArr, pourArr) {
        //// MERGE KEYS
        const keysA = idleArr.map(aItem => aItem.date);
        const keysB = pourArr.map(bItem => bItem.date);
        const keysAB = keysA.concat(keysB);

        // add start and end
        /*
        if (props.startDate)
            keysAB.push(formatDate(props.startDate, 'YYYY-MM-DD'));
        if (props.endDate)
            keysAB.push(formatDate(props.endDate, 'YYYY-MM-DD'));
         */

        // deduplicate keys
        const keysABdedup = [...new Set(keysAB)];

        // sort keys
        const keysABsorted = keysABdedup.sort( (a,b) => {
            if (a > b) return 1;
            if (a < b) return -1;
            return 0;
        }  );

        //console.log(keysABsorted);

        return keysABsorted.map(key => {
            const idleItem = idleArr.find(i => i.date === key);
            const pourItem = pourArr.find(i => i.date === key);

            const idle = idleItem ? idleItem.idle : null;
            const idleRaw = idleItem ? idleItem.idleRaw : null;
            const pour = pourItem ? pourItem.pour : null;

            return ({
                date: key,//formatDate(key, props.withTime ? 'HH:mm DD MMMM YYYY' : 'DD MMMM YYYY', lang),
                idle: idle,
                idleRaw: idleRaw,
                pour: pour,
            });
        });
    }

    const data = merge(dataIdleX, dataPourX);
    /*dataPourX.concat(dataIdleX).sort( (a,b) => {
        if (a.date > b.date) return 1;
        if (a.date < b.date) return -1;
        return 0;
    }  );*/

    console.log(data);

    /*
    const data = [
        {
            date: '2021-01-01T00:00:00',
            idle: 100,
            pour: 0,
        },
        {
            date: '2021-01-01T07:00:00',
            idle: 0,
            pour: 50,
        },
        {
            date: '2021-01-01T00:00:00',
            idle: 200,
            pour: 0,
        },
        {
            date: '2021-01-01T07:00:00',
            idle: 0,
            pour: 20,
        },
    ];*/

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    let first_d = data[0];

    const days = [first_d];

    for (let i = 1; i < data.length; i++) {
        const d = data[i];

        const formated1 = formatDate(first_d.date,  'DD MMMM YYYY', lang);
        const formated2 = formatDate(d.date, 'DD MMMM YYYY', lang);

        //console.log(formated1, formated2);

        if (formated1 !== formated2) {
            days.push(d);
            first_d = d;

            //console.log(days);
        }
    }

    return (
        <Paper className={classes.root}>
            <div className={classes.header}>
                <Typography>Общий итог</Typography>
            </div>
            <CustomResponsiveContainer width="99%" height="100%" className={classes.chart}>
                {loading ?
                    <div>Загрузка...</div>
                    :
                    <ComposedChart
                        data={data}
                    >
                        <CartesianGrid stroke="#f5f5f5"/>
                        <XAxis dataKey="date" scale="band"
                               tickLine={false}
                               style={customTick}
                               axisLine={false}
                               tickFormatter={(val)=>{
                                   return formatDate(val, props.withTime ? 'HH:mm' : 'DD.MM', lang);
                               }}
                        />

                        {props.withTime && days.map(d =>
                            <ReferenceLine x={d.date} yAxisId="left" stroke="#aaaaaa" /*label={
                                formatDate(data[1].date, 'DD MMMM YYYY', lang)
                            } */ label={
                                { angle: -90, position: 'right', offset: 10,  value: formatDate(d.date, 'DD MMM', lang), fill: 'black', fontSize: 14 }
                            }/>)
                        }

                        <YAxis yAxisId="left" style={customTick}
                               axisLine={false}
                               tickLine={false}
                               interval="preserveEnd"
                               domain={[ 0, 'auto']}
                               tickFormatter={(val)=>{
                                   return val.toLocaleString(locale.code, {maximumFractionDigits:2})
                               }}
                        >
                            {!isMobile &&
                            <Label angle={-90} dy={45} position="insideLeft"
                                   textAnchor="middle" fontSize={13}
                                   fill={theme.colors.main.secondaryText}
                            >
                                Простой, часы
                            </Label>
                            }
                        </YAxis>

                        <YAxis yAxisId="right" orientation="right" style={customTick}
                               axisLine={false}
                               tickLine={false}
                               interval="preserveEnd"
                               tickFormatter={(val)=>{
                                   return val.toLocaleString(locale.code, {maximumFractionDigits:2})
                               }}
                        >
                            {!isMobile &&
                            <Label angle={90} position="insideRight" dy={45}
                                   textAnchor="middle" fontSize={13} fill={theme.colors.main.secondaryText}
                            >
                                Наливы, литры
                            </Label>
                            }
                        </YAxis>
                        <Tooltip content={<CustomTooltip/>}/>
                        <Legend/>

                        {props.t === 'line' &&
                        <Line yAxisId="left"
                              name="Простой, часы"
                              type="monotone"
                              strokeDasharray="4 4"
                              dataKey="idle"
                              stroke={theme.colors.grey.c100}
                              dot={false}
                              strokeWidth={2}
                              isAnimationActive={false}
                              connectNulls
                        />
                        }
                        {props.t === 'line' &&
                        <Line yAxisId="right"
                              name="Наливы, литры"
                              type="monotone"
                              dataKey="pour"
                              stroke={theme.colors.blue.default}
                              dot={false}
                              strokeWidth={2}
                              isAnimationActive={false}
                              connectNulls
                        />
                        }
                        {props.t === 'bar' &&
                        <Bar yAxisId="left" dataKey="pv" barSize={20} fill={theme.colors.grey.c100}
                             name="Простой, часы"
                             isAnimationActive={false}
                        />
                        }
                        {props.t === 'bar' &&
                        <Bar yAxisId="right" dataKey="uv" barSize={20} fill={theme.colors.blue.default}
                             name="Наливы, литры"
                             isAnimationActive={false}
                        />
                        }
                    </ComposedChart>
                }
            </CustomResponsiveContainer>
        </Paper>
    );
}
export default Charts;

const styles = (theme) => ({
    root: {
        height: 500,
        display: 'flex',
        flexDirection: 'column',
    },
    header: {
        marginBottom: 24,
    },
    chart: {
        flex: 1,
    },
    tooltip: {
        background: 'rgba(22, 25, 56, 0.8)',
        color: theme.colors.main.white,
        padding: theme.spacing(2, 3),
        borderRadius: theme.spacing(4),
    },
});
const useStyles = makeStyles(styles);
