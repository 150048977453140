import {combineReducers} from 'redux-immutable';
import tokens from './tokens';
import user from './user';
import deviceList from './deviceList';
import deviceDetails from './deviceDetails';
import cities from './cities';
import chains from './chains';
import companies from './companies';
import producers from './producers';
import brands from './brands';
import version from './version';
import localeReducer, {reducerName as localeReducerName} from './locale';
import sections from './sections';
import logs from './logs';
import stats from './stats.js';
import storeDetails from './storeDetails.js';

const rootReducer = combineReducers({
    tokens,
    user,
    deviceList,
    deviceDetails,
    storeDetails,
    cities,
    chains,
    producers,
    brands,
    companies,
    version,
    [localeReducerName]: localeReducer,
    sections,
    logs,
    stats,
});

export default rootReducer;
