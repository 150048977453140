import React, {useContext, useEffect, useState} from 'react';
import {Paper, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {getWithToken} from '../../app/rest.js';
import {tokensGetBearerToken} from '../../redux/reducers/tokens.js';
import {useSelector} from 'react-redux';
import {formatDate} from '../../app/utils.js';
import {t} from '../../i18n/utils.js';
import classNames from 'classnames';
import StatPanel from '../Dashboard/StatPanel.js';
import {ifnn} from '../Dashboard/DailyStats.js';
import {LocaleContext} from '../../i18n/LocaleContext.js';
import Badge from '../Dashboard/Badge.js';
import {ReactComponent as InfoSvg} from '../../icons/info.svg';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import './tippy.css';

export const APIPREFIX = '/v2/api'

const MetricsIdle = ({
    dateFrom, dateTo, selCities, selChains, selStores, metrics,
                     ...props
}) => {
    const classes = useStyles();



    const locale = useContext(LocaleContext);
    const lang = locale?.code;



    function formatHours(time) {
        if (!time || !time.split) {
            return '-';
        }

        const split = time.split(':');

        return parseInt(split[0], 10)?.toLocaleString(locale.code, {maximumFractionDigits:2}) + ' ч ' + split[1] + ' м';
    }

    function val(src, type) {
        if (src === null || src === undefined) {
            return '-';
        }

        if (type === 'time') {
            return formatHours(src);
        }

        const num = Number(src);
        if (isNaN(num)) {
            return '-';
        }

        const localizedStr = num.toLocaleString(lang, {maximumFractionDigits: 0});
        return localizedStr;
    }


    function MetricPanel({title, tooltip, value, diff, units, showDiff = 'true', type, ...props}) {
        return (
            <StatPanel label={title} className={classes.panel} loading={!metrics} icon={
                <Tippy className={classes.tippy} content={<span>{tooltip}</span>}>
                    <InfoSvg />
                </Tippy>
            }>
                <div className={classes.metricInner}>
                    <div className={classes.big}>{val(value, type)}</div>
                    { showDiff &&
                        <div className={classes.small}>
                            <Badge v={val(diff) + '%'} t={diff} reverse={true}/>
                            <div>С прошлым периодом</div>
                        </div>
                    }
                </div>
            </StatPanel>
        );
    }

    return (
        <div className={classes.row}>
            <MetricPanel title={'Общее время простоя'}
                         value={metrics?.total_idles}
                         diff={metrics?.diff_idle}
                         units={'ч'}
                         type="time"
                         tooltip={'Сумма всех простоев по отфильтрованным магазинам за выбранный период'}
            />
            <MetricPanel title={'Среднее время простоя в день'}
                         value={metrics?.avg_idles}
                         diff={metrics?.avg_idles_diff}
                         units={'ч'}
                         type="time"
                         tooltip={'Сумма всех простоев по отфильтрованным магазинам за выбранный период/кол-во дней в выбранном периоде'}
            />
        </div>
    )
};

export default MetricsIdle;

const styles = theme => ({
    row: {
        display: 'flex',
        gap: 24,

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    panel: {
        padding: 20,
        flex: 1,
        alignSelf: 'flex-start', // questionable

        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    big: {
        color: '#1D1D1D',
        marginRight: 24,
        display: 'block',
    },
    small: {
        color: '#868A8F',
        fontSize: 13,
        lineHeight: '16px',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 8,
    }
});
const useStyles = makeStyles(styles);
