import React, {useEffect, useState} from 'react';
import MetricsIdle, {APIPREFIX} from './MetricsIdle.js';
import Dynamics from './Dynamics.js';
import {useSelector} from 'react-redux';
import {tokensGetBearerToken} from '../../redux/reducers/tokens.js';
import {getWithToken} from '../../app/rest.js';
import {formatDate} from '../../app/utils.js';
import DynamicsIdle from './DynamicsIdle.js';
import ReasonsIdle from './ReasonsIdle.js';

const IdleLoader = ({
                        dateFrom, dateTo, selCities, selChains, selStores, setSelStores, diff,
                        ...props
                    }) => {

    const [sumary, setSumary] = useState(null);

    const token = useSelector(tokensGetBearerToken);

    async function fetchSummary(dateFrom, dateTo) {
        let query = `?date_from=${dateFrom}&date_to=${dateTo}`;
        const cityIds = selCities.map(c => c.id);
        let t;
        t = cityIds?.map(c => {
            query += `&cities_ids=${c}`;
        });
        const chainIds = selChains.map(c => c.id);
        t = chainIds?.map(c => {
            query += `&chains_ids=${c}`;
        });
        const storeIds = selStores.map(c => c.id);
        t = storeIds?.map(c => {
            query += `&stores_ids=${c}`;
        });
        //&city_ids=${cityIds}&chains_ids=${chainIds}`;

        const resp = await getWithToken(APIPREFIX + '/reports/idles/summary' + query, token, 'Bearer');
        console.log(resp);

        /*
        bottles_by_period: 14113
        bottles_by_period_diff: -6.57
        poured_by_day: 1765.52
        poured_by_day_diff: -7.34
        poured_by_period: 12358.67
        poured_by_period_diff: -7.34
         */

        setSumary(resp);
    }

    useEffect(() => {
        const df = formatDate(dateFrom, 'YYYY-MM-DD');
        const dt = formatDate(dateTo, 'YYYY-MM-DD');
        fetchSummary(df, dt);
    }, [dateFrom, dateTo, selChains, selCities, selStores]);


    return (
        <>
            <MetricsIdle dateFrom={dateFrom} dateTo={dateTo}
                         selCities={selCities} selChains={selChains}
                         selStores={selStores} setSelStores={setSelStores}
                         metrics={sumary?.metrics}
            />
            <DynamicsIdle dateFrom={dateFrom} dateTo={dateTo}
                      selCities={selCities} selChains={selChains}
                      selStores={selStores} setSelStores={setSelStores}
                      withTime={diff < 5}
                      dynamics={sumary?.graphic?.idle || []}
            />
            <ReasonsIdle dateFrom={dateFrom} dateTo={dateTo}
                          selCities={selCities} selChains={selChains}
                          selStores={selStores} setSelStores={setSelStores}
                          withTime={diff < 5}
                          dynamics={sumary?.graphic?.idle || []}
            />
        </>
    );
};

export default IdleLoader;
