import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import React from 'react';
import {getLoadedLanguage as getSavedLocale} from '../redux/reducers/locale';
import {prefixPath} from '../app/utils';

function LocalizedLink({to, localize = true, children, external, disabled, ...props}) {
    const locale = useSelector(getSavedLocale);

    let toProp = localize ? prefixPath(to, locale) : to;

    if (disabled) {
        return (
            <span {...props}>
                {children}
            </span>
        );
    }

    return external ?
        (
            <a {...props} href={toProp}>
                {children}
            </a>
        )
        :
        (
            <Link
                {...props}
                to={toProp}
            >
                {children}
            </Link>
    );
}

export default LocalizedLink;
