import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import StatPanel from './StatPanel.js';
import classNames from 'classnames';
import {Typography} from '@material-ui/core';

import {ReactComponent as ErrorIcon} from './svg/errorIcon.svg';
import {ReactComponent as KegIcon} from './svg/kegIcon.svg';
import {ReactComponent as WarnIcon} from './svg/warnIcon.svg';
import {useDispatch, useSelector} from 'react-redux';
import {statsSummaryWFFetch, statsSummaryWFGet, statsSummaryWFGetState} from '../../redux/reducers/stats.js';

export function ifnn(obj) {
    if (!obj) {
        if (obj === 0) {
            return obj;
        } else {
            return '-'
        }
    } else {
        return obj;
    }
}

function DailyStats({city, store, ...props}) {
    const classes = useStyles();

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(statsSummaryWFFetch(city, store));
    }, [city, store]);

    const summary = useSelector(statsSummaryWFGet)?.toJS();
    const summaryState = useSelector(statsSummaryWFGetState);
    const loading = summaryState === 'loading';

    const redTreshhold = 5;
    const isRed = summary?.taps_error > redTreshhold;

    return (
        <div className={classes.root}>
            <Typography variant="h4" className={classes.header}>Ежедневный мониторинг</Typography>
            <div className={classes.cont}>
                {/*<div className={classes.stats}>*/}
                    <StatPanel label="Краны" className={classNames(classes.statsItem)} loading={loading}>
                        <span className={classes.red}>{ifnn(summary?.blocked_taps)}</span>/{ifnn(summary?.taps_total)}
                    </StatPanel>
                    <StatPanel label="Критические ошибки" red={isRed} icon={<ErrorIcon />} className={classNames(classes.statsItem)} loading={loading}>
                        <span className={classNames({[classes.red]: isRed})}>{ifnn(summary?.count_errors)}</span>
                    </StatPanel>
                {/*</div>
                <div className={classes.stats}>*/}
                    <StatPanel label="Требует реакции" icon={<WarnIcon />} className={classNames(classes.statsItem)} loading={loading}>
                        {ifnn(summary?.count_warnings)}
                    </StatPanel>
                    <StatPanel label="Требуется замена кеги" icon={<KegIcon />} className={classNames(classes.statsItem)} loading={loading}>
                        {ifnn(summary?.keg_replace_needed)}
                    </StatPanel>
                {/*</div>*/}
            </div>
        </div>
    );
}
export default DailyStats;

const styles = (theme) => ({
    root: {
        marginBottom: 28,
    },
    header: {
        marginBottom: 16,
    },
    cont: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: 24,
        [theme.breakpoints.down('md')]: {
            maxWidth: 700,
        },
    },
    stats: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: 24
    },
    statsItem: {
        minWidth: 276,
        //flex: 1,
        [theme.breakpoints.down('md')]: {
            flex: 1,
        },
    },
    red: {
        color: theme.colors.colors.error,
    }
});
const useStyles = makeStyles(styles);
