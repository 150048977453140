import React from 'react';
import {tokensGetAccessToken, tokensRefresh} from '../redux/reducers/tokens';
import {connect} from 'react-redux';
import {userInfo} from '../redux/reducers/user';
import LinearProgress from '@material-ui/core/LinearProgress';
import {Wrap} from '../pages/DevicePage/ModulesPage';

/**
 * Shows children if has the access token.
 * Try to refresh the access token if has the refresh token.
 * Show the Sing in page otherwise
 */
function AuthGuard(props) {
    if (!props.accessToken) {
        /*
        if (props.refreshToken) {
            // has no access, but refresh -- access is expired
            props.tryToRefreshTokens(props.refreshToken);

            return props.refreshingTokensPage;
        }*/

        return props.singInPage;
    }

    if (props.userInfo) {
        return props.children;
    } else {
        return (
            <Wrap>
                <div style={{marginTop: 28}}>
                    <LinearProgress />
                </div>
            </Wrap>
        );
    }
}

function mapStateToProps(state) {
    return {
        accessToken: tokensGetAccessToken(state),
        /*refreshToken: tokensGetRefreshToken(state),*/
        userInfo: userInfo(state),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        tryToRefreshTokens: (refreshToken) => dispatch(tokensRefresh(refreshToken)),
    };
}


export default AuthGuard |> connect(mapStateToProps, mapDispatchToProps);
