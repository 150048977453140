import React, {useContext, useEffect, useState} from 'react';
import Pagination from './Pagination.js';
import {getWithToken} from '../../app/rest.js';
import {useDispatch, useSelector} from 'react-redux';
import {tokensGetBearerToken} from '../../redux/reducers/tokens.js';
import {LinearProgress} from '@material-ui/core';
import Store from './Store.js';
import {makeStyles} from '@material-ui/core/styles';
import {LocaleContext} from '../../i18n/LocaleContext.js';
import {t} from '../../i18n/utils.js';
import {DEVICE_LIST_UPDATE_FILTERS} from '../../redux/reducers/deviceList.js';

function StoresList({chain, city, withProblems, query, setQuery, deviceTypes, errorTypes, ...props}) {

    const deflimit = localStorage.getItem('pagination') || 10;
    console.log('DEFLIMIT', deflimit);


    //console.log('URL', window.location);

    const dispatch = useDispatch();
    const classes = useStyles();
    const [stores, setStores] = useState([]);
    const [limit, setLimit] = useState(deflimit || 10);

    const [error,setError] = useState(false);

    const offset = useSelector(state => state.getIn(['deviceList', 'filters', 'offset']));

    //alert(offset);
    //const [offset, setOffset] = useState(offsetSaved);

    const setOffset = (v) => {
        dispatch({type: DEVICE_LIST_UPDATE_FILTERS, filters: {offset: v}});
    }

    const [total, setTotal] = useState(0);
    const [loaded, setLoaded] = useState(false);

    const token = useSelector(tokensGetBearerToken);

    const locale = useContext(LocaleContext);

    async function loadStores() {
        setLoaded(false);

        let endpoint = `/v2/api/stores/full`;
        let params= `?limit=${limit}&offset=${offset}&with_problems=${withProblems}&lang=${locale.code}`;
        if(city?.length > 0) {
            params += '&' + city.map(c => 'city_ids=' + c).join('&');
        }
        if(chain?.length > 0) {
            params += '&' + chain.map(c => 'chain_ids=' + c).join('&');
        }
        if(deviceTypes?.length > 0) {
            params += '&' + deviceTypes.map(c => 'devices=' + c).join('&');
        }
        if(errorTypes?.length > 0) {
            params += '&' + errorTypes.map(c => 'errors=' + c).join('&');
        }
        if(query?.length > 0) {
            params += '&search=' + query;
        }

        console.log('update, ', params, city);
        //window.history.pushState('','', params);
        //document.location.search = params;

        const resp = await getWithToken(endpoint+params, token, 'Bearer');

        if (resp.items === undefined || resp.items === null) {
            setLoaded(false);
            setTotal(0);
            setStores([]);
            setError(true);
        } else {
            setTotal(resp.total);
            setStores(resp.items);
            setLoaded(true);
            setError(false);
        }
    }

    useEffect(() => {
        //setOffset(0); console.log('reset offset');
        //loadStores();
    }, [chain, city, withProblems, query, deviceTypes, errorTypes]);

    useEffect(() => {
        loadStores();
    }, [limit, offset, chain, city, withProblems, query, deviceTypes, errorTypes]);

    if (!loaded) {
        return (
            <div>
                <LinearProgress />
            </div>
        );
    }

     if (error) {
         return (
             <div className={classes.list}>
                 <strong>{t('washDialogError')}</strong>
             </div>
         );
     }

    return (
        <div className={classes.list}>
            {stores.length === 0 &&
                <strong>{t('deviceListIsEmpty')}</strong>
            }
            {stores.map((store, index) => {
                return (
                    <Store key={index} className={classes.store} store={store} {...props} />
                );
            })}
            {total > limit &&
                <Pagination total={total} offset={offset} limit={limit} setLimit={(l) => {
                    setLimit(l);
                    localStorage.setItem('pagination', l);
                    //dispatch({type: 'DEVICE_LIST_SETLIMIT', limit: l});

                    dispatch({type: DEVICE_LIST_UPDATE_FILTERS, filters: {
                            offset, limit: l, city, chain, withProblems
                        }});
                }} setOffset={(v)=>{
                    setOffset(v);
                    dispatch({type: DEVICE_LIST_UPDATE_FILTERS, filters: {
                            offset: v, limit, city, chain, withProblems
                        }});
                }}/>
            }
        </div>
    );
}

export default StoresList;


const styles = (theme) => ({
    list: {
        display: 'flex',
        flexDirection: 'column',
        //padding: '8px 24px',
        gap: '16px',
    },
});

const useStyles = makeStyles(styles);
