import React from 'react';
import LocalizedLink from '../../localization/LocalizedLink';
import {t} from '../../i18n/utils.js';

function Error404Page() {
    return (
        <main role="main" className="error404">
            <h1>{t('error404_title')}</h1>
            <p>{t('error404_desc')} <LocalizedLink to='/stores/' external={true}>{t('error404_link')}</LocalizedLink></p>
        </main>
    );
}

export default Error404Page;
