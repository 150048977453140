import {getWithToken, postWithToken} from './rest';

export async function apiChangeItem(token, v1token, device, item, value) {
    const body = {
        device_id: parseInt(device, 10),
        device_item_id: item,
        value: value,
        //v1_token: v1token,
    };
    return postWithToken('/v2/api/devices/change_item', body, 'PUT', token, 'Bearer');
}

export async function apiChangeParam(token, v1token, device, item, value) {
    const body = {
        device_id: device,
        device_param_id: item,
        value: value,
        //v1_token: v1token,
    };
    return postWithToken('/v2/api/devices/change_param', body, 'PUT', token, 'Bearer');
}

/**
 * Fetch the device list with minimal info for each one
 *
 * @param token -- access token
 * @param filters -- object with filters e.g: {search: 'hello', offset: 0}
 * @returns {Promise<*|undefined>}
 */
export async function apiDeviceList(token, filters, locale) {
    // convert from obj to request arguments e.g
    // {search: 'hello', offset: 0} ==> ?search=helllo&offet=0
    const keys = filters ? Object.keys(filters) : [];
    const argArr = [];
    keys.map(k => {
        const val = filters[k];
        if(val) {
            argArr.push(`${k}=${val}`);
        }

        return val;
    });
    argArr.push(`lang=${locale}`);
    const argStrSrc = argArr.length > 0 ? '?' + argArr.join('&') : '';
    const argStr = encodeURI(argStrSrc);

    return getWithToken(`/api/devices/details/${argStr}`, token);
}

/**
 * Get full details for the device by id
 *
 * @param token -- access token
 * @param id -- id of the device
 * @returns {Promise<*|undefined>}
 */
export async function apiDeviceDetails(token, id, locale) {
    return getWithToken(`/v2/api/groups/details/${id}?lang=${locale}`, token, 'Bearer');
}

export async function apistoreDetails(token, id,sort='name', locale) {
    return getWithToken(`/v2/api/stores/full/${id}?lang=${locale}&sorting=${sort}`, token, 'Bearer');
}
