import {applyMiddleware, compose, createStore} from 'redux';
import thunk from 'redux-thunk';
import Immutable, {fromJS} from 'immutable';
import rootReducer from './reducers';
import throttle from 'lodash.throttle';
import {tokensGetAccessToken} from './reducers/tokens';
import {version} from './reducers/version';

// noinspection JSUnresolvedVariable
const reduxDevTools = window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        serialize: {
            immutable: Immutable,
        }
    });

const composeEnhancers = reduxDevTools || compose;

const persistedState = fromJS(loadState());

export const store = createStore(rootReducer,
    persistedState,
    composeEnhancers(
        applyMiddleware(
            thunk,
        )
    )
);

function loadState() {
    console.log(`LOAD me`);

    let serializedState;// = sessionStorage.getItem('state');
    let state;// = JSON.parse(serializedState);
    let serializedTokens;// = localStorage.getItem('tokens');
    let tokens;// = JSON.parse(serializedTokens);
    let serializedUser;// = localStorage.getItem('user');
    let user;

    try {
        //console.log(1);
        serializedState = sessionStorage.getItem('state');
        //console.log(2);
        state = JSON.parse(serializedState);
    } catch (e) {
        ///
    }
    try {
       // console.log(3);
        serializedTokens = localStorage.getItem('tokens');
        //console.log(4);
        tokens = JSON.parse(serializedTokens);
    } catch (e) {
        ///
    }
    try {
        //console.log(5);
        serializedUser = localStorage.getItem('user');
        //console.log(6);
        user = JSON.parse(serializedUser);
    } catch (e) {
        ///
    }

    try {
        console.log('SAVED USER', user);

        let result = {
            version: version,
        };

        // check version
        if (state && state.version && state.version < version) {
            // reset all
            return result;
        }

        result = {
            ...result,
            tokens: tokens ? tokens : undefined,
            user: user ? user : undefined,
            ...state,

        }
        return result;
    } catch (err) {
        console.log(err);
        return undefined;
    }
}

function saveState(state) {
    if(!tokensGetAccessToken(state))
        return;

    try {
        // exlude from saving
        state = state.setIn(['locale', 'loadedLanguage'], undefined);
        state = state.setIn(['locale', 'isFetching'], undefined);
        state = state.setIn(['locale', 'error'], undefined);
        state = state.setIn(['deviceDetails'], undefined);
        state = state.setIn(['logs'], undefined);

        const serializedState = JSON.stringify(state);
        sessionStorage.setItem('state', serializedState);
        localStorage.setItem('tokens', JSON.stringify(state.get('tokens')));
        localStorage.setItem('user', JSON.stringify(state.get('user')));
    } catch {
        // ignore write errors
    }
}

store.subscribe(throttle(() => {
    saveState(store.getState());
}, 1000));
