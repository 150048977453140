import React, {useState} from 'react';
import {IconButton} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

import {ReactComponent as MenuIcon} from './svg/menu.svg';
import {useSelector} from 'react-redux';
import {userInfo} from '../../redux/reducers/user.js';
import {t} from '../../i18n/utils.js';
import MobileMenu from '../../componets/Layout2/MobileMenu.js';

function MobileTopBar() {
    const classes = useStyles();

    const [open, setOpen] = useState(false);

    const handleClickAway = () => {
        setOpen(false);
    };

    const [profileMenu, setProfileMenu] = useState(false);
    const profileTabRef = React.useRef(null);


    const user = useSelector(userInfo);
    const username = user?.get('first_name') || user?.get('username');
    const profileBtnLabel = username || t('mainMenu_profile');

    return (
        <div className={classes.wrap}>
            <div className={classes.topbar}>
                <IconButton id="menu-btn" className={classes.menuBtn} onClick={() => {
                    setOpen(true);
                }}>
                    <MenuIcon />
                </IconButton>

                <div className={classes.spacer} />
                <div className={classes.topbarButtons}>
                    {/*<TopBarIcons summary={summary} />*/}
                </div>
                {/*<div className={classNames(classes.topbarButtons, classes.bigButtons)}>
                    <BigBtn icon={<SearchIcon />} />
                    <BigBtn icon={<SettingsIcon />} />
                </div>*/}

            </div>

            {open &&
                <MobileMenu onClose={handleClickAway} />
            }
        </div>
    );
}
export default MobileTopBar;

const topbarHeight = 56;

const styles = (theme) => ({
    menuItem: {
        display: 'flex',
        padding: '10px 20px',
        gap: 18,
        alignItems: 'center',
        cursor: 'pointer',
        color: 'rgba(114, 142, 192, 1)',
        transition: 'all .3s ease',

        "&:hover": {
            background: 'rgba(11, 46, 109, 1)',
            color: theme.colors.main.white,
        },

        "&.active": {
            background: 'rgba(11, 46, 109, 1)',
            color: theme.colors.main.white,
        },
    },
    menuItemLabel: {
        color: theme.colors.main.white,
    },
    menuItemIcon: {
        width: 24,
        height: 24,
        overflow: 'hidden',
    },

    logo: {
        padding: '16px 20px',
    },

    leftMenu: {
        background: theme.colors.main.secondaryBg,
        color: theme.colors.main.white,
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        zIndex: 500,
        width: 264,

        display: 'flex',
        flexDirection: 'column',
    },

    wrap: {
        //paddingTop: topbarHeight,

        [theme.breakpoints.up('lg')]: {
            display: 'none'
        }
    },
    spacer: {
        flex: 1,
    },

    menuBtn: {
        width: 24,
        height: 24,
        padding: 0,
    },

    topbar: {
        display: 'flex',
        background: theme.colors.main.white,
        height: topbarHeight,
        padding: theme.spacing(4, 6),
        boxShadow: theme.shadows[1],
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 100,
    },
    topbarButtons: {
        display: 'flex',
        alignItems: 'center',
    },

    toplineBtn: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),

        '&:last-child': {
            marginRight: 0,
        },
    },
    toplineBtnIcon: {
        width: 24,
        height: 24,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        marginRight: theme.spacing(2),

        '&:last-child': {
            marginRight: 0,
        },
    },
    smallBtnText: {
        color: theme.colors.grey.c100,
    },

    bigButtons:{
        marginLeft: theme.spacing(6),
    },
    toplineBigBtn: {
        width: 24,
        height: 24,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        marginRight: theme.spacing(4),
        '&:last-child': {
            marginRight: 0,
        },
    },

    profileBtn: {
        display: 'flex',
        padding: '10px 20px',
        gap: 12,
        alignItems: 'center',
        cursor: 'pointer',
        color: theme.colors.main.white1,
    },

    profileBtnIcon: {
        width: 40,
        height: 40,
        overflow: 'hidden',
    },
});
const useStyles = makeStyles(styles);
