import React, {useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, TextField, Typography} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {tokensGetBearerToken} from '../../../../redux/reducers/tokens';
import {connect} from 'react-redux';
import {postWithToken} from '../../../../app/rest';
import {t} from '../../../../i18n/utils.js';

function KegDisableDialog({open, device, keg, onClose, ...props}) {
    const classes = useStyles();

    const [val, setV] = useState(null);

    function handleSubmit() {
        const devId = device.get('id');
        const kegId = keg.get('id');
        const reason = val.id;
        const token = props.token;

        postWithToken('/v2/api/devices/disable_keg',
            {
                keg_id: kegId,
                reason: reason,
            },
            'PUT', token,'Bearer').then(resp=>resp === 'error'? Promise.reject(resp) : resp).then(
              resp => {
                    handleClose();
                },
                err => {
                console.error('ERR', err);
                     alert(t('kegDisableDialogError'));
               });

    }

    const empty = keg.get('is_empty');

    const handleClose = () => {
        setV(null);
        onClose();
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth={'md'}>
            <Typography variant="h1" className={classes.heading}>{t('kegDisableDialogHeading', {keg: keg.get('title')})}</Typography>
            {!empty && <div className={classes.stripe}>{t('kegIsNotEmpty')}</div>}
            <DialogContent className={classes.cont}>
                <div className={classes.label}>{t('selectDisableReason')}</div>
                <Autocomplete
                    options={[
                        {id: "expired", name: t('expired')},
                        {id: "invalid_balance", name: t('invalid_balance')},
                        {id: "incorrect_connect", name: t('incorrect_connect')},
                    ]}
                    getOptionLabel={(options) => options?.name}
                    getOptionSelected={(option, value) => option?.id === value?.id}
                    renderInput={params =>
                        <TextField {...params} variant="outlined" />
                    }
                    value={val}
                    onChange={(e,v)=>{
                        setV(v);
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={handleClose}>{t('cancelBtn')}</Button>
                <Button color="primary" variant="contained" disabled={!val} onClick={handleSubmit} id={'kegDisableAccept'}>{t('disableBtn')}</Button>

            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles(theme => ({
    heading: {
        textAlign: "left",
        margin: "24px 0",

        [theme.breakpoints.up('md')]: {
            minWidth: 320,
        },
        fontSize: 19,
        lineHeight: '22px',
        fontWeight: 500,
    },
    stripe: {
        background: "rgba(253, 148, 38, 0.1)",
        padding: "16px 32px",
        marginLeft: -24,
        marginRight: -24,
        marginBottom: 32,
        //minWidth: 320,

        fontWeight: 500,
        fontSize: "13px",
        lineHeight: "16px", /* identical to box height, or 123% */
        letterSpacing: "0.2px",
        color: "#FD9426",
    },
    label: {
        fontSize: "12px",
        lineHeight: "15px",/* identical to box height */
        letterSpacing: "0.00039375px",
        marginBottom: 8,
    },
    cont: {
        marginBottom: 32,
    }
}));

export default KegDisableDialog |> connect(mapStateToProps);

function mapStateToProps(state) {
    return {
        token: tokensGetBearerToken(state),
    };
}
