import React from 'react';
import {makeStyles, Paper, Typography} from '@material-ui/core';
import classNames from 'classnames';

function StatPanel({label, children, className, red, icon, badge, loading, ...props}) {
    const classes = useStyles();

    return (
        <Paper {...props} className={classNames(classes.root, className, {red})}>
            <div className={classes.topline}>
                <Typography className={classes.panelLabel}>{label}</Typography>
                {icon &&
                <div className={classes.icon}>{icon}</div>
                }
            </div>
            {!loading &&
                <React.Fragment>
                    <Typography className={classes.bigFont}>{children}</Typography>
                    {badge &&
                        <div className={classes.badgeplace}>
                            {badge}
                            <Typography variant="subtitle1" className={classes.periodtext}>С прошлым периодом</Typography>
                        </div>
                    }
                </React.Fragment>
            }
            {loading &&
                <div>Загрузка...</div>
            }
        </Paper>
    );
}
export default StatPanel;

const styles = theme => ({
    root: {
        '&.red': {
            border: `1px solid ${theme.palette.error.main}`,
            padding: 19,
        },
    },
    panelLabel: {
        flex: 1,
    },
    icon: {
        width: 24,
        height: 24,
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
    },
    topline: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
    },
    bigFont: {
        fontSize: '32px',
        lineHeight: '32px',
        letterSpacing: '0.002em',

        '&.red': {
            color: theme.palette.error.main,
        }
    },

    periodtext: {
        color: theme.colors.grey.c100,
    },

    badgeplace: {
        display: 'flex',
        gap: 12,
        marginTop: 12,
        alignItems: 'center',
    }
});
const useStyles = makeStyles(styles);