import React from 'react';
import {ClickAwayListener, Popover} from '@material-ui/core';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import {useDispatch, useSelector} from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {getLoadedLanguage} from '../../../redux/reducers/locale';
import {userIsSuper} from '../../../redux/reducers/tokens';
import LocalizedLink from '../../../localization/LocalizedLink';

const useStyles = makeStyles(theme => ({
    popoverRoot: {
        zIndex: 50,
    },
    menuItem: {
        width: '100%',
    },
}));

const DevActionsMenu = props => {
    const classes = useStyles();
    const { open, anchorRef, onClose, device, menuItems } = props;

    const dispatch = useDispatch();
    const locale = useSelector(getLoadedLanguage);

    const indics = device.get('indicators');
    const conInd = indics?.find(ind => ind.get('type') === 'connection');
    const isConnected = conInd?.get('value') === true;
    const isSuper = useSelector(userIsSuper);


    const handleMenuItemClick = (event, index) => {
        menuItems[index].action();
        onClose();
    };

    return (
        <Popover open={open} anchorEl={anchorRef.current}
                classes={{root: classes.popoverRoot}}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
        >
                        <ClickAwayListener onClickAway={onClose}>
                            <MenuList id="split-button-menu">
                                {menuItems.map((item, index) => (
                                    <LocalizedLink disabled={item.disabled} to={item.href} key={index}>
                                        <MenuItem
                                            component="button"
                                            //onClick={(event) => handleMenuItemClick(event, index)}
                                            disabled={item.disabled}
                                            className={classes.menuItem}
                                        >
                                                {item.title}
                                        </MenuItem>
                                    </LocalizedLink>
                                ))}
                            </MenuList>
                        </ClickAwayListener>
        </Popover>
    );
}

export default DevActionsMenu;
