import React, {useContext} from 'react';
import {Button, Dialog, DialogActions, DialogContent, Typography} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {useDispatch} from 'react-redux';
import {DeviceContext} from '../../../componets/DeviceLoader';
import {reboot} from '../../../redux/reducers/deviceDetails';
import {t} from '../../../i18n/utils.js';
import IconButton from '../../../componets/IconButton.js';
import CloseIcon from '@material-ui/icons/Close';
import {colors} from '../../StoresListPage/theme.js';

function  RebootDialog({open, onClose, ...props}) {
    const classes = useStyles();

    const device = useContext(DeviceContext);
    const deviceId = device.get('id');

    const dispatch = useDispatch();

    function handleSubmit() {
        // here
        dispatch(reboot(deviceId));

        onClose();
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <div className={classes.header}>
                {onClose ? (
                    <IconButton
                        onClick={onClose}
                        style={{
                            color: colors.grey.c100,
                            minWidth: 0,
                            minHeight: 0,
                            padding: 0,
                        }}
                    >
                        <CloseIcon style={{
                            width: 24,
                            height: 24,
                            margin: 0,
                        }} />
                    </IconButton>
                ) : null}
            </div>

            <DialogContent className={classes.cont}>
                <Typography className={classes.strong}>{t('rebootPrompt')}</Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onClose}>{t('noBtn')}</Button>
                <Button color="primary" variant="contained" onClick={handleSubmit}>{t('yesBtn')}</Button>
            </DialogActions>
        </Dialog>
    );
}

export default RebootDialog;


const useStyles = makeStyles(theme => ({
    header: {
        height: 56,
        margin: '-16px 0 0',
        //border: '1px solid red',
        padding: 0,
        justifyContent: 'flex-end',
        alignItems: 'center',
        display: 'flex',
    },
    heading: {
        textAlign: "center",
        margin: "24px 0",
        minWidth: 320,
        maxWidth: 580,
        width: "100vw",
    },
    cont: {
        margin: 24,
        marginBottom: 48,
    },
    paramSec: {
        padding: "8px 0",
    },
    strong: {
        fontWeight: 500,
        textAlign: 'center',
    },
}));
