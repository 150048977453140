import React from 'react';

function UserIcon({width = 22, height = 22, ...props}) {
    return (
        <svg width={width} height={height} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 22C8.82441 22 6.69767 21.3549 4.88873 20.1462C3.07979 18.9375 1.66989 17.2195 0.83733 15.2095C0.00476613 13.1995 -0.213071 10.9878 0.211367 8.85401C0.635804 6.72022 1.68345 4.76021 3.22183 3.22183C4.76021 1.68345 6.72022 0.635804 8.85401 0.211367C10.9878 -0.213071 13.1995 0.00476613 15.2095 0.83733C17.2195 1.66989 18.9375 3.07979 20.1462 4.88873C21.3549 6.69767 22 8.82441 22 11C22 13.9174 20.8411 16.7153 18.7782 18.7782C16.7153 20.8411 13.9174 22 11 22ZM11 1.69231C9.15911 1.69231 7.35957 2.2382 5.82893 3.26094C4.29829 4.28368 3.10529 5.73735 2.40082 7.4381C1.69634 9.13886 1.51202 11.0103 1.87116 12.8158C2.2303 14.6214 3.11677 16.2798 4.41847 17.5815C5.72018 18.8832 7.37865 19.7697 9.18416 20.1288C10.9897 20.488 12.8611 20.3037 14.5619 19.5992C16.2627 18.8947 17.7163 17.7017 18.7391 16.1711C19.7618 14.6404 20.3077 12.8409 20.3077 11C20.3077 8.53145 19.3271 6.164 17.5815 4.41847C15.836 2.67294 13.4686 1.69231 11 1.69231Z" fill="currentColor"/>
            <path d="M11.0001 11.8462C10.1633 11.8462 9.34531 11.598 8.64957 11.1331C7.95382 10.6683 7.41155 10.0075 7.09134 9.23443C6.77112 8.46136 6.68734 7.61069 6.85058 6.79C7.01383 5.96931 7.41677 5.21546 8.00845 4.62378C8.60014 4.03209 9.35399 3.62915 10.1747 3.46591C10.9954 3.30266 11.846 3.38645 12.6191 3.70666C13.3922 4.02688 14.0529 4.56915 14.5178 5.26489C14.9827 5.96064 15.2308 6.77862 15.2308 7.61538C15.2308 8.73745 14.7851 9.81357 13.9917 10.607C13.1982 11.4004 12.1221 11.8462 11.0001 11.8462ZM11.0001 5.07692C10.498 5.07692 10.0072 5.2258 9.58976 5.50473C9.17232 5.78366 8.84696 6.18011 8.65483 6.64396C8.4627 7.1078 8.41243 7.6182 8.51037 8.11061C8.60832 8.60303 8.85008 9.05534 9.20509 9.41035C9.5601 9.76536 10.0124 10.0071 10.5048 10.1051C10.9972 10.203 11.5076 10.1527 11.9715 9.96062C12.4353 9.76849 12.8318 9.44312 13.1107 9.02568C13.3896 8.60823 13.5385 8.11744 13.5385 7.61538C13.5385 6.94214 13.2711 6.29647 12.795 5.82042C12.319 5.34437 11.6733 5.07692 11.0001 5.07692Z" fill="currentColor"/>
            <path d="M19.0807 17.7692C18.9633 17.77 18.847 17.7464 18.7392 17.6998C18.6314 17.6531 18.5344 17.5846 18.4546 17.4985C17.5565 16.5188 16.4648 15.7362 15.2486 15.2002C14.0324 14.6643 12.7182 14.3865 11.3892 14.3846H10.6107C8.13338 14.3793 5.75117 15.3382 3.96843 17.0585C3.80372 17.1944 3.5937 17.263 3.38052 17.2505C3.16733 17.2381 2.96674 17.1455 2.81898 16.9913C2.67122 16.8371 2.58723 16.6327 2.58385 16.4192C2.58046 16.2057 2.65794 15.9988 2.80074 15.84C4.89571 13.8151 7.69714 12.686 10.6107 12.6923H11.3892C12.9514 12.6958 14.496 13.0224 15.9259 13.6515C17.3558 14.2805 18.6403 15.1985 19.6984 16.3477C19.8102 16.4682 19.8845 16.6188 19.9121 16.7808C19.9398 16.9429 19.9196 17.1095 19.8541 17.2603C19.7886 17.4111 19.6805 17.5395 19.5432 17.6299C19.4058 17.7203 19.2451 17.7687 19.0807 17.7692Z" fill="currentColor"/>
        </svg>
    );
}

export default UserIcon;