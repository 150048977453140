import React, {useContext, useState} from 'react';
import {useResolution} from '../myHooks';
import PageLayoutDesktop from './PageLayoutDesktop';
import PageLayoutMobile from './PageLayoutMobile';
import {Button, Dialog, DialogContent, Typography} from '@material-ui/core';
import {LocaleContext} from '../../i18n/LocaleContext.js';
import IconButton from '../IconButton.js';
import {colors} from '../../pages/StoresListPage/theme.js';
import CloseIcon from '@material-ui/icons/Close.js';
import makeStyles from '@material-ui/core/styles/makeStyles.js';
import Cookies from 'js-cookie';

export function ChangeDesignWidet(to = "new") {

    const classes = useStyles();
    const lang = useContext(LocaleContext);

    const showNewDesignDialog = Cookies.get('newdesign');
    const showNewDesignMessage = Cookies.get('newdesignMsg');

    const [open, setOpen] = useState(showNewDesignDialog !== 'no');
    const [showMsg, setShowMsg] = useState(showNewDesignMessage !== 'no');

    //alert(lang.code);
    if (lang.code !== "ru") {
        return null;
    }



    const onClose = ()=> {
      setOpen(false);

      Cookies.set('newdesign', 'no');
    };

    const onClose2 = ()=> {
        setShowMsg(false);

        Cookies.set('newdesignMsg', 'no');
    };

        return showMsg && (
            <div style={{
                minHeight: 32,
                padding: 8,
                width: '100%',
                background: '#007AFF',
                color: '#fff',
                textAlign: 'center',
                overflow: 'hidden',
                fontSize: 13,
                lineHeight: '16px',
            }}>
                <a href="https://old.sm.npmgroup.ru" style={{
                    textDecoration: 'underline',
                }}>
                    Вернуться к старому дизайну
                </a>


                <IconButton
                    onClick={onClose2}
                    style={{
                        color: '#fff',
                        minWidth: 0,
                        minHeight: 0,
                        padding: 0,
                        marginLeft: 8,
                    }}
                >
                    <CloseIcon style={{
                        width: 16,
                        height: 16,
                        margin: 0,
                    }}/>
                </IconButton>
            </div>
        );

}


const useStyles = makeStyles(theme => ({
    header: {
        height: 56,
        margin: '-16px 0 0',
        //border: '1px solid red',
        padding: 0,
        justifyContent: 'flex-end',
        alignItems: 'center',
        display: 'flex',
    },
    heading: {
        textAlign: "center",
        margin: "16px 0",
        minWidth: 320,
        maxWidth: 580,
        width: "100vw",
    },
    cont: {
        margin: '0 24px',
        marginBottom: 8,
    },
    paramSec: {
        padding: "8px 0",
    },
    strong: {
        fontWeight: 500,
        textAlign: 'center',
        fontSize: 19,
        lineHeight: '22px',
        marginBottom: 16,
    }
}));



/**
 * Main page layout
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const PageLayout = props => {
    // hooks
    const res = useResolution();

    // props
    const { children } = props;

    if (res === "desktop") {
        // desktop layout
        return (
            <PageLayoutDesktop url={props.url} noContainer={props.noContainer}>
                {children}
            </PageLayoutDesktop>
        );
    } else {
        // mobile layout
        return (
            <PageLayoutMobile pt={props.pt} url={props.url} noContainer={props.noContainer}  customMobileTopBar={props.customMobileTopBar}>
                {children}
            </PageLayoutMobile>
        );
    }
};

export default PageLayout;
