import React from 'react';
import {Route, Switch, useParams} from 'react-router-dom';
import ModulesPage from '../DevicePage/ModulesPage.js';
import {useDispatch, useSelector} from 'react-redux';
import {checkPermission} from '../../redux/reducers/tokens.js';
import StorePage from '../StorePage/StorePage.js';
import WebSocketWrap from '../../componets/WebSocketWrap.js';
import {DEVICE_DETAILS_WS_CHANGE} from '../../redux/reducers/deviceDetails.js';
import Error404Page from '../Error404/Error404Page.js';

const StoreRoute = ({children, ...props}) => {
    const params = useParams();
    const {storeId} = params;

    const dispatch = useDispatch();

    const onMessage = (event) => {
      //console.log(event);
      const data = JSON.parse(event.data);
      //console.log(data);
      dispatch({type: 'DEVICE_DETAILS_WS_CHANGE', data: data});

      if (data.payload_type === 'notification_add') {
          dispatch({type: 'NOTIFICATION_ADD', data: data});
      }

        if (data.payload_type === 'notification_remove') {
            dispatch({type: 'NOTIFICATION_REMOVE', data: data});
        }

    };

    return (<WebSocketWrap url={`/v2/ws/stores/${storeId}`} onMessage={onMessage}>
        <Switch>
        {/* страница устройства */
            <Route path="/:locale/stores/:storeId/groups/:deviceId/modules" component={ModulesPage}/>
        }

        {
            useSelector(checkPermission('access_to_elements_page')) &&
            <Route path="/:locale/stores/:storeId/groups/:deviceId/elements" component={ModulesPage}/>
        }

        {
            useSelector(checkPermission('access_to_read_control_panel')) &&
            <Route path="/:locale/stores/:storeId/groups/:deviceId/settings" component={ModulesPage}/>
        }

        {
            useSelector(checkPermission('access_to_journal')) &&
            <Route path="/:locale/stores/:storeId/groups/:deviceId/log" component={ModulesPage}/>
        }

        {
            useSelector(checkPermission('access_to_user_action_journal')) &&
            <Route path="/:locale/stores/:storeId/groups/:deviceId/userlog" component={ModulesPage}/>
        }

        {
            useSelector(checkPermission('access_to_calibration')) &&
            <Route path="/:locale/stores/:storeId/groups/:deviceId/calibration" component={ModulesPage}/>
        }

        {/* страница устройства */
            <Route path="/:locale/stores/:storeId" exact component={StorePage}/>
        }

        <Error404Page />
        </Switch>
    </WebSocketWrap>);
};

export default StoreRoute;
