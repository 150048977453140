import React, {useContext, useState} from 'react';
import {Paper, Typography, useTheme} from '@material-ui/core';
import classNames from 'classnames';
import NotificationCount from './NotificationCount.js';
import {makeStyles} from '@material-ui/core/styles';
import ConnectionIcon from './ConnectionIcon.js';

import {ReactComponent as TapIcon} from './svg/TapIcon.svg';
import {ReactComponent as KegIcon} from './svg/KegIcon2.svg';
import {ReactComponent as PrinterIcon} from './svg/PrinterIcon.svg';
import {ReactComponent as GasIcon} from './svg/GasIcon.svg';
import {ReactComponent as HubIcon} from './svg/HubIcon.svg';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {t} from '../../i18n/utils.js';
import {Link} from 'react-router-dom';
import {LocaleContext} from '../../i18n/LocaleContext.js';
import NotifDialog from './NotifDialog.js';

const Store = ({className, store, ...props}) => {
    const classes = useStyles();

    const online = store?.is_connected;
    const opacity = !online ? classes.offline : null;

    const [notifdialog, setNotifDialog] = useState(null);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const locale = useContext(LocaleContext);
    const storeId = store.id;

    let errors = {
        'tap': 0,
        'printer': 0,
        'co2': 0,
        'keg': 0,
        'hub': 0,
    };

    let hasErrors = false;

    let notificationCount;
    let notificationsColor;

    if (Array.isArray(store?.notifications)) {
        notificationCount = store?.notifications?.length;

        for (const n of store.notifications) {
            if (n.type === 'error') {
                notificationsColor = 'red';
                break;
            } else if (n.type === 'warning') {
                notificationsColor = 'yellow';
            } else {
                console.log(n);
            }
        }

        for (const n of store.notifications) {
            if (n.type === 'error' || n.type === 'warning') {
                const where = n.module.type;
                errors[where] += 1;
                hasErrors = true;
            }
        }
    }

    function workHours() {
        if (!store.work_hours?.start || !store.work_hours?.end) {
            return null;
        }


        return ` ${store.work_hours?.start} – ${store.work_hours?.end}`
    }

    const isEmpty = store?.groups_count < 1;

    const errorsClass = isMobile ? classes.errorsMobile : classes.errors;
    const errorsIconClass = isMobile ? classes.errorIconMobile : classes.errorIcon;

    const errorsBlock = (
        <div className={classNames(errorsClass, opacity)}>
            <div className={classNames(errorsIconClass, {'visible': errors['tap'] > 0})}  onClick={(ev) => {
                ev.preventDefault();
                ev.stopPropagation();
                // show dialog
                setNotifDialog('tap');
            }}>
                <TapIcon/>
                <Typography variant={'subtitle1'} className={classes.errorNum}>
                    {errors['tap']}
                </Typography>
            </div>
            <div className={classNames(errorsIconClass, {'visible': errors['keg'] > 0})}  onClick={(ev) => {
                ev.preventDefault();
                ev.stopPropagation();
                // show dialog
                setNotifDialog('keg');
            }}>
                <KegIcon/>
                <Typography variant={'subtitle1'} className={classes.errorNum}>
                    {errors['keg']}
                </Typography>
            </div>
            <div className={classNames(errorsIconClass, {'visible': errors['printer'] > 0})}  onClick={(ev) => {
                ev.preventDefault();
                ev.stopPropagation();
                // show dialog
                setNotifDialog('printer');
            }}>
                <PrinterIcon/>
                <Typography variant={'subtitle1'} className={classes.errorNum}>
                    {errors['printer']}
                </Typography>
            </div>
            <div className={classNames(errorsIconClass, {'visible': errors['co2'] > 0})}  onClick={(ev) => {
                ev.preventDefault();
                ev.stopPropagation();
                // show dialog
                setNotifDialog('co2');
            }}>
                <GasIcon/>
                <Typography variant={'subtitle1'} className={classes.errorNum}>
                    {errors['co2']}
                </Typography>
            </div>
            <div className={classNames(errorsIconClass, {'visible': errors['hub'] > 0})}  onClick={(ev) => {
                ev.preventDefault();
                ev.stopPropagation();
                // show dialog
                setNotifDialog('hub');
            }}>
                <HubIcon/>
                <Typography variant={'subtitle1'} className={classes.errorNum}>
                    {errors['hub']}
                </Typography>
            </div>
        </div>
    );

    let rightBlock;

    if (isEmpty) {
        rightBlock = (
            <div className={classNames(errorsClass, classes.emptyBlock)}>
                {t('emptyStore')}
            </div>
        );
    } else {
        rightBlock = props.showErrors && hasErrors && errorsBlock;
    }


    if (isMobile) {
        return (
            <>
                <NotifDialog open={notifdialog !== null} store={store} type={notifdialog} onclose={()=>{
                    setNotifDialog(null);
                }} isStore={true} />
            <Link to={`/${locale.code}/stores/${storeId}/`}>
            <Paper className={classNames(classes.rootMobile, className)}>

                <div className={classes.mobileTop}>
                    <div className={classes.mobileTopGrp}>
                        <div className={classes.iconWrap}>
                            <ConnectionIcon online={online} signalLevel={store?.signal_level}/>
                        </div>
                        <div className={classNames(classes.wrapNotifications, opacity)}>
                            <NotificationCount count={notificationCount} color={notificationsColor} onClick={(ev) => {
                                ev.preventDefault();
                                ev.stopPropagation();
                                // show dialog
                                setNotifDialog('all');
                            }} />
                        </div>
                    </div>
                    <div className={classes.mobileTopGrp}>
                        {props.showTime &&
                        <Typography variant="subtitle1" className={classes.workHours}>{workHours()}</Typography>
                        }
                    </div>
                </div>
                <div>
                    <Typography className={classes.storenameMobile}>{store?.name || '-'}</Typography>
                    {props.showAddress &&
                    <Typography className={classes.mobileaddress}>{store?.address || '-'}</Typography>
                    }
                </div>
                {rightBlock}
            </Paper>
            </Link>
                </>
        );
    }

    const hasIdle = false;

    let idleStr = '';
    if(store?.idle) {
        if (store?.idle?.days) {
            idleStr = t('d', {count: store?.idle?.days });
        } else
        if (store?.idle?.hours) {
            idleStr = t('h', {count: store?.idle?.hours });
        } else
        if (store?.idle?.minutes || store?.idle?.minutes === 0) {
            idleStr = t('m', {count: store?.idle?.minutes });
        }
    } else {
        idleStr = '';
    }

    /*
    return (
        <div className={classes.storeRoot}>
            <div>store info</div>
            <StoreErrors />
        </div>
    );*/

    return (
        <>
            <NotifDialog open={notifdialog != null} store={store} type={notifdialog} onclose={()=>{
                setNotifDialog(null);
            }} isStore={true} />
        <Link to={`/${locale.code}/stores/${storeId}/`}>
        <Paper className={classNames(classes.root, className)}>
            <div className={classNames(classes.grid, classes.info)}>
                <div className={classes.iconWrap}>
                    <ConnectionIcon online={online} signalLevel={store?.signal_level}/>
                </div>
                <div className={classNames(classes.wrapNotifications, opacity)}>
                    <NotificationCount count={notificationCount} color={notificationsColor} onClick={(ev) => {
                        ev.preventDefault();
                        ev.stopPropagation();
                        // show dialog
                        setNotifDialog('all');
                    }} />
                </div>
                <div className={classes.item}>
                    <Typography className={classes.elipsys}>{store?.name || '-'}</Typography>
                    <Typography variant="subtitle1"
                                className={classNames(classes.addressStr, classes.elipsys)}>
                        {props.showAddress && store?.address}
                        {props.showTime && props.showAddress && <>&nbsp;&nbsp;</>}
                        {props.showTime && workHours()}
                    </Typography>
                </div>

                <div className={classNames(classes.idleBlock, {'visible': hasIdle})}>
                    <Typography variant={"subtitle1"} className={classes.idlelabel}>{t('idle')}</Typography>
                    <Typography variant={"subtitle1"}>{idleStr}</Typography>
                </div>

                {rightBlock}
            </div>
        </Paper>
        </Link>
        </>
    );
};

export default Store;

const styles = (theme) => ({
    emptyBlock: {
        color: '#666',

        [theme.breakpoints.down('md')]: {
            display: 'flex !important',
            minWidth: '0 !important',
        },
    },
    storeRoot: {
        background: '#fff',
        padding: '8px 12px 8px 20px',
        borderRadius: 12,
        display: 'flex',
    },

    grid: {
        //margin: 30,
        minWidth: 0,
        display: 'flex',
        flex: 1,
    },
    item: {
        //background: 'red',
        minWidth: 0,
        overflow: 'hidden',
        flexGrow: 1,
        flexBasis: 0,
        marginRight: 10,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: 450,
    },

    elipsys: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        //maxWidth: 332,
    },
    mainName: {
        flex: 1,
        background: 'red',
        minWidth: 0,
    },
    idlelabel: {
        fontSize: 11,
        lineHeight: "14px",
        color: theme.colors.grey.c100,
    },
    idleBlock: {
        width: 64,
        flexGrow: 0,
        flexShrink: 0,
        visibility: 'hidden',

        '&.visible': {
            visibility: 'visible',
        }

    },
    workHours: {
        color: theme.colors.grey.c100,
    },
    mobileaddress: {
        color: theme.colors.grey.c100,
    },
    mobileTop: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 10,
        gap: 8,
        justifyContent: 'space-between',
    },
    mobileTopGrp: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
    },
    storenameMobile: {
        fontWeight: 500,
        fontSize: 17,
        lineHeight: '20px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    rootMobile: {
        padding: '16px',
        cursor: 'pointer',

        "&:hover": {
            boxShadow: theme.shadows[2],
        },
    },

    root: {
        display: 'flex',
        alignItems: 'center',
        padding: '8px 24px',
        cursor: 'pointer',

        "&:hover": {
            boxShadow: theme.shadows[2],
        },
    },
    info: {
        display: 'flex',
        alignItems: 'center',
        gap: '16px',
        //background: 'blue',
        flex: 1,
    },
    iconWrap: {
        height: 24,
        width: 24,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexShrink: 0,
        flexGrow: 0,
    },
    wrapNotifications: {
        height: 24,
        width: 32,
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexShrink: 0,
    },
    addressStr: {
        color: theme.colors.grey.c68,
    },
    offline: {
        opacity: 0.4,
    },
    errors: {
        minWidth: 350,
        display: 'flex',
        gap: '48px',
        paddingLeft: '20px',
        borderLeft: '1px solid ' + theme.colors.lightGrey.c100,
        //marginLeft: '30px',

        [theme.breakpoints.down('md')]: {
            display: 'none'
        },
    },

    errorsMobile: {
        marginTop: 10,
        display: 'flex',
        gap: '42px',
        paddingTop: 11,
        borderTop: '1px solid ' + theme.colors.lightGrey.c100,
    },

    errorIcon: {
        visibility: 'hidden',
        position: 'relative',
        display: 'flex',

        '&.visible': {
            visibility: 'visible',
        }
    },

    errorIconMobile: {
        display: 'none',
        position: 'relative',

        '&.visible': {
            display: 'flex',
        }
    },

    errorNum: {
        color: theme.colors.colors.error,
        fontWeight: 500,
        position: 'absolute',
        left: 26,
    },

});

const useStyles = makeStyles(styles);
