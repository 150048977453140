import React from 'react';
import {ReactComponent as IconSvg} from '../svg/Door.svg';

function GasIcon(props) {
    return (
        <IconSvg {...props} />
    );
}

export default GasIcon;
