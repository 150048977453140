import {useDispatch, useSelector} from 'react-redux';
import React, {useContext, useEffect, useState} from 'react';
import DevicePageWrap from '../ElementsPage/DevicePageWrap';
import {Checkbox, FilledInput, IconButton, InputAdornment, Paper, Typography} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
    fetchEventsPage,
    fetchLogTypes,
    getEventsCount,
    getEventsPage,
    getLogTypes,
} from '../../../redux/reducers/logs';
import {formatDate} from '../../../app/utils';
import classNames from 'classnames';
import TablePagination from '@material-ui/core/TablePagination';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {DatePicker} from '@material-ui/pickers';
import moment from 'moment';
import {getWithToken} from '../../../app/rest';
import {checkPermission, tokensGetBearerToken} from '../../../redux/reducers/tokens';
import EventIcon from '@material-ui/icons/Event';
import ClearIcon from '@material-ui/icons/Clear';

import './customOverrides.css';
import ArrowNavigation from '../SettingsPage/ArrowNavigation';
import {t} from '../../../i18n/utils.js';
import {LocaleContext} from '../../../i18n/LocaleContext.js';

function SpoilerText({content, ...props}) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const maxLen = 200;

    if (!content) {
        return null;
    }

    if (open || content.length < maxLen) {
        return (
            <React.Fragment>
                {content}
            </React.Fragment>
        );
    }

    const shortText = content?.substring(0, maxLen);

    return (
            <React.Fragment>
                {shortText}
                <a href="#" className={classes.link} onClick={(e) => {
                    e.preventDefault();
                    setOpen(true);
                }}>{t('showAll')}</a>
            </React.Fragment>
    );
}

function UserActionLogPage({device, pageList, currentPage, ...props}) {
    const classes = useStyles();

    const dispatch = useDispatch();

    const logTypes = useSelector(getLogTypes);
    const count = useSelector(getEventsCount);
    const logPage = useSelector(getEventsPage);

    const [offset, setOffset] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(35);
    const pageNum = offset / rowsPerPage;

    const deviceId = device.get('id');

    const [category, setCategory] = useState([]);
    const [typeId, setTypeId] = useState([]);

    const [searchVal, setSearchVal] = useState("");
    const [cmdVal, setCmdVal] = useState("");
    const [search, setSearch] = useState("");

    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);

    const [userId, setUserId] = useState(null);

    const [users, setUsers] = useState([]);

    const hasAccessToAllPeriods = useSelector(checkPermission('access_to_all_journal_periods'));
    const minDate = hasAccessToAllPeriods ? undefined : moment();

    const token = useSelector(tokensGetBearerToken);
    const fetchUsers = async () => {
        const resp = await getWithToken('/v2/api/users?limit=9999', token, 'Bearer');
        return resp;
    }

    useEffect(()=>{
        dispatch(fetchLogTypes());

        fetchUsers().then(r => {
            setUsers(r.items);
        });
    }, []);

    const searchForce = () => {
        let dateMoment = moment(dateFrom);
        const dateFromStr = dateFrom ? dateMoment.format('YYYY-MM-DD') : null;

        dateMoment = moment(dateTo);
        const dateToStr = dateTo ? dateMoment.format('YYYY-MM-DD') : null;

        dispatch(fetchEventsPage(deviceId, rowsPerPage, offset, category.join(','), search, typeId.join(','), userId, dateFromStr, dateToStr));
    }

    useEffect(()=>{
        searchForce();
    }, [deviceId, offset, rowsPerPage, category, typeId, search, userId, dateFrom, dateTo]);

    const localeObj = useContext(LocaleContext);

    function doSearch() {
        setSearch(searchVal);
    }

    const pickerFormat = localeObj.dateFormatForPicker;

    console.log(logTypes?.toJS());

    const typeText = logTypes?.size > 0 ? logTypes.map(lt => lt.get('name')) : [];

    const renderCalInput = ({label, ...props}) => {
        return (
            <TextField {...props} placeholder={label} variant="filled"
                       InputProps={{
                           endAdornment: props.value ? (
                               <IconButton
                                   onClick={(event)=>{
                                       event.stopPropagation();
                                       props.from ? setDateFrom(null)  :setDateTo(null);
                                   }}
                                   size="small"
                               >
                                   <ClearIcon />
                               </IconButton>
                           ) : <InputAdornment position="end"><EventIcon /></InputAdornment>,
                       }}
            />
        );
    }

    function formatUserName(usr) {
        if (!usr) {
            return t('systemUser');
        }

        let name;
        if (usr.username?.trim()) {
            name = usr.username?.trim();
        } else {
            name = '-';
        }

        return name
    }

    function getTypeText(id) {
        const typeObj = logTypes?.find(lt=>lt.get('id') === id);
        const typeStr = typeObj?.get('title');

        if (typeStr) {
            return t('eventType_' + typeStr);
        } else {
            return '-';
        }
    }

    const clearAdornment = (visible, clearFn, className) => visible ? (
        <InputAdornment position="end" className={className ? className : classes.clearAdornment} >
            <IconButton
                onClick={clearFn}
                size="small"
            >
                <ClearIcon />
            </IconButton>
        </InputAdornment>
    ) : null;

    const inputProps = {
        endAdornment: clearAdornment,
    };

    return (
        <DevicePageWrap device={device}>
            <Paper className={classes.root}>
                <div className={classes.header}>
                    <Typography variant={"h1"}>{t('userLogHeading')}</Typography>

                    <ArrowNavigation pageList={pageList} currentPage={currentPage} />
                </div>

                <div className={classes.content}>
                    <div className={classes.toolbar}>
                        <div className={classes.toolbarItem}>
                            <DatePicker
                                className={classes.datePicker}
                                from={true}
                                autoOk
                                label={t('dateFrom')}
                                clearable
                                value={dateFrom}
                                onChange={setDateFrom}
                                format={pickerFormat}
                                clearLabel={t('clearLabel')}
                                cancelLabel={t('cancelLabel')}
                                okLabel={t('okLabel')}
                                disableFuture
                                TextFieldComponent={renderCalInput}
                                minDate={minDate}
                            />
                        </div>
                        <div className={classes.toolbarItem}>
                            <DatePicker
                                className={classes.datePicker}
                                autoOk
                                from={false}
                                label={t('dateTo')}
                                clearable
                                value={dateTo}
                                onChange={setDateTo}
                                TextFieldComponent={renderCalInput}
                                format={pickerFormat}
                                clearLabel={t('clearLabel')}
                                cancelLabel={t('cancelLabel')}
                                okLabel={t('okLabel')}
                                disableFuture
                                minDate={minDate}
                            />
                        </div>
                        <div className={classes.toolbarItem}>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <Select
                                    className={classes.select}
                                    value={typeId}
                                    onChange={(ev)=>{
                                        const newVal = ev.target.value;
                                        setTypeId(newVal);
                                    }}
                                    multiple
                                    renderValue={(selected) => !selected.length ? t('type') : selected.map(id=>getTypeText(id)).join(', ')}
                                    variant={"filled"}
                                    displayEmpty
                                    endAdornment={clearAdornment(typeId.length > 0, ()=>{setTypeId([])})}

                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left"
                                        },
                                        getContentAnchorEl: null
                                    }}
                                >
                                    {logTypes?.map((tt, index) => (
                                        <MenuItem value={tt.get('id')}>
                                            <Checkbox checked={typeId.indexOf(tt.get('id')) > -1} />
                                            {getTypeText(tt.get('id'))}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>

                        <div className={classes.toolbarItem}>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <Select
                                    className={classes.select}
                                    value={userId}
                                    onChange={(ev)=>{
                                        const newVal = ev.target.value;
                                        setUserId(newVal);
                                    }}
                                    /*multiple*/
                                    renderValue={(selected) => !selected? t('author') : formatUserName(users.find(u => u.id === selected))}
                                    variant={"filled"}
                                    displayEmpty
                                    endAdornment={clearAdornment(userId, ()=>{setUserId("")})}

                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left"
                                        },
                                        getContentAnchorEl: null
                                    }}
                                >
                                    <MenuItem value=""><em>{t('anyType')}</em></MenuItem>
                                    <MenuItem value="0">{t('systemUser')}</MenuItem>
                                    {users?.map(usr => {
                                        const usrStr = formatUserName(usr);

                                        return (
                                            <MenuItem value={usr.id}>{usrStr}</MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </div>

                        <div className={classNames([classes.toolbarItem, classes.searchField])}>
                            <FilledInput placeholder={t('searchPlaceholder')} variant="filled" value={searchVal} fullWidth={true}
                                       onChange={(ev)=>{
                                           const newVal = ev.target.value;
                                           setSearchVal(newVal);
                                       }}
                                       onBlur={doSearch}
                                       onKeyDown={(e)=>{
                                           if (e.key === 'Enter') {
                                               doSearch();
                                           }
                                       }}
                                         endAdornment={clearAdornment(searchVal, ()=>{setSearchVal(""); setSearch("");}, classes.clearAdornment2)}
                            />
                        </div>
                    </div>

                    <div className={classes.tableWrap}>
                        <table className={classes.table}>
                            <thead>
                            <tr className={classes.tableHeader}>
                                <th className={classes.row1}>
                                    {t('tableLogDateTime')}
                                </th>
                                <th className={classes.row3}>
                                    {t('author')}
                                </th>
                                <th className={classes.row2}>
                                    {t('type')}
                                </th>
                                <th className={classes.row4}>
                                    {t('tableLogText')}
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {logPage?.map((row, index) => {
                                const dtFormat = localeObj.dateTimeFormatSeconds;
                                const datetime = formatDate(row.get('datetime'), dtFormat);

                                const userId = row.getIn(['user_id']);
                                const usr = users.find(u => u.id === userId);
                                console.log('USER', userId, usr, users, row?.toJS());
                                let name = null;
                                if (usr) {
                                    name = formatUserName(usr)
                                } else {
                                    if (!userId) {
                                        name = t('systemUser')
                                    } else {
                                        name = '-' || `User #${userId}`;
                                    }
                                }
                                const author = name;

                                const type = row.get('type');

                                function userLogInfo(row) {
                                    return row.getIn(['value']);
                                }

                                return (
                                    <tr className={classNames(classes.row, `type-${type}`)}>
                                        <td className={classes.cell}>
                                            {datetime}
                                        </td>
                                        <td className={classes.cell}>
                                            {author}
                                        </td>
                                        <td className={classes.cell}>
                                            {getTypeText(row.getIn(['event_type', 'id']))}
                                        </td>
                                        <td className={classNames([classes.cell, classes.cellText])}>
                                            <SpoilerText content={userLogInfo(row)} />
                                        </td>
                                    </tr>
                                    );
                            })}
                            </tbody>
                        </table>

                        <TablePagination
                            className={classes.pagination}
                            component="div"
                            count={count}
                            page={pageNum}
                            onChangePage={(ev, newPage)=>{
                                const newOffset = newPage * rowsPerPage;
                                setOffset(newOffset);
                            }}
                            rowsPerPage={rowsPerPage}
                            rowsPerPageOptions={[10, 35, 50, 100]}
                            onChangeRowsPerPage={(ev)=>{
                                const newVal = ev.target.value;
                                setRowsPerPage(newVal);
                            }}
                        />
                    </div>
                </div>
            </Paper>
        </DevicePageWrap>
    );
}

export default UserActionLogPage;

const useStyles = makeStyles(theme => ({
    clearAdornment: {
        //marginRight: theme.spacing(3),
        position: 'absolute',
        right: 30,
    },
    clearAdornment2: {
        marginRight: theme.spacing(0),
    },

    root: {
        margin: 8,
        padding: 25,
    },
    content: {
        marginTop: '1em',
    },
    row: {
        "&:nth-child(2n)": {
            backgroundColor: '#eee',
        },

        "&.type-0": {
            color: '#5A9740',
        },
        "&.type-1": {
            color: '#A39242',
        },
        "&.type-2": {
            color: '#994242',
        },
        "&.type-4": {
            color: '#407D9E',
        },
    },
    cell: {
        padding: 8,
    },
    toolbar: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '12px',
        justifyContent: 'space-between',
        margin: '2em 0',
    },
    toolbarItem: {
       // minWidth: 200,
    },
    formControl: {
        width: '100%',
    },
    select: {
        width: '100%',
        minWidth: 200,
        maxWidth: 200,

        "&.MuiFilledInput-adornedEnd":  {
            paddingRight: 0,
        },

        "& .MuiSelect-filled": {
            paddingRight: 60,
        }
    },
    tableHeader: {
        background: '#ddd',

        "& > th": {
            textAlign: "left",
            padding: "8px",
        },
    },
    table: {
        border: 0,
        borderSpacing: 0,
        width: '100%',
        tableLayout: 'fixed',
        minWidth: 600,
    },
    pagination: {
        overflow: 'hidden',
        minWidth: 600,
    },
    searchField: {
        flex: 1,
        width: '100%',
    },
    header: {
        display: "flex",
        alignItems: "center",
        gap: "16px",
    },
    datePicker: {
        maxWidth: 160,
    },
    row1: {
        width: '15%',
    },
    row2: {
        width: '15%',
    },
    row3: {
        width: '15%',
    },
    tableWrap: {
        overflow: 'auto',
    },

    // mobile styles
    [theme.breakpoints.down('sm')]: {
        toolbarItem: {
            width: '100%',
        },
        select: {
            maxWidth: 'unset',
        },
        datePicker: {
            maxWidth: 'unset',
            width: '100%',
        },
    },
    cmdBar: {
        marginBottom: '2em',
    },
    cellText: {
        wordBreak: "break-all",
    },
    link: {
        textDecoration: "underline",
        color: "#176BFB",
        marginLeft: "1ch",
    }
}));
