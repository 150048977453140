import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {IconButton} from '@material-ui/core';
import BackIcon from '../../icons/BackIcon';

function BackBtn({className, ...props}) {
    const classes = useStyles();

    return (
        <IconButton {...props} className={[classes.root, className].join(' ')}>
            <BackIcon/>
        </IconButton>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        width: 36,
        height: 36,
    },
}));

export default BackBtn;
