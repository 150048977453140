import React, {useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, TextField, Typography} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {tokensGetBearerToken} from '../../../../redux/reducers/tokens';
import {connect} from 'react-redux';
import {postWithToken} from '../../../../app/rest';
import {t} from '../../../../i18n/utils.js';

function WashDialog({open, device, tap, onClose, ...props}) {
    const classes = useStyles();

    const [val, setV] = useState(null);

    function handleSubmit() {
        const devId = device.get('id');
        const tapId = tap.get('id');
        const washType = val.id;
        const token = props.token;

        postWithToken('/v2/api/devices/washing',
            {
                device_id: tapId,
                washing_type_id: washType,
            },
            'PUT', token, 'Bearer').then(resp=>resp === 'error'? Promise.reject(resp) : resp).then(
              resp => {
                    onClose();
                },
                err => {
                console.error('ERR', err);
                     alert(t('washDialogError'));
               });

    }

    const empty = true;

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md">
            <Typography variant="h1" className={classes.heading}>{t('washDialogHeading')}</Typography>
            {!empty && <div className={classes.stripe}>{t('kegIsNotEmpty')}</div>}
            <DialogContent className={classes.cont}>
                <div className={classes.label}>{t('selectWashType')}</div>
                <Autocomplete
                    options={[
                        {
                            "id": 1,
                            "title": t('washType1')
                        },
                        {
                            "id": 2,
                            "title": t('washType2')
                        },
                        {
                            "id": 3,
                            "title": t('washType3')
                        },
                    ]}
                    getOptionLabel={(options) => options?.title}
                    getOptionSelected={(option, value) => option?.id === value?.id}
                    renderInput={params =>
                        <TextField {...params} variant="outlined" />
                    }
                    value={val}
                    onChange={(e,v)=>{
                        setV(v);
                    }}
                />
            </DialogContent>
            <DialogActions>

                <Button variant="outlined" onClick={onClose}>
                    {t('cancelBtn')}
                </Button>

                <Button color="primary" variant="contained" disabled={!val} onClick={handleSubmit}>
                    {t('washDialogWashBtn')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles(theme => ({
    heading: {
        textAlign: "center",
        margin: "24px 0",

        [theme.breakpoints.up('md')]: {
            minWidth: 320,
        },
    },
    stripe: {
        background: "rgba(253, 148, 38, 0.1)",
        padding: "16px 32px",
        marginLeft: -8,
        marginRight: -8,
        marginBottom: 32,
        minWidth: 320,

        fontWeight: 500,
        fontSize: "13px",
        lineHeight: "16px", /* identical to box height, or 123% */
        letterSpacing: "0.2px",
        color: "#FD9426",
    },
    label: {
        fontSize: "12px",
        lineHeight: "15px",/* identical to box height */
        letterSpacing: "0.00039375px",
        marginBottom: 8,
    },
    cont: {
        marginBottom: 32,
    }
}));

export default WashDialog |> connect(mapStateToProps);

function mapStateToProps(state) {
    return {
        token: tokensGetBearerToken(state),
    };
}
