import React, {useContext} from 'react';
import LocalizedLink from '../../../localization/LocalizedLink';
import {IconButton} from '@material-ui/core';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import {generatePath} from 'react-router';
import {DeviceContext} from '../../../componets/DeviceLoader';
import {LocaleContext} from '../../../i18n/LocaleContext.js';
import {useParams} from 'react-router-dom';

function ArrowNavigation({pageList, currentPage, ...props}) {
    let prevLink = null;
    let nextLink = null;

    const localeObj = useContext(LocaleContext);
    const locale = localeObj.code;

    const device = useContext(DeviceContext);
    const deviceId = device.get('id');

    const params = useParams();
    console.log('PARAMS', params);
    const storeId = params.storeId;

    const currentIndex = pageList.findIndex(p => p === currentPage);

    if (currentIndex > 0) {
        // add previous
        const prevPage = pageList[currentIndex - 1];
        prevLink = generatePath(prevPage, {
            locale,
            storeId,
            deviceId,
        })
    }

    if (currentIndex < pageList.length - 1) {
        // add next
        const nextPage = pageList[currentIndex + 1];
        nextLink = generatePath(nextPage, {
            locale,
            storeId,
            deviceId,
        })
    }

    const Btn = ({link, children}) => link ? (
        <LocalizedLink to={link} localize={false}>
            <IconButton>
                {children}
            </IconButton>
        </LocalizedLink>
    ) : (
        <IconButton disabled>
            {children}
        </IconButton>
    );

    return (
        <div>
            <Btn link={prevLink}><KeyboardArrowLeftIcon /></Btn>
            <Btn link={nextLink}><KeyboardArrowRightIcon /></Btn>
        </div>
    );
}

export default ArrowNavigation;
