import React from 'react';
import classNames from 'classnames';
import {getMostBadNotification} from '../../app/utils';
import {makeStyles} from '@material-ui/core/styles';
import {t} from '../../i18n/utils.js';

const styles = theme => ({
    root: {
        fontWeight: 500,
        fontSize: "13px",
        lineHeight: "16px",
    },
    warn: {
        color: theme.palette.orange,
    },
    error: {
        color: theme.palette.red,
    },
});

const useStyles = makeStyles(styles);

/**
 * Shown notifications count
 */
function NotificationsCounter(props) {
    const {notifs, className} = props;
    const classes = useStyles();
    const count = notifs.size;
    const [mdn, state] = getMostBadNotification(notifs);

    return (
        <span className={classNames(classes.root,
            {
                [classes.warn]: state === "Warn",
                [classes.error]: state === "Error",
            },
            className)}>
            {count} {t('notification', {count})}
        </span>
    );
}

export default NotificationsCounter;