import React, {useRef, useState} from 'react';
import {IconButton} from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import DevActionsMenu from './DevActionsMenu';
import {useDispatch, useSelector} from 'react-redux';
import {getLoadedLanguage} from '../../../redux/reducers/locale';
import {checkPermission} from '../../../redux/reducers/tokens';
import {t} from '../../../i18n/utils.js';
import {useParams} from 'react-router-dom';

function DevActions({device, ...props}) {
    const devActionsRef = useRef(null);
    const [devActions, setDevActions] = useState(false);

    const dispatch = useDispatch();
    const locale = useSelector(getLoadedLanguage);

    const indics = device.get('indicators');
    const conInd = indics?.find(ind => ind.get('type') === 'connection');
    const isConnected = conInd?.get('value') === true;

    const elementsAccess = useSelector(checkPermission('access_to_elements_page'));
    const logAccess = useSelector(checkPermission('access_to_journal'));
    const userLogAccess = useSelector(checkPermission('access_to_user_action_journal'));
    const calibAccess = useSelector(checkPermission('access_to_calibration'));
    const settingsAccess = useSelector(checkPermission('access_to_read_control_panel'));

    const menuItems = [];

    const params = useParams();
    const storeId = params.storeId;

    const groupId = device.get('id');

    if (settingsAccess) {
        menuItems.push({
            title: t('settingsHeading'),
            disabled: false,
            href: `/stores/${storeId}/groups/${device.get('id')}/settings/`,
        });
    }

    if (elementsAccess) {
        menuItems.push({
            title: t('elementsHeading'),
            disabled: false,
            href: `/stores/${storeId}/groups/${groupId}/elements/`,
        });
    }

    if (logAccess) {
        menuItems.push({
            title: t('logHeading'),
            href: `/stores/${storeId}/groups/${groupId}/log/`,
            disabled: false,
        });
    }

    if (userLogAccess) {
        menuItems.push({
            title: t('userLogHeading'),
            href: `/stores/${storeId}/groups/${groupId}/userlog/`,
            disabled: false,
        });
    }

    if (calibAccess) {
        if(device.get('type') === 'beerbox') {
            menuItems.push({
                title: t('calib'),
                //disabled: !isConnected,
                href:  `/stores/${storeId}/groups/${groupId}/calibration/`,
                disabled: false,
            });
        }
    }

    return (
        menuItems.length > 0 ?
        <React.Fragment>
            <IconButton ref={devActionsRef} onClick={() => {
                setDevActions(true);
            }} id={'device-menu-btn'}>
                <MoreHorizIcon/>
            </IconButton>
            <DevActionsMenu open={devActions} anchorRef={devActionsRef} device={device}
                            onClose={() => {
                                setDevActions(false);
                            }} menuItems={menuItems} />
        </React.Fragment>
            : null
    );
}

export default DevActions;
