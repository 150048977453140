import React, {useContext, useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, MenuItem, TextField, Typography} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {useDispatch, useSelector} from 'react-redux';
import {tokensGetAccessToken} from '../../../redux/reducers/tokens';
import {SectionContext} from './ElementsPage';
import {changeItemValue} from '../../../redux/reducers/sections';
import {DeviceContext} from '../../../componets/DeviceLoader';
import {t} from '../../../i18n/utils.js';
import Select from '@material-ui/core/Select'
import {translateNormalize, translateNormalizeOpt} from './Item.js';

/**
 * Диалог изменения айтема
 *
 * @param open
 * @param onClose
 * @param item
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function ItemChangeDialog({open,item,  onClose, itemId, value, title, itemKey, ...props}) {
    const classes = useStyles();

    const [val, setVal] = useState(value);

    const token = useSelector(tokensGetAccessToken);
    const device = useContext(DeviceContext);
    const device_id = device.get('id');
    const section = useContext(SectionContext);

    const dispatch = useDispatch();

    function handleSubmit() {
        dispatch(changeItemValue(itemId, val, itemKey, props.secId));

        /*/ TODO костыльный апдейт
        setTimeout(()=>{
            console.log('UPDATE SECTION', section);
            dispatch(sectionDetailsFetch(section.id));
        }, 3000);*/

        onClose();
    }

    const options = item.options;
    const hasOptions = Array.isArray(options);

    const changer = hasOptions ? (
        <Select
            value={val}
        onChange={(ev)=>{
            const newVal = ev.target.value;
            setVal(newVal);
        }}
            variant='outlined'
            fullWidth={true}
        >
            {options.map((opt, i) => {
                return (
                    <MenuItem value={i} key={i}>{translateNormalizeOpt(item, opt)}</MenuItem>
                );
            })}
        </Select>
    ) : (
        <TextField value={val} placeholder={title} onChange={(ev)=>{
            const newVal = ev.target.value;
            setVal(newVal);
        }}/>
    );

    return (
        <Dialog open={open} onClose={onClose} maxWidth="lg">
            <Typography variant="h1" className={classes.heading}>{t('itemHeading')}: {title}</Typography>

            <DialogContent className={classes.cont}>
                {changer}
            </DialogContent>
            <DialogActions>
                <Button color="primary" variant="outlined" onClick={handleSubmit}>{t('applyBtn')}</Button>
                <Button variant="outlined" onClick={onClose}>{t('cancelBtn')}</Button>
            </DialogActions>
        </Dialog>
    );
}

export default ItemChangeDialog;


const useStyles = makeStyles(theme => ({
    heading: {
        textAlign: "center",
        margin: "24px 0",
        minWidth: 320,
        maxWidth: 580,
        width: "100vw",
    },
    cont: {
        marginBottom: 32,
    },

    paramSec: {
        padding: "8px 0",
    }
}));
