import React, {useContext, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Button, Typography} from '@material-ui/core';

import {ReactComponent as CalIcon} from './svg/calIcon.svg';
import Charts from './Charts.js';
import StatPanel from './StatPanel.js';
import classNames from 'classnames';
import Badge from './Badge.js';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {createStaticRanges, DateRangePicker} from 'react-date-range';
import * as rdrLocales from 'react-date-range/dist/locale';
import Popover from '@material-ui/core/Popover';
import {theme} from '../Dashboard/theme.js';

import {
    addDays,
    addMonths,
    differenceInCalendarDays,
    endOfDay,
    endOfMonth,
    endOfQuarter,
    endOfWeek,
    endOfYear,
    startOfDay,
    startOfMonth,
    startOfQuarter,
    startOfWeek,
    startOfYear,
} from 'date-fns';
import {useDispatch, useSelector} from 'react-redux';
import {statsGraphWFFetch, statsSaleWFFetch, statsSaleWFGet, statsSaleWFGetState} from '../../redux/reducers/stats.js';
import {ifnn} from './DailyStats.js';
import {plural} from '../../app/utils.js';
import {LocaleContext} from '../../i18n/LocaleContext.js';
import {Link} from 'react-router-dom';
import {checkPermission} from '../../redux/reducers/tokens.js';

const weekStart = {weekStartsOn: 1};

export const defineds = {
    startOfWeek: startOfWeek(new Date(), weekStart),
    endOfWeek: endOfWeek(new Date(), weekStart),
    startOfLastWeek: startOfWeek(addDays(new Date(), -7), weekStart),
    endOfLastWeek: endOfWeek(addDays(new Date(), -7), weekStart),
    startOfToday: startOfDay(new Date()),
    endOfToday: endOfDay(new Date()),
    startOfYesterday: startOfDay(addDays(new Date(), -1)),
    endOfYesterday: endOfDay(addDays(new Date(), -1)),
    startOfMonth: startOfMonth(new Date()),
    endOfMonth: endOfMonth(new Date()),
    startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
    endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
    startOfQuarter: startOfQuarter(new Date()),
    endOfQuarter: endOfQuarter(new Date()),
    startOfYear: startOfYear(new Date()),
    endOfYear: endOfYear(new Date()),
};

function upToYesterday(date) {
    return date > defineds.endOfYesterday ? defineds.endOfYesterday : date;
}

export const staticRanges = createStaticRanges([
    /*{
        label: 'Сегодня',
        range: () => ({
            startDate: defineds.startOfToday,
            endDate: defineds.endOfToday,
        }),
    },*/
    {
        label: 'Вчера',
        range: () => ({
            startDate: defineds.startOfYesterday,
            endDate: defineds.endOfYesterday,
        }),
    },
    {
        label: 'Последние 7 дней',
        range: () => ({
            startDate: startOfDay(addDays(new Date(), -6)),
            endDate: defineds.endOfYesterday,
        }),
    },
    {
        label: 'Текущая неделя',
        range: () => ({
            startDate: defineds.startOfWeek,
            endDate: upToYesterday(defineds.endOfWeek),
        }),
    },
    {
        label: 'Текущий месяц',
        range: () => ({
            startDate: defineds.startOfMonth,
            endDate: upToYesterday(defineds.endOfMonth),
        }),
    },
    {
        label: 'Прошлый месяц',
        range: () => ({
            startDate: defineds.startOfLastMonth,
            endDate: upToYesterday(defineds.endOfLastMonth),
        }),
    },
    {
        label: 'Текущий квартал',
        range: () => ({
            startDate: defineds.startOfQuarter,
            endDate: upToYesterday(defineds.endOfQuarter),
        }),
    },
    {
        label: 'Текущий год',
        range: () => ({
            startDate: defineds.startOfYear,
            endDate: upToYesterday(defineds.endOfYear),
        }),
    },
]);



export function timeToNum(time) {
    if (!time?.split) {
        return 0;
    }

    //console.log(time);
    const split = time.split(':');
    return parseInt(split[0]) + parseInt(split[1]) / 60 + (parseInt(split[2]) / 60) / 60;
}

function CalendarStats({city, store, chains, ...props}) {
    const classes = useStyles();
    const locale = useContext(LocaleContext);

    const sale = useSelector(statsSaleWFGet)?.toJS();

    const saleState = useSelector(statsSaleWFGetState);
    const loading = saleState === 'loading';

    const hasAccessToAllPeriods = useSelector(checkPermission('access_to_all_statistic_periods'));

    const [anchorEl, setAnchorEl] = React.useState(null);

    const [selectionRange, setSelectionRange] = useState(
        {
            startDate: addDays(new Date(), -7),
            endDate: addDays(new Date(), -1),
            key: 'selection',
        }
    );

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(statsSaleWFFetch(selectionRange, city, store, chains));
        dispatch(statsGraphWFFetch(selectionRange, city, store, chains));
    }, [selectionRange, city, store, chains]);

    function handleSelect(ranges){
        //console.log('RANGES', ranges);

        const keys = Object.keys(ranges);
        const key = keys[0];

        setSelectionRange(ranges[key]);
        // {
        //   selection: {
        //     startDate: [native Date Object],
        //     endDate: [native Date Object],
        //   }
        // }
    }

    function formatHours(time) {
        if (!time || !time.split) {
            return '-';
        }

        const split = time.split(':');

        return parseInt(split[0], 10)?.toLocaleString(locale.code, {maximumFractionDigits:2}) + ' ч ' + split[1] + ' м';
    }

    function formatPoured(poured) {
        return poured.toLocaleString(locale.code, {maximumFractionDigits:2})
    }

    const poured = ifnn(sale?.poured);

    const diff = differenceInCalendarDays(selectionRange.endDate, selectionRange.startDate) + 1;

    //console.log(sale, saleLast);
    let pourDiff = sale?.poured_diff;
    let idleDiff = sale?.idle_diff;

    /*
    pourDiff = pourDiff.toFixed(2);
    idleDiff = idleDiff.toFixed(2);
     */
    const lang = locale.code;

    let pourBadge = pourDiff !== undefined && pourDiff !== null ? <Badge v={!isFinite(pourDiff) ? '-' : `${Number(pourDiff).toLocaleString(lang, {maximumFractionDigits: 2})}%`} t={pourDiff} /> : null;
    let idleBadge = idleDiff !== undefined && idleDiff !== null ? <Badge v={!isFinite(idleDiff) ? '-' :`${Number(idleDiff).toLocaleString(lang, {maximumFractionDigits: 2})}%`} t={idleDiff} reverse  /> : null;

    let rangeSelected = null;

    for (let r of staticRanges) {
        const sel = r.isSelected(selectionRange);
        if (sel) {
            rangeSelected = r;
            break;
        }
    }/*
    staticRanges.map(r => {
        const sel = r.isSelected(selectionRange);
        console.log(r.label, sel);
    });*/

    const showTime = diff <= 5;

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <Typography variant="h4">Показатели за</Typography>

                <Button variant="contained" color="primary" className={classes.calBtn} onClick={handleClick}
                    disabled={!hasAccessToAllPeriods}
                >
                    <Typography>
                        {rangeSelected ?
                            <React.Fragment>{rangeSelected.label}</React.Fragment>
                            :
                            <React.Fragment>{diff} {plural(diff, 'день', 'дня', 'дней')}</React.Fragment>
                        }
                    </Typography>
                    <div className={classes.btnIcon}>
                        <CalIcon />
                    </div>
                </Button>

                {open &&
                    <Popover
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        elevation={2}
                        className={classes.datePickerPopover}
                    >
                        <DateRangePicker
                            ranges={[selectionRange]}
                            onChange={handleSelect}
                            locale={rdrLocales.ru}
                            staticRanges={staticRanges}
                            inputRanges={[]}
                            className={classes.rdp}
                            maxDate={addDays(new Date(), -1)}
                        />
                    </Popover>
                }
            </div>

            <div className={classes.cols}>
                <div className={classes.col1}>
                    {/*
                    <div className={classes.stats}>
                        <StatPanel label="Пролив" className={classNames(classes.statsItem)} badge={pourBadge} loading={loading}>
                            {poured} {poured !== '-' ? 'л' : ''}
                        </StatPanel>
                        <StatPanel label="Простой" className={classNames(classes.statsItem)} badge={idleBadge} loading={loading}>
                            {formatHours(sale?.idle)}
                        </StatPanel>
                    </div>*/}
                    <div className={classes.charts}>
                        <Charts t="line"
                            startDate={selectionRange.startDate}
                            endDate={selectionRange.endDate}
                            withTime={showTime}
                        />
                    </div>
                </div>
                <div className={classes.stats2}>
                    <Link
                        to={{
                            pathname: `/${lang}/new_reports/dispensing`,
                            state: {
                                range: selectionRange,
                                cities: city ? [city] : undefined,
                                chains: chains ? chains : undefined,
                                stores: store.map(s => {
                                    return {
                                        ...s,
                                        address: s.name,
                                    }
                                }),
                            }
                        }}

                        className={classes.linkBx}
                    >
                        <StatPanel label="Наливы" className={classNames(classes.statsItem)} badge={pourBadge} loading={loading}>
                            {formatPoured(poured)} {poured !== '-' ? 'л' : ''}
                        </StatPanel>
                    </Link>
                    <Link
                        className={classes.linkBx}
                        to={{
                            pathname: `/${lang}/new_reports/idle`,
                            state: {
                                range: selectionRange,
                                cities: city ? [city] : undefined,
                                chains: chains ? chains : undefined,
                                stores: store.map(s => {
                                    return {
                                        ...s,
                                        address: s.name,
                                    }
                                }),
                            }
                        }}
                    >
                        <StatPanel label="Простой" className={classNames(classes.statsItem)} badge={idleBadge} loading={loading}>
                            {formatHours(sale?.idle)}
                        </StatPanel>
                    </Link>
                </div>
                {/*<SaleStats loading={loading} />*/}
            </div>
        </div>
    );
}
export default CalendarStats;

const styles = (theme) => ({
    datePickerPopover: {
        "& .rdrDefinedRangesWrapper": {
            [theme.breakpoints.down('md')]: {
                display: 'none',
            }
        },
    },
    root: {

    },
    header: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 16,
        flexWrap: 'wrap',
        gap: 12,
    },

    btnIcon: {
        marginLeft: 8,
        width: 24,
        height: 24,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: -2,
        marginBottom: -2,
    },
    stats: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: 24,
        marginBottom: 24,
    },
    stats2: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        gap: 24,
        alignSelf: 'flex-start',
        flex: 1,
        maxWidth: 360,
        [theme.breakpoints.down('lg')]: {
            flexGrow: 1,
            width: 'auto',
            maxWidth: 700,
            flexDirection: 'row',
            flexWrap: 'wrap',
        }
    },
    statsItem: {
        flex: 1,
        minWidth: 276,
    },

    charts: {
        display: 'flex',
        flexDirection: 'column',
        gap: 24.
    },

    col1: {
        width: '100%',
        maxWidth: 700,
        flexGrow: 1,
        flexShrink: 0,
    },

    cols: {
        display: 'flex',
        gap: 24,
        flexWrap: 'wrap',
    },

    rdp: {
        width: '100%',

        '& .rdrMonth': {
            width: 'auto',
        },
        '& .rdrDateRangeWrapper': {
            width: '100%',
        },
    },
    linkBx: {
    textDecoration: 'none',
        cursor: 'pointer',

        '&:hover': {
        boxShadow: theme.shadows[2],
            borderRadius: 16,
    },
    },
});
const useStyles = makeStyles(styles);
