import React from 'react';
import {useSelector} from 'react-redux';
import {getLoadedLanguage} from '../../redux/reducers/locale.js';
import {t} from '../../i18n/utils.js';
import {checkPermission, tokensGetAccessToken} from '../../redux/reducers/tokens.js';
import {ReactComponent as DashboardIcon} from '../../icons/menu/Home.svg';


import {prefixPath} from '../../app/utils.js';
import {ReactComponent as ShopIcon} from '../../icons/menu/Stores.svg';
import {ReactComponent as ReportsIcon} from '../../icons/menu/Reports.svg';
import {ReactComponent as ManageIcon} from '../../icons/menu/Admin.svg';
import {ReactComponent as InfoIcon} from '../../icons/menu/Help.svg';
import {ReactComponent as TarifIcon} from '../../icons/menu/Tariffs.svg';
import {ReactComponent as DocsIcon} from '../../icons/menu/Documents.svg';
import {makeStyles} from '@material-ui/styles';

export function MenuItems() {
    const classes = useStyles();
    const locale = useSelector(getLoadedLanguage);

    const currentDomain = window.location.hostname;
    const isProduction = currentDomain === 'sm.npmgroup.ru'; // TODO: change to compile option
    const supportMail = 'beerbox3@npmgroup.ru,ivan.ryzhikov@npmgroup.ru,natalia.lehova@npmgroup.ru'
    const supportLink = `mailto:${supportMail}?subject=${t('supportMailSubj')}`

    const token = useSelector(tokensGetAccessToken);

    const ruLocale = locale === 'ru';

    const menuItems = [
        {
            label: t('mainMenu_dashboard'),
            icon: (<DashboardIcon className={classes.tempIcon} />),
            disabled: false,
            href: prefixPath('/dashboard', locale),
        },
        {
            label: t('mainMenu_stores'),
            icon: (<ShopIcon  className={classes.tempIcon}  />),
            disabled: false,
            href: prefixPath('/stores', locale),
        },
        /*
            label: t('mainMenu_reports'),
            icon: <ReportsIcon  className={classes.tempIcon} />,
            disabled: !useSelector(checkPermission('section_reports')),
            href: prefixPath('/reports', locale),
        */
        {
            label: t('mainMenu_reports'),
            icon: <ReportsIcon  className={classes.tempIcon} />,
            disabled: !useSelector(checkPermission('section_reports')),
            href: prefixPath('/new_reports', locale),
        },
        {
            label: t('mainMenu_tarifs'),
            icon: <TarifIcon  className={classes.tempIcon} />,
            disabled: !useSelector(checkPermission('access_to_tariffs')) || !ruLocale,
            href: prefixPath('/tarif', locale),
        },
        {
            label: t('mainMenu_docs'),
            icon: <DocsIcon  className={classes.tempIcon} />,
            disabled: !useSelector(checkPermission('access_to_tariffs')) || !ruLocale,
            href: prefixPath('/docs', locale),
        },
        {
            label: t('mainMenu_admin'),
            icon: <ManageIcon  className={classes.tempIcon} />,
            disabled: !useSelector(checkPermission('section_service')),
            href: prefixPath('/adm', locale),
        },
        {
            label: t('mainMenu_support'),
            icon: <InfoIcon className={classes.tempIcon} />,
            disabled: !useSelector(checkPermission('section_help')),
            href: locale === 'ru' ? 'https://soft-npm.gitbook.io/smart-manager/' : 'https://docs.google.com/document/d/19sk1anNr43pQUpFJgiUMTnAqU0lFWaBHIlbgaZk3aGw/edit?usp=sharing',
            external: true,
            target: '_blank',
        },
    ];

    console.log('MENU ITEMS UPDATE', menuItems);

    return menuItems;
}

const useStyles = makeStyles({
    tempIcon: {
        width: "100%",
        height: "100%",
    },
});
