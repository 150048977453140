import React, {useRef, useState} from 'react';
import {IconButton, Typography} from '@material-ui/core';
import classNames from 'classnames';
import {ReactComponent as SettingsIcon} from '../StoresListPage/svg/settings.svg';
import {makeStyles} from '@material-ui/core/styles';
import BackButton from './BackButton.js';
import {ReactComponent as PrinterIcon} from '../StorePage/svg/printer.svg';
import SettingsMenu from './SettingsMenu.js';
import {theme} from '../StoresListPage/theme.js';

function Topbar({name, backUrl, address, ...props}) {
    const classes = useStyles();

    const [settings, setSettings] = useState(false);
    const ref = useRef(null);

    return (
        <div className={classes.overallBlock}>
            <div className={classes.addressBlock}>
                <Typography variant={'h3'} style={{color: theme.colors.main.mainText}}>{address}</Typography>
            </div>
            <div className={classes.topbar}>
                <BackButton className={classes.back} href={backUrl}/>

                <div className={classes.moduleName}>
                    <div className={classNames(classes.iconWrap, {'yellow': props.hasWarns}, {'red': props.hasErrors})}>
                        <PrinterIcon/>
                    </div>
                    <div>
                        <Typography variant={'h3'} className={classes.moduleNameText}>
                            {name}
                        </Typography>
                        <Typography variant={'subtitle1'} className={classes.addressText}>
                            {address}
                        </Typography>
                    </div>
                </div>

                <div className={classes.spacer}/>

                <div className={classNames(classes.topbarButtons, classes.bigButtons)}>
                    <IconButton className={classNames(classes.toplineBigBtn2, {'pressed': settings}, 'lastChild')}
                                onClick={() => {
                                    setSettings(true);
                                }} ref={ref}
                    ><SettingsIcon/></IconButton>
                    <SettingsMenu open={settings} anchorRef={ref} {...props} onClose={() => setSettings(false)}/>
                </div>

            </div>
        </div>
    );
}

export default Topbar;


const topbarHeight = 68;

const styles = (theme) => ({
    iconWrap: {
        '&.red': {
            color: '#FF6370',
        },
        '&.yellow': {
            color: '#FFAE17',
        },
    },
    moduleName: {
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        color: theme.colors.main.mainText,

    },
    moduleNameText: {
        fontSize: 19,
        lineHeight: '32px',
    },
    searchField: {
        position: 'absolute',
        zIndex: 800,
        background: '#F2F4F6',
        top: 10,
        left: 64,
        bottom: 8,
        borderRadius: 4,
        right: 8,
        display: 'none',
        border: 'none !important',
        outline: 'none !important',
        boxShadow: 'none !important',
        height: 36,

        '& fieldset': {
            display: 'none',
        },

        '&.visible': {
            display: 'inherit',
        },
    },
    menuItem: {
        display: 'flex',
        padding: '10px 20px',
        gap: 18,
        alignItems: 'center',
        cursor: 'pointer',
        color: 'rgba(114, 142, 192, 1)',
        transition: 'all .3s ease',

        '&:hover': {
            background: 'rgba(11, 46, 109, 1)',
            color: theme.colors.main.white,
        },

        '&.active': {
            background: 'rgba(11, 46, 109, 1)',
            color: theme.colors.main.white,
        },
    },
    menuItemLabel: {
        color: theme.colors.main.white,
    },
    menuItemIcon: {
        width: 24,
        height: 24,
        overflow: 'hidden',
    },

    logo: {
        padding: '16px 20px',
    },

    leftMenu: {
        background: theme.colors.main.secondaryBg,
        color: theme.colors.main.white,
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        zIndex: 500,
        width: 264,

        display: 'flex',
        flexDirection: 'column',
    },

    addressText: {
        color: '#868A8F',

        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },

    toplineBtn: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: theme.spacing(3),
        marginLeft: theme.spacing(0),
        fontWeight: 'normal',

        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },

    wrap: {
        //paddingTop: topbarHeight,

        [theme.breakpoints.up('lg')]: {
            display: 'none',
        },
    },
    spacer: {
        flex: 1,
    },

    menuBtn: {
        width: 24,
        height: 24,
        padding: 0,
    },

    back: {
        marginRight: 22,

        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },

    topbar: {
        display: 'flex',
        padding: theme.spacing(4, 6),

        [theme.breakpoints.down('md')]: {
            padding: '14px 20px',
        },
    },

    addressBlock: {
        padding: theme.spacing(4, 6),
        [theme.breakpoints.down('md')]: {
            padding: '14px 20px',
        },
        borderBottom: '1px solid #DFE3E9',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },

    overallBlock: {
        background: theme.colors.main.white,

        boxShadow: theme.shadows[1],
        marginBottom: 16,
    },

    topbarButtons: {
        display: 'flex',
        alignItems: 'center',

        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },

    toplineBtnIcon: {
        width: 24,
        height: 24,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        marginRight: theme.spacing(2),

        '&:last-child': {
            marginRight: 0,
        },
    },
    smallBtnText: {
        color: theme.colors.grey.c100,
    },

    bigButtons: {
        marginLeft: theme.spacing(6),
    },
    toplineBigBtn: {
        width: 24,
        height: 24,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        marginRight: theme.spacing(4),
        '&:last-child': {
            marginRight: 0,
        },
        padding: 0,
    },

    toplineBigBtn2: {
        width: 36,
        height: 36,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        marginRight: theme.spacing(2),
        '&.lastChild': {
            marginRight: 0,
        },
        padding: 0,

        '&.pressed': {
            background: '#007AFF1A',
            color: '#007AFF',

            '&.search': {
                display: 'none',
            },
        },
    },

    profileBtn: {
        display: 'flex',
        padding: '10px 20px',
        gap: 12,
        alignItems: 'center',
        cursor: 'pointer',
        color: theme.colors.main.white1,
    },

    profileBtnIcon: {
        width: 40,
        height: 40,
        overflow: 'hidden',
    },

    fab: {
        position: 'fixed',
        bottom: 24,
        right: 24,
        width: 44,
        height: 44,
        display: 'none',

        [theme.breakpoints.down('sm')]: {
            display: 'flex',
        },
    },
});
const useStyles = makeStyles(styles);
