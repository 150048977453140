import React, {useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {LocaleContext} from '../../i18n/LocaleContext.js';
import {tokensGetBearerToken} from '../../redux/reducers/tokens.js';
import {useDispatch, useSelector} from 'react-redux';
import {LinearProgress, ThemeProvider, Typography} from '@material-ui/core';
import PageLayout from '../../componets/PageLayout/PageLayout.js';
import {makeStyles} from '@material-ui/core/styles';
import {theme} from '../StoresListPage/theme.js';
import * as colors from './uiKit/colors.js';
import TabsFilter from './components/TabsFilter.js';
import classNames from 'classnames';
import MobileTopBar from './MobileTopBar.js';
import {t} from '../../i18n/utils.js';
import {statsSummaryWFGet} from '../../redux/reducers/stats.js';
import {setTitle} from '../StoresListPage/StoresPage.js';
import DeviceGroup from './DeviceGroup.js';
import NotifDialog from '../StoresListPage/NotifDialog.js';
import BackButton from '../TapPage/BackButton.js';
import {fetchstoreDetails, getstoreDetails} from '../../redux/reducers/storeDetails.js';

function workHours(store) {
    if (!store?.work_hours?.start || !store?.work_hours?.end) {
        return '-';
    }


    return ` ${store.work_hours?.start} – ${store.work_hours?.end}`
}

function StorePage() {
    const classes = useStyles();
    const params = useParams();
    const id = params?.storeId;

    const [tabsFilter, setTabsFilter] = useState(0);

    const types = [
        'all',
        'tap',
        'manager',
        'printer',
        'temperature',
    ];

    const locale = useContext(LocaleContext);
    const lang = locale.code;

    const token = useSelector(tokensGetBearerToken);

    const [sort, setSort] = useState('name');


    const store = useSelector(state => getstoreDetails(state, id))?.toJS() || null;

    console.log(store);

    const [error, setError] = useState(null);

    const [notifdialog, setNotifDialog] = useState(null);
    const [name, setName] = useState('-');

    useEffect(() => {
        dispatch(fetchstoreDetails(id, sort));
    }, [sort]);

    const devices = [];
    const grps = store?.groups;

    if (grps) {
        for (const g of store?.groups) {
            const ents = Object.entries(g?.devices);
            devices.push(...ents);
        }
    }

    //console.log(devices);

    const dispatch = useDispatch();

    const summary = useSelector(statsSummaryWFGet)?.toJS();

    const storeName = store?.name || t('store');

    setTitle(storeName);

    if (store?.isLoading) {
        return (
            <ThemeProvider theme={theme}>
                <PageLayout noContainer={true} customMobileTopBar={<MobileTopBar
                    setSearch={()=>{}} store={id} summary={summary} backUrl={`/${locale.code}/stores/`}
                />}>
                    <LinearProgress />
                </PageLayout>
            </ThemeProvider>
        );
    }

    let address = store?.address;

    if (store?.city?.name) {
        address = store?.city?.name + ', ' + address;
    }

    return (
        <ThemeProvider theme={theme}>
        <PageLayout noContainer={true} customMobileTopBar={<MobileTopBar
            setSearch={()=>{}} store={id} summary={summary} backUrl={`/${locale.code}/stores/`}
        />}>
            <div className={classes.header}>
                <div className={classNames(classes.container, classes.headerContainer)}>
                    <div className={classes.inner}>
                        <BackButton className={classes.back} href={`/${locale.code}/stores/`} />

                        <div  className={classes.storeInfo}>
                            {!error ?
                                <>
                                    <Typography variant={'h1'} className={classes.storeName}>{store?.name}</Typography>
                                    <Typography
                                        className={classes.storeAddress}>{address}, {workHours(store)}</Typography>
                                </> :
                                <Typography variant={'h1'}>Error {error.status}</Typography>
                            }
                        </div>
                    </div>

                    <div className={classes.tabsRow}>
                        <TabsFilter devices={devices} value={tabsFilter} onChange={setTabsFilter} />
                        { /*sort === 'notifications' &&
                            <SortButton className={classes.sortBtn} style={{alignSelf: 'center'}} label={t('sortErrors')}
                                onClick={()=>{ setSort('name') }}
                            />
                        }
                        {sort === 'name' &&
                        <SortButton className={classes.sortBtn} style={{alignSelf: 'center'}} label={t('sortName')}
                                    onClick={()=>{ setSort('notifications') }}
                        />
                        */}
                    </div>
                </div>
            </div>
            <div className={classes.container}>
            <div className={classes.deviceList}>
                {store?.groups?.map((g, i) => {
                    return (
                        <DeviceGroup g={g} key={i} types={types} tabsFilter={tabsFilter} id={id}
                                     setNotifDialog={setNotifDialog} setName={setName}
                                     store={store}
                        />
                    );
                })}
            </div>
            </div>
            {notifdialog?.length &&
                <NotifDialog open={notifdialog != null} store={{notifications: notifdialog,
                    name: store?.name,
                    address: store?.address,
                }} type={'all'} onclose={() => {
                    setNotifDialog(null);
                }} isStore={true} />
            }
        </PageLayout>
        </ThemeProvider>
    );
}

export default StorePage;

const text13 = {
    fontSize: 13,
    lineHeight: '16px',
};

const styles = (/*theme*/) => ({
    inner: {
        display: 'flex',
    },

    back: {
        marginRight: 22,
        width: 36,
        height: 36,
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        }
    },
    backtoolbar: {
        marginBottom: 12,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    container: {
        maxWidth: 1492,
    },
    storeName: {
        color: colors.mainText,
        fontSize: 19,
        lineHeight: 1,
    },
    storeAddress: {
        color: colors.grey100,
        ...text13,
    },

    sortBtn: {
        [theme.breakpoints.down('md')]: {
            display: 'none',
        }
    },
    tabsRow: {
        marginTop: 14,
        display: 'flex',
        justifyContent: 'space-between',
    },
    header: {
        background: "#fff",
        borderBottom: '1px solid ' + theme.colors.lightGrey.c100,
    },
    headerContainer: {
        padding: '16px 20px 0',
    },
    group: {
        background: "#fff",
        border: '1px solid ' + theme.colors.lightGrey.c100,
        borderRadius: 16,
        margin: '16px 24px',

        [theme.breakpoints.down('sm')]: {
            margin: '16px 0px',
            borderRadius: 0,
        }
    },
    rw: {
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        overflow: 'hidden',
    },
    rw2: {
        margin: '8px 0 -6px',
    },
    device: {
        borderBottom: '1px solid ' + theme.colors.lightGrey.c100,
        padding: '20px 24px',
        //height: 60,
        fontSize: 13,
        lineHeight: '16px',
        overflow: 'hidden',
    },
    groupHeader: {
        borderBottom: '1px solid ' + theme.colors.lightGrey.c100,
        padding: '20px 24px',
        height: 60,
    },
    groupTitle: {
        fontWeight: 500,
    },

    deviceName: {
        fontWeight: 500,
    },
    deviceInfo: {
        display: 'flex',
        //width: 300,
        flex: 1,
        gap: '50px',

        '& > *': {
            flex: 1,
            //minWidth: 120,
        },
    },
    [theme.breakpoints.down('sm')]: {
        deviceInfo: {
            gap: '20px',
        },
    },
    col3: {
        flex: 2,
    },
    text13: {
      fontSize: 13,
      lineHeight: 1.33,
    },
    lowercase: {
        textTransform: 'lowercase',
    },
    tapsInfo: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        }
    },
    connInfo: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: 8,
        /*
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        }*/
    },
    addInfo: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        }
    },
    grpcol3: {
        justifyContent: 'flex-end',
        display: 'flex',
        marginRight: -10,

        [theme.breakpoints.down('sm')]: {
            //display: 'none',
        }
    },
    notifWrap: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    notifText: {
        color: theme.colors.colors.success,

        '.color-error &': {
            color: theme.colors.colors.error,
        },

        '.color-warning &': {
            color: theme.colors.colors.warning,
        }
    },
    deviceIcon: {
        width: 20,
        height: 20,
        flexShrink: 0,
        flexGrow: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        color: theme.colors.colors.success,

        '.color-error &': {
            color: theme.colors.colors.error,
        },

        '.color-warning &': {
            color: theme.colors.colors.warning,
        }
    },
    deviceNameWrap: {
        flex: 1,
        display: 'flex',
        gap: 8,
    },
    kegInfo: {
        display: 'flex',
        //justifyContent: 'center',
        alignItems: 'center',
    },
    kegLabel: {
        whiteSpace: 'nowrap',
    },
    kegIcon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 8,

        '&.color-red': {
            color: theme.colors.colors.error,
        },
        '&.color-grey': {
            color: theme.colors.grey.c40,
        }
    },
});

const useStyles = makeStyles(styles);
