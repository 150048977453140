import React, {useContext, useRef, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import classNames from 'classnames';
import {ClickAwayListener, IconButton, Typography, useTheme} from '@material-ui/core';
import NotificationCount from '../StoresListPage/NotificationCount.js';
import ConnectionIcon from '../StoresListPage/ConnectionIcon.js';
import {t} from '../../i18n/utils.js';
import {MoreVert as MenuIcon} from '@material-ui/icons';
import DeviceMenu from './DeviceMenu.js';
import * as colors from './uiKit/colors.js';
import {theme} from '../StoresListPage/theme.js';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import NotifDate from './components/NotifDate.js';
import {LocaleContext} from '../../i18n/LocaleContext.js';
import {ReactComponent as TapIcon} from './svg/tap.svg';
import {ReactComponent as KegIcon} from './svg/keg.svg';
import moment from 'moment';
import {Link} from 'react-router-dom';
import {ReactComponent as PrinterIcon} from './svg/printer.svg';
import {ReactComponent as FreezerIcon} from './svg/freezer.svg';
import RebootDialog from '../DevicePage/header/RebootDialog.js';
import {DeviceContext} from '../../componets/DeviceLoader.js';
import {fromJS} from 'immutable';
import ChangeStoreDialog from '../DevicePage/header/ChangeStoreDialog.js';
import {ifnn} from '../Dashboard/DailyStats.js';
import GasIcon from '../../icons/GasIcon.js';
import DoorIcon from '../../icons/Door.js';

function workHours(store) {
    if (!store?.work_hours?.start || !store?.work_hours?.end) {
        return '-';
    }


    return ` ${store.work_hours?.start} – ${store.work_hours?.end}`
}

function Device({allnotifs, device, name, icon, info, additionalInfo, isHifa, co2dev}) {
    const classes = useStyles();

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    let errors = [];
    let warns = [];

    errors.push(...device?.notifications?.errors);
    warns.push(...device?.notifications?.warnings);

    if (!isHifa) {
        for (const n of allnotifs) {
            const bm = n.blocked_modules || [];

            if (bm.find(m => m.id === device.device_id)) {
                //console.log(n);
                if (n.type === 'error') {
                    errors.push(n);
                } else {
                    warns.push(n);
                }
            }
        }
    } else {
        if (co2dev) {
            errors.push(...co2dev?.notifications?.errors);
            warns.push(...co2dev?.notifications?.warnings);
        }
    }

    let notifs = [...errors, ...warns];

    console.log('NOTI', device, notifs);

    let notif = notifs.length > 0 ? notifs[0] : null;

    let color = notif?.type;
    console.log('ALL NOTIFS', allnotifs);

    if (isHifa && device.type === 'tap' && device?.info?.is_blocked) {
        color = 'error';
    }

    //notifs = [...errors, ...warns];
    //notif = notifs.length > 0 ? notifs[0] : null;

    let more = '';
    if (notifs.length > 1) {
        more = `(+${notifs.length -1} ${t('notifs', {count:notifs.length -1 })})`;
    }

    if (mobile) {
        return (
            <div className={classNames(classes.device, `color-${color}`)}>
                <div className={classes.rw}>
                    <div className={classes.deviceIcon}>{icon}</div>
                    <div className={classes.deviceNameWrap}>
                        <Typography variant={'sub1'} className={classes.deviceName}>{name}</Typography>
                    </div>
                    <div className={classNames([classes.deviceInfo, {[classes.hifaDevInfo]: isHifa}])}>{info}</div>
                </div>

                <div className={classes.rw2}>
                    <div className={classes.col3}>{notif ?
                        <div className={classes.notifWrap}>
                            <strong className={classes.notifText}>{notif.text} {more}</strong>
                            <NotifDate notif={notif} />
                        </div> :
                        <div className={classes.addInfo}>{additionalInfo}</div>
                    }</div>
                </div>
            </div>
        );
    }

    //console.log('NOTIF', notif);

    return (
        <div className={classNames(classes.device, classes.rw, `color-${color}`)}>
            <div className={classes.deviceIcon}>{icon}</div>
            <div className={classes.deviceNameWrap}>
                <Typography variant={'subtitle1'} className={classes.deviceName}>{name}</Typography>
            </div>
            <div className={classNames([classes.deviceInfo])}>
                {info}
            </div>
            <div className={classes.col3}>{notif ?
                <div className={classes.notifWrap}>
                    <strong className={classes.notifText}>{notif.text} {more}</strong>
                    <NotifDate notif={notif}/>
                </div> : <>{additionalInfo}</>
            }</div>
            {/*
            <pre>
                {JSON.stringify(device, null, 2)}
            </pre>*/}
        </div>
    );
}

function toLitres(v) {
    if(v === null) {
        return '-.--';
    }

    return v?.toLocaleString('ru', 2) || 0;
}

function DeviceNameFactory(device, co2asTap = false) {
    switch (device.type) {
        case 'tap': {
            const nameStr = device && device?.uuid ? device.uuid.split('/')[1] : '-';
            const tapNumArr = nameStr?.match('.*([0-9]+).*');
            const tapNum = tapNumArr?.length > 0 ? tapNumArr[1] : '-';
            const name = t('tap', {count: 1}) + ' ' + tapNum;
            return name;
        }
        case 'printer': {
            const name =  t('printer', {count: 1});
            return name;
        }
        case 'co2': {
            if (!co2asTap) {
                const name = t('gas', {count: 1});
                return name;
            } else {
                const name = t('tap', {count: 1}) + ' CO2';
                return name;
            }
        }
        case 'temperature': {
            const name = t('tabs_FREEZER');
            return name;
        }
        default:
            return device?.uuid;
    }
}

function DeviceFactory({allnotifs, device, storeid, groupid, isBeerbox, type, isHifa, allDevices}) {
    const classes = useStyles();

    const locale = useContext(LocaleContext);

    switch (device.type) {
        case 'tap': {
            const icon = <TapIcon />
            const nameStr = device && device?.uuid ? device.uuid.split('/')[1] : '-';
            const tapNumArr = nameStr?.match('.*([0-9]+).*');
            const tapNum = tapNumArr?.length > 0 ? tapNumArr[1] : '-';
            const name = DeviceNameFactory(device);

            const info = device?.info?.kegs?.map((keg, i) => {
                const left = keg.keg_left_volume;

                let color;
                if (left < 10) {
                    color = 'red';
                }
                if (left <= 0) {
                    color = 'grey';
                }

                const units = t(keg?.volume_unit);

                return (
                    <div key={i} className={classes.kegInfo}>
                        <div className={classNames(classes.kegIcon, `color-${color}`)}>
                            <KegIcon />
                        </div>
                        <div className={classes.kegLabel}>
                            {keg?.is_empty ?
                                `-.--/-.-- ${units}`
                                :
                                `${toLitres(left)}/${toLitres(keg.keg_volume)} ${units}`
                            }
                        </div>
                    </div>
                );
            })

            let co2dev;

            if (isHifa) {
                //console.log(allDevices.devices);
                co2dev = allDevices.devices.find(d => d?.parent?.id === device.device_id);
                console.log(co2dev);

                if (co2dev) {
                    let color = 'green';

                    if (co2dev.notifications.warnings?.length > 0) {
                        color = 'yellow'
                    }
                    if (co2dev.notifications.errors?.length > 0) {
                        color = 'red'
                    }

                    info.push(
                        <div className={classes.kegInfo}>
                            <div className={classNames(classes.kegIcon, `color-${color}`)}>
                                <GasIcon/>
                            </div>
                            <div className={classes.kegLabel}>
                                {co2dev?.info?.pressure_co2_value} {t('bar')}
                            </div>
                        </div>
                    );
                }
            }

            const nexWashDate = moment(device.info?.next_cleaning_date);
            const now = moment();
            const duration = moment.duration(now.diff(nexWashDate));
            const d = moment(nexWashDate).startOf('day').diff(moment().startOf('day'), 'day');

            let daysnum = '';
            if (moment(nexWashDate).isValid()) {
                daysnum = t('d', {count: d});
            } else
                daysnum = '-';


            let addInfo;
            if (!isHifa)
                if (d <= 0 ) {
                    addInfo = t('washing_Today');
                } else {
                    addInfo = t('nextWash', {count: daysnum});
                }

            return (
                <Link to={`/${locale.code}/stores/${storeid}/groups/${groupid}/modules/${device.device_id}`}>
                    <Device allnotifs={allnotifs} device={device} name={name} icon={icon} info={info} additionalInfo={addInfo} isHifa={isHifa}
                        co2dev={co2dev}
                    />
                </Link>
            );
        }
        case 'printer': {
            const icon = <PrinterIcon />
            const name =  t('printer', {count: 1});

            let info = ifnn(device.info?.labels_num);
            info += '/';
            info += ifnn(device.info?.labels_num_full);
            info += ' ' + t('labels');

            return (
                <Link to={`/${locale.code}/stores/${storeid}/groups/${groupid}/modules/${device.device_id}`}>
                    <Device  allnotifs={allnotifs} device={device} name={name} icon={icon} info={info}  isHifa={isHifa} />
                </Link>
            );
        }
        case 'co2': {
            if (isBeerbox || type === 'hifa_v3') {


                const icon = <GasIcon/>
                const name = t('gas', {count: 1});

                let info = device.info.pressure_co2_value + ' ' + t('bar');

                if (type.includes('hifa') && device.parent.type === 'manager') {
                    info = ''
                }

                if (type === 'hifa_v3' && device.parent.type !== 'manager') {
                    return null;
                }

                return (
                    <>
                        <Device allnotifs={allnotifs} device={device} name={name} icon={icon} info={info}  isHifa={isHifa}/>
                    </>
                );
            } else {
                return null;
            }
        }
        case 'temperature': {
            const icon = <FreezerIcon />
            const name = t('tabs_FREEZER');

            let info = device.info?.termo_cool || '-';
            info += '°C';

            return <Device  allnotifs={allnotifs} device={device} name={name} icon={icon} info={info}  isHifa={isHifa}/>
        }
        case 'door': {
            const icon = <DoorIcon />
            const name = t('device_types.door');

            let info = '';

            return <Device  allnotifs={allnotifs} device={device} name={name} icon={icon} info={info}  isHifa={isHifa}/>
        }
        default:
            return null;
    }
}

function DeviceGroup({g, types, tabsFilter, id, store, ...props}) {
    const classes = useStyles();
    const [devMenu, setDevMenu] = useState(false);
    const ref = useRef(null);

    const [reboot, setReboot] = useState(false);
    const [changeStore, setChangeStore] = useState(false);

    const devices = Object.entries(g.devices).map(d => d[1]);

    const hub = devices.find(d => d.type === 'manager' || d.type === 'hub');

    const typeFilter = types[tabsFilter];
    let filtered = devices;
    if (typeFilter !== 'all') {
        filtered = devices.filter(d => d.type === typeFilter);
    }

    const presorted = filtered.sort((a, b) => {
            // alphabetic sotr
            const aname = DeviceNameFactory(a, true);
            const bname = DeviceNameFactory(b, true);

            console.log(aname, bname);

            if (aname < bname) {
                return -1;
            }
            if (aname > bname) {
                return 1;
            }
            return 0;
    });

    const sorted = presorted;

    const tapsN = hub?.info?.taps_count;
    const printsN = hub?.info?.printers_count;

    const sigLvl = hub?.info?.signal_level;

    let totalNotifs = 0;
    let clr = 'yellow';

    const notifs = [];

    const locale = useContext(LocaleContext);

    if(g.devices?.map) {
        g.devices.map(d => {
            notifs.push(...d.notifications?.errors);
            notifs.push(...d.notifications?.warnings);
            totalNotifs += d.notifications?.errors?.length;
            totalNotifs += d.notifications?.warnings?.length;
            if (d.notifications?.errors?.length > 0) {
                clr = 'red';
            }
        });
    }

    const isBeerbox = g.type === 'beerbox';

    const isHifa = g.type.includes('hifa');

    const warranty = moment(g.warranty_date);

    let warrantyStr = '';

    if (warranty.isValid()) {
        if (warranty >= moment()) {
            const dateStr = warranty.format(locale.dateFormat);
            warrantyStr = t('onWarrant', {date: dateStr});
        } else {
            const dateStr = warranty.add(1, 'days').format(locale.dateFormat);
            warrantyStr = t('offWarrant', {date: dateStr});
        }
    }

    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    let groupHeader = null;

    if (mobile) {
        groupHeader = (
            <div className={classNames(classes.device)}>
                <div className={classes.rw}>
                    <div className={classes.deviceNameWrap}>
                        <Typography className={classes.groupTitle}>
                            {hub && hub?.uuid ? hub.uuid.split('/')[0] : '-'}
                        </Typography>
                        <NotificationCount count={totalNotifs} color={clr} onClick={()=>{
                            props.setNotifDialog(notifs);
                            props.setName(hub && hub?.uuid ? hub.uuid.split('/')[0] : '-')
                        }} className={classes.notifcount}/>
                    </div>
                    <div className={classNames([classes.deviceInfo])}>
                        <div className={classes.kegInfo}>
                            <div className={classes.connInfo}>
                                <ConnectionIcon online={g.is_connected} signalLevel={sigLvl} />
                                <Typography className={classes.text13}>{parseInt(sigLvl, 10) ? sigLvl + '%' : null}</Typography>
                            </div>
                        </div>
                        <div className={classes.kegInfo}>
                            <div className={classes.tapsInfo}>
                                <Typography  className={classNames(classes.text13, classes.lowercase)}>
                                    {tapsN}&nbsp;{t('tap', {count: tapsN})}, {printsN}&nbsp;{t('printer', {count:  printsN})}
                                </Typography>
                            </div>
                        </div>
                    </div>

                    <ClickAwayListener onClickAway={()=>{
                        setDevMenu(false);
                    }}>
                        <div className={classNames(classes.col3, classes.grpcol3)}>

                            <IconButton  className={classes.dots}>
                                <MenuIcon ref={ref} onClick={() => {
                                    setDevMenu(!devMenu);
                                    //console.log('sdfsfsd');
                                }}/>
                            </IconButton>
                            <DeviceMenu open={devMenu} anchorRef={ref} onClose={()=>{
                                //setDevMenu(false);
                            }} storeId={id} groupId={g.id} isBeerbox={isBeerbox} reboot={()=>setReboot(true)}
                                        changeStore={() => setChangeStore(true)}
                            />

                        </div>
                    </ClickAwayListener>
                </div>

                <div className={classes.rw2}>
                    <div className={classes.col3}>
                        <div className={classes.warranty}>{warrantyStr}</div>
                    </div>
                </div>
            </div>
        );
    } else {
        groupHeader =  (
            <div className={classNames(classes.device, classes.rw)}>
                <div className={classes.deviceNameWrap}>
                    <Typography className={classes.groupTitle}>
                        {hub && hub?.uuid ? hub.uuid.split('/')[0] : '-'}
                    </Typography>
                    <NotificationCount count={totalNotifs} color={clr} onClick={()=>{
                        props.setNotifDialog(notifs);
                        props.setName(hub && hub?.uuid ? hub.uuid.split('/')[0] : '-')
                    }} className={classes.notifcount}/>
                </div>
                <div style={{width: 32}} />
                <div className={classNames([classes.deviceInfo])}>
                    <div className={classes.kegInfo}>
                        <div className={classes.connInfo}>
                            <ConnectionIcon online={g.is_connected} signalLevel={sigLvl} />
                            <Typography className={classes.text13}>{parseInt(sigLvl, 10) ? sigLvl + '%' : null}</Typography>
                        </div>
                    </div>
                    <div className={classes.kegInfo}>
                        <div className={classes.tapsInfo}>
                            {!isHifa && !isNaN(tapsN) && !isNaN(printsN) &&
                                <Typography className={classNames(classes.text13, classes.lowercase)}>
                                    {tapsN}&nbsp;{t('tap', {count: tapsN})}, {printsN}&nbsp;{t('printer', {count: printsN})}
                                </Typography>
                            }
                        </div>
                    </div>
                </div>
                <div className={classes.col3}>
                    {isHifa && !isNaN(tapsN) && !isNaN(printsN) &&
                    <Typography className={classNames(classes.text13, classes.lowercase)}>
                        {tapsN}&nbsp;{t('tap', {count: tapsN})}, {printsN}&nbsp;{t('printer', {count: printsN})}
                    </Typography>
                    }
                    <>{warrantyStr}</>
                </div>
                <ClickAwayListener onClickAway={()=>{
                    setDevMenu(false);
                }}>
                    <div className={classNames(classes.grpcol3)}>

                        <IconButton className={classes.dots}>
                            <MenuIcon ref={ref} onClick={() => {
                                setDevMenu(!devMenu);
                                //console.log('sdfsfsd');
                            }}/>
                        </IconButton>
                        <DeviceMenu open={devMenu} anchorRef={ref} onClose={()=>{
                            //setDevMenu(false);
                        }} storeId={id} groupId={g.id} isBeerbox={isBeerbox} reboot={()=>setReboot(true)}
                                    changeStore={() => setChangeStore(true)}
                        />

                    </div>
                </ClickAwayListener>
                {/*
            <pre>
                {JSON.stringify(device, null, 2)}
            </pre>*/}
            </div>
        );
    }

    const allnotifs = notifs;

    return (
        <div className={classNames(classes.group, classes.grid)}>
            {groupHeader}

                <DeviceContext.Provider value={fromJS(g)}>
                    {reboot &&
                    <RebootDialog disbaled={true} open={reboot} onClose={() => {
                        setReboot(false);
                    }}/>
                    }
                    {changeStore &&
                    <ChangeStoreDialog open={changeStore} onClose={() => {
                        setChangeStore(false);
                    }} store={store} />
                    }
                </DeviceContext.Provider>

            {sorted?.map((d, i) => {
                return (
                    <DeviceFactory type={g?.type}  allnotifs={allnotifs} key={i} device={d} groupid={g.id} storeid={id} isBeerbox={isBeerbox}  isHifa={isHifa}
                        allDevices={g}
                    />
                );
            })}
        </div>
    );
}

export default DeviceGroup;


const text13 = {
    fontSize: 13,
    lineHeight: '16px',
};

const styles = (/*theme*/) => ({
    dots: {
        padding: '0 8px',
    },
    backtoolbar: {
        marginBottom: 12,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    container: {
        maxWidth: 1492,
    },
    storeName: {
        color: colors.mainText,
    },
    storeAddress: {
        color: colors.grey100,
        ...text13,
    },

    sortBtn: {
        [theme.breakpoints.down('md')]: {
            display: 'none',
        }
    },
    tabsRow: {
        marginTop: 14,
        display: 'flex',
        justifyContent: 'space-between',
    },
    header: {
        background: "#fff",
        borderBottom: '1px solid ' + theme.colors.lightGrey.c100,
    },
    headerContainer: {
        padding: '16px 20px 0',
    },
    group: {
        background: "#fff",
        border: '1px solid ' + theme.colors.lightGrey.c100,
        borderRadius: 16,
        margin: '16px 24px',

        [theme.breakpoints.down('sm')]: {
            margin: '16px 0px',
            borderRadius: 0,
        }
    },
    rw: {
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        overflow: 'hidden',
    },
    rw2: {
        margin: '8px 0 -6px',
    },
    device: {
        borderBottom: '1px solid ' + theme.colors.lightGrey.c100,
        padding: '20px 24px',
        //height: 60,
        fontSize: 13,
        lineHeight: '16px',
        overflow: 'hidden',

        "a:last-child &": {
            borderBottom: 0,
        }
    },
    groupHeader: {
        borderBottom: '1px solid ' + theme.colors.lightGrey.c100,
        padding: '20px 24px',
        height: 60,
    },
    groupTitle: {
        fontWeight: 500,
    },

    notifcount: {
        cursor: 'pointer',
    },

    deviceName: {
        fontWeight: 500,
    },
    deviceInfo: {
        display: 'flex',
        //width: 300,
        flex: 1,
        //gap: '50px',

        '& > *:first-child': {
            //flex: 1,
            //minWidth: 120,
            minWidth: '50%',
        },
    },
    [theme.breakpoints.down('sm')]: {
        deviceInfo: {
            gap: '20px',
        },
    },
    col3: {
        flex: 2,
    },
    text13: {
        fontSize: 13,
        lineHeight: 1.33,
    },
    lowercase: {
        textTransform: 'lowercase',
    },
    tapsInfo: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        }
    },
    connInfo: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: 8,
        /*
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        }*/
    },
    addInfo: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        }
    },
    grpcol3: {
        justifyContent: 'flex-end',
        display: 'flex',
        marginLeft: -41,

        [theme.breakpoints.down('sm')]: {
            //display: 'none',
        }
    },
    notifWrap: {
        display: 'flex',
        justifyContent: 'flex-start',
    },
    notifText: {
        color: theme.colors.colors.success,
        marginRight: 8,

        '.color-error &': {
            color: theme.colors.colors.error,
        },

        '.color-warning &': {
            color: theme.colors.colors.warning,
        }
    },
    deviceIcon: {
        width: 20,
        height: 20,
        flexShrink: 0,
        flexGrow: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        color: theme.colors.colors.success,

        '.color-error &': {
            color: theme.colors.colors.error,
        },

        '.color-warning &': {
            color: theme.colors.colors.warning,
        }
    },
    deviceNameWrap: {
        flex: 1,
        display: 'flex',
        gap: 8,
    },
    kegInfo: {
        display: 'flex',
        //justifyContent: 'center',
        alignItems: 'center',
    },
    kegLabel: {
        whiteSpace: 'nowrap',
    },
    kegIcon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 8,

        '&.color-red': {
            color: theme.colors.colors.error,
        },
        '&.color-green': {
            color: theme.colors.colors.success,
        },
        '&.color-yellow': {
            color: theme.colors.colors.warning,
        },
        '&.color-grey': {
            color: theme.colors.grey.c40,
        }
    },
});

const useStyles = makeStyles(styles);

