import React, {useState} from 'react';
import {Button, Typography} from '@material-ui/core';
import {plural} from '../../app/utils.js';
import {ReactComponent as CalIcon} from '../Dashboard/svg/calIcon.svg';
import Popover from '@material-ui/core/Popover';
import {createStaticRanges, DateRangePicker} from 'react-date-range';
import * as rdrLocales from 'react-date-range/dist/locale';
import {defineds} from '../Dashboard/CalendarStats.js';
import {addDays, differenceInCalendarDays, startOfDay} from 'date-fns';
import {makeStyles} from '@material-ui/core/styles';
import {useSelector} from 'react-redux';
import {checkPermission} from '../../redux/reducers/tokens.js';


export const staticRanges = createStaticRanges([
    {
        label: 'Сегодня',
        range: () => ({
            startDate: defineds.startOfToday,
            endDate: defineds.endOfToday,
        }),
    },
    {
        label: 'Вчера',
        range: () => ({
            startDate: defineds.startOfYesterday,
            endDate: defineds.endOfYesterday,
        }),
    },
    {
        label: 'Последние 7 дней',
        range: () => ({
            startDate: startOfDay(addDays(new Date(), -6)),
            endDate: defineds.endOfToday,
        }),
    },
    {
        label: 'Текущая неделя',
        range: () => ({
            startDate: defineds.startOfWeek,
            endDate: defineds.endOfWeek,
        }),
    },
    {
        label: 'Текущий месяц',
        range: () => ({
            startDate: defineds.startOfMonth,
            endDate: defineds.endOfMonth,
        }),
    },
    {
        label: 'Прошлый месяц',
        range: () => ({
            startDate: defineds.startOfLastMonth,
            endDate: defineds.endOfLastMonth,
        }),
    },
    {
        label: 'Текущий квартал',
        range: () => ({
            startDate: defineds.startOfQuarter,
            endDate: defineds.endOfQuarter,
        }),
    },
    {
        label: 'Текущий год',
        range: () => ({
            startDate: defineds.startOfYear,
            endDate: defineds.endOfYear,
        }),
    },
]);

const CalendarFilter = ({
    selectionRange, setSelectionRange,
    ...props
}) => {
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState(null);

    const hasAccessToAllPeriods = useSelector(checkPermission('access_to_all_report_periods'));

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    let rangeSelected = null;

    for (let r of staticRanges) {
        const sel = r.isSelected(selectionRange);
        if (sel) {
            rangeSelected = r;
            break;
        }
    }

    function handleSelect(ranges){
        //console.log('RANGES', ranges);

        const keys = Object.keys(ranges);
        const key = keys[0];

        setSelectionRange(ranges[key]);
        // {
        //   selection: {
        //     startDate: [native Date Object],
        //     endDate: [native Date Object],
        //   }
        // }
    }

    const diff = differenceInCalendarDays(selectionRange.endDate, selectionRange.startDate) + 1;


    return (
        <div>
            <Button variant="contained" color="primary" className={classes.calBtn} onClick={handleClick}
                disabled={!hasAccessToAllPeriods}
            >
                <Typography>
                    {rangeSelected ?
                        <React.Fragment>{rangeSelected.label}</React.Fragment>
                        :
                        <React.Fragment>{diff} {plural(diff, 'день', 'дня', 'дней')}</React.Fragment>
                    }
                </Typography>
                <div className={classes.btnIcon}>
                    <CalIcon />
                </div>
            </Button>

            {open &&
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                elevation={2}
                className={classes.datePickerPopover}
            >
                <DateRangePicker
                    ranges={[selectionRange]}
                    onChange={handleSelect}
                    locale={rdrLocales.ru}
                    staticRanges={staticRanges}
                    inputRanges={[]}
                    className={classes.rdp}
                    maxDate={addDays(new Date(), 0)}
                />
            </Popover>
            }
        </div>
    );
};

export default CalendarFilter;


const styles = (theme) => ({
    datePickerPopover: {
        "& .rdrDefinedRangesWrapper": {
            [theme.breakpoints.down('md')]: {
                display: 'none',
            }
        },
    },
    root: {

    },
    header: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 16,
        flexWrap: 'wrap',
        gap: 12,
    },

    btnIcon: {
        marginLeft: 8,
        width: 24,
        height: 24,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: -2,
        marginBottom: -2,
    },
    stats: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: 24,
        marginBottom: 24,
    },
    stats2: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        gap: 24,
        alignSelf: 'flex-start',
        flex: 1,
        maxWidth: 360,
        [theme.breakpoints.down('lg')]: {
            flexGrow: 1,
            width: 'auto',
            maxWidth: 700,
            flexDirection: 'row',
            flexWrap: 'wrap',
        }
    },
    statsItem: {
        flex: 1,
        minWidth: 276,
    },

    charts: {
        display: 'flex',
        flexDirection: 'column',
        gap: 24.
    },

    col1: {
        width: '100%',
        maxWidth: 700,
        flexGrow: 1,
        flexShrink: 0,
    },

    cols: {
        display: 'flex',
        gap: 24,
        flexWrap: 'wrap',
    },

    rdp: {
        width: '100%',

        '& .rdrMonth': {
            width: 'auto',
        },
        '& .rdrDateRangeWrapper': {
            width: '100%',
        },
    }
});
const useStyles = makeStyles(styles);
