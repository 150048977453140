import {fromJS, Map} from 'immutable';
import {getWithToken} from '../../app/rest';
import {tokensGetBearerToken} from './tokens';

const initialState = new Map({
    info: null,
    lang: null,
    isFetching: false,
    isError: false,
});

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'USER_FETCH_REQUEST':
            return state.set('isFetching', true)
                .set('isError', false);
        case 'USER_FETCH_SUCCESS':
            return state.set('isFetching', false)
                .set('info', fromJS(action.info));
        case 'USER_FETCH_ERROR':
            return state.set('isFetching', false)
                .set('isError', true);
        case 'USER_SET_LANG':
            return state.setIn(['user', 'info', 'language'], action.lang);
        case 'USER_WS_UPDATE': {
            const permissions = action.permissions || [];

            return state.setIn(['info', 'permissions'], permissions).setIn(['info', 'is_superuser'], action.is_superuser)
        }
        default:
            return state;
    }
};
export default reducer;

export const userInfo = (state) => state.getIn(['user', 'info']);

export const getSavedLanguage = state => {
    return state.getIn(['user', 'info', 'language']);
};

export const userGetInfo = (id) => async (dispatch, getState) => {
    dispatch({type: 'USER_FETCH_REQUEST'});

    console.log(id);

    const state = getState();
    const token = tokensGetBearerToken(state);

    try {
        const resp = await getWithToken(`/v2/api/users/${id}`, token, 'Bearer');
        dispatch({type: 'USER_FETCH_SUCCESS', info: resp});
        console.log(resp);
        dispatch({type: 'USER_PERM_UPDATE', permissions: resp.permissions});
    } catch (err) {
        dispatch({type: 'USER_FETCH_ERROR'});
    }
}
