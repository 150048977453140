import React from 'react';
import {ReactComponent as Logo} from '../Dashboard/svg/logo.svg';
import {ReactComponent as ShopIcon} from '../Dashboard/svg/shopIcon.svg';
import {ReactComponent as ReportsIcon} from '../Dashboard/svg/reportsIcon.svg';
import {ReactComponent as ManageIcon} from '../Dashboard/svg/adminIcon.svg';
import {ReactComponent as InfoIcon} from '../Dashboard/svg/infoIcon.svg';
import {Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {t} from '../../i18n/utils.js';
import {prefixPath} from '../../app/utils.js';
import {useSelector} from 'react-redux';
import {getLoadedLanguage} from '../../redux/reducers/locale.js';
import {checkPermission, tokensGetAccessToken} from '../../redux/reducers/tokens.js';
import classNames from 'classnames';

import DashboardIcon from '@material-ui/icons/Dashboard';
import ProfileBtn from '../Dashboard/ProfileBtn.js';
import {Link} from 'react-router-dom';
import {MenuItems} from '../../componets/Layout2/MenuItems.js';

function MenuItem({disabled, icon, label, href, active, external, ...props}) {
    const classes = useStyles();

    const content = (
        <React.Fragment>
            <div className={classes.menuItemIcon}>
                {icon}
            </div>
            <Typography variant={'subtitle2'} className={classes.menuItemLabel}>
                {label}
            </Typography>
        </React.Fragment>
    );


    if (disabled) {
        return (
            null
        );
    }

    if (!external) {
        return (
            <Link className={classNames(classes.menuItem, {active})} to={!disabled ? href : null} {...props}>
                {content}
            </Link>
        );
    } else {
        return (
            <a className={classNames(classes.menuItem, {active})} href={!disabled ? href : null} {...props}>
                {content}
            </a>
        );
    }
}

function Sidebar() {
    const classes = useStyles();

    const menuItems = MenuItems();

    return (
        <div className={classes.sidebar}>
            <div className={classes.logoItem}>
                <a href="/">
                    <Logo />
                </a>
            </div>

            <div className={classes.menuList}>
                {menuItems.map((item, index) => {
                    const curPath = window.location.pathname;
                    const isActive = curPath.startsWith(item.href);

                    //console.log(item);

                    return (
                        <MenuItem key={index} {...item} active={isActive}/>
                    );
                })}
            </div>

            <div className={classes.spacer} />

            <div className={classes.profileRegion}>
                <ProfileBtn />
            </div>
        </div>
    );
}

export default Sidebar;

export const sidebarWidth = 72;

const styles = (theme) => ({
    sidebar: {
        width: sidebarWidth,
        background: theme.colors.main.secondaryBg,
        color: theme.colors.main.white,
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 25,
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,

        [theme.breakpoints.down('md')]: {
            display: 'none',
        }
    },
    menuItemLabel: {
        color: theme.colors.main.white,
    },
    logoItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

    },
    tempIcon: {
        width: "100%",
        height: "100%",
    },

    menuItem: {
        transition: 'all .3s ease',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: 4,
        padding: 4,
        cursor: 'pointer',
        color: '#B0BBCF',

        "&:hover": {
            background: 'rgba(11, 46, 109, 1)',
            color: theme.colors.main.white,
        },

        "&.active": {
            background: 'rgba(11, 46, 109, 1)',
            color: theme.colors.main.white,
        },
    },
    disabled: {

    },
    menuItemIcon: {
        width: 32,
        height: 32,
        overflow: 'hidden',
    },

    menuList: {
        margin: -8,
        marginTop: 30,
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
    },

    profileRegion: {
        margin: '30px -8px 8px -8px',
        display: 'flex',
        justifyContent: 'center',
    },

    spacer: {
        flex: 1,
    },
});
const useStyles = makeStyles(styles);
