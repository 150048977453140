export async function post(endpoint, body) {
    const resp = await fetch(endpoint,{
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify(body),
    });

    if (resp.ok) {
        return resp.json();
    } else {
        throw resp;
    }
}

export async function post2(endpoint, body) {

    var formBody = [];
    for (var property in body) {
        if(!body.hasOwnProperty(property)) continue;
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(body[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");


    const resp = await fetch(endpoint,{
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            'Accept': 'application/json',
        },
        body: formBody,
    });

    if (resp.ok) {
        return resp.json();
    } else {
        throw resp;
    }
}

export async function getWithToken(endpoint, token, tokenPrefix = 'JWT') {
    const resp = await fetch(endpoint,{
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `${tokenPrefix} ${token}`
        },
    });

    if (resp.ok) {
        return resp.json();
    } else {
        //if (tokenPrefix === 'JWT') {
            throw resp;
        //} else {
            // do nothing
        //}
    }
}


export async function postWithToken(endpoint, body, method = 'POST', token, tokenType = 'JWT') {
    const resp = await fetch(endpoint,{
        method: method,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `${tokenType} ${token}`
        },
        body: JSON.stringify(body),
    });

    if (resp.ok) {
        return resp.json();
    } else {
        throw resp;
    }
}
