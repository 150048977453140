import {createTheme} from '@material-ui/core';

import '@fontsource/rubik/400.css';
import '@fontsource/rubik/500.css';

export const colors = {
    main: {
        mainText: '#1D1D1D',
        secondaryText: '#666666',
        white: '#FFFFFF',
        mainBg: '#F3F7F9',
        secondaryBg: '#0C2556',
        menuItem: '#728EC0',
        menuBgActive: '#0B2E6D',
    },
    blue: {
        default: '#007AFF',
        hover: '#1269EC',
        pressed: '#1F47CD',
        active: '#009EFF',
        light: 'rgba(0, 122, 255, 0.1)',
    },
    grey: {
        c100: 'rgba(134, 138, 143, 1)',
        c68: 'rgba(173, 175, 179, 1)',
        c40: 'rgba(207, 208, 210, 1)',
    },
    lightGrey: {
        c100: 'rgba(223, 227, 233, 1)',
        c68: 'rgba(233, 236, 240, 1)',
        c40: 'rgba(242, 244, 246, 1)',
        c24: 'rgba(247, 248, 250, 1)',
    },

    colors: {
        success: 'rgba(19, 205, 60, 1)',
        successBg: 'rgba(231, 250, 235, 1)',
        warning: '#FFAE17',
        warningBg: 'rgba(255, 174, 23, 0.1)',
        error: '#FF6370',
        errorBg: '#FFEFF1',
    },
};

export const theme = createTheme({
    colors,

    palette: {
        type: 'light',

        primary: {
            main: colors.blue.default,
            contrastText: colors.main.white,
        },

        text: {
            primary: colors.main.mainText,
            secondary: colors.main.secondaryText,
            disabled: colors.lightGrey.c100,
        },

        background: {
            default: colors.main.mainBg,
            paper: colors.main.white,
        },
    },
    spacing: 4,
    breakpoints: {
        values: {
            xs: 0,
            sm: 360,
            md: 640,
            lg: 960,
            xl: 1300,
        },
    },
    shadows: [
        /*0:*/ 'none',
        /*1:*/ '0px 3px 12px 0px rgba(12, 37, 86, 0.06)',
        /*2:*/ '0px 3px 12px 0px rgba(12, 37, 86, 0.16)',
        /*3:*/ '0px 4px 40px 0px rgba(74, 88, 109, 0.24)',
    ],
    typography: {
        fontFamily: ['Rubik', 'sans-serif'].join(','),
        htmlFontSize: 16,
        fontSize: 15,
        fontWeightLight: 400,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 500,

        h1: {
            fontSize: 23,
            fontWeight: 400,
            lineHeight: '32px',
        },
        h2: {
            fontSize: 21,
            fontWeight: 400,
            lineHeight: '26px',
        },
        h3: {
            fontSize: 19,
            fontWeight: 400,
            lineHeight: '22px',
        },
        h4: {
            fontSize: 17,
            fontWeight: 400,
            lineHeight: '20px',
        },
        body1: {
            fontSize: 15,
            fontWeight: 400,
            lineHeight: '20px',
        },
        subtitle1: {
            fontSize: 13,
            fontWeight: 400,
            lineHeight: '16px',
        },
        subtitle2: {
            fontSize: 10,
            fontWeight: 400,
            lineHeight: '14px',
        }
    },
});

const dropdownPaper = {
    padding: 0,
    boxShadow: theme.shadows[3],
    borderRadius: 4,
    border: '1px solid ' + theme.colors.lightGrey.c100,

    width: '320px',
};

theme.overrides = {
    MuiPaper: {
        root: {
            margin: 0,
            padding: 20,
            background: theme.colors.main.white,
            boxShadow: theme.shadows[1],
            transition: 'box-shadow 0.2s ease-in 0s'
        },
        rounded: {
            borderRadius: 16,
        }
    },

    MuiTextField: {
        root: {
            borderColor: theme.colors.lightGrey.c100,
        },
    },

    MuiOutlinedInput: {
        root: {
            background: theme.colors.main.white,
            borderRadius: 8,
        },
        input: {
            padding: '8px 12px',
            height: 36,
            fontSize: 14,
            lineHeight: '20px',
            boxSizing: 'border-box',
        },
        notchedOutline: {
            borderColor: theme.colors.lightGrey.c100,
            transition: 'border-color .3s ease',
        },
    },

    MuiTouchRipple: {
        root: {
            display: 'none',
        }
    },

    MuiButton: {
        root: {
            textTransform: "none",
            padding: '8px 16px',
            minHeight: 36,
            ...theme.typography.body1,
        },
        contained: {
            boxShadow: "none !important",
            borderRadius: "6px",
            border: "none !important",
            padding: '8px 16px',
        },
        containedPrimary: {
            backgroundColor: colors.blue.default,
            color: colors.main.white,
            transition: 'background-color .3s ease',

            "&:hover": {
                backgroundColor: colors.blue.hover,
            },

            "&:active": {
                backgroundColor: colors.blue.pressed,
            }
        },
        focusVisible: {
            boxShadow: `0 0 0 1.5pt ${colors.blue.active} !important`,
        },
        outlined: {
            borderColor: colors.lightGrey.c100,
            padding: '8px 16px',
            borderRadius: "6px",
        },
    },

    MuiAutocomplete: {
        paper: dropdownPaper,
        popupIndicator: {
            background: 'transparent !important',
        },
        inputRoot: {
            padding: '0 !important',

            '&[class*="MuiOutlinedInput-root"]': {
                padding: 0,

                '& $input': {
                    padding: '8px 12px',

                    '&:first-child': {
                        paddingLeft: 8,
                    },

                    '$hasPopupIcon &': {
                        paddingRight: '64px',
                        textOverflow: 'ellipsis',
                    }
                },
            },

            '& $input': {
                minWidth: 72,
            }
        },
        option: {
            wordBreak: 'break-word',
        },
    },

    MuiIconButton: {
        root: {
            borderRadius: 4,
        },
    },

    MuiSelect: {
        select: {
            "&:focus": {
                backgroundColor: theme.colors.main.white,
                borderRadius: 8,
            }
        },
        icon: {
            top: 'initial',
        },
    },

    MuiSwitch: {
        switchBase: {
            borderRadius: '50%',
        },
    },

    MuiMenu: {
        paper: dropdownPaper,
    },
    MuiMenuItem: {
        root: {
            padding: '8px 16px',

            '&.Mui-focusVisible': {
                boxShadow: 'none !important',
            }
        },
    },

    MuiTabs: {
        root: {

        },
        flexContainer: {
            gap: '28px',
        },
    },
    MuiTab: {
        root: {
            padding: 0,
            minWidth: '32px !important',
        },
        wrapper: {
            color: colors.grey.c100,
            fontSize: 12,
            lineHeight: '16px',
            fontWeight: 500,

            '$selected &': {
                color: colors.main.mainText,
            }
        }
    },

    MuiTabScrollButton: {
        root: {
            width: 'auto',
            padding: 8,

            '&:first-child': {
                marginLeft: -4,
            },
            '&:last-child': {
                marginRight: -4,
            },

            '&.Mui-disabled': {
                opacity: 0.25,
            }
        },
    },

    MuiDialog: {
      paper: {
          padding: '16px 24px',
      }
    },

    MuiDialogContent: {
        root: {
            padding: 0,
            paddingBottom: 24,
        }
    },

    MuiDialogActions: {
        root: {
            borderTop: '1px solid ' + colors.lightGrey.c100,
            padding: '16px 24px',
            margin: '0px -24px -16px',
        },
        spacing: {
            '& > :not(:first-child)': {
                marginLeft: 12,
            },
        }
    }
};
