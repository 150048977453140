import React, {useState} from 'react';
import {theme} from './theme.js';
import {CssBaseline, ThemeProvider, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import TopBar from './TopBar.js';
import Filters from './Filters.js';
import MobileTopBar from './MobileTopBar.js';
import PageLayout from '../../componets/PageLayout/PageLayout.js';
import {useDispatch, useSelector} from 'react-redux';
import StoresList from './StoresList.js';
import {t} from '../../i18n/utils.js';
import useTheme from '@material-ui/core/styles/useTheme.js';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useLocation} from 'react-router-dom';
import {useLocalStorage} from '../TapPage/TapPage.js';
import {DEVICE_LIST_UPDATE_FILTERS} from '../../redux/reducers/deviceList.js';

export function setTitle(title) {
    document.title = title;
}

function StoresPage() {
    const classes = useStyles();

    const params = useLocation();
    const s = params?.search;
    const urls = new URLSearchParams(s);

    const defCity = urls.get('city_ids')?.split(',')?.map(n => parseInt(n ,10));
    //console.log('DEFCITY', defCity);

    const cityImm = useSelector(state => state.getIn(['deviceList', 'filters', 'city']));
    const city = cityImm?.toJS ? cityImm.toJS() : [];

    //console.log('CITY', city);

    const dispatch = useDispatch();

    const chainImm = useSelector(state => state.getIn(['deviceList', 'filters', 'store']));
    console.log(chainImm);
    const chain = chainImm?.toJS ? chainImm?.toJS() : [];
    const withProblems = useSelector(state => state.getIn(['deviceList', 'filters', 'withProblems']));
    const filters = useSelector(state => state.getIn(['deviceList', 'filters']));
    console.log('FIL', filters.toJS());
    const [search, setSearch] = useState(false);
    const query = useSelector(state => state.getIn(['deviceList', 'filters', 'query']));

    const deviceTypesImm = useSelector(state => state.getIn(['deviceList', 'filters', 'devices']));
    const errorTypesImm = useSelector(state => state.getIn(['deviceList', 'filters', 'errors']));

    const deviceTypes = deviceTypesImm?.toJS ? deviceTypesImm.toJS() : [];
    const errorTypes = errorTypesImm?.toJS ? errorTypesImm.toJS() : [];

    const setQuery = (val) => {

        dispatch({type: DEVICE_LIST_UPDATE_FILTERS, filters: {offset: 0, query: val}})

        //setQuery2(val);
    }


    const [showTime, setShowTime] = useLocalStorage('showTime', true);
    const [showAddress, setShowAddress] = useLocalStorage('showAddress', true);
    const [showErrors, setShowErrors] = useLocalStorage('showErrors', true);

    setTitle(t('pageTitle'));

    const themeq = useTheme();
    const isMobile = useMediaQuery(themeq.breakpoints.down('md'));


    const [fil, setFil] = useState(true);
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>

            <PageLayout noContainer={true} customMobileTopBar={<MobileTopBar
                filters={filters} setFilters={setFil} fil={fil}
                search={search} setSearch={setSearch}
                query={query} setQuery={setQuery}
                showTime={showTime} setShowTime={setShowTime}
                showAddress={showAddress} setShowAddress={setShowAddress}
                showErrors={showErrors} setShowErrors={setShowErrors}
            />}>
                <div className={classes.layout}>
                    <div className={classes.content}>
                        {!isMobile &&
                            <TopBar search={search} setSearch={setSearch}
                                    query={query} setQuery={setQuery}
                                    showTime={showTime} setShowTime={setShowTime}
                                    showAddress={showAddress} setShowAddress={setShowAddress}
                                    showErrors={showErrors} setShowErrors={setShowErrors}
                            />
                        }
                        <div className={classes.main}>
                            <div className={classes.pageTitle}>
                                <Typography variant="h1">{t('pageTitle')}</Typography>
                            </div>
                            <Filters
                                filters={filters} fil={fil}
                                query={query} setQuery={null}
                                city={city} setCity={null} store={chain} setStore={null} withProblems={withProblems} setWithProblems={null}
                                deviceTypes={deviceTypes} errorTypes={errorTypes}
                            />

                            <StoresList

                                showTime={showTime} setShowTime={setShowTime}
                                showAddress={showAddress} setShowAddress={setShowAddress}
                                showErrors={showErrors} setShowErrors={setShowErrors}
                                city={city} chain={chain}  withProblems={withProblems} query={query} setQuery={setQuery}
                                deviceTypes={deviceTypes} errorTypes={errorTypes} />
                        </div>
                    </div>
                </div>
            </PageLayout>

        </ThemeProvider>
    );
}
export default StoresPage;

const styles = (/*theme*/) => ({
    main: {
        maxWidth: 1440,
    },

    layout: {
        display: 'flex',
        minHeight: '100vh',
    },
    content: {
        minWidth: '360px',
        width: '100%',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        paddingRight: theme.spacing(6),
        paddingLeft: theme.spacing(6),

        [theme.breakpoints.down('sm')]: {
            padding: 20,
        }
    },
    pageTitle: {
        marginBottom: 16,

        [theme.breakpoints.up('md')]: {
            display: 'none',
        }
    }
});
const useStyles = makeStyles(styles);
