import React, {useRef, useState} from 'react';
import {Button, IconButton, Typography, useTheme} from '@material-ui/core';
import classNames from 'classnames';
import {ReactComponent as SettingsIcon} from '../StoresListPage/svg/settings.svg';
import {makeStyles} from '@material-ui/core/styles';
import BackButton from './BackButton.js';
import {t} from '../../i18n/utils.js';
import SettingsMenu from './SettingsMenu.js';

import {ReactComponent as GasIcon} from '../StorePage/svg/gas.svg';
import {ReactComponent as FreezerIcon} from '../StorePage/svg/freezer.svg';
import {ReactComponent as TapIcon} from '../StorePage/svg/tap.svg';
import {theme} from '../StoresListPage/theme.js';
import {ifnn} from '../Dashboard/DailyStats.js';


function Topbar({name, backUrl, setWashDialog, isWash, permissionWash, showWash, washDays, temp, co2, address, isHifa, isBlocked, ...props}) {
    const classes = useStyles();

    const [settings, setSettings] = useState(false);
    const ref = useRef(null);

    let washStr = '';

    if (washDays === 0) {
        washStr = t('washing_Today');
    } else if (washDays === 1) {
        washStr = t('washing_Tomorrow');
    } else if (washDays > 0) {
        washStr = t('washing_Next') + t('d', {count: washDays});
    }

    const canwash = permissionWash && !isWash;

    let tempVal = ifnn(temp?.info.termo_cool);
    let co2Val = ifnn(co2?.info.pressure_co2_value);

    let tempClr = classes.green;
    if (temp?.notifications?.errors?.length > 0) {
        tempClr = classes.red;
    } else if (temp?.notifications?.warnings?.length > 0) {
        tempClr = classes.yellow;
    }

    let co2Clr = classes.green;
    if (co2?.notifications?.errors?.length > 0) {
        co2Clr = classes.red;
    } else if (co2?.notifications?.warnings?.length > 0) {
        co2Clr = classes.yellow;
    }

    let washStep = t('washStep') + ' ';
    washStep += props.tap?.washing_step ? props.tap?.washing_step : '-';

    console.log('hjsbjsgsjdgsugj', props.tap)

    return (
        <div className={classes.overallBlock}>
            <div className={classes.addressBlock}>
                <Typography variant={'h3'} style={{color: theme.colors.main.mainText}}>{address}</Typography>
            </div>
        <div className={classes.topbar}>
            <div className={classes.topbarInner}>
                <BackButton className={classes.back} href={backUrl} />

                <div className={classes.moduleName}>
                    {!isHifa ? (
                        <div
                            className={classNames(classes.iconWrap, {'yellow': props.hasWarns}, {'red': props.hasErrors})}>
                            <TapIcon/>
                        </div>
                    ) : (
                        <div
                            className={classNames(classes.iconWrap, {'red': props.tap?.is_blocked})}>
                            <TapIcon/>
                        </div>
                    )
                    }
                    <div>
                        <Typography variant={'h3'} className={classes.moduleNameText}>
                            {name}
                        </Typography>
                        <Typography variant={'subtitle1'} className={classes.addressText}>
                            {address}
                        </Typography>
                    </div>
                </div>


                {!isHifa && (
                <div className={classes.washBar}>
                    {isWash ? (
                        <Typography component={'span'} className={classes.smalltxt} style={{color: '#FF6370', fontWeight: 500,}}>
                            {t('washing_Now')}. {washStep} &nbsp;
                        </Typography>
                        ) : (
                        <Typography component={'span'} className={classes.smalltxt} style={{color: "#666666"}}>
                            {washStr} &nbsp;
                        </Typography>
                        )
                    }

                    {showWash && (canwash) &&
                    <a className={classes.washBtn2} href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                setWashDialog(true);
                            }}
                    >
                        {t('washBtn')}
                    </a>
                    }

                    {showWash && (!canwash) &&
                    <span className={classes.washBtn3} href="#"
                    >
                        {t('washBtn')}
                    </span>
                    }
                </div>
                )}


                <div className={classes.spacer} />

                    <div className={classes.indic}>
                        <FreezerIcon className={tempClr} />
                        <Typography>{tempVal}°C</Typography>
                    </div>

                    <div className={classes.indic}>
                        <GasIcon className={co2Clr} />
                        <Typography>{co2Val} {t('bar')}</Typography>
                    </div>

                {showWash &&
                    <>
                <Button className={classes.washBtn} classes={{label: classes.washBtnLabel}}
                    variant="contained" color="primary"
                        disabled={isWash || /*!tap.get('can_wash') ||*/ !permissionWash}
                        onClick={() => {
                            setWashDialog(true);
                        }}
                >
                    {t('washBtn')}
                </Button>
                    </>
                }

                    <div className={classNames(classes.topbarButtons, classes.bigButtons)}>
                        <IconButton className={classNames(classes.toplineBigBtn2, {'pressed':  settings}, 'lastChild')}
                                    onClick={() => {
                                        setSettings(true);
                                    }}  ref={ref}
                        ><SettingsIcon/></IconButton>
                        <SettingsMenu showStats={props.showStats} setShowStats={props.setShowStats} open={settings} anchorRef={ref} {...props} onClose={()=>setSettings(false)} />
                    </div>
            </div>
        </div>
        </div>
    );
}

export default Topbar;


const topbarHeight = 68;

const styles = (theme) => ({

    green: {
        color: theme.colors.colors.success,
    },
    yellow: {
        color: theme.colors.colors.warning,
    },
    red: {
        color: theme.colors.colors.error,
    },

    addressText: {
        color: '#868A8F',

        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },

    indic: {
      display: 'flex',
        gap: 6,
        margin: 13,
        alignItems: 'center',

        [theme.breakpoints.down('md')]: {
          display: 'none',
        },
    },

    // green: {
    //     color: theme.colors.colors.success,
    // },
    iconWrap: {
        height: 24,

        '&.yellow': {
            color: '#FFAE17',
        },

        '&.red': {
            color: '#FF6370',
        },
    },
    moduleName: {
      display: 'flex',
      gap: '10px',
        //alignItems: 'baseline',
        color: theme.colors.main.mainText,
        //display: 'flex',
        alignItems: 'center',
        marginRight: 27,
    },
    moduleNameText: {
        fontSize: 19,
        lineHeight: '19px',
    },

    infoIcon: {
        alignSelf: 'center',
        color: '#868A8F',
        display: 'flex',
    },
    searchField: {
        position: "absolute",
        zIndex: 800,
        background: "#F2F4F6",
        top: 10,
        left: 64,
        bottom: 8,
        borderRadius: 4,
        right: 8,
        display: 'none',
        border: 'none !important',
        outline: 'none !important',
        boxShadow: 'none !important',
        height: 36,

        "& fieldset": {
            display: 'none',
        },

        '&.visible': {
            display: 'inherit',
        },
    },
    menuItem: {
        display: 'flex',
        padding: '10px 20px',
        gap: 18,
        alignItems: 'center',
        cursor: 'pointer',
        color: 'rgba(114, 142, 192, 1)',
        transition: 'all .3s ease',

        "&:hover": {
            background: 'rgba(11, 46, 109, 1)',
            color: theme.colors.main.white,
        },

        "&.active": {
            background: 'rgba(11, 46, 109, 1)',
            color: theme.colors.main.white,
        },
    },
    menuItemLabel: {
        color: theme.colors.main.white,
    },
    menuItemIcon: {
        width: 24,
        height: 24,
        overflow: 'hidden',
    },

    logo: {
        padding: '16px 20px',
    },

    leftMenu: {
        background: theme.colors.main.secondaryBg,
        color: theme.colors.main.white,
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        zIndex: 500,
        width: 264,

        display: 'flex',
        flexDirection: 'column',
    },

    toplineBtn: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: theme.spacing(3),
        marginLeft: theme.spacing(0),
        fontWeight: 'normal',

        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },

    wrap: {
        //paddingTop: topbarHeight,

        [theme.breakpoints.up('lg')]: {
            display: 'none'
        }
    },

    smalltxt: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '13px'
        }
    },
    spacer: {
        flex: 1,
    },

    washBar: {
        display: 'block',
        alignSelf: 'center',
        marginLeft: 36,

        [theme.breakpoints.down('md')]: {
            marginLeft: 0,
            marginTop: 0,
            alignSelf: 'flex-start',
            paddingTop: 4,
        }
    },

    washBtn: {
        minHeight: 0,

        marginLeft: 12 - 8,
        [theme.breakpoints.down('md')]: {
           display: 'none',
        }
    },
    washBtn2: {
        minHeight: 0,
        color: '#007AFF',
        ///marginLeft: '12px',
        display: 'none',
        [theme.breakpoints.down('md')]: {
            display: 'inline',
        },

            [theme.breakpoints.down('sm')]: {
                fontSize: '13px',
            }
    },

    washBtn3: {
        minHeight: 0,
        color: '#ccc',
        ///marginLeft: '12px',
        display: 'none',
        [theme.breakpoints.down('md')]: {
            display: 'inline',
        },

        [theme.breakpoints.down('sm')]: {
            fontSize: '13px',
        }
    },

    washBtnLabel: {

    },

    menuBtn: {
        width: 24,
        height: 24,
        padding: 0,
    },

    back: {
        marginRight: 22,
        width: 36,
        height: 36,

        [theme.breakpoints.down('md')]: {
            display: 'none',
        }
    },

    topbar: {
        display: 'flex',
        padding: theme.spacing(4, 6),

        [theme.breakpoints.down('md')]: {
            padding: '14px 20px',
        },
    },

    addressBlock: {
        padding: theme.spacing(4, 6),
        [theme.breakpoints.down('md')]: {
            padding: '14px 20px',
        },
        borderBottom: '1px solid #DFE3E9',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },

    overallBlock: {
        background: theme.colors.main.white,

        boxShadow: theme.shadows[1],
        marginBottom: 16,
    },

    topbarInner: {
        display: 'flex',
        background: theme.colors.main.white,
        //marginBottom: 16,
        width: '100%',
        height: 32,

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            height: 'auto',
        }
    },
    topbarButtons: {
        display: 'flex',
        alignItems: 'center',

        [theme.breakpoints.down('md')]: {
           display: 'none',
        }
    },

    toplineBtnIcon: {
        width: 24,
        height: 24,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        marginRight: theme.spacing(2),

        '&:last-child': {
            marginRight: 0,
        },
    },
    smallBtnText: {
        color: theme.colors.grey.c100,
    },

    bigButtons:{
        marginLeft: theme.spacing(3),
    },
    toplineBigBtn: {
        width: 24,
        height: 24,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        marginRight: theme.spacing(4),
        '&:last-child': {
            marginRight: 0,
        },
        padding: 0,
    },

    toplineBigBtn2: {
        width: 36,
        height: 36,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        marginRight: theme.spacing(2),
        '&.lastChild': {
            marginRight: 0,
        },
        padding: 0,

        '&.pressed': {
            background: '#007AFF1A',
            color: '#007AFF',

            '&.search': {
                display: 'none',
            }
        },
    },

    profileBtn: {
        display: 'flex',
        padding: '10px 20px',
        gap: 12,
        alignItems: 'center',
        cursor: 'pointer',
        color: theme.colors.main.white1,
    },

    profileBtnIcon: {
        width: 40,
        height: 40,
        overflow: 'hidden',
    },

    fab: {
        position: 'fixed',
        bottom: 24,
        right: 24,
        width: 44,
        height: 44,
        display: 'none',

        [theme.breakpoints.down('sm')]: {
            display: 'flex',
        }
    },
});
const useStyles = makeStyles(styles);
