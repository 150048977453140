import React, {useContext, useEffect, useState} from 'react';
import StatusIndicator from '../../../componets/StatusIndicator';
import {Paper} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';
import {getWithToken} from '../../../app/rest';
import {useSelector} from 'react-redux';
import {tokensGetBearerToken} from '../../../redux/reducers/tokens';
import moment from 'moment';
import WorkTimeChangeDialog from './WorkTimeChangeDialog';
import {t} from '../../../i18n/utils.js';
import {LocaleContext} from '../../../i18n/LocaleContext.js';

function TimeGroup({device, isDisconnected, ...props}) {
    const classes = useStyles();

    const deviceId = device.get('id');
    const token = useSelector(tokensGetBearerToken);
    const localeObj = useContext(LocaleContext);

    const [workDay, setWorkDay] = useState({wds: null, wde: null});
    const [open, setOpen] = useState(false);

    useEffect(()=>{
        const apiTimeFormat = 'HH:mm:ss';

        getWithToken(`/v2/api/groups/${deviceId}/work_time`, token, 'Bearer').then(r => {
            let wds = moment(r?.work_day_start, apiTimeFormat);
            let wde = moment(r?.work_day_end, apiTimeFormat);
            setWorkDay({wds, wde});
        });
    }, []);

    const apiTimeFormat = 'HH:mm:ss';
    const showTimeFormat = localeObj.timeFormat;

    const wdsFormatted = workDay.wds?.format(showTimeFormat);
    const wdeFormatted = workDay.wde?.format(showTimeFormat);

    const isValid = workDay.wds?.isValid() && workDay.wde?.isValid();

    // TODO: i18n
    return (
        <Paper className={classes.group}>
            <div className={classes.groupHeader}>
                <StatusIndicator lvl={1} className={classes.indic} />
                <div className={classes.groupTitle}>
                    {t('timeSecHeading')}
                </div>
            </div>
            <div className={classes.items}>
                <div className={classNames([classes.item])}>
                    <div className={classes.itemTitle}>
                        <strong>{t('pouringAlldays')}</strong>
                    </div>
                    <div className={classes.itemValue}>
                    </div>
                </div>

                <div className={classNames([classes.item])}>
                    <div className={classes.itemTitle}>
                        {t('periodHeading')}
                    </div>
                    <div className={classes.itemValue}>
                        <button disabled={isDisconnected} className={classNames([classes.itemEditable, {'disabled': isDisconnected}])} onClick={()=>{setOpen(true)}}
                            id={'settings-workhours-btn'}
                        >
                            {(!isValid) ?
                                t('undefined')  :
                                `${wdsFormatted} - ${wdeFormatted}`
                            }
                        </button>
                    </div>
                </div>

                {/*
                <div className={classNames([classes.item])}>
                    <div className={classes.itemTitle}>
                        <strong>{t('pouringWeekend')}</strong>
                    </div>
                    <div className={classes.itemValue}>
                    </div>
                </div>
                <div className={classNames([classes.item])}>
                    <div className={classes.itemTitle}>
                        {t('periodHeading')}
                    </div>
                    <div className={classes.itemValue}>
                        <button className={classNames([classes.itemEditable])} onClick={()=>{setOpen(true)}}>
                            {(!isValid) ?
                                t('undefined') :
                                `${wdsFormatted} - ${wdeFormatted}`
                            }
                        </button>
                    </div>
                </div>
                */}

                {open &&
                    <WorkTimeChangeDialog open={open} onClose={(update, wds, wde) => {
                        setOpen(false);

                        if (update === true) {
                            setWorkDay({wds: moment(wds), wde: moment(wde)});
                        }
                    }} workDay={isValid ? workDay : {wds: null, wde: null}}/>
                }
            </div>
        </Paper>
    );
}

export default TimeGroup;


const useStyles = makeStyles(theme => ({
    root: {
        margin: 8,
        padding: 25,
    },
    section: {
        padding: 4,
        //marginLeft: -4,
    },
    sectionTitle: {
        fontSize: '21px',
        marginBottom: 16,
    },
    groups: {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
    },
    group: {
        display: "inline-block",
        //background: "#6a9d80",
        padding: 16,
        //color: "#fff",
        borderRadius: 8,
        maxWidth: 350,
        minWidth: 270,
        width: "100vw",
    },
    header: {
        display: "flex",
        alignItems: "center",
        gap: "16px",
    },
    groupLink: {
        textDecoration: 'underline',
        cursor: "pointer",
        padding: "4px",

        "&:hover": {
            color: "#fff",
            background: 'rgba(0,0,0,.26)!important',
            borderRadius: "8px",
        },
    },
    groupTitle: {

    },
    groupHeader: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        fontSize: '16px',
        marginBottom: '1em',
    },
    items: {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px 0',
    },
    indic: {
        //boxShadow: '0px 0px 6px 1px #0c7715',
        borderRadius: '100%',
    },
    item: {
        display: 'flex',
        alignItems: 'center',

        "&.no-value": {
            opacity: 0.5,
        }
    },
    itemTitle: {
        flex: 1,
    },
    itemValue: {
        flex: 1,
        textAlign: 'center',
    },
    btn: {
        backgroundColor: 'rgba(0,0,0,.26)!important',
        color: '#fff',
    },
    itemEditable: {
        border: 0,
        background: "transparent",
        color: "#646464",
        cursor: "pointer",
        minHeight: '1em',
        textDecoration: 'underline',

        "&:not(.disabled):hover": {
            color: "#fff",
            background: 'rgba(0,0,0,.26)!important',
            borderRadius: "8px",
        },

        "&.disabled": {
            cursor: "default",
        }
    },
}));

