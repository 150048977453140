import React, {useContext, useEffect} from 'react';
import {Provider, useDispatch, useSelector} from 'react-redux';
import {store} from './redux/store';
import {ThemeProvider} from '@material-ui/styles';
import {theme} from './app/theme';
import 'date-fns';
import {CssBaseline} from '@material-ui/core';
import {BrowserRouter as Router, Redirect, Route, Switch, useHistory} from 'react-router-dom';
import './App.css';
import LocaleLoader from './i18n/LocaleLoader.js';
import LocaleRouting from './i18n/LocaleRouting.js';
import SlashNormalizer from './SlashNormalizer.js';
import {LocaleContext} from './i18n/LocaleContext.js';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {userGetInfo, userInfo} from './redux/reducers/user.js';
import {checkPermission, tokensGetBearerToken} from './redux/reducers/tokens.js';
import {startWebPush} from './app/webpush.js';
import AuthGuard from './componets/AuthGuard.js';
import SingInPage from './pages/SingIn/SingInPage.js';
import ReportsPage from './pages/Reports/ReportsPage.js';
import Error404Page from './pages/Error404/Error404Page.js';
import Dashboard from './pages/Dashboard/Dashboard.js';
import AdminPage from './pages/AdminPage.js';
import {statsSummaryFetch} from './redux/reducers/stats.js';
import StoresPage from './pages/StoresListPage/StoresPage.js';
import StoreRoute from './pages/Store/StoreRoute.js';
import DispensingReport from './pages/ReportsPage/DispensingReport.js';
import IdleReport from './pages/ReportsPage/idleReport.js';
import TariffPage from './pages/TariffPage/TariffPage.js';
import {getLoadedLanguage} from './redux/reducers/locale.js';
import DocsPage from './pages/DocsPage/DocsPage.js';

import * as jwtDecode from 'jwt-decode';

function ScrollToTop() {
    const history = useHistory();

    useEffect(() => {
        const unlisten = history.listen(() => {
            window.scrollTo(0, 0);
        });
        return () => {
            unlisten();
        }
    }, []);

    return null;
}

/**
 * Основная функция.
 * Тут инициализуются redux, material ui, роутер, локализатор, дефолтные стили для страниц
 *
 * @component
 */
function App() {
    return (
        <Provider store={store}>
            <Router>
                <ScrollToTop />
                <ThemeProvider theme={theme}>
                    <CssBaseline/>
                    <SlashNormalizer>
                        <LocaleLoader>
                            <LocaleRouting>
                                <LocaleContext.Consumer>
                                    {localeObj => (
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeObj.dateFnsLocale}>
                                            <AppRouting/>
                                        </MuiPickersUtilsProvider>
                                    )}
                                </LocaleContext.Consumer>
                            </LocaleRouting>
                        </LocaleLoader>
                    </SlashNormalizer>
                </ThemeProvider>
            </Router>
        </Provider>
    );
}

export default App;

function WebPush(props) {
    const user = useSelector(userInfo);
    const token = useSelector(tokensGetBearerToken);

    useEffect(()=>{
        //console.log('START WEB PUSH');
        const username = user?.get('username');

        try {
            startWebPush(username, token);
        } catch (e) {
            console.log(e);
        }
    }, []);


    return props.children;
}

function UserWs(props) {
    const user = useSelector(userInfo);
    const token = useSelector(tokensGetBearerToken);
    const dispatch = useDispatch();

        // WebSocket
    // TODO: refactor this!
    useEffect( () => {
        // connect
        const decoded = jwtDecode(token);
        const userId = decoded.user?.id;

        const host = window.location.host.match(/localhost/) ? 'develop.smdev.npmgroup.ru' : window.location.host;
        const ws = new WebSocket(`wss://${host}/v2/ws/users/${userId}`);
        //console.log('USER WS connect');

        ws.onmessage = (event) => {
            const eventData = JSON.parse(event.data);

            dispatch({type: 'USER_WS_UPDATE', ...eventData});
        };

        dispatch(userGetInfo(userId));

        return () => {
            // disconnect
            //console.log('USER WS disconnect');
            ws.close();
        };
    }, []);

    return props.children;
}

function TopBarStatsLoader() {
    return null;
}

/**
 * Здесь происходит роутинг
 *
 * @component
 */
function AppRouting() {
    const locale = useSelector(getLoadedLanguage);

    const ruLocale = locale === 'ru';
    return (
        <AuthGuard singInPage={<SingInPage/>} refreshingTokensPage={<div>Refreshing...</div>}>
            <UserWs>
                <WebPush>
                    <TopBarStatsLoader />
                    <Switch>
                        {/* redirect */
                            <Redirect from="/:locale" to="/:locale/dashboard" exact />
                        }

                        {/* adminka */
                            useSelector(checkPermission('section_service')) &&
                            <Route path="/:locale/adm" exact component={AdminPage}/>
                        }

                        {/* adminka */
                            useSelector(checkPermission('section_service')) &&
                            <Route path="/:locale/adm/**" component={AdminPage}/>
                        }

                        {/* dashboard */
                            <Route path="/:locale/dashboard" exact component={Dashboard}/>
                        }

                        {/* список устройств*/
                            <Route path="/:locale/stores" exact component={StoresPage}/>
                        }

                        {/* страница устройства */
                            <Route path="/:locale/stores/:storeId" component={StoreRoute}/>
                        }

                        {/* страница отчётов */}
                            useSelector(checkPermission('section_reports')) &&
                            <Route path="/:locale/reports" exact component={ReportsPage}/>
                        }

                        {/* страница отчётов */}
                        useSelector(checkPermission('section_reports')) &&
                        <Redirect from="/:locale/new_reports" exact to="/:locale/new_reports/dispensing"/>
                        }
                        {/* страница отчётов */}
                        useSelector(checkPermission('section_reports')) &&
                        <Route path="/:locale/new_reports/dispensing" exact component={DispensingReport}/>
                        }
                        {/* страница отчётов */}
                        useSelector(checkPermission('section_reports')) &&
                        <Route path="/:locale/new_reports/idle" exact component={IdleReport}/>
                        }

                        {/* страница тарифов */
                            useSelector(checkPermission('access_to_tariffs')) &&
                                ruLocale &&
                            <Route path="/ru/tarif" exact component={TariffPage}/>
                        }

                        {/* страница тарифов */
                            useSelector(checkPermission('access_to_tariffs')) &&
                            ruLocale &&
                            <Route path="/ru/docs" exact component={DocsPage}/>
                        }

                        {/* 404 */}
                        <Route component={Error404Page}/>
                    </Switch>
                </WebPush>
            </UserWs>
        </AuthGuard>
    );
}
