import React, {useContext, useEffect, useState} from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    FormControlLabel,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {getWithToken, postWithToken} from '../../../app/rest';
import {useSelector} from 'react-redux';
import {tokensGetBearerToken} from '../../../redux/reducers/tokens';
import throttle from 'lodash.throttle';
import useDebounce from './useDebounce';
import {DeviceContext} from '../../../componets/DeviceLoader';
import {t} from '../../../i18n/utils.js';
import {useParams} from 'react-router-dom';

function RenderStore({store, ...props}) {
    const classes = useStyles();

    const chain = store?.chain?.name ? store.chain.name + ', ' : '';
    const city = store?.city?.name ? store.city.name + ', ' : '';
    const address = store?.address ? store?.address : '-';
    const hours = store?.work_hours?.start && store?.work_hours?.end ? ', ' + store?.work_hours.start + '-' + store?.work_hours.end : '';

    const labelStr = chain+city+address+hours;

    return (
        <FormControlLabel className={classes.item} value={String(store.id)} control={<Radio />} label={labelStr} />
    );
}

function ChangeStoreDialog({open, onClose, store, ...props}) {
    const classes = useStyles();
    const token = useSelector(tokensGetBearerToken);
    const group = useContext(DeviceContext);

    const params = useParams();
    //console.log(params);
    const pStore_id = params.storeId;

    const store_id = pStore_id;

    const [stores, setStores] = useState([]);
    const [count, setCount] = useState(0);
    const [search, setSearch] = useState('');
    const [storeVal, setStoreVal] = useState(store_id);
    const debouncedSearch = useDebounce(search, 1000);
    const [offset, setOffset] = useState(0);

    console.log('SSSSSSS', group?.toJS(), store_id);

    const pageSize = 35;

    const loadPage = () => {
        if (offset !== 0) {
            setStores([]);
            setCount(0);
            setOffset(0);
        } else {
            loadPage2(offset, true);
        }
    };

    const loadPage2 = async (offset, reload) => {
        let endpoint = `/v2/api/stores/full?offset=${offset}&limit=${pageSize}`;

        if (debouncedSearch) {
            endpoint += `&search=${debouncedSearch}`;
        }

        const resp = await getWithToken(endpoint, token, 'Bearer');
        const res = resp?.items || [];

        if (!reload) {
            setStores([...stores, ...res]);
        } else {
            setStores([...res]);
        }
        setCount(resp.count);
    };

    useEffect(()=> {
        loadPage();
    }, [debouncedSearch]);


    useEffect(()=> {
        loadPage2(offset);
    }, [offset]);

    const handleSubmit = () => {
        const endpoint = '/v2/api/stores/assign_group'

        const device_id = group.get('id');

        const body = {
            group_id: device_id,
            store_id: parseInt(storeVal, 10) || null,
        };

        postWithToken(endpoint, body, 'PUT', token, 'Bearer').then(
            resp => {
                onClose();
                window.location.href = '';
            },
            err => {
                alert(t('washDialogError'));
            }
        )
    }

    const handleScroll = (event) => {
        const { target } = event;
        const bottom = target.scrollHeight - target.scrollTop === target.clientHeight;

        if (bottom && offset + pageSize < count) {
            // load more
            setOffset(offset + pageSize)
        }
    }

    const handleScrollThrottled = throttle(handleScroll, 100);

    const handleScrollPersist = (ev) => {
        ev.persist();
        handleScrollThrottled(ev);
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth={"md"} classes={{paper: classes.paper}}>
            <Typography variant="h1" className={classes.heading}>{t('store')}</Typography>

            <div className={classes.searchWrap}>
                <TextField fullWidth label={t('storeSearch')} value={search} onChange={(ev)=>setSearch(ev.target.value)}/>
            </div>

            <DialogContent className={classes.cont} onScroll={handleScrollPersist}>
                <RadioGroup name="store" value={storeVal} onChange={(event, value)=>{
                    setStoreVal(value);
                }}>
                    <FormControlLabel className={classes.item} value="" control={<Radio />} label={t('undefinedStore')} />
                    {stores.map((s, index) => <RenderStore key={index} store={s} />)}
                </RadioGroup>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onClose}>{t('cancelBtn')}</Button>
                <Button color="primary" variant="contained" onClick={handleSubmit}>
                    {t('saveBtn')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ChangeStoreDialog

const useStyles = makeStyles(theme => ({
    searchWrap: {
        padding: '0 16px',
        marginBottom: 32,
    },
    heading: {
        textAlign: "left",
        margin: "24px 0",
        minWidth: 320,
        fontSize: 19,
        lineHeight: '22px',
        fontWeight: 500,
    },
    cont: {
        marginBottom: 32,
    },
    paper: {
        width: 470,
    },
    item: {
        marginBottom: 8,
    }
}));
