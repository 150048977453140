import React, {useEffect} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import DetailHeader from './header/DetailHeader';
import Tap from './modulesWidgets/Tap';
import Printer from './modulesWidgets/Printer';
import {Route, Switch, useParams} from 'react-router-dom';
import ModuleView from './ModulePage/ModuleView';
import {fetchDeviceDetails, getDeviceDetails} from '../../redux/reducers/deviceDetails';
import LinearProgress from '@material-ui/core/LinearProgress';
import {makeStyles} from '@material-ui/core/styles';
import {DeviceContext} from '../../componets/DeviceLoader';
import {Paper, Typography} from '@material-ui/core';
import PageLayout from '../../componets/PageLayout/PageLayout';
import LocalizedLink from '../../localization/LocalizedLink';
import {adminLink} from '../../app/utils';
import {checkPermission, tokensGetBearerToken} from '../../redux/reducers/tokens';
import {getLoadedLanguage} from '../../redux/reducers/locale';
import ElementsPage from './ElementsPage/ElementsPage';
import LogPage from './LogPage/LogPage';
import CalibrationPage from './CalibrationPage/CalibrationPage';
import SettingsPage from './SettingsPage/SettingsPage';
import {getWithToken} from '../../app/rest';
import {t} from '../../i18n/utils.js';
import UserActionLogPage from './UserActionLogPage/UserActionLogPage.js';
import {setTitle} from '../Dashboard/Dashboard.js';
import classNames from 'classnames';

export function Wrap({children}) {
    return (
        <PageLayout url="stores">
            <section>
                {children}
            </section>
        </PageLayout>
    );
}

export function DetailLayout({children, device}) {
    return (
        <Wrap>
            <DetailHeader device={device} linkBack={'/stores'}/>

            {children}
        </Wrap>
    );
}

function renderModule(device, deviceId, module, index) {
    const type = module.get('type');
    switch (type) {
        case 'tap':
            return (
                <LocalizedLink to={`/groups¡/${deviceId}/modules/${module.get('id')}/`}>
                    <Tap tapData={module} index={index} device={device}/>
                </LocalizedLink>
            );
        case 'manager':
            return (
                <LocalizedLink to={`/groups/${deviceId}/modules/${module.get('id')}/`}>
                    <Printer printerData={module} index={index} device={device}/>
                </LocalizedLink>
            );
        default:
            return null;
    }
}

function ModulesPage(props) {
    const classes = useStyles();
    const token = useSelector(tokensGetBearerToken);
    const locale = useSelector(getLoadedLanguage);

    /* permissions */
    const elementsAccess = useSelector(checkPermission('access_to_elements_page'));
    const logAccess = useSelector(checkPermission('access_to_journal'));
    const userLogAccess = useSelector(checkPermission('access_to_user_action_journal'));
    const calibAccess = useSelector(checkPermission('access_to_calibration'));
    const settingsAccess = useSelector(checkPermission('access_to_read_control_panel'));

    const { deviceId } = props.match.params;
    const { getDevice, load } = props;

    const dispatch = useDispatch();

    const params = useParams();
    const storeId = params.storeId;

    useEffect(() => {
        //console.log('LOAD!!!');
        load(deviceId);
        //console.log('LOAD end!!!');

        /*
        const ii = setInterval(()=>{
            //console.log('update');
            load(deviceId, true);
        }, 2000);

        return ()=>{
            clearInterval(ii);
        }*/

    }, []);

    const device = getDevice(deviceId);

    //console.log(device);

    if (!(device?.get instanceof Function)) {
        return (
            <Wrap>
                <div className={classes.loadingWrap}>
                    <LinearProgress />
                </div>
            </Wrap>
        );
    }

    if (device?.get("isError")) {
        return (
            <Wrap>
                <div className={classNames(classes.loadingWrap, classes.padding)}>
                    <Typography variant={"h1"}>{t('washDialogError')}</Typography>
                </div>
            </Wrap>
        );
    }

    if (!device?.get("isLoaded")) {
        return (
            <Wrap>
                <div className={classes.loadingWrap}>
                    <LinearProgress />
                </div>
            </Wrap>
        );
    }


    const modules = device.get('modules');

    let modulesShowed = 0;

    const indicators = device.get('indicators');
    const connection = indicators?.find(i => i.get('type') === 'connection');
    const isDisconnected = !connection?.get('value');

    let pageList = [];

    if (settingsAccess) pageList.push('/:locale/stores/:storeId/groups/:deviceId/settings/');
    if (elementsAccess && false) pageList.push('/:locale/stores/:storeId/groups/:deviceId/elements/');
    if (logAccess) pageList.push('/:locale/stores/:storeId/groups/:deviceId/log/');
    if (userLogAccess) pageList.push('/:locale/stores/:storeId/groups/:deviceId/userlog/');
    if (calibAccess && device.get('type') !== 'Smart') pageList.push('/:locale/stores/:storeId/groups/:deviceId/calibration/');

    //console.log(pageList);

    const deviceNumTitle = device?.get('device_number');

    setTitle(deviceNumTitle);

    //console.log('MODULE PAGE');

    return (
        <>
            <div className={isDisconnected ? 'disconnected' : 'connected'}>
                <DeviceContext.Provider value={device}>
                    <Switch>
                        {settingsAccess &&
                        <Route path="/:locale/stores/:storeId/groups/:deviceId/settings/"
                               render={(props) => <SettingsPage device={device}
                                                                pageList={pageList}
                                                                currentPage={'/:locale/stores/:storeId/groups/:deviceId/settings/'}
                                                                {...props}/>}/>
                        }
                        {elementsAccess &&
                        <Route path="/:locale/stores/:storeId/groups/:deviceId/elements/" exact
                               render={(props) => <ElementsPage device={device}
                                                                pageList={pageList}
                                                                currentPage={'/:locale/stores/:storeId/groups/:deviceId/elements/'}
                                                                {...props}/>}/>
                        }
                        {logAccess &&
                        <Route path="/:locale/stores/:storeId/groups/:deviceId/log/" exact
                               render={(props) => <LogPage device={device}
                                                           pageList={pageList}
                                                           currentPage={'/:locale/stores/:storeId/groups/:deviceId/log/'}
                                                           {...props}/>}/>
                        }
                        {userLogAccess &&
                        <Route path="/:locale/stores/:storeId/groups/:deviceId/userlog/" exact
                               render={(props) => <UserActionLogPage device={device}
                                                           pageList={pageList}
                                                           currentPage={'/:locale/stores/:storeId/groups/:deviceId/userlog/'}
                                                           {...props}/>}/>
                        }
                        {calibAccess && device.get('type') !== 'Smart' &&
                        <Route path="/:locale/stores/:storeId/groups/:deviceId/calibration/" exact
                               render={(props) => <CalibrationPage device={device}
                                                                   pageList={pageList}
                                                                   currentPage={'/:locale/stores/:storeId/groups/:deviceId/calibration/'}
                                                                   {...props}/>}/>
                        }
                    <Route path="/:locale/stores/:storeId/groups/:deviceId/modules/:moduleId/"
                           render={(props) => <ModuleView device={device} {...props}/>}/>
                    <Route path="/:locale/stores/:storeId/groups/:deviceId/modules/" exact render={() =>
                        <DetailLayout device={device}>
                            <div className={classes.modules}>
                                {modules.map((m, i) => {
                                    // show module
                                    const rendered = renderModule(device, deviceId, m, i);
                                    if (rendered) {
                                        modulesShowed++;
                                        return (
                                            <div className={classes.module} key={i}>
                                                {rendered}
                                            </div>
                                        );
                                    }
                                })}
                                {!modulesShowed &&
                                    <Paper className={classes.noModulesPaper}>
                                        <Typography variant="h1" className={classes.noModulesh1}>
                                            {t('noModulesFound')}
                                        </Typography>
                                        <Typography>
                                            {t('pleaseConfigure')}
                                            <a href={adminLink(token, '/admin/', locale)}>
                                                {t('configureHere')}
                                            </a>
                                        </Typography>
                                    </Paper>
                                }
                            </div>
                        </DetailLayout>
                    }/>
                        <Route render={(props) => {
                            return (<div>{JSON.stringify(props, null, 2)}</div>);
                        }} />
                    </Switch>
                </DeviceContext.Provider>
            </div>
        </>
    );
}

function mapStateToProps(state) {
    return {
        getDevice: (id) => getDeviceDetails(state, id),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        load: (id, light) => dispatch(fetchDeviceDetails(id, light)),
    };
}

export default ModulesPage |> connect(mapStateToProps, mapDispatchToProps);

const useStyles = makeStyles(theme => ({
    module: {
        margin: theme.spacing(1),
        ".disconnected & *": {
            color: "#c1c1c1 !important",
        },
    },
    loadingWrap: {
        marginTop: 28,
    },
    padding: {
        margin: "1em",
        padding: "24px 16px",
    },
    noModulesPaper: {
        margin: "1em",
        padding: "24px 16px",

        "& a": {
            marginLeft: "0.5ch",
            marginRight: "0.5ch",
            textDecoration: "underline",
        },
    },
    noModulesh1: {
        marginBottom: "1em",
    },
}));
