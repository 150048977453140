import React from 'react';
import {Redirect, useRouteMatch} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {getLanguageError, getLoadedLanguage, isLanguageFetching, loadLanguage} from '../redux/reducers/locale.js';
import {LocaleContext} from './LocaleContext.js';
import {defaultLocale} from './locales.js';
import {getLocaleByCode, getSupportedBrowserLang, isLangSupported} from './utils.js';
import {getSavedLanguage, userInfo} from '../redux/reducers/user.js';
import {tokensGetAccessToken, tokensGetBearerToken} from '../redux/reducers/tokens.js';

/**
 * Отображает заглушку пока грузится локаль
 *
 * @returns {JSX.Element}
 * @constructor
 */
function LocaleLoading() {
    return (
        <div>Locale loading...</div>
    );
}

/**
 * Отображает заглушку пока грузится локаль
 *
 * @returns {JSX.Element}
 * @constructor
 */
function LocaleLoadingError() {
    return (
        <div>Locale loading error!</div>
    );
}

/**
 * Определяет на каком языке отображать сайт
 *
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
function LocaleLoader({children}) {
    const match = useRouteMatch('/:locale');
    const urlLocale = match ? match.params.locale : undefined;

    const browserSupportedLocale = getSupportedBrowserLang();

    let localeToShow;

    const savedLang = useSelector(getSavedLanguage);

    /*
    let userLocale;
    const accessToken = useSelector(tokensGetAccessToken);
    const uInfoImm = useSelector(userInfo);

    if (accessToken) {
        const uInfo = uInfoImm.toJS ? uInfoImm.toJS() : null;
        //console.log('USER INFO', uInfo, accessToken);
        userLocale = uInfo.language;
    }
    if (userLocale && isLangSupported(userLocale)) {
        return (<Redirect to={`/${userLocale}`}/>);
    }
     */

    let userLocale;
    const accessToken = useSelector(tokensGetBearerToken);

    if (accessToken) {
        userLocale = savedLang;
    }

    if (isLangSupported(userLocale)) {
        localeToShow = userLocale;
    } else if (isLangSupported(urlLocale)) {
        localeToShow = urlLocale;
    } else if (browserSupportedLocale) {
        localeToShow = browserSupportedLocale;
    } else {
        localeToShow = defaultLocale;
    }

    console.log(urlLocale, savedLang, browserSupportedLocale, 'SHOW:', localeToShow);

    const isLocaleLoading = useSelector(isLanguageFetching);
    const loadedLocale = useSelector(getLoadedLanguage);
    const localeLoadingError = useSelector(getLanguageError);

    const dispatch = useDispatch();

    if (isLocaleLoading) {
        return (
            null//<LocaleLoading />
        );
    }

    if (localeLoadingError) {
        return (
            <LocaleLoadingError />
        );
    }

    console.log('LANG LOADER', localeToShow, loadedLocale);

    if (localeToShow !== loadedLocale) {
        dispatch(loadLanguage(localeToShow));

        return (
            <LocaleLoading />
        );
    }

    const localeObj = getLocaleByCode(localeToShow);

    return (
        <LocaleContext.Provider value={localeObj}>
            {children}
        </LocaleContext.Provider>
    );
}

export default LocaleLoader;
