import React, {useEffect} from 'react';
import {connect, useSelector} from 'react-redux';
import {fetchDeviceDetails, getDeviceDetails} from '../redux/reducers/deviceDetails';
import {tokensGetAccessToken} from '../redux/reducers/tokens';

export const DeviceContext = React.createContext(undefined);

function DeviceLoader(props) {
    const { deviceId, fetchDeviceDetails, getDevice } = props;

    const token = useSelector(tokensGetAccessToken);

    useEffect(() => {
        fetchDeviceDetails(deviceId);
    }, [fetchDeviceDetails, deviceId]);

    const device = getDevice(deviceId);

    if (!device?.get("isLoaded")) {
        return (
            <div>Loading</div>
        );
    }

    return (
        <DeviceContext.Provider value={device}>
            {props.children}
        </DeviceContext.Provider>
    );
}

function mapStateToProps(state) {
    return {
        getDevice: (id) => getDeviceDetails(state, id),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        fetchDeviceDetails: (id) => dispatch(fetchDeviceDetails(id)),
    };
}

export default DeviceLoader |> connect(mapStateToProps, mapDispatchToProps);