import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, Typography} from '@material-ui/core';
import {t} from '../../../i18n/utils.js';

function ConfirmDialog({open, text, onClose, ...props}) {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogContent>
                <Typography>
                    {text}
                </Typography>
            </DialogContent>

            <DialogActions>
                <Button  variant={'outlined'} onClick={onClose}>{t('closeBtnBackup')}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmDialog;
