import React, {useContext} from 'react';
import {LocaleContext} from './LocaleContext.js';
import {Redirect, useLocation, useRouteMatch} from 'react-router-dom';
import {isLangSupported} from './utils.js';
import Error404Page from '../pages/Error404/Error404Page.js';
import {useSelector} from 'react-redux';
import {tokensGetAccessToken, tokensGetBearerToken} from '../redux/reducers/tokens.js';
import {userInfo} from '../redux/reducers/user.js';

/**
 * Рисует страницу с ошибкой о неподдерживаемой локале
 * @param wrong
 * @returns {JSX.Element}
 * @constructor
 */
export function ErrorWrongLocale({wrong}) {
    return (
        <Error404Page />
    );
}

/**
 * Роутит страницы относительно локали
 * С корня перенаправляет на определившуюся локаль
 * Для неподдерживаемых локалей выдаёт 404
 *
 * @returns {JSX.Element}
 * @constructor
 */
function LocaleRouting({children}) {
    const localeObj = useContext(LocaleContext);
    const isRoot = useRouteMatch({path: '/', exact: true}) !== null;

    const match = useRouteMatch('/:locale');
    const urlLocale = match ? match.params.locale : undefined;

    let location = useLocation();

    let userLocale;
    const accessToken = useSelector(tokensGetBearerToken);
    const uInfoImm = useSelector(userInfo);

    if (accessToken) {
        const uInfo = uInfoImm?.toJS ? uInfoImm?.toJS() : null;
        //console.log('USER INFO', uInfo, accessToken);
        userLocale = uInfo?.language;
    }
    if (userLocale && isLangSupported(userLocale) && userLocale !== urlLocale && !isRoot) {
        let oldUrl = location.pathname + location.search + location.hash;
        console.log('URL LOCATION',oldUrl);
        let newUrl = oldUrl.replace(`/${urlLocale}`, `/${userLocale}`);
        console.log('NEW LOCATION', newUrl);

        console.log('AAAAAAAAAAAA', userLocale, urlLocale);

        return <Redirect to={newUrl} />;

        /*
        return (
            <h1>
                userLocale: {userLocale}<br/>
                urlLocale: {urlLocale}
            </h1>
        )*/
    }


    if (urlLocale === 'auto') {
        console.log('URL LOCATION',location);
        let oldUrl = location.pathname + location.search + location.hash;
        let newUrl = oldUrl.replace(/^\/auto/, `/${localeObj.code}`);
        console.log('NEW LOCATION', newUrl);
        return (
            <Redirect to={newUrl} />
        );
    }

    const isUrlLocaleSupported = isLangSupported(urlLocale);

    if (isRoot) {
        return (
            <Redirect to={`/${localeObj.code}`}/>
        );
    }

    if (!isUrlLocaleSupported) {
        return (
            <ErrorWrongLocale wrong={urlLocale}/>
        );
    }

    return children;
}

export default LocaleRouting;
