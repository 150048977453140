import React, {useState} from 'react';
import {theme} from './theme.js';
import {CssBaseline, ThemeProvider, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import TopBar from './TopBar.js';
import Filters from './Filters.js';
import DailyStats from './DailyStats.js';
import CalendarStats from './CalendarStats.js';
import PageLayout from '../../componets/PageLayout/PageLayout.js';

export function setTitle(title) {
    document.title = title;
}

function Dashboard() {
    const classes = useStyles();

    const [city, setCity] = useState(null);
    const [store, setStore] = useState([]);
    const [chains, setChains] = useState([]);

    setTitle('Статистика');

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>

            <PageLayout noContainer={true}>
                <div className={classes.layout}>
                    <div className={classes.content}>
                        <TopBar />
                        <div className={classes.main}>
                            <div className={classes.pageTitle}>
                                <Typography variant="h1">Статистика</Typography>
                            </div>
                            <Filters city={city} setCity={setCity} store={store} setStore={setStore}
                                chains={chains} setChains={setChains}
                            />
                            <div className={classes.sep} />
                            <DailyStats city={city} store={store} chains={chains} />
                            <div className={classes.sep} />
                            <CalendarStats city={city} store={store} chains={chains} />
                        </div>
                    </div>
                </div>
            </PageLayout>

        </ThemeProvider>
    );
}
export default Dashboard;

const styles = (/*theme*/) => ({
    main: {
        maxWidth: 1200,
    },
    sep: {
        background: theme.colors.lightGrey.c100,
        height: 1,
        width: '100%',
        marginBottom: 24,

        [theme.breakpoints.up('md')]: {
            display: 'none',
        }
    },
    layout: {
        display: 'flex',
        minHeight: '100vh',
    },
    content: {
        minWidth: '360px',
        width: '100%',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        paddingRight: theme.spacing(6),
        paddingLeft: theme.spacing(6),
    },
    pageTitle: {
        marginBottom: 16,

        [theme.breakpoints.up('md')]: {
            display: 'none',
        }
    }
});
const useStyles = makeStyles(styles);
