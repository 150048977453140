import React from 'react';
import {useDispatch} from 'react-redux';
import {generatePath, useHistory, useLocation, useRouteMatch} from 'react-router-dom';
import {t} from '../../i18n/utils.js';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import {Checkbox, ClickAwayListener} from '@material-ui/core';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import {makeStyles} from '@material-ui/core/styles';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank.js';
import CheckBoxIcon from '@material-ui/icons/CheckBox.js';

function SettingsMenu(props) {
    const classes = useStyles();
    const { open, anchorRef, onClose } = props;

    const dispatch = useDispatch();

    const loc = useLocation();
    const match = useRouteMatch();
    const history = useHistory();

    function changeLanguage(lang) {
        //console.log(match);

        const newPath = generatePath(match.path, {
            ...match.params,
            locale: lang,
        });

        const newLoc = {
            ...loc,
            pathname: newPath,
        }

        history.push(newLoc);
    }

    // TODO: load languages from config
    const menuItems = [
        {
            title: t('showTimeSet'),
            checked: props.showTime,
            change: (ev) => {
                const v = ev.target.checked;
                props.setShowTime(!props.showTime);
            }
        },
        {
            title: t('showAddressSet'),
            checked: props.showAddress,
            change: (ev) => {
                const v = ev.target.checked;
                props.setShowAddress(!props.showAddress);
            }
        },
        {
            title: t('showErrorsSet'),
            checked: props.showErrors,
            change: (ev) => {
                const v = ev.target.checked;
                props.setShowErrors(!props.showErrors);
            }
        },
    ];

    const handleMenuItemClick = (event, index) => {
        menuItems[index].change(event);
        //onClose();
    };

    const uncheckedIcon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    return (
        <Popper open={open} anchorEl={anchorRef.current} transition disablePortal>
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin: placement === 'left bottom',
                    }}
                >
                    <Paper className={classes.paper}>
                        <ClickAwayListener onClickAway={onClose}>
                            <MenuList id="split-button-menu" className={classes.menuList}>
                                {menuItems.map((item, index) => (
                                    <MenuItem
                                        key={item.title}
                                        onClick={(event) => handleMenuItemClick(event, index)}
                                        className={classes.menuItem}
                                    >
                                        <Checkbox
                                            icon={uncheckedIcon}
                                            checkedIcon={checkedIcon}
                                            style={{marginRight: 8}}
                                            className={classes.checkbox}
                                            checked={item.checked}
                                            color="primary"
                                            onChange={(ev) => {item.change(ev)}}
                                        />
                                        {item.title}
                                    </MenuItem>
                                ))}
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    );
}

export default SettingsMenu;


const styles = (theme) => ({
    paper: {
        zIndex: 200,
        border: '1px solid ' + theme.colors.lightGrey.c68,
        boxShadow: theme.shadows[3],
        borderRadius: 4,
        padding: 0,
    },
    menuList: {
        padding: 0,
    },
    menuItem: {
        padding: 8,
    },
    checkbox: {
        padding: 0,
    }
});
const useStyles = makeStyles(styles);
