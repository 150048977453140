import React, {useState} from 'react';
import {Container, ThemeProvider} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import HomeIcon from '../../icons/HomeIcon';
import ManageIcon from '../../icons/ManageIcon';
import UserIcon from '../../icons/UserIcon';
import {useSelector} from 'react-redux';
import {checkPermission, tokensGetAccessToken} from '../../redux/reducers/tokens';
import LocalizedLink from '../../localization/LocalizedLink';
import {getLoadedLanguage} from '../../redux/reducers/locale';
import ChartIcon from '../../icons/ChartIcon';
import {adminLink, prefixPath} from '../../app/utils';
import {t} from '../../i18n/utils.js';
import MobileTopBar from '../../pages/Dashboard/MobileTopBar.js';
import {theme} from '../../pages/Dashboard/theme.js';

const PageLayoutMobile = props => {
    const classes = useStyles(props);
    const [profileMenu, setProfileMenu] = useState(false);
    const profileTabRef = React.useRef(null);
    const locale = useSelector(getLoadedLanguage);

    const { children } = props;

    const token = useSelector(tokensGetAccessToken);

    const menuItems = [
        {
            label: t('mainMenu_profile'),
            icon: <UserIcon/>,
            disabled: false,
            onClick: () => {setProfileMenu(true)},
            ref: profileTabRef,
            component: 'button',
        },
        {
            label: t('mainMenu_stores'),
            icon: <HomeIcon/>,
            disabled: false,
            to: "/stores",
            component: LocalizedLink,
        },
        {
            label: t('mainMenu_admin'),
            icon: <ManageIcon/>,
            disabled: !useSelector(checkPermission('section_service')),
            href: adminLink(token, '/admin/', locale),
            component: 'a',
        },
        {
            label: t('mainMenu_reports'),
            icon: <ChartIcon/>,
            disabled: !useSelector(checkPermission('section_reports')),
            href: prefixPath('/reports', locale),
        },
    ];

    const tabClasses = {
        root: classes.tab,
        selected: classes.tabActive,
    };

    let activeTab;
    if (props.url === 'stores') {
        activeTab = 1;
    }
    if (props.url === 'reports') {
        activeTab = 3;
    }

    return (
        <Container disableGutters className={classes.container}>
            <main id="main"  className={classes.main}>
                {children}
            </main>

            <ThemeProvider theme={theme}>
                {props.customMobileTopBar ?
                    props.customMobileTopBar
                    :
                    <MobileTopBar/>
                }
            </ThemeProvider>
            {/*}
            <nav className={classes.bottomNavigation}>
                <Tabs
                    classes={{
                        indicator: classes.tabIndicator,
                        flexContainer: classes.tabsFlexContainer
                    }}
                    value={activeTab}>
                    {menuItems.map((menuOpt, index) => (
                        !menuOpt.disabled &&
                        <Tab key={index} {...menuOpt} classes={tabClasses} />
                    ))}
                </Tabs>
                <ProfileMenu open={profileMenu} anchorRef={profileTabRef} onClose={() => {setProfileMenu(false);}} />
            </nav>
            <div className={classes.bottomNavigationSpacer} />*/}
        </Container>
    );
}

export default PageLayoutMobile;

const constants = {
    bottomNavigation: {
        height: 82,
    },
};

const styles = theme => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: 'calc(100vh - 56px)',
    },
    container: {
        paddingTop: props => props.pt ? props.pt : 56,
    },
    bottomNavigation: {
        width: "100vw",
        bottom: 0,
        height: constants.bottomNavigation.height,
        zIndex: 50,
        position: "fixed",
        background: "rgba(249, 249, 249, 0.94)",
        boxShadow: "0px -0.5px 2px rgba(0, 0, 0, 0.3)",
        backdropFilter: "blur(20px)",
    },
    bottomNavigationSpacer: {
        height: constants.bottomNavigation.height,
    },

    tabIndicator: {
        display: "none",
    },
    tabsFlexContainer: {
        justifyContent: "space-around",
    },
    tab: {
        fontSize: "10px",
        letterSpacing: "-0.24px",
        lineHeight: "12px",
        fontWeight: "normal",
        textTransform: "capitalize",
        opacity: 1,
        color: theme.palette.text.primary,
        margin: "0",
        padding: 0,
    },
    tabActive: {
        color:  theme.palette.primary.main,
    },
})

const useStyles = makeStyles(styles);
