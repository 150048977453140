import React from 'react';

function BackIcon(props) {
    return (
        <svg {...props} width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.3487 19.8643C10.2587 19.9548 10.1237 20 9.98875 20C9.80877 20 9.67379 19.9548 9.5838 19.8643L0.179977 10.362C0.0449944 10.2715 0 10.181 0 10C0 9.86425 0.0449944 9.72851 0.179977 9.63801L9.5838 0.135747C9.67379 0.0452489 9.80877 0 9.98875 0C10.1237 0 10.2587 0.0452489 10.3487 0.135747L11.2486 1.04072C11.3386 1.13122 11.4286 1.26697 11.4286 1.40271C11.4286 1.58371 11.3386 1.71946 11.2486 1.80995L3.10461 10L11.2486 18.19C11.3386 18.3258 11.4286 18.4615 11.4286 18.5973C11.4286 18.7783 11.3386 18.8688 11.2486 18.9593L10.3487 19.8643Z" fill="currentColor"/>
        </svg>
    );
}

export default BackIcon;
