import React from 'react';
import {
    Dialog,
    DialogContent,
    DialogTitle as MuiDialogTitle,
    IconButton,
    Typography,
    withStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {makeStyles} from '@material-ui/core/styles';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    storename: {
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 500,
    },
    storeaddress: {
        color: "#7E858E",
        fontSize: '12px',
        lineHeight: '16px',
    },
    notifType: {
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '0.002em',
    },
    dialogContent: {
        padding: 8,
        marginTop: 8,
    },
    notiHeader: {
        display: 'flex',
        gap: '6px',
    },
    notiBlock: {
        padding: '8px 0',
    },
    paper: {
        padding: 12,
        maxWidth: 500,
    },
    notiIcon: {
        width: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    notifItem: {
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0.002em',

        color: '#7E858E',
    },
    h: {
        color: '#1D1D1D',
        fontSize: 19,
        fontWeight: 500,
        lineHeight: '22px',
        letterSpacing: '0em',
        marginBottom: 16,
        textAlign: 'center'
    },
    c: {
        fontSize: 15,
        fontWeight: 400,
        lineHeight: '20px',
        marginBottom: 18,
        textAlign: 'center',

        '&:last-child': {
            marginBottom: 20,
        }
    }
});

const useStyles = makeStyles(styles);

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

function MyDialog({open, store, type, onclose, isStore}) {
    const classes = useStyles();

    return (
        <Dialog open={open} onClose={onclose} fullWidth={true} classes={{
            paper: classes.paper,
        }}>
            <DialogTitle onClose={onclose}>

            </DialogTitle>

            <DialogContent className={classes.dialogContent}>
                <div className={classes.h}>
                    Запрос счета отправлен менеджеру
                </div>
                <div className={classes.c}>
                    В течении суток на вашу почту придет письмо
                    со счетом для оплаты
                </div>
            </DialogContent>
        </Dialog>
    );
}

export default MyDialog;
