import React, {useState} from 'react';
import {postWithToken} from '../../../app/rest';
import {Button, Dialog, DialogActions, DialogContent, TextField, Typography} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {apiChangeParam} from '../../../app/api';
import {useDispatch, useSelector} from 'react-redux';
import {tokensGetAccessToken, tokensGetBearerToken} from '../../../redux/reducers/tokens';
import {t} from '../../../i18n/utils.js';

function ParamValue({device, param, isDisconnected}) {
    const classes = useStyles();

    const [val, setVal] = useState(param.value);

    const token = useSelector(tokensGetAccessToken);
    const device_id = device.get('id');

    return (
        <div className={classes.paramSec}>
            <TextField label={param.title} value={val} fullWidth className={classes.paramEdit}
                       onChange={(ev)=>{
                           //console.log(ev.target.value);
                           setVal(ev.target.value);
                       }}
                       onBlur={(ev) => {
                           if (param.value !== val) {
                               // update value
                               apiChangeParam(token, device_id, param.id, val).then(r => {
                                   //console.log(r);
                               });
                           }
                       }}
                       disabled={isDisconnected}
            />
        </div>
    );
}

function ServiceModeDialog({open, onClose, grp, device, isDisconnected, ...props}) {
    const classes = useStyles();

    const token = useSelector(tokensGetBearerToken);

    const dispatch = useDispatch();

    function handleSubmit() {
        const deviceId = device.get('id');
        postWithToken(`/v2/api/groups/${deviceId}/service`, {value: true}, 'PUT', token, 'Bearer').then(r => {

            //DEBUG
            //dispatch({type: 'DEVICE_DETAILS_SERVICE_MODE', enabled: true, enabledTo: '2021-05-20 10:40:00', id: device.get('id')});

            onClose();
        }, err => {
            alert(t('washDialogError'));
        });

        //onClose();
    }

    function handleSubmit2() {
        const deviceId = device.get('id');
        postWithToken(`/v2/api/groups/${deviceId}/service`, {value: false}, 'PUT', token, 'Bearer').then(r => {

            //DEBUG
            //dispatch({type: 'DEVICE_DETAILS_SERVICE_MODE', enabled: true, enabledTo: '2021-05-20 10:40:00', id: device.get('id')});

            onClose();
        }, err => {
            alert(t('washDialogError'));
        });

        //onClose();
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <Typography variant="h1" className={classes.heading}>{t('serviceMode_header')}</Typography>

            <DialogContent className={classes.cont}>
                <Typography>
                    {t('serviceMode_dialog').split('\n').map(t => (
                        <React.Fragment>
                            {t}<br/>
                        </React.Fragment>
                    ))}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button color="primary" variant="outlined" onClick={handleSubmit} disabled={isDisconnected}>{t('serviceMode_onBtn')}</Button>
                {/*<Button color="secondary" variant="outlined" onClick={handleSubmit2} disabled={isDisconnected}>{t('serviceMode_offBtn')}</Button>*/}
                <Button variant="outlined" onClick={onClose}>{t('cancelBtn')}</Button>
            </DialogActions>
        </Dialog>
    );
}

export default ServiceModeDialog;


const useStyles = makeStyles(theme => ({
    heading: {
        textAlign: "center",
        margin: "24px 0",
        minWidth: 320,
        maxWidth: 580,
    },
    cont: {
        marginBottom: 32,
    },

    paramSec: {
        padding: "8px 0",
    }
}));
