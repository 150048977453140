import React from 'react';
import {t} from '../../../i18n/utils.js';
import moment from 'moment';

function NotifDate({notif}) {

    const date = moment(notif?.date);
    const now = moment();

    const duration = moment.duration(now.diff(date));
    //console.log(duration);
    const d = now.diff(date, 'days')
    const h = duration.hours();
    const m = duration.minutes();



    let daysnum = '';
    if (d >= 1) {
        daysnum = t('dShort', {count: d});
    } else if (h >= 1) {
        daysnum = t('hShort', {count: h});
    } else {
        daysnum = t('mShort', {count: m});
    }

    return (
        <span>{daysnum} {t('ago')}</span>
    );
}
export default NotifDate;
