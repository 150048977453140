import Immutable, {fromJS} from 'immutable';
import {tokensGetAccessToken} from './tokens';
import {apiDeviceList} from '../../app/api';
import {fetchDeviceDetails} from './deviceDetails';
import {check401, getResults} from '../../app/utils';
import {getLoadedLanguage as getSavedLocale} from './locale';

// action types
export const DEVICE_LIST_FETCH_REQUEST = "DEVICE_LIST_FETCH_REQUEST";
export const DEVICE_LIST_FETCH_SUCCESS = "DEVICE_LIST_FETCH_SUCCESS";
export const DEVICE_LIST_FETCH_FAILURE = "DEVICE_LIST_FETCH_FAILURE";

export const DEVICE_LIST_UPDATE_FILTERS = "DEVICE_LIST_UPDATE_FILTERS";

// reducer
const initialState = new Immutable.Map({
    count: 0,
    list: null,
    isFetching: false,
    isError: null,
    filters: new Immutable.Map({
        offset: 0,
        limit: 10,
        query: '',
        city: [],
        store: [],
        errors: [],
        devices: [],
        withProblems: false,
    }),
    limit: 10,
});
function reducer(state = initialState, action) {
    switch (action.type) {
        case DEVICE_LIST_FETCH_REQUEST:
            return state
                .set("isFetching", true)
                .set('isError', false);
        case DEVICE_LIST_FETCH_SUCCESS:
            return state
                .set("isFetching", false)
                .set('isError', false)
                .set('list', Immutable.fromJS(action.list))
                .set('count', action.count);
        case DEVICE_LIST_FETCH_FAILURE:
            return state
                .set("isFetching", false)
                .set('isError', true);
        case DEVICE_LIST_UPDATE_FILTERS:
            return state.mergeIn(['filters'], fromJS(action.filters));

        case 'DEVICE_LIST_SETLIMIT':
            console.log('SET LIMIT', action.limit);
            return state.set('limit', action.limit);
        default:
            return state;
    }
}
export default reducer;

// selectors
export function getCount(state) {
    return state.getIn(['deviceList', 'count']);
}

export function getFilters(state) {
    return state.getIn(['deviceList', 'filters']);
}

export const getDeviceList = state => state.getIn(['deviceList', 'list']);
export const deviceListIsFetching = state => state.getIn(['deviceList', 'isFetching']);
export const deviceListFetchError = state => state.getIn(['deviceList', 'isError']);

// actions
export const setFiltersAndFetch = filters => (dispatch) => {
    dispatch({type: DEVICE_LIST_UPDATE_FILTERS, filters});
    dispatch(fetchDeviceList());
}

export const fetchDeviceList = () => async (dispatch, getState) => {
    const state = getState();
    const token = tokensGetAccessToken(state);
    const filters = getFilters(state);
    const locale = getSavedLocale(state);
    const localeCode = locale;

    dispatch({type: DEVICE_LIST_FETCH_REQUEST, filters});

    try {
        const resp = await apiDeviceList(token, filters?.toJS(), localeCode);
        const list = getResults(resp);
        dispatch({type: DEVICE_LIST_FETCH_SUCCESS, list: list, count: resp.count});

        list.map(d => dispatch(fetchDeviceDetails(d.id, false, true)));
    } catch (err) {
        check401(err, dispatch);
        dispatch({type: DEVICE_LIST_FETCH_FAILURE});
    }
}

export function getSavedLimit(state) {
    return state.getIn(['deviceList', 'limit']);
}
