import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation, useRouteMatch} from 'react-router-dom';
import {t} from '../../i18n/utils.js';
import {checkPermission} from '../../redux/reducers/tokens.js';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import {ClickAwayListener} from '@material-ui/core';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import {makeStyles} from '@material-ui/core/styles';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank.js';
import CheckBoxIcon from '@material-ui/icons/CheckBox.js';
import LocalizedLink from '../../localization/LocalizedLink.js';

function DeviceMenu(props) {
    const classes = useStyles();
    const { open, anchorRef, onClose, storeId, groupId, } = props;

    const dispatch = useDispatch();

    const loc = useLocation();
    const match = useRouteMatch();
    const history = useHistory();

    const canReboot = useSelector(checkPermission('access_to_reload_device'));
    const canChangeStore = useSelector(checkPermission('access_to_change_store'));

    const accessToControlPanel = useSelector(checkPermission('access_to_read_control_panel'));
    const accessToElements = useSelector(checkPermission('access_to_elements_page'));
    const accessToLog = useSelector(checkPermission('access_to_journal'));
    const accessToUserLog = useSelector(checkPermission('access_to_user_action_journal'));
    const accessToCalib = false; //useSelector(checkPermission('access_to_calibration'));

    const menuItems = [
        {
            title: t('reboot'),
            href: ``,
            disabled: !canReboot,
            onClick: props.reboot,
        },
        {
            title: t('changeStore'),
            href: ``,
            disabled: !canChangeStore,
            onClick: props.changeStore,
        },
    ];

    if (accessToElements) {
        menuItems.push(
            {
                title: t('elementsHeading'),
                href: `/stores/${storeId}/groups/${groupId}/elements/`,
                disabled: false,
            });
    }

    console.log(props.isBeerbox, accessToCalib);
    if (props.isBeerbox && accessToCalib) {
        menuItems.push(
            {
                title: t('calib'),
                href: `/stores/${storeId}/groups/${groupId}/calibration/`,
                disabled: false,
            });
    }

    if (accessToUserLog) {
        menuItems.push({
            title: t('userLogHeading'),
            href: `/stores/${storeId}/groups/${groupId}/userlog/`,
            disabled: false,
        });
    }

    if (accessToControlPanel) {
        menuItems.push(
            {
                title: t('settingsHeading'),
                href: `/stores/${storeId}/groups/${groupId}/settings/`,
                disabled: false,
            });
    }

    if (accessToLog) {
        menuItems.push(
            {
                title: t('logHeading'),
                href: `/stores/${storeId}/groups/${groupId}/log/`,
                disabled: false,
            }
        );
    }

    const uncheckedIcon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    return (
        <Popper open={open} anchorEl={anchorRef.current} transition disablePortal>
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin: placement === 'left bottom',
                    }}
                >
                    <Paper className={classes.paper}>
                        <ClickAwayListener onClickAway={onClose}>
                            <MenuList id="split-button-menu" className={classes.menuList}>
                                {menuItems.map((item, index) => (
                                    item.href ?

                                        (<LocalizedLink to={item.href} key={index}>
                                            <MenuItem
                                                className={classes.menuItem}
                                                disabled={item.disabled}
                                            >
                                                {item.title}
                                            </MenuItem>
                                        </LocalizedLink>)
                                        :
                                        (
                                            <MenuItem
                                                className={classes.menuItem}
                                                disabled={item.disabled}
                                                onClick={item.onClick}
                                            >
                                                {item.title}
                                            </MenuItem>
                                        )
                                ))}
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    );
}

export default DeviceMenu;


const styles = (theme) => ({
    paper: {
        zIndex: 200,
        border: '1px solid ' + theme.colors.lightGrey.c68,
        boxShadow: theme.shadows[3],
        borderRadius: 4,
        padding: 0,
        minWidth: 15
    },
    menuList: {
        padding: 8,
    },
    menuItem: {
        padding: 8,
    },
    checkbox: {
        padding: 0,
    }
});
const useStyles = makeStyles(styles);
