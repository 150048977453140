import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classnames from 'classnames';

function SectionSwitch({title, active = false, ...props}) {
    const classes = useStyles();

    return (
        <div {...props} className={classes.sectionBtn}>
            {title}

            <div className={classnames([classes.onIndicator, {[classes.active]: active}])} />
        </div>
    );
}

export default SectionSwitch;

const useStyles = makeStyles(theme => ({
    sectionBtn: {
        background: '#eee',
        borderRadius: '4px',
        padding: 4,
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        cursor: "pointer",
    },
    onIndicator: {
        background: "#737373",
        width: 6,
        height: 6,
        borderRadius: "100%",
    },

    active: {
        background: "#30B402",
    },
}));