import React, {useState} from 'react';
import {Button, Paper} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {postWithToken} from '../../../app/rest';
import {tokensGetBearerToken} from '../../../redux/reducers/tokens';
import {useSelector} from 'react-redux';
import {t} from '../../../i18n/utils.js';

function TapCalibrationWidget({device, tap, ...props}) {
    const classes = useStyles();

    const [volEmpty, setVolEmpty] = useState('');
    const [volFull, setVolFull] = useState('');

    const token = useSelector(tokensGetBearerToken);

    const [succ, setSucc] = useState(false);

    const indics = device.get('indicators');
    const conInd = indics?.find(ind => ind.get('type') === 'connection');
    const isConnected =  conInd?.get('value') === true;

    function performCalibration() {
        const body = {
            //device_id: device.get('id'),
            device_id: tap.id,
            volume_empty: volEmpty,
            volume_full: volFull
        };
        postWithToken('/v2/api/devices/calibration', body, 'POST', token).then(r => {
            if (r === 'Ok') {
                setSucc(true);
            } else {
                alert(t('washDialogError'));
            }

        });
    }

    return (
        <div className={classes.tap}>
            <div className={classes.tapHeading}>{tap.title}</div>
            <Paper className={classes.tapPaper}>
                <div className={classes.calibHeading}>{t('calib')}</div>

                <div className={classes.step}>
                    <div className={classes.stepHeading}>{t('calib_step1')}</div>
                    <p className={classes.stepDesc}>{t('calib_step1_desc')}</p>
                    <div className={classes.inputGrp}>
                        <label>{t('calib_step1_label')}</label>
                        <TextField variant={'outlined'} size={'small'} fullWidth type={'number'}
                                   InputProps={{
                                       inputProps: {
                                           min: 0
                                       }
                                   }}
                                   value={volEmpty} onChange={(ev)=>{
                            setVolEmpty(ev.target.value);
                        }}
                        />
                    </div>
                </div>

                <div className={classes.step}>
                    <div className={classes.stepHeading}>{t('calib_step2')}</div>
                    <p className={classes.stepDesc}>{t('calib_step2_desc')}</p>

                    <div className={classes.inputGrp}>
                        <label>{t('calib_step2_label')}</label>
                        <TextField variant={'outlined'} size={'small'} fullWidth type={'number'}
                                   InputProps={{
                                       inputProps: {
                                           min: 0
                                       }
                                   }}
                                   value={volFull} onChange={(ev)=>{
                            setVolFull(ev.target.value);
                        }}
                        />
                    </div>
                </div>

                {succ ?
                    <div className={classes.step}>
                        <div className={classes.stepHeading}>{t('calib_step3')}</div>
                        <p className={classes.stepDesc}>
                            {t('calib_step3_desc')}
                        </p>
                        <p className={classes.stepDesc}>
                            {t('calib_step3_desc2')}
                        </p>
                        <Button variant="outlined" className={classes.btnStart}
                                onClick={()=>{
                                    setVolEmpty('');
                                    setVolFull('');
                                    setSucc(false);
                                }}
                        >
                            {t('calib_btn2')}
                        </Button>
                    </div>
                    :
                    <div className={classes.step}>
                        <Button variant="outlined" className={classes.btnStart} color={"primary"}
                                disabled={!(volFull > 0 && volEmpty > 0) || !isConnected}
                                onClick={performCalibration}
                        >
                            {t('calib_btn')}
                        </Button>
                    </div>
                }
            </Paper>
        </div>
    );
}

export default TapCalibrationWidget;

const useStyles = makeStyles(theme => ({
    tapHeading: {
        fontSize: '21px',
        marginBottom: '16px',
    },
    calibHeading: {
        fontSize: '16px',
        marginBottom: '1em',
    },
    btnStart: {
        width: '100%',
    },
    tap: {
        maxWidth: 320,
    },
    step: {
        marginBottom: '1.5em',
    },
    stepHeading: {
        fontWeight: 'bold',
    },
    stepDesc: {
        margin: 0,
        marginBottom: '1em',
    },
    inputGrp: {
        '& label': {
            marginBottom: 4,
            display: 'inline-block',
        }
    }
}));
