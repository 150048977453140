import {fromJS, Map} from 'immutable';
import {getWithToken} from '../../app/rest';
import {tokensGetAccessToken, tokensGetBearerToken} from './tokens';
import {check401} from '../../app/utils.js';

const initialState = new Map({
    categories: null,
    events: null,
    eventsCount: 0,
    types: null,
    page: null,
    count: 0,
});

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'LOG_CATEGORIES_FETCH_SUCCESS':
            return state.set('isFetching', false)
                .set('categories', fromJS(action.categories));

        case 'LOG_TYPES_FETCH_SUCCESS':
            return state.set('isFetching', false)
                .set('types', fromJS(action.categories));

        case 'LOG_PAGE_FETCH_SUCCESS':
            return state.set('isFetching', false)
                .set('page', fromJS(action.page))
                .set('count', action.count);

        case 'EVENTS_PAGE_FETCH_SUCCESS':
            return state.set('isFetching', false)
                .set('events', fromJS(action.page))
                .set('eventsCount', action.count);

        default:
            return state;
    }
};
export default reducer;

/**
 * Загружает страницу логов
 *
 * @param deviceId
 * @param userId
 * @param dateFrom в формате строки Y-m-d
 * @param dateTo в формате строки Y-m-d
 * @param typeId пока работать не будет потому что тип логов почему то не сохраняется
 * @param category передвать строку
 * @param limit размер страницы
 * @param offset оффест от начала
 */
export const fetchLogPage = (deviceId, limit, offset, category, search, typeId, userId, dateFrom, dateTo) => async (dispatch, getState) => {
    let endpoint = `/v2/api/logs?`;
    if (deviceId) {
        endpoint += `group_id=${deviceId}&`;
    }
    if (limit) {
        endpoint += `limit=${limit}&`;
    }
    if (offset) {
        endpoint += `offset=${offset}&`;
    }
    if (category) {
        endpoint += `category=${category}&`;
    }
    if (search) {
        endpoint += `search=${search}&`;
    }
    if (typeId) {
        endpoint += `log_type=${typeId}&`;
    }
    if (userId) {
        endpoint += `user_id=${userId}&`;
    }
    if (dateFrom) {
        endpoint += `date_from=${dateFrom}&`;
    }
    if (dateTo) {
        endpoint += `date_to=${dateTo}&`;
    }

    if (endpoint.endsWith('&')) {
        endpoint = endpoint.slice(0, -1);
    }

    const state = getState();
    const token = tokensGetBearerToken(state);

    dispatch({type: 'LOG_PAGE_FETCH_REQUEST'});
    try {
        const response = await getWithToken(endpoint, token, 'Bearer');

        const page = response.items;
        const count = response.total;

        dispatch({type: 'LOG_PAGE_FETCH_SUCCESS', page, count});
    } catch (err) {
        check401(err, dispatch);

        if (err.status === 404) {
            try {
                const errjson = await err.json();
                if (errjson.error === 'Logs not found') {
                    dispatch({type: 'LOG_PAGE_FETCH_SUCCESS', page: [], count: 0});
                }
            } catch (e) {
                return null;
            }
        }

        dispatch({type: 'LOG_PAGE_FETCH_ERROR'});
    }
}

export const getLogPage = (state) => state.getIn(['logs', 'page']);
export const getLogCount = (state) => state.getIn(['logs', 'count']);

/**
 * Загружает список категорий в логах
 */
export const fetchLogCategories = () => async (dispatch, getState) => {
    const endpoint = '/v2/api/logs/categories';
    const state = getState();
    const token = tokensGetBearerToken(state);

    dispatch({type: 'LOG_CATEGORIES_FETCH_REQUEST'});
    try {
        const categories = await getWithToken(endpoint, token, 'Bearer');

        dispatch({type: 'LOG_CATEGORIES_FETCH_SUCCESS', categories});
    } catch (err) {
        check401(err, dispatch)

        dispatch({type: 'LOG_CATEGORIES_FETCH_ERROR'});
    }
}

export const getLogCategories = (state) => state.getIn(['logs', 'categories']);

/**
 * TODO separate file
 */
export const fetchLogTypes = () => async (dispatch, getState) => {
    const endpoint = '/v2/api/events/types';
    const state = getState();
    const token = tokensGetBearerToken(state);

    dispatch({type: 'LOG_TYPES_FETCH_REQUEST'});
    try {
        const categories = await getWithToken(endpoint, token, 'Bearer');
        const types = categories || [];

        dispatch({type: 'LOG_TYPES_FETCH_SUCCESS', categories: types});
    } catch (err) {
        check401(err, dispatch)

        dispatch({type: 'LOG_TYPES_FETCH_ERROR'});
    }
}

export const getLogTypes = (state) => state.getIn(['logs', 'types']);



export const fetchEventsPage = (deviceId, limit, offset, category, search, typeId, userId, dateFrom, dateTo) => async (dispatch, getState) => {
    let endpoint = `/v2/api/events?`;
    if (deviceId) {
        endpoint += `group_id=${deviceId}&`;
    }
    if (limit) {
        endpoint += `limit=${limit}&`;
    }
    if (offset) {
        endpoint += `offset=${offset}&`;
    }
    if (search) {
        endpoint += `search=${search}&`;
    }
    if (typeId) {
        endpoint += `event_type=${typeId}&`;
    }
    if (userId) {
        endpoint += `user_id=${userId}&`;
    }
    if (dateFrom) {
        endpoint += `date_from=${dateFrom}&`;
    }
    if (dateTo) {
        endpoint += `date_to=${dateTo}&`;
    }

    if (endpoint.endsWith('&')) {
        endpoint = endpoint.slice(0, -1);
    }

    const state = getState();
    const token = tokensGetBearerToken(state);

    dispatch({type: 'LOG_EVENTS_FETCH_REQUEST'});
    try {
        const response = await getWithToken(endpoint, token, 'Bearer');

        const page = response.items;
        const count = response.total;

        dispatch({type: 'EVENTS_PAGE_FETCH_SUCCESS', page, count});
    } catch (err) {
        check401(err, dispatch)

        dispatch({type: 'LOG_EVENTS_FETCH_ERROR'});
    }
}

export const getEventsPage = (state) => state.getIn(['logs', 'events']);
export const getEventsCount = (state) => state.getIn(['logs', 'eventsCount']);
