import React, {useRef, useState} from 'react';
import ProfileIcon from './ProfileIcon.js';
import ProfileMenu from './ProfileMenu.js';
import {useSelector} from 'react-redux';
import {userInfo} from '../../redux/reducers/user.js';
import {t} from '../../i18n/utils.js';
import {makeStyles} from '@material-ui/core/styles';

/**
 * Выводит кнопку с иконкой пользователя и менюшкой по клику
 * @returns {JSX.Element}
 * @constructor
 */
function ProfileBtn() {
    const classes = useStyles();
    const [menuOpen, setMenuOpen] = useState(false);
    const ref = useRef(null);

    const user = useSelector(userInfo);
    const username = user?.get('full_name')?.trim() || user?.get('username') || t('mainMenu_profile');

    return (
        <>
            <button id="profile-btn" className={classes.profileBtn} ref={ref} onClick={() => {setMenuOpen(true)}}>
                <ProfileIcon username={username} />
            </button>

            <ProfileMenu open={menuOpen} anchorRef={ref} onClose={() => {setMenuOpen(false);}} />
        </>
    );
}
export default ProfileBtn;

const styles = (theme) => ({
    profileBtn: {
        background: 'none',
        border: 'none',
        cursor: 'pointer',
    },
});
const useStyles = makeStyles(styles);
