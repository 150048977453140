import React, {useContext} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {generatePath, useHistory, useLocation, useRouteMatch} from 'react-router-dom';
import {t} from '../../i18n/utils.js';
import {tokensGetAccessToken} from '../../redux/reducers/tokens.js';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import {ClickAwayListener} from '@material-ui/core';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import {makeStyles} from '@material-ui/core/styles';
import {adminLink} from '../../app/utils.js';
import {LocaleContext} from '../../i18n/LocaleContext.js';

function SettingsMenu(props) {
    const classes = useStyles();
    const { open, anchorRef, onClose } = props;

    const localeObj = useContext(LocaleContext);
    const locale = localeObj.code;
    const token = useSelector(tokensGetAccessToken);

    const dispatch = useDispatch();

    const loc = useLocation();
    const match = useRouteMatch();
    const history = useHistory();

    function changeLanguage(lang) {
        //console.log(match);

        const newPath = generatePath(match.path, {
            ...match.params,
            locale: lang,
        });

        const newLoc = {
            ...loc,
            pathname: newPath,
        }

        history.push(newLoc);
    }

    // TODO: load languages from config
    const menuItems = [
        {
            title: t('createLabel'),
            checked: props.showTime,
            href: adminLink(token, '/admin/labels/', locale),
        },
    ];

    return (
        <Popper open={open} anchorEl={anchorRef.current} transition disablePortal placement="bottom-end">
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin: placement === 'left bottom',
                    }}
                >
                    <Paper className={classes.paper}>
                        <ClickAwayListener onClickAway={onClose}>
                            <MenuList id="split-button-menu" className={classes.menuList}>
                                {menuItems.map((item, index) => (
                                    <a key={item.title} href={item.href}>
                                        <MenuItem
                                            className={classes.menuItem}
                                        >
                                            {item.title}
                                        </MenuItem>
                                    </a>
                                ))}
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    );
}

export default SettingsMenu;


const styles = (theme) => ({
    paper: {
        zIndex: 200,
        border: '1px solid ' + theme.colors.lightGrey.c68,
        boxShadow: theme.shadows[3],
        borderRadius: 4,
        padding: 6,
        minWidth: 230,
    },
    menuList: {
        padding: 0,
    },
    menuItem: {
        padding: 8,
    },
    checkbox: {
        padding: 0,
    }
});
const useStyles = makeStyles(styles);
