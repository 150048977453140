import React, {useEffect, useRef} from 'react';

function WebSocketWrap(props) {
    const ws = useRef(null);
    const reconnect = useRef(true);

    useEffect(() => {
        // connect
        connect();

        return () => {
            reconnect.current = false;

            if (ws.current?.close) {
                ws.current.close();
            }
        }
    }, []);


    function connect() {
        const host = window.location.hostname === 'localhost' ? 'smstage.npmgroup.ru' : window.location.host;
        ws.current = new WebSocket(`wss://${host}${props.url}`);

        console.log('CONNECT', ws);

        if (ws.current) {
            reconnect.current = true;
            ws.current.onclose = onClose;
            ws.current.onmessage = props.onMessage;
        }
    }

    function onClose() {
        if (reconnect.current) {
            setTimeout(() => {connect();}, 3000);
        }
    }

    return props.children;
}

export default WebSocketWrap;
