import React, {useContext, useEffect, useState} from 'react';
import {makeStyles, ThemeProvider} from '@material-ui/styles';
import {theme} from '../StoresListPage/theme.js';
import PageLayout from '../../componets/PageLayout/PageLayout.js';
import Topbar from './Topbar.js';
import {useDispatch, useSelector} from 'react-redux';
import {Button, LinearProgress, Paper, Typography} from '@material-ui/core';
import {t} from '../../i18n/utils.js';
import ProgressBar from './ProgressBar.js';
import {DeviceContext} from '../../componets/DeviceLoader.js';
import NotifDate from '../StorePage/components/NotifDate.js';
import classNames from 'classnames';
import {getWithToken} from '../../app/rest.js';
import {checkPermission, tokensGetBearerToken} from '../../redux/reducers/tokens.js';
import MobileTopbar from './MobileTopbar.js';
import {LocaleContext} from '../../i18n/LocaleContext.js';
import {useParams} from 'react-router-dom';
import {ReactComponent as KegIcon} from '../StorePage/svg/keg.svg';
import moment from 'moment';
import {setTitle} from '../StoresListPage/StoresPage.js';
import KegReplaceDialog from '../DevicePage/ModulePage/tap/KegReplaceDialog.js';
import KegDisableDialog from '../DevicePage/ModulePage/tap/KegDisableDialog.js';
import ConfirmDialog from '../DevicePage/ModulePage/tap/ConfirmDialog.js';
import WashDialog from '../DevicePage/ModulePage/tap/WashDialog.js';
import {fromJS} from 'immutable';
import {ExpandLess, ExpandMore} from '@material-ui/icons';
import {fetchstoreDetails, getstoreDetails} from '../../redux/reducers/storeDetails.js';
import {days, daysAgo} from '../../app/utils.js';
import {DetailLayout, Wrap} from '../DevicePage/ModulesPage.js';
import {ifnn} from '../Dashboard/DailyStats.js';

function Notification({noti}) {
    const classes = useStyles();

    //console.log(noti);

    const dt = moment(noti.date).startOf('day');
    const now = moment().startOf('day');
    const diff = now.diff(dt, 'day');
    const daysAgoStr = days(diff);
    const type = noti.type;

    return (
        <div className={classes.notif}>
            <Typography className={classNames(classes.notifText, {[type]: true})}>{noti.text}</Typography>
            <NotifDate notif={noti} />
        </div>
    );
}

function Keg({keg, notifs, deviceId, isWash, ...props}) {
    const classes = useStyles();

    const canDisable = useSelector(checkPermission('access_to_keg_disabling'));
    const canAssign = useSelector(checkPermission('brand_choosing'));


    const max = keg.is_empty ? null : keg.statistics?.keg_volume?.value;
    const cur = keg.is_empty ? null : keg.statistics?.beer_remained?.value;
    const percent = keg.is_empty ? 0 : Math.round(cur / max * 100);
    console.log(percent);

    const units = keg.statistics?.keg_volume?.unit;

    const dateSrc = keg.replacement_date;

    let diffStr = '-';
    if (dateSrc) {
        const date = moment(dateSrc).startOf('day');
        const now = moment().startOf('day');

        const diff = now.diff(date, 'day');
        diffStr = diff;
    }

    const isEmpty = keg?.is_empty;

    console.log('isempty', isEmpty);

    const notifications = notifs;

    const locale = useContext(LocaleContext);

    const ifnn = (obj) => {
        if (!obj) {
            if (obj === 0) {
                return Number(obj).toLocaleString(locale, {maximumFractionDigits: 2});
            } else {
                return '-'
            }
        } else {
            return Number(obj).toLocaleString(locale, {maximumFractionDigits: 2});
        }
    }

    return (
        <Paper className={classNames(classes.paper, classes.pGrid)}>
            <div className={classNames( classes.pRow,  classes.pRow1)}>
                <div className={classes.pNameCol}>
                    <div className={classes.pNameColRow1}>
                        <div className={classes.kegIcon}><KegIcon /></div>
                        <Typography variant="h4">{keg.title}</Typography>
                    </div>
                    <div className={classes.pNameColRow2}>
                        <Typography variant="subtitle1" className={classes.labelStat}>Установлен {daysAgo(diffStr)}</Typography>
                    </div>
                </div>
                <div className={classes.pProgressBarCol}>
                    <div className={classes.labelAndVol}>
                        <div>{keg.label || '-'}</div>
                        <div>
                            {cur === null || isNaN(cur) ? '-.--' : Number(cur).toLocaleString('ru', {maximumFractionDigits:2})}
                            &nbsp;/&nbsp;
                            {max === null || isNaN(max) ? '-.--' : Number(max).toLocaleString('ru', {maximumFractionDigits:2})}
                            &nbsp;{t(units)}
                        </div>
                    </div>
                    <div>
                        <ProgressBar value={percent} disabled={isEmpty} />
                    </div>
                </div>
                <div className={classes.pNotiCol}>
                    {notifications.map((n, i) => {
                        return (
                            <Notification noti={n} key={i} />
                        );
                    })}
                </div>
                <div className={classes.btnbni}>
                    {!isEmpty ?
                        <Button variant="outlined" className={classes.btn} onClick={() => {
                            props.setDisableDialog(keg);
                        }} disabled={isWash || !canDisable}>Отключить кег</Button>
                        : props.isHifa ?
                            <Button variant="outlined" className={classes.btn}
                                    onClick={() => {
                                        props.setReplaceDialog({keg: fromJS(keg), new: false});
                                    }}  disabled={!canAssign}
                            >Назначить бренд</Button>
                            :
                        <Button variant="outlined" className={classes.btn}
                                onClick={() => {
                                    props.setReplaceDialog({keg: fromJS(keg), new: false});
                                }}  disabled={isWash || !canAssign}
                        >Подключить кег</Button>
                    }
                </div>
            </div>
            {props.showStats &&
            <div className={classNames(classes.pRow, classes.pRow2)}>
                <div>
                    <Typography className={classes.labelStat}>Налито бутылок</Typography>
                    <Typography variant="h4" style={{display: 'flex', marginRight: 8}}>{ifnn(keg.statistics?.poured_success_count)} шт
                    </Typography>
                </div>
                <div>
                    <Typography className={classes.labelStat}>Налито</Typography>
                    <Typography
                        variant="h4">{ifnn(keg.statistics?.poured_success_volume?.value)} {t(units)}</Typography>
                </div>
                <div>
                    <Typography className={classes.labelStat}>Ошибочный налив</Typography>
                    <Typography variant="h4">{ifnn(keg.statistics?.poured_fail_volume?.value)} {t(units)}</Typography>
                </div>
                <div>
                    <Typography className={classes.labelStat}>
                        {!props.isHifa ? "Вытеснения" : "Тестовый налив"}
                    </Typography>
                    <Typography variant="h4">{ifnn(keg.statistics?.wash_success_volume?.value)} {t(units)}</Typography>
                </div>
                {!props.isHifa && (
                    <div>
                        <Typography className={classes.labelStat}>Калибровка</Typography>
                        <Typography variant="h4">{ifnn(keg.statistics?.calib_volume?.value)} {t(units)}</Typography>
                    </div>
                )}
            </div>
            }
            {/*
            <pre>
                {JSON.stringify(keg, null, 2)}
            </pre>
            */}
        </Paper>
    )
}


function Keg320({keg, notifs, deviceId, isWash, ...props}) {
    const classes = useStyles2();

    const canDisable = useSelector(checkPermission('access_to_keg_disabling'));
    const canAssign = useSelector(checkPermission('brand_choosing'));

    const max = keg.is_empty ? null : keg.statistics?.keg_volume?.value;
    const cur = keg.is_empty ? null : keg.statistics?.beer_remained?.value;
    const percent = keg.is_empty ? 0 : Math.round(cur / max * 100);

    const units = keg.statistics?.keg_volume?.unit;

    const dateSrc = keg.replacement_date;

    let diffStr = '-';
    if (dateSrc) {
        const date = moment(dateSrc).startOf('day');
        const now = moment().startOf('day');

        const diff = now.diff(date, 'day');
        diffStr = diff;
    }

    const isEmpty = keg?.is_empty;

    const notifications = notifs;

    const locale = useContext(LocaleContext);

    const ifnn = (obj) => {
        if (!obj) {
            if (obj === 0) {
                return Number(obj).toLocaleString(locale, {maximumFractionDigits: 2});
            } else {
                return '-'
            }
        } else {
            return Number(obj).toLocaleString(locale, {maximumFractionDigits: 2});
        }
    }

    return (
        <Paper className={classNames(classes.paper, classes.pGrid)}>
            <div className={classNames( classes.pRow,  classes.pRow1)}>
                <div className={classes.pNameCol}>
                    <div className={classes.pNameColRow1}>
                        <div className={classes.kegIcon}><KegIcon /></div>
                        <Typography variant="h4">{keg.title}</Typography>
                    </div>
                    <div className={classes.pNameColRow2}>
                        <Typography variant="subtitle1" className={classes.labelStat}>Установлен {daysAgo(diffStr)}</Typography>
                    </div>
                </div>
                <div className={classes.pProgressBarCol}>
                    <div className={classes.labelAndVol}>
                        <div>{keg.label || '-'}</div>
                        <div>
                            {cur === null || isNaN(cur) ? '-.--' : Number(cur).toLocaleString('ru', {maximumFractionDigits:2})}
                            &nbsp;/&nbsp;
                            {max === null || isNaN(max) ? '-.--' : Number(max).toLocaleString('ru', {maximumFractionDigits:2})}
                            &nbsp;{t(units)}
                        </div>
                    </div>
                    <div>
                        <ProgressBar value={percent} disabled={isEmpty} />
                    </div>
                </div>
                <div className={classes.pNotiCol}>
                    {notifications.map((n, i) => {
                        return (
                            <Notification noti={n} key={i} />
                        );
                    })}
                </div>
                <div className={classes.btns}>
                    {!isEmpty ?
                        <Button variant="outlined" className={classes.btn} onClick={() => {
                            props.setDisableDialog(keg);
                        }}  disabled={isWash || !canDisable}>Отключить кег</Button>
                        :
                        props.isHifa ?
                            <Button variant="outlined" className={classes.btn}
                                    onClick={() => {
                                        props.setReplaceDialog({keg: fromJS(keg), new: false});
                                    }}  disabled={!canAssign}
                            >Назначить бренд</Button>
                            :
                            <Button variant="outlined" className={classes.btn}
                                    onClick={() => {
                                        props.setReplaceDialog({keg: fromJS(keg), new: false});
                                    }}  disabled={isWash || !canAssign}
                            >Подключить кег</Button>
                    }

                    <Button variant="outlined" className={classes.btn2}
                            onClick={() => {
                                props.setShowStats(!props.showStats);
                            }}
                    >{props.showStats ? <ExpandLess /> : <ExpandMore />}</Button>
                </div>
            </div>
            { props.showStats &&
            <div className={classNames(classes.pRow, classes.pRow2)}>
                <div>
                    <Typography className={classes.labelStat}>Налито бутылок</Typography>
                    <Typography variant="h4" style={{display: 'flex', marginRight: 8}}>{ifnn(keg.statistics?.poured_success_count)} шт
                    </Typography>
                </div>
                <div>
                    <Typography className={classes.labelStat}>Налито</Typography>
                    <Typography
                        variant="h4">{ifnn(keg.statistics?.poured_success_volume?.value)} {t(units)}</Typography>
                </div>
                <div>
                    <Typography className={classes.labelStat}>Ошибочный налив</Typography>
                    <Typography
                        variant="h4">{ifnn(keg.statistics?.poured_fail_volume?.value)} {t(units)}</Typography>
                </div>
                <div>
                    <Typography className={classes.labelStat}>
                        {!props.isHifa ? "Вытеснения" : "Тестовый налив"}
                    </Typography>
                    <Typography variant="h4">{ifnn(keg.statistics?.wash_success_volume?.value)} {t(units)}</Typography>
                </div>
                {!props.isHifa && (
                    <div>
                        <Typography className={classes.labelStat}>Калибровка</Typography>
                        <Typography variant="h4">{ifnn(keg.statistics?.calib_volume?.value)} {t(units)}</Typography>
                    </div>
                )}
            </div>
            }
            {/*
            <pre>
                {JSON.stringify(keg, null, 2)}
            </pre>
            */}
        </Paper>
    )
}


function Keg640({keg, notifs, deviceId, isWash, ...props}) {
    const classes = useStyles640();

    const canDisable = useSelector(checkPermission('access_to_keg_disabling'));
    const canAssign = useSelector(checkPermission('brand_choosing'));

    const max = keg.is_empty ? null : keg.statistics?.keg_volume?.value;
    const cur = keg.is_empty ? null : keg.statistics?.beer_remained?.value;
    const percent = keg.is_empty ? 0 : Math.round(cur / max * 100);

    const units = keg.statistics?.keg_volume?.unit;

    const dateSrc = keg.replacement_date;

    let diffStr = '-';
    if (dateSrc) {
        const date = moment(dateSrc).startOf('day');
        const now = moment().startOf('day');

        const diff = now.diff(date, 'day');
        diffStr = diff;
    }

    const isEmpty = keg?.is_empty;

    const notifications = notifs;

    const locale = useContext(LocaleContext);

    const ifnn = (obj) => {
        if (!obj) {
            if (obj === 0) {
                return Number(obj).toLocaleString(locale, {maximumFractionDigits: 2});
            } else {
                return '-'
            }
        } else {
            return Number(obj).toLocaleString(locale, {maximumFractionDigits: 2});
        }
    }

    return (
        <Paper className={classNames(classes.paper, classes.pGrid)}>
            <div className={classNames( classes.pRow,  classes.pRow1)}>
                <div className={classes.rrr1}>
                    <div className={classes.pNameCol}>
                        <div className={classes.pNameColRow1}>
                            <div className={classes.kegIcon}><KegIcon /></div>
                            <Typography variant="h4">{keg.title}</Typography>
                        </div>
                        <div className={classes.pNameColRow2}>
                            <Typography variant="subtitle1" className={classes.labelStat}>Установлен {daysAgo(diffStr)}</Typography>
                        </div>
                    </div>
                    <div className={classes.pProgressBarCol}>
                        <div className={classes.labelAndVol}>
                            <div>{keg.label || '-'}</div>
                            <div>
                                {cur === null || isNaN(cur) ? '-.--' : Number(cur).toLocaleString('ru', {maximumFractionDigits:2})}
                                &nbsp;/&nbsp;
                                {max === null || isNaN(max) ? '-.--' : Number(max).toLocaleString('ru', {maximumFractionDigits:2})}
                                &nbsp;{t(units)}
                            </div>
                        </div>
                        <div>
                            <ProgressBar value={percent} disabled={isEmpty} />
                        </div>
                    </div>
                </div>
                <div className={classes.pNotiCol}>
                    {notifications.map((n, i) => {
                        return (
                            <Notification noti={n} key={i}/>
                        );
                    })}
                </div>
                <div className={classes.btns}>
                    {!isEmpty ?
                        <Button variant="outlined" className={classes.btn} onClick={() => {
                            props.setDisableDialog(keg);
                        }}  disabled={isWash || !canDisable}>Отключить кег</Button>
                        :
                        props.isHifa ?
                            <Button variant="outlined" className={classes.btn}
                                    onClick={() => {
                                        props.setReplaceDialog({keg: fromJS(keg), new: false});
                                    }}  disabled={!canAssign}
                            >Назначить бренд</Button>
                            :
                            <Button variant="outlined" className={classes.btn}
                                    onClick={() => {
                                        props.setReplaceDialog({keg: fromJS(keg), new: false});
                                    }}  disabled={isWash || !canAssign}
                            >Подключить кег</Button>
                    }

                    <Button variant="outlined" className={classes.btn2}
                            onClick={() => {
                                props.setShowStats(!props.showStats);
                            }}
                            disabled={isWash}>{props.showStats ? <ExpandLess /> : <ExpandMore />}</Button>
                </div>
            </div>
            { props.showStats &&
            <div className={classNames(classes.pRow, classes.pRow2)}>
                <div>
                    <Typography className={classes.labelStat}>Налито бутылок</Typography>
                    <Typography variant="h4" style={{display: 'flex', marginRight: 8}}>{ifnn(keg.statistics?.poured_success_count)} шт
                    </Typography>
                </div>
                <div>
                    <Typography className={classes.labelStat}>Налито</Typography>
                    <Typography
                        variant="h4">{ifnn(keg.statistics?.poured_success_volume?.value)} {t(units)}</Typography>
                </div>
                <div>
                    <Typography className={classes.labelStat}>Ошибочный налив</Typography>
                    <Typography
                        variant="h4">{ifnn(keg.statistics?.poured_fail_volume?.value)} {t(units)}</Typography>
                </div>
                <div>
                    <Typography className={classes.labelStat}>
                        {!props.isHifa ? "Вытеснения" : "Тестовый налив"}
                    </Typography>
                    <Typography variant="h4">{ifnn(keg.statistics?.wash_success_volume?.value)} {t(units)}</Typography>
                </div>
                {!props.isHifa && (
                    <div>
                        <Typography className={classes.labelStat}>Калибровка</Typography>
                        <Typography variant="h4">{ifnn(keg.statistics?.calib_volume?.value)} {t(units)}</Typography>
                    </div>
                )}
            </div>
            }
            {/*
            <pre>
                {JSON.stringify(keg, null, 2)}
            </pre>
            */}
        </Paper>
    )
}

export function useLocalStorage(key, defaultVal) {
    let savedVal = JSON.parse(localStorage.getItem(key));

    if (savedVal === undefined || savedVal === null) {
        savedVal = defaultVal;
    }

    const [val, setVal] = useState(savedVal);

    useEffect(()=> {
        localStorage.setItem(key, JSON.stringify(val));
    }, [val]);

    return [val, setVal];
}

function TapPage({tap, store}) {
    const classes = useStyles();

    const [washDialog, setWashDialog] = useState(false);
    const [disableDialog, setDisableDialog] = useState(null);
    const [replaceDialog, setReplaceDialog] = useState(null);
    const [assignDialog, setAssignDialog] = useState(null);
    const [confirmDialog, setConfirmDialog] = useState(false);

    const [showStats, setShowStats] = useLocalStorage('showStats', true);

    const token = useSelector(tokensGetBearerToken);


    const params = useParams();
    console.log(params);
    const storeId = params.storeId;
    const groupId = parseInt(params.deviceId, 10);
    const deviceId = parseInt(params.moduleId, 10);

    const locale = useContext(LocaleContext);
    const lang = locale.code;

    const [tapDetails, setTapDetails] = useState(null);

    useEffect(() => {
        fetchTapDetails(deviceId, lang, token).then(r => {
            console.log('DEVICE VIEW', r);
            setTapDetails(r);
        });
    }, []);

    async function fetchTapDetails(id, lang, token) {
        const resp = await getWithToken(`/v2/api/devices/view/${id}?lang=${lang}`, token, 'Bearer');

        if (resp.ok) {
            const json = await resp.json();

            return json;
        } else {
            return resp;
        }
    }

    const permissionWash = useSelector(checkPermission('access_washing'));

    const storeDetailsImm = useSelector(state => getstoreDetails(state, storeId));
    const storeDetails = storeDetailsImm?.toJS ? storeDetailsImm.toJS() : storeDetailsImm;

    const [error, setError] = useState(null);

    if (!Array.isArray(storeDetails?.groups) || !Array.isArray(tapDetails?.notifications.errors)) {
        return (
            <Wrap>
                <LinearProgress />
            </Wrap>
        );
    }

    console.log('STORE DETAILS', storeDetails);
    const grps = storeDetails?.groups;
    console.log('GROUPS', grps);
    const grp = grps.find(g => g.id === groupId);
    console.log(groupId, grps, grp);
    const device = grp?.devices.find(d => d.device_id === deviceId);
    console.log(deviceId, device);

    const isHifa = grp?.type?.includes('hifa');

    const notifs = [...device?.notifications.errors, ...device?.notifications.warnings];
    //let notifs = [];

    if (!isHifa)
    for (const d of grp?.devices) {
        for (const n of d?.notifications?.errors) {
            const bm = n.blocked_modules || [];

            //console.log('BM', bm);
            if (bm.find(m => m.id === deviceId)) {
                //console.log(n);
                notifs.push(n);
            }
        }

        for (const n of d?.notifications?.warnings) {
            const bm = n.blocked_modules || [];

            //console.log('BM', bm);
            if (bm.find(m => m.id === deviceId)) {
                //console.log(n);
                notifs.push(n);
            }
        }
    }

    if (isHifa) {
        /// merge gas errors
        const co2dev = grp.devices.find(d => d?.parent?.id === device.device_id);

        if (co2dev) {
            console.log(co2dev);
            notifs.push(...co2dev.notifications.errors);
            notifs.push(...co2dev.notifications.warnings);
        }
    }


    const backUrl = `/${locale.code}/stores/${storeId}/`;

    const hasErrors = notifs.find(n => n.type === 'error') || (isHifa && tap.is_blocked);
    console.log(tap);
    const hasWarns = notifs.find(n => n.type === 'warning');

    const storeName = device.name;

    setTitle(storeName);

    const kegIds = tap.kegs?.map(k => k.id);

    const notifications = notifs;

    const showWash = grp.type.includes('beerbox');


    let isWash = tapDetails?.info?.is_washing;
    if (device?.info?.is_washing !== undefined && device?.info?.is_washing !== null) {
        isWash = device?.info?.is_washing;
    }

    let nextWashDate;


    //const indicators = tapOld?.indicators;
    //console.log('DDDDDD', tapDetails);
    const tempInd = grp.devices.find(d => d.device_id === tapDetails?.info?.cooler_id)
    const co2Ind = grp.devices.find(d => d.device_id === tapDetails?.info?.co2_id)

    //console.log('DDDDDD', tempInd);

    let temp = tempInd;
    let co2 = co2Ind;

    if (storeDetails?.groups) {
        //console.log(devDetails);
        console.log('NEXT CLEANING DATE', device?.info?.next_cleaning_date);
        nextWashDate = device?.info?.next_cleaning_date;
    }

    let washdays = moment(nextWashDate).isValid() ? moment(nextWashDate).startOf('day').diff(moment().startOf('day'), 'day') : null;

    //console.log('TAP', tap);

    const address = storeDetails?.address;


    const isSmart = grp.type?.toLowerCase() === 'smart';


    console.log('TTTTTT', tapDetails);

    const tapOld = device;

    return (
        <ThemeProvider theme={theme}>
            <PageLayout noContainer={true} customMobileTopBar={<MobileTopbar backUrl={backUrl} temp={temp} co2={co2} showStats={showStats} setShowStats={setShowStats}  />}>
                <Topbar washDays={washdays} isWash={isWash} permissionWash={permissionWash}
                        temp={temp} co2={co2} showStats={showStats} setShowStats={setShowStats}
                        name={tap.title} hasErrors={hasErrors} hasWarns={hasWarns} backUrl={backUrl} showWash={showWash} setWashDialog={setWashDialog}
                        address={address} tap={tap} isHifa={isHifa}
                />
                <div className={classes.container}>
                    {notifications.length > 0 &&
                    <Paper className={classes.paper}>
                        <div>
                            <Typography variant="h4" style={{marginBottom: 12}}>Требует реакции</Typography>
                            {notifications.map((n, i) => {
                                return (
                                    <Notification key={i} noti={n}/>
                                );
                            })}
                        </div>
                    </Paper>
                    }

                    {tap.kegs?.map((keg, i) => {
                        const kegNotifs = notifs?.filter(n => n.module?.id === keg.id) || [];

                        //keg.statistics.poured_fail_volume.value = 0.3333;

                        return (
                            <React.Fragment key={i+'frag'}>
                                <Keg keg={keg}
                                     showStats={showStats} setShowStats={setShowStats} isWash={isWash} isHifa={isHifa}
                                     key={i+'keg'} notifs={kegNotifs} setDisableDialog={setDisableDialog} setReplaceDialog={setReplaceDialog} />
                                <Keg320
                                    showStats={showStats} setShowStats={setShowStats} isWash={isWash} isHifa={isHifa}
                                    keg={keg} key={i+'keg320'} notifs={kegNotifs} setDisableDialog={setDisableDialog} setReplaceDialog={setReplaceDialog} />

                                <Keg640
                                    showStats={showStats} setShowStats={setShowStats} isWash={isWash} isHifa={isHifa}
                                    keg={keg} key={i+'keg640'} notifs={kegNotifs} setDisableDialog={setDisableDialog} setReplaceDialog={setReplaceDialog} />
                            </React.Fragment>
                        );
                    })}

                    {/*<Paper className={classes.paper}>
                        <pre>
                            {JSON.stringify(tap, null, 2)}
                        </pre>
                    </Paper>*/}
                </div>

                {/* old dialogs */}
                {disableDialog !== null &&
                <KegDisableDialog device={fromJS(device)}  keg={fromJS(disableDialog)} open={disableDialog !== null}
                                  onClose={() => {
                                      setDisableDialog(null)
                                  }}/>
                }
                {replaceDialog !== null &&
                <KegReplaceDialog device={fromJS(device)}  keg={replaceDialog} open2={replaceDialog !== null}
                                  onClose={(succ = false) => {
                                      if (device.type === 'smart' && succ) {
                                          setConfirmDialog(true);
                                      }
                                      setReplaceDialog(null);
                                  }}
                                  only_change={isHifa} new={true} isSmart={isSmart}

                                  tapData={tapDetails}
                />
                }
                {assignDialog !== null &&
                <KegReplaceDialog device={fromJS(device)} keg={assignDialog} open2={assignDialog !== null}
                                  onClose={(succ = false) => {
                                      setAssignDialog(null);
                                  }}
                                  only_change={true}
                />
                }
                {confirmDialog &&
                <ConfirmDialog open={confirmDialog} onClose={() => {
                    setConfirmDialog(false)
                }}/>
                }
                {washDialog &&
                <WashDialog device={fromJS(device)} tap={fromJS(tap)} open={washDialog} onClose={() => {
                    setWashDialog(false)
                }}/>
                }

            </PageLayout>
        </ThemeProvider>
    );
}

export default TapPage;


const styles = () => ({
    pRow: {
        display: 'flex',
    },
    pRow1: {
        gap: 48,
    },
    pRow2: {
        display: 'flex',
        '& > *': {
            flex: 1,
        },

        borderTop: '1px solid #DFE3E9',
        paddingTop: 20,
        marginTop: 4,
    },
    pProgressBarCol: {
        width: 348,
    },
    pNameCol: {
        //width: 164,
    },
    pNameColRow1: {
        display: 'flex',
        gap: 12,
    },
    kegIcon: {
        color: "#666666",
    },
    pNameColRow2: {
        display: 'flex',
        marginTop: 8,
    },
    pNotiCol: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
    },
    btnbni: {
        alignItems: 'center',
        display: 'flex',
    },
    labelAndVol: {
        display: 'flex',
        justifyContent: 'space-between',

        marginBottom: 16,
        fontWeight: 400,
        fontSize: 15,
        lineHeight: '20px',
    },

    labelStat: {
        color: '#666666',
        fontSize: 13,
        lineHeight: '16px',
    },

    container: {
        padding: '0 24px 24px',

        [theme.breakpoints.down('sm')]: {
            padding: 0,
        }
    },
    paper: {
        boxShadow: '0px 3px 12px rgba(12, 37, 86, 0.06)',
        padding: '20px 24px',
        display: 'flex',
        gap: 16,
        maxWidth: 1246,
        marginBottom: 16,

        [theme.breakpoints.down('md')]: {
            gap: '26px 24px',
        },

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            borderRadius: 0,
        }
    },
    pGrid: {
        display: 'flex',
        flexDirection: 'column',

        [theme.breakpoints.down('md')]: {
            background: '#f00',
            display: 'none',
        },
    },
    btn: {
        border: '1px solid rgba(223, 227, 233, 1)',
        alignSelf: 'flex-start',
        borderRadius: 8,
        alignSelf: 'center',
    },
    btnsGrp: {
        display: 'flex',
        gap: '16px',

        [theme.breakpoints.down('sm')]: {
            justifyContent: 'flex-end',
            borderTop: '1px solid ' + theme.colors.lightGrey.c100,
            paddingTop: 16,
        }
    },
    mGrp: {
        flex: 1,
        display: 'flex',
        gap: '0px 42px',
        flexWrap: 'wrap',

        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },

        [theme.breakpoints.down('sm')]: {
            gap: '16px',
        }
    },
    ngrp:{
        fontSize: 13,
        lineHeight: '16px',
        fontWeight: 500,
    },
    pb: {
        width: 348,
        [theme.breakpoints.down('md')]: {
            width: '100%',
        }

    },
    row: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 14,
    },
    c17: {
        fontSize: 17
    },
    c15: {
        fontSize: 15,
    },
    notif: {
        fontSize: 13,
        fontWeight: 500,
        color: "#666666",
        display: "flex",
        gap: '0 8px',
        flexWrap: 'wrap',
        alignItems: 'baseline',
    },
    notifText: {
        fontSize: 13,
        fontWeight: 500,
        color: "#666666",

        '&.Warn': {
            color: theme.colors.colors.warning,
        },
        '&.Error': {
            color: theme.colors.colors.error,
        },
        '&.warning': {
            color: theme.colors.colors.warning,
        },
        '&.error': {
            color: theme.colors.colors.error,
        },
    },

    Warn: {
        color: theme.colors.colors.warning,
    },
    Error: {
        color: theme.colors.colors.error,
    },
    infoIcon: {
        alignSelf: 'center',
        color: '#868A8F',
        marginLeft: 8,
    },
});

const useStyles = makeStyles(styles);


const styles2 = () => ({
    pGrid: {
        display: 'flex',
        flexDirection: 'column',

        [theme.breakpoints.up('md')]: {
            display: 'none !important' ,
        },
    },
    pRow: {
        display: 'flex',
    },
    pRow1: {
        gap: 18,
        flexDirection: 'column',
    },
    pRow2: {
        display: 'flex',


        paddingTop: 20,
        marginTop: 4,
        flexWrap: 'wrap',

        "& > *": {
            flex: '1 1 50%',
            paddingBottom: 16,
        }
    },
    pProgressBarCol: {
        width: '100%',
    },
    pNameCol: {
        gap: 26,
        display: 'flex',
        alignItems: 'center',
    },
    pNameColRow1: {
        display: 'flex',
        gap: 12,
        alignItems: 'center',
    },
    kegIcon: {
        color: "#666666",
        lineHeight: 0,
    },
    pNameColRow2: {
        display: 'flex',
        alignItems: 'center',
    },
    pNotiCol: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
    },
    labelAndVol: {
        display: 'flex',
        justifyContent: 'space-between',

        marginBottom: 16,
        fontWeight: 400,
        fontSize: 15,
        lineHeight: '20px',
    },

    labelStat: {
        color: '#666666',
        fontSize: 13,
        lineHeight: '16px',
    },

    container: {
        padding: '0 24px 24px',

        [theme.breakpoints.down('sm')]: {
            padding: 0,
        }
    },
    paper: {
        boxShadow: '0px 3px 12px rgba(12, 37, 86, 0.06)',
        padding: '20px 24px',
        display: 'flex',
        gap: 16,
        maxWidth: 1246,
        marginBottom: 16,

        [theme.breakpoints.down('md')]: {
            gap: '0 24px',
        },

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            borderRadius: 0,
        }
    },
    btn: {
        border: '1px solid rgba(223, 227, 233, 1)',
        alignSelf: 'flex-start',
        borderRadius: 8,
    },
    btns: {
        justifyContent: 'space-between',
        display: 'flex',

        borderTop: '1px solid #DFE3E9',
        paddingTop: 16,
    },
    btn2: {
        border: '1px solid rgba(223, 227, 233, 1)',
        alignSelf: 'flex-start',
        borderRadius: 8,
        minWidth: 36,
        width: 36,
        height: 36,
    },
    btnsGrp: {
        display: 'flex',
        gap: '16px',

        [theme.breakpoints.down('sm')]: {
            justifyContent: 'flex-end',
            borderTop: '1px solid ' + theme.colors.lightGrey.c100,
            paddingTop: 16,
        }
    },
    mGrp: {
        flex: 1,
        display: 'flex',
        gap: '0px 42px',
        flexWrap: 'wrap',

        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },

        [theme.breakpoints.down('sm')]: {
            gap: '16px',
        }
    },
    ngrp:{
        fontSize: 13,
        lineHeight: '16px',
        fontWeight: 500,
    },
    pb: {
        width: 348,
        [theme.breakpoints.down('md')]: {
            width: '100%',
        }

    },
    row: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 14,
    },
    c17: {
        fontSize: 17
    },
    c15: {
        fontSize: 15,
    },
    notif: {
        fontSize: 13,
        fontWeight: 500,
        color: "#666666",
        display: "flex",
        gap: '0 8px',
        flexWrap: 'wrap',
        alignItems: 'baseline',
    },
    notifText: {
        fontSize: 13,
        fontWeight: 500,
        color: "#666666",

        '&.Warn': {
            color: theme.colors.colors.warning,
        },
        '&.Error': {
            color: theme.colors.colors.error,
        },
    },

    Warn: {
        color: theme.colors.colors.warning,
    },
    Error: {
        color: theme.colors.colors.error,
    },
    infoIcon: {
        alignSelf: 'center',
        color: '#868A8F',
        marginLeft: 8,
    },
});

const useStyles2 = makeStyles(styles2);

const styles640 = () => ({
    pGrid: {
        display: 'flex',
        flexDirection: 'column',
        //background: '#faa',

        [theme.breakpoints.down('sm')]: {
            display: 'none !important' ,
        },

        [theme.breakpoints.up('lg')]: {
            display: 'none !important' ,
        },
    },
    pRow: {
        display: 'flex',
    },
    pRow1: {
        gap: 18,
        flexDirection: 'column',
    },
    pRow2: {
        display: 'flex',
        '& > *': {
            flex: 1,
        },

        paddingTop: 20,
        marginTop: 4,
        flexWrap: 'wrap',

        "& > *": {
            flex: '1 1 50%',
            paddingBottom: 16,
        }
    },
    pProgressBarCol: {
        width: '348px',
    },
    pNameCol: {
        gap: 26,
        display: 'flex',
        alignItems: 'center',
    },
    pNameColRow1: {
        display: 'flex',
        gap: 12,
        alignItems: 'center',
    },
    kegIcon: {
        color: "#666666",
        lineHeight: 0,
    },
    pNameColRow2: {
        display: 'flex',
        alignItems: 'center',
    },
    pNotiCol: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
    },
    rrr1: {
        display: 'flex',
        gap: '0 48px',
    },
    labelAndVol: {
        display: 'flex',
        justifyContent: 'space-between',

        marginBottom: 16,
        fontWeight: 400,
        fontSize: 15,
        lineHeight: '20px',
    },

    labelStat: {
        color: '#666666',
        fontSize: 13,
        lineHeight: '16px',
    },

    container: {
        padding: '0 24px 24px',

        [theme.breakpoints.down('sm')]: {
            padding: 0,
        }
    },
    paper: {
        boxShadow: '0px 3px 12px rgba(12, 37, 86, 0.06)',
        padding: '20px 24px',
        display: 'flex',
        gap: 16,
        maxWidth: 1246,
        marginBottom: 16,

        [theme.breakpoints.down('md')]: {
            gap: '0 24px',
        },

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            borderRadius: 0,
        }
    },
    btn: {
        border: '1px solid rgba(223, 227, 233, 1)',
        alignSelf: 'flex-start',
        borderRadius: 8,
    },
    btns: {
        justifyContent: 'space-between',
        display: 'flex',

        borderTop: '1px solid #DFE3E9',
        paddingTop: 16,
    },
    btn2: {
        border: '1px solid rgba(223, 227, 233, 1)',
        alignSelf: 'flex-start',
        borderRadius: 8,
        minWidth: 36,
        width: 36,
        height: 36,
    },
    btnsGrp: {
        display: 'flex',
        gap: '16px',

        [theme.breakpoints.down('sm')]: {
            justifyContent: 'flex-end',
            borderTop: '1px solid ' + theme.colors.lightGrey.c100,
            paddingTop: 16,
        }
    },
    mGrp: {
        flex: 1,
        display: 'flex',
        gap: '0px 42px',
        flexWrap: 'wrap',

        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },

        [theme.breakpoints.down('sm')]: {
            gap: '16px',
        }
    },
    ngrp:{
        fontSize: 13,
        lineHeight: '16px',
        fontWeight: 500,
    },
    pb: {
        width: 348,
        [theme.breakpoints.down('md')]: {
            width: '100%',
        }

    },
    row: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 14,
    },
    c17: {
        fontSize: 17
    },
    c15: {
        fontSize: 15,
    },
    notif: {
        fontSize: 13,
        fontWeight: 500,
        color: "#666666",
        display: "flex",
        gap: '0 8px',
        flexWrap: 'wrap',
        alignItems: 'baseline',
    },
    notifText: {
        fontSize: 13,
        fontWeight: 500,
        color: "#666666",

        '&.Warn': {
            color: theme.colors.colors.warning,
        },
        '&.Error': {
            color: theme.colors.colors.error,
        },
    },

    Warn: {
        color: theme.colors.colors.warning,
    },
    Error: {
        color: theme.colors.colors.error,
    },
    infoIcon: {
        alignSelf: 'center',
        color: '#868A8F',
        marginLeft: 8,
    },
});

const useStyles640 = makeStyles(styles640);
