import React, {useEffect} from 'react';
import {LinearProgress, Paper} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {useHistory, useParams} from 'react-router-dom';
import PageLayout from '../../../componets/PageLayout/PageLayout';
import DetailHeader from '../header/DetailHeader';
import {t} from '../../../i18n/utils.js';
import MobileTopBar from '../../StorePage/MobileTopBar.js';
import PrinterPage from '../../PrinterPage/PrinterPage.js';
import TapPage from '../../TapPage/TapPage.js';
import TapScreen from '../../tap/TapScreen.js';
import {useDispatch, useSelector} from 'react-redux';
import {fetchstoreDetails, getstoreDetails} from '../../../redux/reducers/storeDetails.js';

function Wrap({children, storeId}) {
    return (
        <PageLayout url="stores" customMobileTopBar={<MobileTopBar
            setSearch={()=>{}} store={storeId}
        />}>
            <section>
                {children}
            </section>
        </PageLayout>
    );
}

function DetailLayout({children, device, storeId}) {
    const devId = device.get('id');

    //console.log(storeId);
    return (
        <Wrap storeId={storeId}>
            <DetailHeader device={device} linkBack={`/stores/${storeId}`} hideSec={true}/>

            {children}
        </Wrap>
    );
}

/**
 * Page displays module's details
 * @param device
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function ModuleView({device, ...props}) {
    const classes = useStyles();
    let history = useHistory();

    const params = useParams();
    //console.log(params);
    const storeId  = params.storeId;
    const groupId = params.groupId;
    const deviceId = params.deviceId;
    //console.log('DEVICE CHANGE', device.toJS());

    const storeDetailsImm = useSelector(state => getstoreDetails(state, storeId));
    const store = storeDetailsImm?.toJS ? storeDetailsImm.toJS() : storeDetailsImm;

    const dispatch = useDispatch();

    const location = window.location;
    useEffect(
        () => {
            const element = document.getElementById(location.hash.replace("#", ""));

            setTimeout(() => {
                window.scrollTo({
                    behavior: element ? "smooth" : "auto",
                    top: element ? element.offsetTop : 0
                });
            }, 100);
        }, [location]);

    useEffect(() => {
       dispatch(fetchstoreDetails(storeId));
    }, []);

    const moduleIdStr = props.match.params.moduleId;
    const moduleId = parseInt(moduleIdStr, 10);

    const modulesList = device.get('modules');
    const module = modulesList.find(m => m.get('id') === moduleId);

    if (!store?.groups) {
        return (
            <Wrap>
                <div className={classes.loadingWrap}>
                    <LinearProgress />
                </div>
            </Wrap>
        );
    }

    if (!module) {
        return (
            <DetailLayout device={device} storeId={storeId}>
                <Paper>
                    {t('moduleNotFound', {moduleId})}
                </Paper>
            </DetailLayout>
        );
    }

    const type = module.get('type');
    switch (type) {
        case 'tap': {
            return (
                    <TapPage tap={module?.toJS()} moduleId={deviceId} groupId={groupId} store={store}
                    />
                    /*<TapScreen />*/
                );
        }
        case 'printer': {
            return (
                <PrinterPage printer={module?.toJS()} moduleId={moduleId} store={store} />
            );
        }
        default: {
            return (
                <DetailLayout device={device} storeId={storeId}>
                <Paper>
                    {t('moduleCannotBeDisplayed', {moduleId})}
                </Paper>
                </DetailLayout>
            );
        }
    }
}


const useStyles = makeStyles(theme => ({
    keglist: {
        "& > *": {
            margin: 8,
        }  ,
    },
    loadingWrap: {
        marginTop: 28,
    },
    [theme.breakpoints.up('md')]: {
        /* desktop version */
        /*
        root: {
            padding: "28px 16px 34px",
            margin: 8,
            paddingLeft: 25,
        },*/
        washBtn: {
            width: 360,
        },
        moduleHeader: {
            display: "grid",
            gridTemplateColumns: "max-content max-content max-content max-content 1fr max-content",
            gridTemplateAreas:
                '"status icon name prevNext . washButton"' +
                '". washDate washDate washDate washDate washDate"' +
                '". notifications notifications notifications notifications notifications"',
            alignItems: "center",
        },
        status: {
            paddingLeft: 8,
            paddingRight: 4,
            gridArea: "status",
        },
        icon: {
            paddingLeft: 2,
            paddingRight: 8,
            gridArea: "icon",

            "& > svg": {
                fill: "#21293A",
                stroke: "#21293A",
            },
        },
        name: {
            gridArea: "name",
        },
        prevNext: {
            gridArea: "prevNext",
            marginLeft: 24,
            marginBottom: 8,
            marginTop: 8,
        },
        washDate: {
            gridArea: "washDate",
            fontSize: 13,
            fontWeight: 500,
            lineHeight: "16px",
            paddingBottom: 12,
        },
        washButton: {
            gridArea: "washButton"
        },
        notifications: {
            gridArea: "notifications",
            borderTop: "1px solid",
            borderColor: theme.palette.border.main,
            padding: 15,
        },
    },

    [theme.breakpoints.down('sm')]: {
        /* mobile version */
        moduleHeader: {
            display: "grid",
            gridTemplateColumns: "min-content min-content 1fr 1fr 1fr min-content",
            gridTemplateAreas:
                '"status icon name name name washDate"' +
                '". notifications notifications notifications notifications notifications"' +
                '"washButton washButton washButton washButton washButton washButton"',
            alignItems: "center",
            zIndex: 10,
            position: "relative",
            paddingLeft: "16px",
            paddingRight: "16px",
            marginLeft: 0,
            marginRight: 0,
            paddingBottom: 16,
        },
        status: {
            paddingLeft: 8,
            paddingRight: 4,
            gridArea: "status",
        },
        icon: {
            paddingLeft: 2,
            paddingRight: 8,
            gridArea: "icon",

            "& > svg": {
                fill: "#21293A",
                stroke: "#21293A",
            },
        },
        name: {
            gridArea: "name",
            margin: "24px 0",
            flexShrink: 0,
        },
        prevNext: {
            gridArea: "prevNext"
        },
        washDate: {
            gridArea: "washDate",
            fontSize: 13,
            fontWeight: 500,
            lineHeight: "16px",
            paddingBottom: 12,
        },
        washButton: {
            gridArea: "washButton",
            "& > $button": {
                width: "100%",
            },
        },
        notifications: {
            gridArea: "notifications",
            borderTop: "1px solid",
            borderColor: theme.palette.border,
            padding: 15,
        },
    }
}));


export default ModuleView;
