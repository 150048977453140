import React from 'react';
import classNames from 'classnames';
import {ReactComponent as MobileIcon} from './svg/mobile.svg';
import {ReactComponent as MobileOfflineIcon} from './svg/mobile-offline.svg';
import {ReactComponent as LanIcon} from './svg/lan.svg';
import {ReactComponent as LanOfflineIcon} from './svg/lan-offline.svg';
import {makeStyles} from '@material-ui/core/styles';

const ConnectionIcon = ({online, signalLevel}) => {
    const classes = useStyles();

    let connectionIcon;

    const connType = !signalLevel || parseInt(signalLevel, 10) === 0 ? 'lan' : 'gsm';

    //console.log(connType, signalLevel);

    if (connType === 'gsm') {
        if (online) {
            connectionIcon = <MobileIcon />;
        } else {
            connectionIcon = <MobileOfflineIcon />;
        }
    } else {
        if (online) {
            connectionIcon = <LanIcon />;
        } else {
            connectionIcon = <LanOfflineIcon />;
        }
    }

    let signalLevelColor = classes.green;

    let signalLevelType;

    const signalLevelValue = parseInt(signalLevel, 10);
    if (signalLevel?.match && signalLevel?.match(/\d+%?/)) {
        signalLevelType = 'percent';

        if (signalLevelValue < 66) {
            signalLevelColor = classes.yellow;
        }

        if (signalLevelValue < 33) {
            signalLevelColor = classes.red;
        }
    }
    if (signalLevel?.match && signalLevel?.match(/-\d+dBm/)) {
        signalLevelType = 'dBm';

        if (signalLevelValue < -79) {
            signalLevelColor = classes.yellow;
        }

        if (signalLevelValue < -99) {
            signalLevelColor = classes.red;
        }
    }

    return (
        <div className={classNames(classes.connectionIcon, signalLevelColor)} title={online ? signalLevel : 'Offline'}>
            {connectionIcon}
        </div>
    );
};


const styles = (theme) => ({
    connectionIcon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    green: {
        color: theme.colors.colors.success,
    },
    yellow: {
        color: theme.colors.colors.warning,
    },
    red: {
        color: theme.colors.colors.error,
    },
});

const useStyles = makeStyles(styles);

export default ConnectionIcon;
