import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

/**
 * Определяет поддерживается ли данный язык
 *
 * @param lang
 * @returns {boolean}
 */
import {locales} from './locales.js';

export function isLangSupported(lang) {
    const supportedLangs = locales.map(l => l.code);
    return supportedLangs.includes(lang);
}

/**
 * Возвращает наиболее предпочитаемый язык из поддерживаемых в браузере или undefined если таких нет
 *
 * @returns {string|undefined}
 */
export function getSupportedBrowserLang() {
    if (window.navigator) {
        for (let lang of window.navigator.languages) {
            const trimmed = lang.substr(0, 2);
            if (isLangSupported(trimmed)) {
                return trimmed;
            }
        }

        /* TODO
         * for IE check
         * window.navigator.systemLanguage
         * window.navigator.userLanguage
         */
    }

    return undefined;
}

/**
 * Изменяет аттрибут lang тега html
 *
 * @param locale
 */
export function switchHtmlLang(locale) {
    const html =  window.document.documentElement

    html.lang = locale
}

/**
 * Загружает файл с локалью и инициализирует переводчик
 * @param locale
 * @returns {Promise<unknown>}
 */
export function initI18next(locale) {
    return fetch(`/langs/${locale}.json`)
        .then(response => response.json())
        .then(loadedResources => (
            new Promise((resolve, reject) => {
                i18next
                    .use(initReactI18next)
                    .init({
                    lng: locale,
                    debug: true,
                    resources: { [locale]: loadedResources },
                    interpolation: {
                        format: function (value, format, locale) {
                            return value;
                        },
                        escapeValue: false,
                    }
                }, (err, t) => {
                    if (err) {
                        reject(err);
                        return;
                    }

                    resolve();
                });
            })
        ))
        .catch(err => Promise.reject(err));
}

/**
 * Функция для перевода
 * @param key
 * @param opt
 * @returns {string}
 */
export const t = (key, opt) => i18next.t(key, opt);

export function getLocaleByCode(code) {
    return locales.find(l => l.code === code);
}
