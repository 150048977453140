import React, {useContext} from 'react';
import classNames from 'classnames';
import {makeStyles} from '@material-ui/core/styles';
import Notification from './Notification';
import NotificationsSpoiler from './NotificationsSpoiler';
import {DeviceContext} from '../DeviceLoader';
import Immutable from 'immutable';
import {useSelector} from 'react-redux';
import {formatDate, formatDateUtc} from '../../app/utils';
import {t} from '../../i18n/utils.js';
import {LocaleContext} from '../../i18n/LocaleContext.js';
import moment from 'moment';

const styles = theme => ({
    root: {},
});
const useStyles = makeStyles(styles);

/**
 * Renders a given notification
 */
function NotificationsArea({className, list, device, superArea, ...props}) {
    const classes = useStyles();

    const errors = list.filter(n => n.get('type') === 'Error');
    const warns = list.filter(n => n.get('type') === 'Warn');

    const contextDevice = useContext(DeviceContext);
    const provDevice = contextDevice ? contextDevice : device;

    const localeObj = useContext(LocaleContext);

    const rebootNotif = Immutable.fromJS({
        id: 'REBOOT_NOTIFICATION',
        text: t('reloading'),
        type: 'Warn',
    });

    const deviceIdStr = provDevice?.get('id')?.toString();
    let isRebooting = useSelector(state => state.getIn(['deviceDetails', 'map', deviceIdStr, 'local', 'reboot'])) === true;

    const isServiceMode = provDevice?.getIn(['serviceMode','enabled']);
    const isShowOff = provDevice?.getIn(['serviceMode','showOff']);
    const dueDate = provDevice?.getIn(['serviceMode','enabledTo']);

    const dueDateStr = formatDateUtc(dueDate, localeObj.timeFormat);

    const serviceOnNotif = Immutable.fromJS({
        id: 'SERVICE_MODE_ON_NOTIFICATION',
        text: t('serviceMode_notifOn', {dueDateStr}),
        type: 'Info',
        date: '',
    });

    const serviceOffNotif = Immutable.fromJS({
        id: 'SERVICE_MODE_OFF_NOTIFICATION',
        text: t('serviceMode_notifOff'),
        type: 'Info',
    });

    return (
        <DeviceContext.Provider value={provDevice}>
            <div className={classNames(classes.root, className)}>
                { isShowOff && superArea &&
                <Notification key={serviceOffNotif.id} noti={serviceOffNotif}/>
                }
                { isServiceMode && superArea &&
                <Notification key={serviceOnNotif.id} noti={serviceOnNotif}/>
                }
                { isRebooting && superArea &&
                    <Notification key={rebootNotif.id} noti={rebootNotif}/>
                }
                {
                    errors?.size <= 2 ?
                        errors.map((n,idx) => <Notification key={idx} noti={n} /> ) :
                        <NotificationsSpoiler list={errors} />
                }
                {
                    warns?.size <= 2 ?
                        warns.map((n,idx) => <Notification key={idx} noti={n} /> ) :
                        <NotificationsSpoiler list={warns} />
                }
            </div>
        </DeviceContext.Provider>
    );
}

export default NotificationsArea;
