import React, {useState} from 'react';
import DevicePageWrap from '../ElementsPage/DevicePageWrap';
import {Paper, Typography} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

import './customOverrides.css';
import TapListWidget from './TapListWidget';
import TapCalibrationWidget from './CalibTap';
import ArrowNavigation from '../SettingsPage/ArrowNavigation';
import {t} from '../../../i18n/utils.js';

function CalibrationPage({device, pageList, currentPage, ...props}) {
    const classes = useStyles();

    const [selTapsIds, setSelTapsIds] = useState([]);

    let taps = device.get('modules').filter(m => m.get('type') === 'tap').toJS();

    const selTapsObjs = taps.filter(t => selTapsIds.includes(t.id));

    return (
        <DevicePageWrap device={device}>
            <Paper className={classes.root}>
                <div className={classes.header}>
                    <Typography variant={"h1"}>{t('calibHeading')}</Typography>

                    <ArrowNavigation pageList={pageList} currentPage={currentPage} />
                </div>

                <div className={classes.content}>
                    <TapListWidget sections={taps} activeSections={selTapsIds} setActiveSections={setSelTapsIds} />

                    <div className={classes.sectionsWidgets}>
                        {
                            selTapsObjs.map(tap => {
                                return <TapCalibrationWidget tap={tap} device={device} />
                            })
                        }
                    </div>
                </div>
            </Paper>
        </DevicePageWrap>
    );
}

export default CalibrationPage;

const useStyles = makeStyles(theme => ({
    root: {
        margin: 8,
        padding: 25,
    },
    content: {
        marginTop: '1em',
    },
    header: {
        display: "flex",
        alignItems: "center",
        gap: "16px",
    },
    sectionsWidgets: {
        display: 'flex',
        gap: '16px',
        //overflow: "auto",
        flexWrap: 'wrap',
    },
}));
