import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ProgressBar from '../../componets/ProgressBar';
import {Typography} from '@material-ui/core';
import KegHeader from '../../componets/KegHeader';
import Box from '@material-ui/core/Box';
import {emptyVol, formatVolume, getUnits, getVolume} from '../../app/utils';
import {t} from '../../i18n/utils.js';

function Keg({keg, index, tapIndex, device, className, ...props}) {
    const classes = useStyles();

    const notifs = device.get('notifications');

    const kegId = keg.get('id');
    const kegNotifs = notifs.filter(n => n.getIn(['module', 'id']) === kegId);

    const isEmpty = keg.get('is_empty');

    const beerRemained = keg.getIn(['statistics', 'beer_remained']);
    const kegVolume = keg.getIn(['statistics', 'keg_volume']);

    const kegVolumeJs = kegVolume?.toJS();
    const beerRemainedJs = beerRemained?.toJS();
    const units = getUnits(kegVolumeJs);

    const beerRemainedV = isEmpty ? emptyVol : getVolume(beerRemainedJs);
    const kegVolumeV = getVolume(kegVolumeJs);
    //console.log (beerRemainedV, kegVolumeV, units);

    let beerRemainedStr = t('AofB', {a: beerRemainedV, b: kegVolumeV});
    beerRemainedStr = formatVolume(beerRemainedStr, units);

    const percentRemained = !isEmpty ? beerRemainedV / kegVolumeV * 100 : 0

    return (
        <section className={classes.root} id={`keg${kegId}`}>
                <Box display={{ xs: "block", md: "none" }}  className={classes.fullwidth}>
                    <div className={classes.header}>
                        <Typography variant="h2" className={classes.h2}>{keg.get('title')}</Typography>
                    </div>
                    <ProgressBar value={percentRemained} disabled={!keg.get('is_active')} />
                </Box>
                <Box display={{ xs: "none", md: "block" }} className={classes.fullwidth}>
                    <KegHeader
                        title={keg.get('title')}
                        isActive={keg.get('is_active')}
                        notifications={kegNotifs}
                        isEmpty={isEmpty}
                        beerTitle={keg.get('label')}
                        beerRemained={keg.getIn(['statistics', 'beer_remained'])}
                        kegVolume={kegVolume}
                    />
                </Box>
        </section>
    );
}

const useStyles = makeStyles(theme => ({
    fullwidth: {
        width: "100%",
    },
    indicator: {
        ".empty &": {
            fill: theme.palette.disabled.main,
        },
    },
    icon: {
        ".empty &": {
            fill: theme.palette.disabled.main,
            stroke: theme.palette.disabled.main,
            color: theme.palette.disabled.main,
        },
    },
    h2: {
        ".empty &": {
            color: theme.palette.disabled.main,
        },
    },
    beerLabel: {
        ".empty &": {
            color: theme.palette.disabled.main,
        },
    },
    labels: {
        ".empty &": {
            color: theme.palette.disabled.main,
        },
    },
    pbar: {
        ".empty &": {
            color: theme.palette.disabled.main,
        },
    },

    [theme.breakpoints.up('md')]: {
        root: {
            display: "flex",
            marginRight: theme.spacing(2),
            flex: 1,
        },
        header: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
        },
        left: {
            position: "relative",
            top: -6,
            maxWidth: 280,
        },
        beerLabel: {
            fontWeight: "bold",
            lineHeight: "16px",
            marginTop: 16,
        },
        indicator: {
            position: "relative",
            top: 13,
            marginRight: 10,
        },
        icon: {
            flexShrink: 0,
            marginRight: 21,
        },
    },

    [theme.breakpoints.down('sm')]: {
        indicator: {
            display: "none",
        },
        icon: {
            display: "none",
        },
        labels: {
            display: "none",
        },
        beerLabel: {
            display: "none",
        },
        h2: {
            fontWeight: 300,
            fontSize: 12,
            lineHeight: "16px",
            marginTop: 16,
        },
        root: {
            paddingRight: 24,
        }
    }
}));

export default Keg;
