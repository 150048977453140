import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import classNames from 'classnames';

const Switch = ({value, setValue, options, className, ...props}) => {
    const classes = useStyles();

    return (
        <div className={classNames([classes.switchBase, className])}>
            {options.map((opt, index) => {
                const isActive = opt.id === value;
                const cl = isActive ? classes.activeBtn : classes.btn;

                return (
                    <div key={index} className={classNames([cl, {[classes.disabled]: opt.disabled}])}
                            onClick={(event) => {setValue(opt.id)}}>
                        {opt.title}
                    </div>
                );
            })}
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    switchBase: {
        background: "rgba(0, 0, 0, 0.09)",
        borderRadius: "8px",
        width: "100%",
        minHeight: 32,
        display: "flex",
        padding: 2,
        overflow: "hidden",
    },
    activeBtn: {
        background: "#FFFFFF",
        border: "0.5px solid rgba(0, 0, 0, 0.04)",
        boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04)",
        borderRadius: "6.93px",
        flex: 1,
        justifyContent: "center",
        fontWeight: 500,
        fontSize: "13px",
        lineHeight: "16px",
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        cursor: "default",
    },
    btn: {
        flex: 1,
        display: "flex",
        border: "0.5px solid transparent",
        justifyContent: "center",
        fontWeight: 500,
        fontSize: "13px",
        lineHeight: "16px",
        alignItems: "center",
        textAlign: "center",

        color: "#77838F",
        cursor: "pointer",
        overflow: "hidden",
    },
    disabled: {
        color: theme.palette.disabled.main,
        pointerEvents: "none",
    }
}));

export default Switch;
