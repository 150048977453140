import Immutable, {fromJS} from 'immutable';
import {tokensGetBearerToken} from './tokens';
import {apistoreDetails} from '../../app/api';
import {check401} from '../../app/utils';
import {getLoadedLanguage as getSavedLocale} from './locale';

export const STORE_DETAILS_FETCH_REQUEST = "STORE_DETAILS_FETCH_REQUEST";
export const STORE_DETAILS_FETCH_SUCCESS = "STORE_DETAILS_FETCH_SUCCESS";
export const STORE_DETAILS_FETCH_FAILURE = "STORE_DETAILS_FETCH_FAILURE";
export const DEVICE_DETAILS_WS_CHANGE = "DEVICE_DETAILS_WS_CHANGE";
export const STORE_DETAILS_WS_STATUS_CHANGE = "STORE_DETAILS_WS_STATUS_CHANGE";

export const storeDetailInitialState = new Immutable.Map({
    map: {},
    isFetching: false,
    isError: null,
});

function reducer(state = storeDetailInitialState, action) {
    switch (action.type) {
        case STORE_DETAILS_FETCH_REQUEST:
            return state
                .setIn(['map', action.id], Immutable.fromJS({isLoading: true}));
        case STORE_DETAILS_FETCH_SUCCESS: {
            const local = state.getIn(['map', action.id.toString(), 'local']);

            return state
                .setIn(['map', action.id], Immutable.fromJS(action.details))
                .setIn(['map', action.id, 'isLoaded'], true)
                .setIn(['map', action.id, 'local'], local);
        }
        case 'STORE_DETAILS_REBOOT': {
            return state
                .setIn(['map', action.id.toString(), 'local'], fromJS({reboot: action.reboot}));
        }
        case STORE_DETAILS_FETCH_FAILURE:
            return state
                .setIn(['map', action.id], Immutable.fromJS({isError: true}));

        case 'STORE_DETAILS_CONNECTION_CHANGE': {

            return state;
        }

        case 'NOTIFICATION_ADD': {
            const data = action.data;
            const module = action.data.payload.module;

            const storeId = data.store_id.toString();
            const groupId = Number(data.group_id);
            const deviceId = Number(module.id);
            const statusUUID = data.payload.status_uuid;



            //console.log('DEVTYPE', devType);


            //console.log(state);
            const store = state.getIn(['map', storeId]);
            //console.log(store);
            const groups = store?.getIn(['groups']);
            //console.log(groups);
            const grpIndex = groups?.findIndex(g => g.get('id') === groupId);
            //console.log(grpIndex);

            const grp = groups?.getIn([grpIndex]);
            const devices = grp?.getIn(['devices']);

            const devType = module.type;
            const needle = devType !== 'keg' ? module.id : module.parent;

            const devIdx = devices?.findIndex(d => d.get('device_id') === needle);
            const dev = devices?.getIn([devIdx]);

            if (!dev || devIdx === -1) {
                console.log(action.data, store, groups);
                return state;
            }

            const oldNotifications = state
                .getIn(['map', storeId, 'groups', grpIndex, 'devices',
                    devIdx, 'notifications'])?.toJS();
            console.log('OLD NOTIFS', oldNotifications);

            if (data.payload.type === 'error') {
                oldNotifications.errors.push(data.payload);
            } else {
                oldNotifications.warnings.push(data.payload);
            }

            return state.setIn(['map', storeId, 'groups', grpIndex, 'devices',
                devIdx, 'notifications'], fromJS(oldNotifications));
        }

        case 'NOTIFICATION_REMOVE': {
            const data = action.data;
            const module = action.data.payload.module;

            const storeId = data.store_id.toString();
            const groupId = Number(data.group_id);
            const deviceId = Number(module.id);
            const statusUUID = data.payload.status_uuid;

            //console.log(state);
            const store = state.getIn(['map', storeId]);
            //console.log(store);
            const groups = store?.getIn(['groups']);
            //console.log(groups);
            const grpIndex = groups?.findIndex(g => g.get('id') === groupId);
            //console.log(grpIndex);

            const grp = groups?.getIn([grpIndex]);
            const devices = grp?.getIn(['devices']);
            const devType = module.type;
            const needle = devType !== 'keg' ? module.id : module.parent;

            const devIdx = devices?.findIndex(d => d.get('device_id') === needle);
            const dev = devices?.getIn([devIdx]);

            if (!dev || devIdx === -1) {
                //console.log(action.data, store, groups);
                return state;
            }

            const oldNotifications = state
                .getIn(['map', storeId, 'groups', grpIndex, 'devices',
                    devIdx, 'notifications'])?.toJS();
            //console.log('OLD NOTIFS', oldNotifications);

            const newNotifications = {
                errors: oldNotifications.errors.filter(n => n.status_uuid !== statusUUID),
                warnings: oldNotifications.warnings.filter(n => n.status_uuid !== statusUUID),
            }
            return state.setIn(['map', storeId, 'groups', grpIndex, 'devices',
                devIdx, 'notifications'], fromJS(newNotifications));
        }

        case DEVICE_DETAILS_WS_CHANGE: {
            const data = action.data;
            //console.log(data);


            if (!data.store_id) return state; //dirty hack for elements

            const storeId = data.store_id.toString();
            const groupId = Number(data.group_id);

            if (data.is_connected !== undefined) {
                try {
                    console.log('CHANGE OFFLINE', data.is_connected);

                    //console.log(state);
                    const store = state.getIn(['map', storeId]);
                    //console.log(store);
                    const groups = store.getIn(['groups']);
                    //console.log(groups);
                    const grpIndex = groups.findIndex(g => g.get('id') === groupId);
                    //console.log(grpIndex);

                    const newstate = state.setIn(['map', storeId, 'groups', grpIndex, 'is_connected'], data.is_connected);
                    //console.log(newstate);

                    return newstate;
                } catch (e) {
                    //...
                }
            }


            if (data.device_type === 'manager' || data.device_type === 'printer' ||
                data.device_type === 'co2' || data.device_type === 'temperature' ||
                data.device_type === 'tap') {

                if (data.payload_type === 'info') {
                    //console.log(state);
                    const store = state.getIn(['map', storeId]);
                    //console.log(store);
                    const groups = store?.getIn(['groups']);
                    //console.log(groups);
                    const grpIndex = groups?.findIndex(g => g.get('id') === groupId);
                    //console.log(grpIndex);

                    const grp = groups?.getIn([grpIndex]);
                    const devices = grp?.getIn(['devices']);
                    const devIdx = devices?.findIndex(d => d.get('device_id') === data.device_id);
                    const dev = devices?.getIn([devIdx]);

                    if (!dev) {
                        console.log(data, store, groups);
                        return state;
                    }

                    let propName = data.payload.name;
                    const newVal = data.payload.value;

                    /*
                    if (propName === 'cleaning_date') {
                        propName = 'next_cleaning_date';
                    }
                     */

                    //console.log(dev.toJS(), data);

                    const newstate = state
                        .setIn(['map', storeId, 'groups', grpIndex, 'devices',
                            devIdx, 'info', propName], newVal);
                    //console.log(newstate);

                    return newstate;
                }
            } else if (data.device_type === 'keg') {

                //console.log('KEG STATISTIC CHANGE');

                const store = state.getIn(['map', storeId]);
                //console.log(store);
                const groups = store?.getIn(['groups']);
                //console.log(groups);
                const grpIndex = groups?.findIndex(g => g.get('id') === groupId);
                //console.log(grpIndex);

                const grp = groups?.getIn([grpIndex]);
                const devices = grp?.getIn(['devices']).toJS();

                if (!devices) {
                    return state;
                }

                //console.log('KEG STATISTIC CHANGE 2');

                const uuid = data.device_uuid;
                let devIdx = -1;
                let kegIdx = -1;

                for (let di = 0; di < devices.length; di++) {
                    const d = devices[di];

                    if (d?.type !== 'tap') continue;

                    for (let ki = 0; ki < d.info?.kegs?.length; ki++) {
                        const k = d.info?.kegs[ki];

                        //console.log(k, uuid);

                        if (k?.uuid !== uuid) continue;

                        devIdx = di;
                        kegIdx = ki;
                        break;
                    }

                    if (kegIdx !== -1) break;
                }

                if (kegIdx === -1) return state;


                if (data.payload_type === 'statistic') {
                    //console.log(devIdx, kegIdx, data);
                    const klv = data.payload?.beer_remained?.value;
                    const kv = data.payload?.keg_volume?.value;

                    const newstate = state
                        .setIn(['map', storeId, 'groups', grpIndex, 'devices',
                            devIdx, 'info', 'kegs', kegIdx, 'keg_left_volume'], klv)
                        .setIn(['map', storeId, 'groups', grpIndex, 'devices',
                            devIdx, 'info', 'kegs', kegIdx, 'keg_volume'], kv);

                    return newstate;
                } else  if (data.payload_type === 'info') {
                    const propName = data.payload.name;
                    const newVal = data.payload.value;

                    //console.log(dev.toJS(), data);

                    const newstate = state
                        .setIn(['map', storeId, 'groups', grpIndex, 'devices',
                            devIdx, 'info', 'kegs', kegIdx, propName], newVal);
                    //console.log(newstate);

                    return newstate;
                }
            }

            return state;
        }

        case STORE_DETAILS_WS_STATUS_CHANGE: {
            return state;
        }

        default:
            return state;
    }
}
export default reducer;

export const fetchstoreDetails = (id, sort = 'name1', withoutService) => async (dispatch, getState) => {
    //console.log('innerLoad');

        dispatch({type: STORE_DETAILS_FETCH_REQUEST, id});

    const state = getState();
    const token = tokensGetBearerToken(state);
    const locale = getSavedLocale(state);

    try {
        const resp = await apistoreDetails(token, id, sort, locale);

        const details = resp;
        dispatch({type: STORE_DETAILS_FETCH_SUCCESS, id, details, locale});
    } catch (err) {
        check401(err, dispatch);

        dispatch({type: STORE_DETAILS_FETCH_FAILURE, id});
    }
}

export const getstoreDetails = (state, id) => {
    const dev = state.getIn(['storeDetails', 'map', id]);
    return dev || null;
};
