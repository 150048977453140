import React, {useContext, useEffect, useState} from 'react';
import {MenuItem, Paper, Select, Typography, useTheme} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {formatDate} from '../../app/utils.js';
import {getWithToken} from '../../app/rest.js';
import {APIPREFIX} from './Metrics.js';
import {useSelector} from 'react-redux';
import {tokensGetBearerToken} from '../../redux/reducers/tokens.js';
import {
    Bar,
    CartesianGrid,
    ComposedChart,
    Label,
    Legend,
    Line, Pie, PieChart, Cell,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import {LocaleContext} from '../../i18n/LocaleContext.js';
import moment from 'moment';
import {ifnn} from '../Dashboard/DailyStats.js';
import {ReactComponent as DownloadIcon} from './DownloadIcon.svg';
import Badge from '../Dashboard/Badge.js';
import {t} from '../../i18n/utils.js';
import classNames from 'classnames';


const CustomTooltip = ({ active, payload, label }) => {
    const classes = useStyles();

    const locale = useContext(LocaleContext);

    //console.log(payload);

    let pour = null;
    let idle = null;
    let idleRaw = null;

    function formatHours(time) {
        if (!time || !time.split) {
            return '-';
        }

        const split = time.split(':');

        return parseInt(split[0], 10)?.toLocaleString(locale.code, {maximumFractionDigits:2}) + ' ч ' + split[1] + ' м';
    }

    if (payload) {
        payload.forEach(p => {
            if (p.dataKey === 'value') {
                pour = p.payload.value_raw;
            }
        });
    }

    //console.log(payload);
    const timeMoment = moment(label);
    const timeStr = timeMoment?.isValid() ? timeMoment.format(locale.dateFormat) : label;

    if (active && payload && payload.length) {
        return (
            <div className={classes.tooltip}>
                <Typography variant="subtitle1"><strong>{timeStr}</strong></Typography>
                <Typography variant="subtitle1">Простой: {formatHours(pour)}</Typography>
            </div>
        );
    }

    return null;
};

function CustomResponsiveContainer(props) {
    return (
        <div style={{ width: '100%', height: '100%', position: 'relative' }}>
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    top: 0,
                    left: 0
                }}
            >
                <ResponsiveContainer {...props} />
            </div>
        </div>
    );
}


const Reasons = ({
                      dateFrom, dateTo, selCities, selChains, selStores, dynamics,
    ...props
}) => {
    const classes = useStyles();

    const [data, setData] = useState(null);

    const token = useSelector(tokensGetBearerToken);

    const theme = useTheme();

    const locale = useContext(LocaleContext);
    const lang = locale.code;

    const customTick = {
        fontSize: '13',
        fontFamily: 'Rubik',
        fill: theme.colors.grey.c100,
    };

    const df = formatDate(dateFrom, 'YYYY-MM-DD');
    const dt = formatDate(dateTo, 'YYYY-MM-DD');

    async function fetchData() {
        let query = `?date_from=${df}&date_to=${dt}`;
        const cityIds = selCities.map(c => c.id);
        let t;
        t = cityIds?.map(c => {
            query += `&cities_ids=${c}`;
        });
        const chainIds = selChains.map(c => c.id);
        t = chainIds?.map(c => {
            query += `&chains_ids=${c}`;
        });
        const storeIds = selStores.map(c => c.id);
        t = storeIds?.map(c => {
            query += `&stores_ids=${c}`;
        });

        const resp = await getWithToken(APIPREFIX + '/reports/idles/reasons' + query, token, 'Bearer');
        console.log(resp);

        return resp;
    }

    const [hover, setHover] = useState(null);

    useEffect(() => {
        fetchData().then(r => {
            const parsed = r?.map(a => {
                let hours = parseInt(a.total_idle, 10);

                //hours = hours / 60 / 60;

                //hours = Math.round((hours + Number.EPSILON) * 100) / 100

                return (
                    {...a,
                        value: hours,
                    }
                )
            }) || [];

            setData(parsed);
            //setNumber(r.length);
        });
    }, [df, dt, selChains, selCities, selStores]);

    const loading = data === null;

    const [number, setNumber]  = useState(5);

    function saveChart() {
        //get svg element.
        var list = document.getElementsByClassName('recharts-surface');
        var svg = list?.length > 0 ? list[2] : null; // TODO

        if (!svg) return;

        //get svg source.
        var serializer = new XMLSerializer();
        var source = serializer.serializeToString(svg);

        //add name spaces.
        if(!source.match(/^<svg[^>]+xmlns="http\:\/\/www\.w3\.org\/2000\/svg"/)){
            source = source.replace(/^<svg/, '<svg xmlns="http://www.w3.org/2000/svg"');
        }
        if(!source.match(/^<svg[^>]+"http\:\/\/www\.w3\.org\/1999\/xlink"/)){
            source = source.replace(/^<svg/, '<svg xmlns:xlink="http://www.w3.org/1999/xlink"');
        }

        //add xml declaration
        source = '<?xml version="1.0" standalone="no"?>\r\n' + source;

        //convert svg source to URI data scheme.
        var url = "data:image/svg+xml;charset=utf-8,"+encodeURIComponent(source);

        //set url value to a element's href attribute.
        var link = document.getElementById("linkReasons");
        link.href = url;
        link.click();
        //you can download svg file by right click menu.
    }

    const firstStore = selStores?.length === 1 ? selStores[0] : null;

    const chartXLabel = firstStore ? firstStore.address : 'Магазины';

    let first_d = data ? data[0] : null;

    const days = [first_d];

    for (let i = 1; i < data?.length; i++) {
        const d = data[i];

        const formated1 = formatDate(first_d.date,  'DD MMMM YYYY', lang);
        const formated2 = formatDate(d.date, 'DD MMMM YYYY', lang);

        //console.log(formated1, formated2);

        if (formated1 !== formated2) {
            days.push(d);
            first_d = d;

            //console.log(days);
        }
    }



    const COLORS = [
        '#FF6D01',
        '#4285F4',
        '#46BDC6',
        '#34A853',
        '#FBBC04',
        '#7BAAF7',
        '#EA4335',
        '#EA4335',
    ];

    const DARK_COLORS = [
        '#803500',
        '#224680',
        '#2d7a80',
        '#28803f',
        '#806003',
        '#405880',
        '#80261d',
        '#80261d',
    ];

    console.log('DATA', data);

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, ...props }) => {
        console.log('PROPS', props);
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    const filteredData = data?.filter(r => {
        return !['work_time_is_out', 'no_data'].includes(r.name)
    }).slice(0, number);

    const warnData = data?.filter(r => {
        return r.name === 'no_data'
    });

    function formatValue(value) {
        const ss = value;
        const mm = Math.floor(ss / 60 % 60);
        const hh = Math.floor(ss / 60 / 60);
        return `${hh.toLocaleString(lang)} ч ${mm} м`;
    }

    const chartSize = 360;

    return (
        <Paper>
            <a href="#" download={'chart.svg'} id="linkReasons" className={classes.link} />
            <div className={classes.header}>
                <div className={classes.headerTitle}>Причины простоя

                    <Select value={number} onChange={(e) => {
                        const val = e.target.value;
                        setNumber(val);
                    }} variant={'outlined'} className={classes.select}>
                        <MenuItem value={5}>Топ 5</MenuItem>
                        <MenuItem value={10}>Топ 10</MenuItem>
                        <MenuItem value={15}>Топ 15</MenuItem>
                    </Select>
                </div>

                <a className={classes.downloadBtn} onClick={(e) => {
                    e.preventDefault();
                    saveChart();
                }}>
                    <div className={classes.downloadBtnIcon}>
                        <DownloadIcon width={24} height={24} />
                    </div>
                    <div className={classes.downloadBtnLabel}>Скачать</div>
                </a>
            </div>

            <div className={classes.layout}>

                    {!filteredData?.map ?
                        <div>Загрузка...</div>
                        :
                        filteredData?.length > 0 &&
                        <div className={classes.chartWrap}>
                        <ResponsiveContainer width={chartSize} height={chartSize}>
                            <PieChart height={chartSize} styles={{margin: 0, padding: 0}}>
                                <Pie
                                    isAnimationActive={false}
                                    data={filteredData}
                                    cx="50%"
                                    cy="50%"
                                    outerRadius={chartSize / 2}
                                    innerRadius={chartSize / 4}
                                    fill="#8884d8"
                                    dataKey="total_idle_percentage"
                                    labelLine={false}
                                    label={({
                                                cx,
                                                cy,
                                                midAngle,
                                                innerRadius,
                                                outerRadius,
                                                value,
                                                percent,
                                                index
                                            }) => {
                                        //console.log("handling label?");
                                        const RADIAN = Math.PI / 180;
                                        // eslint-disable-next-line
                                        const radius = innerRadius + (outerRadius - innerRadius) * 0.4;
                                        const x = cx + radius * Math.cos(-midAngle * RADIAN);
                                        const y = cy + radius * Math.sin(-midAngle * RADIAN);


                                        if (percent < 0.01) {
                                            return null;
                                        }

                                        return (
                                            <text x={x} y={y} fill="white" textAnchor={'middle'} dominantBaseline="central">
                                                {Number(value).toLocaleString('ru', {maximumFractionDigits: 2})}%
                                            </text>
                                        );
                                    }}
                                >
                                    {filteredData.map((entry, index) => {
                                        let fcolor = COLORS[index % COLORS.length];

                                        if (index === hover) {
                                            fcolor = DARK_COLORS[index % COLORS.length];
                                        }

                                        return (
                                        <Cell key={`cell-${index}`} fill={fcolor}
                                                onMouseEnter={() => {
                                                    //console.log(index);
                                                    setHover(index);
                                                }}
                                                onMouseLeave={() => {
                                                    setHover(null);
                                                }}
                                        />
                                    )})}
                                </Pie>
                            </PieChart>
                        </ResponsiveContainer>
                        </div>
                    }

            <div className={classes.tableWrap}>
                <table>
                    <tr>
                        <th className={classes.noBorder}>Причина простоя</th>
                        <th className={classes.noBorder}>Процент</th>
                        <th className={classes.noBorder}>Простой</th>
                        <th className={classes.noBorder}>Динамика</th>
                    </tr>
                    {filteredData?.map((d, i) => {
                        const diff = d.diff_idle;

                        return (
                        <tr key={i} className={classNames([classes.row, {'hover': hover === i}])}
                            onMouseEnter={() => setHover(i)}
                            onMouseLeave={() => setHover(null)}
                        >
                            <td>
                                <div className={classes.circle} style={{background: COLORS[i % COLORS.length]}} />
                                {t(d.name)}</td>
                            <td>{d?.total_idle_percentage}%</td>
                            <td>{formatValue(d.value)}</td>
                            <td><Badge v={diff + '%'} t={diff} reverse />  </td>
                        </tr>);
                    })}

                    {filteredData?.length === 0 &&
                    <tr>
                        <td colSpan={3} style={{padding: '16px 0 16px 0'}}>Нет простоя</td>
                    </tr>}

                    {warnData?.length > 0 &&
                    <>
                        <tr>
                            <td colSpan={3} className={classes.noBorder} />
                        </tr>
                        <tr>
                            <th className={classes.noBorder} >Обратите вимание</th>
                            <th  className={classes.noBorder}></th>
                            <th className={classes.noBorder} >Простой</th>
                            <th className={classes.noBorder} >Динамика</th>
                        </tr>
                        {warnData?.map((d, i) => {
                            const diff = d.diff_idle;

                            return (
                                <tr key={i} className={classNames([classes.rows])}
                                >
                                    <td>
                                        <div className={classes.circle} style={{background: COLORS[i % COLORS.length]}}/>
                                        {t(d.name)}</td>
                                    <td></td>
                                    <td>{formatValue(d.value)}</td>
                                    <td><Badge v={diff + '%'} t={diff} reverse/></td>
                                </tr>);
                        })}
                    </>
                    }
                </table>

            </div>
            </div>
        </Paper>
    )
};

export default Reasons;

const styles = (theme) => ({
    chartWrap: {
        minHeight: 310,
    },
    tooltip: {
        background: 'rgba(22, 25, 56, 0.8)',
        color: theme.colors.main.white,
        padding: theme.spacing(2, 3),
        borderRadius: theme.spacing(4),

        '& strong': {
            fontWeight: 500,
        },
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 36,
        fontSize: 15,
    },
    headerTitle: {
        fontSize: 16,
        letterSpacing: '0.002em',
    },
    downloadBtnIcon: {
        width: 24,
        height: 24,
    },

    downloadBtn: {
        color: '#666',
        display: 'flex',
        gap: 4,
        cursor: 'pointer',

        '&:hover': {
            textDecoration: 'underline',
        }
    },
    link: {
        position: 'fixed',
        top: -9999,
        left: -9999,
        fontSize: 0,
    },
    chart: {
        padding: 0,
    },
    layout: {
        display: 'flex',
        gap: '64px',

        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },

        '& table': {
            width: '100%',
            borderCollapse: 'collapse',

            '& td, & th': {
                borderTop: '1px solid #DFE3E9',
                textAlign: 'left',
                verticalAlign: 'middle',
                fontWeight: 400,
                fontSize: '13px',
                padding: '4px 0',

                "&:nth-child(2)": {
                    padding: '4px 8px',
                }
            },

            '& th': {
                fontWeight: 500,
            },

            '& tr:first-child td': {
                borderTop: 0,
            },

            '& .hover': {
                background: '#F7F8FA',
            }
        }
    },

    tableWrap: {
        flex: 1,
    },

    circle: {
        display: 'inline-block',
        width : 14,
        height: 14,
        borderRadius: '100%',
        marginRight: '16px',
        position: 'relative',
        top: 2,
    },
    select: {
        marginLeft: 24,
    },
    noBorder: {
        border: '0 !important',
    }
});
const useStyles = makeStyles(styles);
