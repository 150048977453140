import React, {useEffect, useRef} from 'react';
import Select from '../Dashboard/Select.js';
import {useDispatch, useSelector} from 'react-redux';
import {citiesFetchList, getCitiesList} from '../../redux/reducers/cities.js';
import {chainsFetchList, getChainsList} from '../../redux/reducers/chains.js';

const ChainsFilter = ({
    selChains, setSelChains,
    ...props
}) => {
    const citiesImm = useSelector(getChainsList);
    const cities = citiesImm?.toJS();
    const citiesLoaded = cities !== null;

    const localCitiesSel = useRef([]);

    useEffect(() => {
        localCitiesSel.current = selChains;
    }, [selChains])

    function updateCitiesFilter() {
        setSelChains(localCitiesSel.current);
    }

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(chainsFetchList());
    }, [])

    return (
        <Select
            options={cities || []}
            placeholder="Все торговые сети"
            value={selChains}
            onChange={(e, v, reason)=>{
                //console.log('VALUE', v);
                /*localCitiesSel.current = v;

                if (reason === 'clear') {
                    updateCitiesFilter();
                }*/
                setSelChains(v)
            }}
            onClose={()=> {
                //updateCitiesFilter();
            }}
            multiple
            loading={!citiesLoaded}
            tagLabelI18n="chain"
        />
    );
}

export default ChainsFilter;
