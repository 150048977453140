import React, {useContext, useEffect, useState} from 'react';
import {Paper, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {getWithToken} from '../../app/rest.js';
import {tokensGetBearerToken} from '../../redux/reducers/tokens.js';
import {useSelector} from 'react-redux';
import {formatDate} from '../../app/utils.js';
import {t} from '../../i18n/utils.js';
import classNames from 'classnames';
import StatPanel from '../Dashboard/StatPanel.js';
import {ifnn} from '../Dashboard/DailyStats.js';
import {LocaleContext} from '../../i18n/LocaleContext.js';
import Badge from '../Dashboard/Badge.js';
import {ReactComponent as InfoSvg} from '../../icons/info.svg';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import './tippy.css';

export const APIPREFIX = '/v2/api'

const Metrics = ({
    dateFrom, dateTo, selCities, selChains, selStores,
                     ...props
}) => {
    const classes = useStyles();

    const [metrics, setMetrics] = useState(null);

    const token = useSelector(tokensGetBearerToken);

    async function fetchMetrics(dateFrom, dateTo) {
        let query = `?date_from=${dateFrom}&date_to=${dateTo}`;
        const cityIds = selCities.map(c => c.id);
        let t;
        t = cityIds?.map(c => {
            query += `&city_ids=${c}`;
        });
        const chainIds = selChains.map(c => c.id);
        t = chainIds?.map(c => {
            query += `&chain_ids=${c}`;
        });
        const storeIds = selStores.map(c => c.id);
        t = storeIds?.map(c => {
            query += `&store_ids=${c}`;
        });
        //&city_ids=${cityIds}&chains_ids=${chainIds}`;

        const resp = await getWithToken(APIPREFIX + '/reports/dispensing/metrics' + query, token, 'Bearer');
        //console.log(resp);

        /*
        bottles_by_period: 14113
        bottles_by_period_diff: -6.57
        poured_by_day: 1765.52
        poured_by_day_diff: -7.34
        poured_by_period: 12358.67
        poured_by_period_diff: -7.34
         */

        setMetrics(resp);
    }

    useEffect(() => {
        const df = formatDate(dateFrom, 'YYYY-MM-DD');
        const dt = formatDate(dateTo, 'YYYY-MM-DD');
       fetchMetrics(df, dt);
    }, [dateFrom, dateTo, selChains, selCities, selStores]);

    const locale = useContext(LocaleContext);
    const lang = locale?.code;

    function val(src) {
        if (src === null || src === undefined) {
            return '-';
        }

        const num = Number(src);
        if (isNaN(num)) {
            return '-';
        }

        const localizedStr = num.toLocaleString(lang, {maximumFractionDigits: 0});
        return localizedStr;
    }


    function MetricPanel({title, tooltip, value, diff, units, showDiff = 'true', reverse, type, ...props}) {
        return (
            <StatPanel label={title} className={classes.panel} loading={!metrics} icon={
                <Tippy className={classes.tippy} content={<span>{tooltip}</span>}>
                    <InfoSvg />
                </Tippy>
            }>
                <div className={classes.metricInner}>
                    <div className={classes.big}>{val(value, type)}</div>
                    { showDiff &&
                    <div className={classes.small}>
                        <Badge v={val(diff) + '%'} t={diff} reverse={reverse}/>
                        <div>С прошлым периодом</div>
                    </div>
                    }
                </div>
            </StatPanel>
        );
    }

    return (
        <div className={classes.row}>
            <MetricPanel title={'Объем наливов'}
                         value={metrics?.poured_by_period}
                         diff={metrics?.poured_by_period_diff}
                         units={'л'}
                         reverse={false}
                tooltip={'Cумма всех наливов по отфильтрованным магазинам за выбранный период'}
            />
            <MetricPanel title={'Средний налив в день'}
                         value={metrics?.poured_by_day}
                         diff={metrics?.poured_by_day_diff}
                         units={'л'}
                         reverse={false}
                         tooltip={'Cумма всех наливов по отфильтрованным магазинам за выбранный период/кол-во дней в выбранном периоде '}/>
            <MetricPanel title={'Налито бутылок'}
                         value={metrics?.bottles_by_period}
                         diff={metrics?.bottles_by_period_diff}
                         units={'шт'}
                         reverse={false}
                         tooltip={'Количество налитых бутылок по отфильтрованным магазинам за выбранный период'}/>
        </div>
    )
};

export default Metrics;

const styles = theme => ({
    row: {
        display: 'flex',
        gap: 24,

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    panel: {
        padding: 20,
        flex: 1,
        alignSelf: 'flex-start', // questionable

        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    big: {
        color: '#1D1D1D',
        marginRight: 24,
        display: 'block',
    },
    small: {
        color: '#868A8F',
        fontSize: 13,
        lineHeight: '16px',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 8,
    }
});
const useStyles = makeStyles(styles);
