import React, {useContext, useState} from 'react';
import Switch from '@material-ui/core/Switch';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';
import {normalizeBool} from '../../../app/utils';
import Button from '@material-ui/core/Button';
import ItemChangeDialog from './ItemChangeDialog';
import {changeItemValue} from '../../../redux/reducers/sections';
import {useDispatch, useSelector} from 'react-redux';
import {tokensGetAccessToken} from '../../../redux/reducers/tokens';
import {DeviceContext} from '../../../componets/DeviceLoader';
import {SectionContext} from './ElementsPage';
import {t} from '../../../i18n/utils.js';
import { useTranslation } from 'react-i18next';

/**
 * Рендерит коил
 * @param props
 * @returns {JSX.Element}
 * @constructor

function Coils(props) {
    const { item } = props;

    if (item.value === null || item.value === undefined) {
        // no value
        return (
            <React.Fragment>
                No value
            </React.Fragment>
        );
    }

    const normalizedValue = normalizeBool(item.value);

    if (normalizedValue === null) {
        // unsupported value
        return (
            <React.Fragment>
                Unsupported Value
            </React.Fragment>
        );
    }

    const isChecked = normalizedValue;

    const activeSwitchColor = "#3f51b5";
    const ColoredSwitch = withStyles({
        switchBase: {
            '&$checked': {
                color: activeSwitchColor,
            },
            '&$checked + $track': {
                backgroundColor: activeSwitchColor,
            },
        },
        checked: {},
        track: {},
    })(Switch);

    return (
        <React.Fragment>
            <ColoredSwitch
                checked={isChecked}
                onChange={event => {
                    const newValue = event.target.checked;
                }}
                title={`raw: ${item.value}`}
            />
        </React.Fragment>
    );
}


 * Рендерит айтем
 * @param item
 * @param props
 * @returns {JSX.Element}
 * @constructor

function Item(props) {
    const { item } = props;

    // render item title

    // renter item value
    const valueType = item.value_type;
    switch (valueType) {
        case 1:
            // DiscreteInputs
            return (<div>DiscreteInputs</div>);
        case 2:
            // Coils
            return <Coils item={item} />
        case 3:
            // InputRegisters
            return (<div>InputRegisters</div>);
        case 4:
            // HoldingRegisters
            return (<div>HoldingRegisters</div>);
        case 5:
            // File
            return (<div>File</div>);
        case 6:
            // SimpleButton
            return (<div>SimpleButton</div>);
        case 0:
        default:
            // None or undefined
            return (<div>None</div>);
    }
}
*/

function ItemValueHoldingRegister(props) {
    const classes = useStyles();
    const { value, isDisconnected, item} = props;

    const [open, setOpen] = useState(false);
    const {secId} = props;

    const options = item.options;
    const hasOptions = Array.isArray(options);

    return (
        <React.Fragment>
            <button className={classNames([classes.itemEditable, {'disabled': isDisconnected}])} onClick={()=>{setOpen(true)}} disabled={isDisconnected}>
                {hasOptions ? translateNormalizeOpt(item, options[value]) || '-' :
                    translateNormalize(item)
                }
            </button>
            <ItemChangeDialog item={item} secId={secId} itemId={props.itemId} title={props.title} itemKey={props.itemKey} open={open} onClose={()=>{
                setOpen(false);
            }} value={value} />
        </React.Fragment>
    );
}

export function translateNormalizeOpt(item, opt) {
    let tr = {
        "set_mode": "set_mode",
        "stage": "stages",
        "error_stage": "stages",
        "error_code": "error_codes",
        "block_status": "block_statuses",
        "blocking_reason": "block_statuses",
        "input_blocking_reason": "block_statuses",
        "door_lock": "doors_info.door_blocked",
        "panel_closed": "doors_info.door_closed",
        "panel_closed_1": "doors_info.door_closed",
        "panel_closed_2": "doors_info.door_closed",
    };

    if (Object.keys(tr).includes(item.name) && opt !== undefined) {
        let vpath = opt;

        //console.log([trObj, vpath.trim()].join('.'));

        const trObj = tr[item.name];
        const trans = t([trObj, vpath?.trim()].join('.'));;
        return trans.startsWith(trObj) ? opt : trans;
    }

    return opt;// + ' ' + item.name;

    //return `${item?.value} (${item.name}) ${list?.length}`;
}

export function translateNormalize(item) {
    //const { t } = useTranslation('translation');

    if (!item.value) {
        return '-';
    }

    let tr = {
        "set_mode": "set_mode",
        "stage": "stages",
        "error_stage": "stages",
        "error_code": "error_codes",
        "block_status": "block_statuses",
        "blocking_reason": "block_statuses",
        "input_blocking_reason": "block_statuses",
        "door_lock": "doors_info.door_blocked",
        "panel_closed": "doors_info.door_closed",
        "panel_closed_1": "doors_info.door_closed",
        "panel_closed_2": "doors_info.door_closed",
    };

    if (Object.keys(tr).includes(item.name) && item.value?.split) {
        const list = item.value?.split(',');

        const trObj = tr[item.name];

        return list.map(v => {
            let vpath = v;
            if (item.value_type === 1) {
                vpath =  item.value ? 'yes' : 'no';
            }

            console.log([trObj, vpath.trim()].join('.'));

            return t([trObj, vpath.trim()].join('.'));
        }).join(', ');
    }

    if (item.value_type === 1) {
        return item.value ? 1 : 0;
    }

    return item.value;// + ' ' + item.name;

    //return `${item?.value} (${item.name}) ${list?.length}`;
}

function ItemValueFactory(props) {
    const classes = useStyles();
    const { type, value,isDisconnected, item, options } = props;

    const token = useSelector(tokensGetAccessToken);
    const device = useContext(DeviceContext);
    const device_id = device.get('id');
    const section = useContext(SectionContext);

    const dispatch = useDispatch();

    const {secId} = props;

    switch (type) {
        case 1:
            return translateNormalize(item); //value ? 1 : 0; // TODO refactor it?
        case 2:
            return (<ItemValueCoil secId={secId}  value={value} itemId={props.itemId} itemKey={props.itemKey} isDisconnected={isDisconnected} />);
        case 3:
            return translateNormalize(item);
        case 4:
            return <ItemValueHoldingRegister options={options} item={item} secId={secId} value={value} itemId={props.itemId} title={props.title} itemKey={props.itemKey} isDisconnected={isDisconnected} />;
        case 5:
            return (<div>File</div>); // TODO add file uploading
        case 6:
            return ( // TODO refactor it
                <Button variant={'contained'} secId={secId}  className={classes.btn} disabled={isDisconnected} onClick={()=>{
                    dispatch(changeItemValue(props.itemId, value, props.itemKey, secId));

                    /*/ TODO костыльный апдейт
                    setTimeout(()=>{
                        console.log('UPDATE SECTION', section);
                        dispatch(sectionDetailsFetch(section.id));
                    }, 3000);*/
                }}>
                    {value}
                </Button>
            );
        default:
            return value;
    }
}

function ItemValueCoil(props) {
    const classes = useStyles();

    const token = useSelector(tokensGetAccessToken);
    const device = useContext(DeviceContext);
    const device_id = device.get('id');
    const section = useContext(SectionContext);

    const dispatch = useDispatch();

    const activeSwitchColor = "#3f51b5";
    const ColoredSwitch = (Switch);

    return (
        <React.Fragment>
            <ColoredSwitch
                color="primary"
                disabled={props.isDisconnected}
                checked={props.value}
                onChange={event => {
                    const newValue = event.target.checked ? '1' : '0';

                    dispatch(changeItemValue(props.itemId, newValue, props.itemKey, props.secId));

                    /*/ TODO костыльный апдейт
                    setTimeout(()=>{
                        console.log('UPDATE SECTION', section);
                        dispatch(sectionDetailsFetch(section.id));
                    }, 3000);*/
                }}
            />
        </React.Fragment>
    );
}

function normalizeValue(srcVal, type) {
    if (type === 1 || type === 2) {
        // convert to bool
        const dstVal = normalizeBool(srcVal);
        if (dstVal !== null) {
            return [dstVal, true, false];
        } else {
            return [null, true, true];
        }
    }
    return [srcVal, false, false];
}

function Item(props) {
    const classes = useStyles();
    const { item,isDisconnected, options } = props;

    if (!item) return null;

    const hasValue = item && item?.value !== undefined && item?.value !== null;

    const {secId} = props;
    const [normalizedValue, wasNormalized, valueIsNotSupported] = normalizeValue(item.value, item.value_type);

    let title = item?.title || '-';

        if (item?.value_type == 5) return null;

        return (
        <div className={classNames([classes.item, {'no-value': !hasValue || valueIsNotSupported}])}>
            <div className={classes.itemTitle} title={`[${item.id}] ${item.name}`}>
                {title}
            </div>
            <div className={classes.itemValue} title={`${item.name} APIVAL: {${item.value}} APITYPE: {${typeof item.value}} REGTYPE: {${item.value_type}}`}>
                {hasValue ?
                    !valueIsNotSupported ?
                        <ItemValueFactory item={item} secId={secId} type={item.value_type} value={normalizedValue} itemId={item.id} itemKey={item.key} isDisconnected={isDisconnected}
                            title={title} options={options} />
                        :
                        <div>Wrong type</div>
                    :
                    <div>N/A</div>
                }

                {/*<div>{item.name} {item.value_type}</div>*/}
            </div>
        </div>
    );
}

export default Item;

const styles = theme => ({
    item: {
        display: 'flex',
        alignItems: 'center',

        "&.no-value": {
            opacity: 0.5,
        }
    },
    itemTitle: {
        flex: 1,
    },
    itemValue: {
        flex: 1,
        textAlign: 'center',
    },
    btn: {
        backgroundColor: 'rgba(0,0,0,.26)!important',
        color: '#fff',
    },
    itemEditable: {
        border: 0,
        background: "transparent",
        color: "#646464",
        cursor: "pointer",
        minHeight: '1em',
        textDecoration: 'underline',

        "&:not(.disabled):hover": {
            color: "#fff",
            background: 'rgba(0,0,0,.26)!important',
            borderRadius: "8px",
        },

        "&.disabled": {
            cursor: "default",
        }
    },
});
const useStyles = makeStyles(styles);
