import React, {useContext, useEffect, useState} from 'react';
import PageLayout from '../../componets/PageLayout/PageLayout.js';
import Typography from '@material-ui/core/Typography';
import {Paper, ThemeProvider} from '@material-ui/core';
import {t} from '../../i18n/utils.js';
import {theme} from '../StoresListPage/theme.js';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import {ReactComponent as DownloadIcon} from '../ReportsPage/DownloadIcon.svg';
import {useSelector} from 'react-redux';
import {getWithToken} from '../../app/rest.js';
import {tokensGetBearerToken} from '../../redux/reducers/tokens.js';
import {formatDate} from '../../app/utils.js';
import {LocaleContext} from '../../i18n/LocaleContext.js';
import {setTitle} from '../Dashboard/Dashboard.js';
import {APIPREFIX} from '../ReportsPage/Metrics.js';

const DownloadBtn = props => {
    const classes = useStyles();

    return (
        <Button {...props} variant="outlined">
            <div className={classes.iconWrap}><DownloadIcon width={18} height={18} /></div>
            <div className={classes.labelWrap}>{t('download')}</div>
        </Button>
    );
}

const DocsPage = () => {
    const classes = useStyles();

    const [invoices, setInvoces] = useState(null);

    const companyId = useSelector(state => state.getIn(['user', 'info', 'company_id']));
    const token = useSelector(tokensGetBearerToken);

    const locale = useContext(LocaleContext);
    const lang = locale?.lang || 'ru';
    const dFormat = locale?.dateFormat;

    const offset = 0;
    const limit = 9999;

    setTitle(t('mainMenu_docs'))

    const fetchInvoices = async companyId => {
        const resp = await getWithToken(
                `/v2/api/payments/invoices?company_id=${companyId}&limit=${limit}&offset=${offset}`,
                token,
                'Bearer',
            );

        return resp;
    }

    useEffect(() => {
        console.log(companyId);
        if (companyId) {
            fetchInvoices(companyId).then(resp => {
                setInvoces(resp.items);
                console.log(resp.items);
            });
        }
    }, [companyId]);

    const formatNumber = num => {
        return Number(num).toLocaleString(lang, {maximumFractionDigits: 2});
    };

    async function saveFile(id) {
        const resp = await fetch(`/v2/api/payments/invoices/${id}/download`,{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        });

        if (resp.ok) {
            const blob = await resp.blob();
            //blob.type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;';

            var link = document.getElementById(`download-${id}`);
            link.href = window.URL.createObjectURL(blob);
            link.click();
        } else {
            alert('Произошла ошибка: нет такого счёта')
        }
    }


    function downloadBtnClick(id, ev) {
        ev.preventDefault();

        //const link = document.getElementById(`download-${id}`);
        saveFile(id)
    }

    return (
        <ThemeProvider theme={theme}>
            <PageLayout>
                <Typography variant={"h1"} className={classes.heading}>{t('mainMenu_docs')}</Typography>

                <div>
                    <Paper className={classes.paper}>
                        {!companyId ?
                            <Typography className={classes.textBox}>
                                У этого пользователя не указана компания
                            </Typography>
                            :
                            <>
                            {invoices !== null ? (
                                <table className={classes.table}>
                                    <tr>
                                        <th>Счёт</th>
                                        <th>Дата</th>
                                        <th>Сумма</th>
                                        <th>Статус</th>
                                        <th />
                                    </tr>
                                    {invoices.map((inv, index) => {
                                        const noFile = !inv.filename;

                                        return (
                                            <tr key={index}>
                                                <td>Счет №{inv.number}</td>
                                                <td>{formatDate(inv.date, dFormat, lang)}</td>
                                                <td>{formatNumber(inv.amount)} руб.</td>
                                                <td>{inv.is_payed ?
                                                    <>Оплачен</> : <>Не оплачен</>
                                                }</td>
                                                <td>
                                                    <a download={`Счет №${inv.number}.pdf`} href="#" id={`download-${inv.id}`} className={classes.hideLink}>&nbsp;</a>
                                                    <DownloadBtn
                                                    disabled={noFile}
                                                    href={'#'}
                                                    onClick={(ev) => downloadBtnClick(inv.id, ev)}
                                                /></td>
                                            </tr>
                                        );
                                    })}
                                </table>
                                        ) :
                                <Typography className={classes.textBox}>{t('loading')}</Typography>
                            }
                            </>
                        }
                    </Paper>
                </div>

                <div>
                <Paper  className={classes.paper}>
                    <table className={classes.table}>
                        <tr>
                            <th>Договор</th>
                            <th />
                        </tr>
                        <tr>
                            <td>Договор-оферты</td>
                            <td><DownloadBtn href={'/v2/api/oferta.pdf'} download={'Договор-оферты.pdf'} /></td>
                        </tr>
                    </table>
                </Paper>
                </div>
            </PageLayout>
        </ThemeProvider>
    );
};
export default DocsPage;

const styles = theme => ({
    heading: {
        marginTop: 13,
        marginBottom: 23,
        color: '#1d1d1d',
    },
    paper: {
        marginBottom: 32,
        padding: '0',
        display: 'inline-block',
    },
    iconWrap: {
        display: 'inline-flex',
        alignSelf: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 8,
        color: '#868A8F',
    },
    labelWrap: {
        fontSize: 15,
        fontWeight: 400,
    },
    table: {
        borderCollapse: 'collapse',

        '& th': {
            fontWeight: 500,
        },

        '& td, & th': {
            fontSize: 15,
            color: '#1d1d1d',
            minWidth: 180,
            textAlign: 'left',
            padding: '12px 20px',
        },

        '& td': {
            borderTop: '1px solid #E9ECF0',
        },

        '& tr:nth-child(2n + 1) td': {
            background: '#F3F7F980'
        }
    },
    textBox: {
        padding: 32,
    },

    hideLink :{
        fontSize: 0,
        position: 'fixed',
        top: -9999,
        left: -9999,
    }
});

const useStyles = makeStyles(styles);
