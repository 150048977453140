import Immutable, {fromJS} from 'immutable';
import {tokensGetBearerToken} from './tokens';
import {apiDeviceDetails} from '../../app/api';
import {getWithToken, postWithToken} from '../../app/rest';
import {check401} from '../../app/utils';
import {getLoadedLanguage as getSavedLocale} from './locale';
import {t} from '../../i18n/utils.js';

export const DEVICE_DETAILS_FETCH_REQUEST = "DEVICE_DETAILS_FETCH_REQUEST";
export const DEVICE_DETAILS_FETCH_SUCCESS = "DEVICE_DETAILS_FETCH_SUCCESS";
export const DEVICE_DETAILS_FETCH_FAILURE = "DEVICE_DETAILS_FETCH_FAILURE";
export const DEVICE_DETAILS_WS_CHANGE = "DEVICE_DETAILS_WS_CHANGE";
export const DEVICE_DETAILS_WS_STATUS_CHANGE = "DEVICE_DETAILS_WS_STATUS_CHANGE";

export const deviceDetailInitialState = new Immutable.Map({
    map: {},
    isFetching: false,
    isError: null,
});

function reducer(state = deviceDetailInitialState, action) {
    switch (action.type) {
        case DEVICE_DETAILS_FETCH_REQUEST:
            return state
                .setIn(['map', action.id], Immutable.fromJS({isLoading: true}));
        case DEVICE_DETAILS_FETCH_SUCCESS: {
            const local = state.getIn(['map', action.id.toString(), 'local']);

            return state
                .setIn(['map', action.id], Immutable.fromJS(action.details))
                .setIn(['map', action.id, 'isLoaded'], true)
                .setIn(['map', action.id, 'local'], local);
        }
        case 'DEVICE_DETAILS_REBOOT': {
            return state
                .setIn(['map', action.id.toString(), 'local'], fromJS({reboot: action.reboot}));
        }
        case DEVICE_DETAILS_FETCH_FAILURE:
            return state
                .setIn(['map', action.id], Immutable.fromJS({isError: true}));

        case 'DEVICE_DETAILS_CONNECTION_CHANGE': {

            return state;
        }

        case 'DEVICE_DETAILS_SERVICE_MODE': {
            const id = action.id;
            //const device = state.getIn(['map', id]);
            //console.log(action);

            let showOff = false;

            const oldstate = state.getIn(['map', action.id, 'serviceMode', 'enabled']);
            if (oldstate === true && action.enabled === false) {
                showOff = true;
            }

            return state.mergeDeep({
                map: {
                    [id]: {
                        serviceMode: {
                            enabled: action.enabled,
                            enabledTo: action.enabledTo,
                            showOff,
                        }
                    }
                },
                });
        }

        case DEVICE_DETAILS_WS_CHANGE: {
            const data = action.data;
            //console.log(action.data);

            const deviceIm = state.getIn(['map', data.group_id]);
            //console.log(deviceIm);
            if (!deviceIm?.toJS) return state;
            const device = deviceIm?.toJS();
            //console.log(device);

            if (!device) return state;

            let newstate = state;

            // for printer
            if (data.device_type === 'printer') {
                const pIdx = device.modules.findIndex(p => p.id === data.device_id);

                if (data.payload_type === 'info') {
                    const nameTr = {
                        labels_num: "labels_remained",
                        labels_num_full: "labels_max",
                    }

                    device.modules[pIdx][nameTr[data.payload.name]] = data.payload.value;

                    //console.log(device);
                    return state.setIn(['map', data.group_id], fromJS(device));
                }

            }


            // for tap
            if (data.device_type === 'keg') {
                //console.log(data, device);

                let exit = false;
                if (!device?.modules) {
                    return state;
                }

                for (let m of device?.modules) {
                    if (m.type !== 'tap') continue;

                    for (let k of m.kegs) {
                        if (k.id === data.device_id) {
                            // change info
                            if (data.payload_type === 'statistic') {
                                //console.log('CHANGE STATS', k, data);
                                k.statistics = data.payload;
                            }

                            if (data.payload_type === 'info') {
                                if (data.payload.name === 'brand') {
                                    console.log('CHANGE BRAND', k, data);
                                    k.label = data.payload.value?.brand_name;
                                }

                                if (data.payload.name === 'is_empty') {
                                    //console.log('CHANGE IS EMPTY', k, data);
                                    k.is_empty = data.payload.value;
                                }
                            }

                            // exit loop
                            exit = true;
                            break;
                        }
                    }

                    if (exit) break;
                }

                return state.setIn(['map', data.group_id], fromJS(device));
            }

            return state;
        }

        case DEVICE_DETAILS_WS_STATUS_CHANGE: {
            return state;
        }

        default:
            return state;
    }
}
export default reducer;

export const reboot = (id) => async (dispatch, getState) => {
    const state = getState();
    const token = tokensGetBearerToken(state);

    try {
        postWithToken(`/v2/api/groups/${id}/reboot`, {device: id}, "PUT", token, 'Bearer').then((resp)=>{
            dispatch({type: 'DEVICE_DETAILS_REBOOT', id, reboot: true});
            setTimeout(()=> {
                dispatch({type: 'DEVICE_DETAILS_REBOOT', id, reboot: false});
            }, 10000);
        });
    } catch (err) {
        // err
    }
};

export const fetchDeviceDetails = (id, light, withoutService) => async (dispatch, getState) => {
    //console.log('innerLoad');

    if (!light)
        dispatch({type: DEVICE_DETAILS_FETCH_REQUEST, id});

    const state = getState();
    const token = tokensGetBearerToken(state);
    const locale = getSavedLocale(state);

    try {
        const resp = await apiDeviceDetails(token, id, locale);

        const details = assignTitlesToModules(resp);
            if (withoutService) {
                // TODO refactor
                details['serviceMode'] = {
                    enabled: false,
                    enabledTo: null,
                }
                dispatch({type: DEVICE_DETAILS_FETCH_SUCCESS, id, details});
            } else {
                getWithToken(`/v2/api/groups/${id}/service`, token, 'Bearer').then(r => {
                    //dispatch({type: 'DEVICE_DETAILS_SERVICE_MODE', enabled: r.enabled, enabledTo: r.enabledTo, id});
                    details['serviceMode'] = {
                        enabled: r.enabled,
                        enabledTo: r.enabled_to,
                    }
                    dispatch({type: DEVICE_DETAILS_FETCH_SUCCESS, id, details});
                }, err => {
                    details['serviceMode'] = {
                        enabled: false,
                        enabledTo: null,
                    }
                    dispatch({type: DEVICE_DETAILS_FETCH_SUCCESS, id, details});
                });
            }
    } catch (err) {
        check401(err, dispatch);

        dispatch({type: DEVICE_DETAILS_FETCH_FAILURE, id});
    }
}

function assignTitlesToModules(src) {
    let tapCounter = 0;
    let printerCounter = 0;
    let gasCounter = 0;

    return {
        ...src,
        modules: src.modules.map(m => {
            if (m.title) {
                return m;
            }

            let newTitle;
            switch (m.type) {
                case 'tap':
                    tapCounter++;
                    newTitle = t('moduleTap', {tapCounter});

                    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

                    const renamedKegs = m.kegs?.map((k, index) => {
                        const kegLetter = letters[index];
                        return {
                            ...k,
                            title: t('moduleKeg', {tapCounter, kegLetter}),
                        };
                    })

                    return {
                        ...m,
                        title: newTitle,
                        kegs: renamedKegs,
                    }
                case 'printer':
                    printerCounter++;
                    newTitle = t('modulePrinter', {printerCounter});
                    break;
                case 'co2':
                    gasCounter++;
                    newTitle =t('moduleGas', {gasCounter});
                    break;
                default:
                    newTitle = t('moduleUnknown');
            }

            return {
                ...m,
                title: newTitle,
            }
        }),
    };
}

export const getDeviceDetails = (state, id) => {
    const dev = state.getIn(['deviceDetails', 'map', id]);
    return dev;
};
