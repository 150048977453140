import {fromJS, Map} from 'immutable';
import {getWithToken} from '../../app/rest';
import {tokensGetBearerToken} from './tokens';
import {check401} from '../../app/utils.js';

const initialState = new Map({
    list: null,
    isFetching: false,
    isError: false,
});

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'COMPANIES_FETCH_REQUEST':
            return state.set('isFetching', true)
                .set('isError', false);
        case 'COMPANIES_FETCH_SUCCESS':
            return state.set('isFetching', false)
                .set('list', fromJS(action.list));
        case 'COMPANIES_FETCH_ERROR':
            return state.set('isFetching', false)
                .set('isError', true);
        default:
            return state;
    }
};
export default reducer;

export const getCompaniesList = (state) => state.getIn(['companies', 'list']);

export const companiesFetchList = () => async (dispatch, getState) => {
    dispatch({type: 'COMPANIES_FETCH_REQUEST'});

    const state = getState();
    const token = tokensGetBearerToken(state);

    try {
        const resp = await getWithToken(`/v2/api/companies?limit=9999`, token, 'Bearer');

        const list = resp.items;

        dispatch({type: 'COMPANIES_FETCH_SUCCESS', list: list});
    } catch (err) {
        check401(err, dispatch)

        dispatch({type: 'COMPANIES_FETCH_ERROR'});
    }
}
