import React, {useContext, useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, Typography} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {useSelector} from 'react-redux';
import {tokensGetBearerToken} from '../../../redux/reducers/tokens';
import {DeviceContext} from '../../../componets/DeviceLoader';
import {TimePicker} from '@material-ui/pickers';
import {postWithToken} from '../../../app/rest';
import moment from 'moment';
import {t} from '../../../i18n/utils.js';
import {LocaleContext} from '../../../i18n/LocaleContext.js';

/**
 * Диалог изменения айтема
 *
 * @param open
 * @param onClose
 * @param item
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function WorkTimeChangeDialog({open, onClose, workDay, ...props}) {
    const classes = useStyles();

    const [wds, setWds] = useState(workDay.wds);
    const [wde, setWde] = useState(workDay.wde);

    const token = useSelector(tokensGetBearerToken);
    const device = useContext(DeviceContext);
    const device_id = device.get('id');
    function handleSubmit() {
        const apiTimeFormat = 'HH:mm:ss';

        const body = {
            work_day_start: moment(wds).format(apiTimeFormat),
            work_day_end: moment(wde).format(apiTimeFormat),
        };

        postWithToken(`/v2/api/groups/${device_id}/work_time`, body, 'PUT', token, 'Bearer').then(r => {
            //
        });

        onClose(true, wds, wde);
    }

    const localeObj = useContext(LocaleContext);
    const ampm = localeObj.timeAmPm;

    return (
        <Dialog open={open} onClose={onClose} maxWidth="lg">
            <Typography variant="h1" className={classes.heading}>{t("timeSecHeading")}</Typography>

            <DialogContent className={classes.cont}>
                <TimePicker
                    ampm={ampm}
                    label={t('begin')}
                    value={wds}
                    onChange={(time)=>{setWds(time)}}
                    cancelLabel={t('cancelLabel')}
                    okLable={t('okLabel')}
                    id={'workhours-timepicker-begin'}
                />

                <TimePicker
                    ampm={ampm}
                    label={t('end')}
                    value={wde}
                    onChange={(time)=>{setWde(time)}}
                    cancelLabel={t('cancelLabel')}
                    okLable={t('okLabel')}
                    id={'workhours-timepicker-end'}
                />
            </DialogContent>
            <DialogActions>
                <Button color="primary" variant="outlined" onClick={handleSubmit} disabled={!wds || !wde}>{t('applyBtn')}</Button>
                <Button variant="outlined" onClick={onClose}>{t('cancelBtn')}</Button>
            </DialogActions>
        </Dialog>
    );
}

export default WorkTimeChangeDialog;


const useStyles = makeStyles(theme => ({
    heading: {
        textAlign: "center",
        margin: "24px 0",
        minWidth: 320,
        maxWidth: 580,
        width: "100vw",
    },
    cont: {
        marginBottom: 32,
        display: 'flex',
        flexWrap: 'wrap',
        gap: '16px 32px',
    },

    paramSec: {
        padding: "8px 0",
    }
}));
