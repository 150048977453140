import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import StatPanel from './StatPanel.js';
import classNames from 'classnames';
import {Typography} from '@material-ui/core';

import {ReactComponent as ErrorIcon} from './svg/errorIcon.svg';
import {ReactComponent as KegIcon} from './svg/kegIcon.svg';
import {ReactComponent as WarnIcon} from './svg/warnIcon.svg';
import {useDispatch, useSelector} from 'react-redux';
import {statsSummaryWFFetch, statsSummaryWFGet, statsSummaryWFGetState} from '../../redux/reducers/stats.js';
import {t} from '../../i18n/utils.js';
import {ReactComponent as InfoSvg} from '../../icons/info.svg';
import MyDialog from './Dialog.js';

export function ifnn(obj) {
    if (!obj) {
        if (obj === 0) {
            return obj;
        } else {
            return '-'
        }
    } else {
        return obj;
    }
}

function DailyStats({city, store, chains, ...props}) {
    const classes = useStyles();

    const [open, setOpen] = useState(false);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(statsSummaryWFFetch(city, store, chains));
    }, [city, store, chains]);

    const summary = useSelector(statsSummaryWFGet)?.toJS();
    const summaryState = useSelector(statsSummaryWFGetState);
    const loading = summaryState === 'loading';

    const redTreshhold = 5;
    const isRed = summary?.taps_error > redTreshhold;

    return (
        <div className={classes.root}>
            <div className={classes.headerRow}>
                <Typography variant="h4" className={classes.header}>Ежедневный мониторинг</Typography>

                <div className={classes.infoBtn} onClick={()=>{
                    setOpen(true);
                }}><InfoSvg /></div>
                <MyDialog open={open} onclose={() => {
                    setOpen(false);
                }} />
            </div>

            <div className={classes.cont}>
                {/*<div className={classes.stats}>*/}
                    <StatPanel label="Нерабочие краны" className={classNames(classes.statsItem)} loading={loading}>
                        <span className={classes.red}>{ifnn(summary?.blocked_taps)}</span> <span className={classes.all}>Всего {ifnn(summary?.taps_total)} <span className={classes.lowercase}>{t('tap', {count: summary?.taps_total})}</span></span>
                    </StatPanel>
                    <StatPanel label="Критические ошибки" red={isRed} icon={<ErrorIcon />} className={classNames(classes.statsItem)} loading={loading}>
                        <span className={classNames({[classes.red]: isRed})}>{ifnn(summary?.count_errors)}</span>
                    </StatPanel>
                {/*</div>
                <div className={classes.stats}>*/}
                    <StatPanel label="Требует реакции" icon={<WarnIcon />} className={classNames(classes.statsItem)} loading={loading}>
                        {ifnn(summary?.count_warnings)}
                    </StatPanel>
                    <StatPanel label="Требуется замена кеги" icon={<KegIcon />} className={classNames(classes.statsItem)} loading={loading}>
                        {ifnn(summary?.keg_replace_needed)}
                    </StatPanel>
                {/*</div>*/}
            </div>
        </div>
    );
}
export default DailyStats;

const styles = (theme) => ({
    headerRow: {
        display: 'flex',
    },
    infoBtn: {
        marginLeft: 8,
        cursor: 'pointer',
    },
    root: {
        marginBottom: 28,
    },
    header: {
        marginBottom: 16,
    },
    cont: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: 24,
        [theme.breakpoints.down('md')]: {
            maxWidth: 700,
        },
    },
    stats: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: 24
    },
    statsItem: {
        minWidth: 276,
        //flex: 1,
        [theme.breakpoints.down('md')]: {
            flex: 1,
        },
    },
    red: {
        color: theme.colors.colors.error,
    },
    lowercase: {
        textTransform: 'lowercase',
    },
    all: {
        color: theme.colors.grey.c100,
        fontSize: 13,
        lineHeight: '16px',
        marginLeft: 12 - 7,
    }
});
const useStyles = makeStyles(styles);
