import React, {useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, TextField, Typography} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {tokensGetBearerToken} from '../../../../redux/reducers/tokens';
import {connect} from 'react-redux';
import {postWithToken} from '../../../../app/rest';
import {t} from '../../../../i18n/utils.js';

function LabelsReplaceDialog({open, device, ptr, onClose, ...props}) {
    const classes = useStyles();

    const [val, setV] = useState('');

    function handleSubmit() {
        console.log(device);
        const devId = device.id;
        //const reason = val.id;
        const token = props.token;

        postWithToken(`/v2/api/groups/${devId}/replace_label`,
            {
                count: val,
            },
            'PUT', token, 'Bearer').then(resp=>resp === 'error'? Promise.reject(resp) : resp).then(
              resp => {
                    onClose();
                },
                err => {
                console.error('ERR', err);
                     alert(t('labelReplaceDialogError'));
               });

    }

    return (
        <Dialog open={open} onClose={onClose} maxWidth={'lg'}>
            <Typography variant="h1" className={classes.heading}>{t('labelReplaceDialogTitle')}</Typography>
            <div className={classes.stripe}>{t('labelReplaceDialogDesc')}</div>
            <DialogContent className={classes.cont}>
                <div className={classes.label}>{t('selectLabelsCount')}</div>

                <div className={classes.bgwrap}>
                    <div>
                        <button className={classes.bt} onClick={()=>{setV(100)}}>100</button>
                        <button className={classes.bt} onClick={()=>{setV(150)}}>150</button>
                        <button className={classes.bt} onClick={()=>{setV(200)}}>200</button>
                        <button className={classes.bt} onClick={()=>{setV(250)}}>250</button>
                    </div>
                </div>

                <TextField
                    label={t('labelsCount')}
                    value={val}
                    onChange={(ev)=>{
                        let val = parseInt(ev.target.value, 10);
                        if (val > 9999) {
                           val = 9999;
                        }
                        setV(val.toString(10));
                    }}
                    type="number"
                    className={classes.numberInput}
                    inputProps={{max: 9999, min: 0, name: 'labelNum'}}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onClose}>{t('cancelBtn')}</Button>
                <Button className={'labelReplaceAccept'} color="primary" variant="contained" disabled={!val || parseInt(val, 10) < 0} onClick={handleSubmit}>{t('applyBtn')}</Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles(theme => ({
    heading: {
        textAlign: "left",
        margin: "24px 0",
        minWidth: 320,
        fontSize: 19,
        lineHeight: '22px',
        fontWeight: 500,
    },
    stripe: {
        background: "rgba(253, 148, 38, 0.1)",
        padding: "16px 32px",
        marginLeft: -24,
        marginRight: -24,
        marginBottom: 32,
        minWidth: 320,

        fontWeight: 500,
        fontSize: "13px",
        lineHeight: "16px", /* identical to box height, or 123% */
        letterSpacing: "0.2px",
        color: "#FD9426",
    },
    label: {
        fontSize: "14px",
        lineHeight: "18px",/* identical to box height */
        letterSpacing: "0.00039375px",
        margin: "16px 0",
    },
    cont: {
        marginBottom: 32,
        textAlign: "center",
    },
    bgwrap: {
        margin: 8,
        "& > *": {
            boxShadow: "none",
        },
    },
    bt: {
        background: "#fff",
        border: 0,
        boxShadow: "none",
        borderRadius: "12px / 50%",
        padding: "8px 16px",
        margin: 8,
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "18px",
        color: "#818C99",

        "&:hover": {
            background: theme.palette.primary.main,
            border: 0,
            boxShadow: "none",
            color: "#fff",
        },
    },
    numberInput: {
        minWidth: 190,
    }
}));

export default LabelsReplaceDialog |> connect(mapStateToProps);

function mapStateToProps(state) {
    return {
        token: tokensGetBearerToken(state),
    };
}
