import React, {useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, Typography} from '@material-ui/core';
import {t} from '../../../i18n/utils.js';

function PromptDialog({open, text, succText, errText, btnText, onClose, onOk, ...props}) {
    const [state, setState] = useState('prompt');
    const [disabled, setDisabled] = useState(false);

    if (state === 'prompt') {
        return (
            <Dialog open={open} onClose={!disabled ? onClose : null}>
                <DialogContent>
                    <Typography>
                        {text}
                    </Typography>
                </DialogContent>

                <DialogActions>
                    <Button disabled={disabled} variant={'outlined'} color={'primary'} onClick={()=>{
                        setDisabled(true);

                        // handle ok
                        props.apiCall().then(
                            succ => {
                                setState('success');
                                setDisabled(false);
                            },
                            err => {
                                setState('error');
                                setDisabled(false);
                            },
                        );


                    }}>{btnText}</Button>
                    <Button  disabled={disabled} variant={'outlined'} onClick={onClose}>{t('noBtnBackup')}</Button>
                </DialogActions>
            </Dialog>
        );
    }

    if (state === 'success') {
        return (
            <Dialog open={open} onClose={onClose}>
                <DialogContent>
                    <Typography>
                        {succText}
                    </Typography>
                </DialogContent>

                <DialogActions>
                    <Button  variant={'outlined'} onClick={onClose}>{t('closeBtnBackup')}</Button>
                </DialogActions>
            </Dialog>
        );
    }

    if (state === 'error') {
        return (
            <Dialog open={open} onClose={onClose}>
                <DialogContent>
                    <Typography>
                        {errText}
                    </Typography>
                </DialogContent>

                <DialogActions>
                    <Button  variant={'outlined'} onClick={onClose}>{t('closeBtnBackup')}</Button>
                </DialogActions>
            </Dialog>
        );
    }

}

export default PromptDialog;
