import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import Keg from '../Keg';
import TapHeader2 from './TapHeader2';

function Tap({tapData, index, className, device, ...props}) {
    const tap = tapData;
    const classes = useStyles();

    const kegs = tap.get('kegs');

    const notifs = device.get('notifications');
    const tapId = tap.get('id');
    const tapNotifs = notifs.filter(n => n.getIn(['module', 'id']) === tapId);

    return (
        <Paper component="section" {...props} className={[classes.root, className].join(' ')}>
            <div className={classes.info}>
                <TapHeader2
                    title={tap.get('title')}
                    isActive={!tap.get('is_blocked')}
                    notifications={tapNotifs}
                    allNotifs={notifs}
                    dateWashed={tap.get('last_wash')}
                    id={tap.get('id')}
                />
            </div>
            <div className={classes.kegs}>
                {kegs.map((keg,kegIndex) =>
                    <Keg key={kegIndex} tap={tap} keg={keg} index={kegIndex} tapIndex={index} device={device} />
                )}
            </div>
        </Paper>
    );
}

const useStyles = makeStyles(theme => ({
    [theme.breakpoints.up('md')]: {
        root: {
            display: "flex",
            padding: "30px 19px 19px 45px",
            marginRight: 0,
        },
        kegs: {
            display: "flex",
            width: "100%",

            "& > *": {
                marginRight: 90,
            }
        },
        title: {
            display: "flex",
            alignItems: "center",
            marginRight: 95,
            flexShrink: 0,
        },
        indicator: {
            position: "relative",
            top: 13,
            marginRight: 10,
        },
        icon: {
            flexShrink: 0,
            marginRight: 10,
        },
        left: {
            flexShrink: 0,
        },
        lastWash: {
            fontSize: 13,
            lineHeight: "15px",
            fontWeight: 300,
        },
        notifCount: {
            fontSize: 13,
            lineHeight: "15px",
            fontWeight: 500,
            color: lvl => lvl === 3 ? theme.palette.red :
                lvl === 2 ? theme.palette.yellow :
                    lvl === 1 ? theme.palette.green :
                        theme.palette.primary.main,
        },
        notifArea: {
            marginTop: 20,
            marginLeft: 8,
            marginBottom: 5,
        },
        info: {
            width: "30%",
        },
    },
    [theme.breakpoints.down('sm')]: {
        indicator: {
            position: "relative",
            top: 13,
            marginRight: 10,
        },
        root: {
            display: "flex",
            marginRight: 0,
        },
        left: {
            display: "flex",
            flexDirection: "column",
            flexShrink: 0,
        },
        title: {
            display: "flex",
            marginBottom: 18,
        },
        kegs: {
            flex: 1,
            marginLeft: 24,
        }
    }
}));

export default Tap;
