import {fromJS, Map} from 'immutable';
import {getWithToken} from '../../app/rest.js';
import {tokensGetBearerToken} from './tokens.js';
import {check401, formatDate} from '../../app/utils.js';

const initialState = new Map({
    summary: null,
    summaryState: 'notLoaded',
    summaryWFilter: null,
    summaryWFilterState: 'notLoaded',
    saleWFilter: null,
    saleWFilterState: 'notLoaded',
    graphWFilter: null,
    graphWFilterState: 'notLoaded',

    saleWFilterLast: null,
});

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'STATS_SUMMARY_LOADING':
            return state.set('summaryState', 'loading');
        case 'STATS_SUMMARY':
            return state.set('summary', fromJS(action.payload))
                .set('summaryState', 'loaded');
        case 'STATS_SUMMARY_W_FILTER_LOADING':
            return state.set('summaryWFilterState', 'loading');
        case 'STATS_SUMMARY_W_FILTER':
            return state.set('summaryWFilter', fromJS(action.payload))
                .set('summaryWFilterState', 'loaded');
        case 'STATS_SALE_W_FILTER_LOADING':
            return state.set('saleWFilterState', 'loading');
        case 'STATS_SALE_W_FILTER':
            return state.set('saleWFilter', fromJS(action.payload))
                .set('saleWFilterLast', fromJS(action.last))
                .set('saleWFilterState', 'loaded');
        case 'STATS_GRAPH_W_FILTER_LOADING':
            return state.set('graphWFilterState', 'loading')
        case 'STATS_GRAPH_W_FILTER':
            return state.set('graphWFilter', fromJS(action.payload))
                .set('graphWFilterState','loaded');
        default:
            return state;
    }
};
export default reducer;

export const statsSummaryGet = (state) => state.getIn(['stats', 'summary']);
export const statsSummaryGetState = (state) => state.getIn(['stats', 'summaryState']);

export const statsSummaryFetch = () => async (dispatch, getState) => {

    const state = getState();
    const token = tokensGetBearerToken(state);

    dispatch({type: "STATS_SUMMARY_LOADING"});

    try {
        const resp = await getWithToken('/v2/api/management/dashboard/summary_info', token, 'Bearer');
        dispatch({type: 'STATS_SUMMARY', payload: resp});
    } catch (err) {
        console.error(err);

        check401(err, dispatch)
    }
}

export const statsSummaryWFGet = (state) => state.getIn(['stats', 'summaryWFilter']);
export const statsSummaryWFGetState = (state) => state.getIn(['stats', 'summaryWFilterState']);

export const statsSummaryWFFetch = (city, store, chains) => async (dispatch, getState) => {

    const state = getState();
    const token = tokensGetBearerToken(state);

    let url = '/v2/api/management/dashboard/summary_info';

    const args = [];
    if (city) {
        args.push('city_id=' + city.id);
    }
    if (store.length > 0) {
        store.map(s => {
            args.push('store_id=' + s.id);
        });
    }
    if (chains.length > 0) {
        chains.map(s => {
            args.push('chain_id=' + s.id);
        });
    }

    if (args) {
        url += '?' + args.join('&');
    }

    dispatch({type: "STATS_SUMMARY_W_FILTER_LOADING"});

    try {
        const resp = await getWithToken(url, token, 'Bearer');
        dispatch({type: 'STATS_SUMMARY_W_FILTER', payload: resp});
    } catch (err) {
        console.error(err);

        check401(err, dispatch)
    }
}

export const statsSaleWFGet = (state) => state.getIn(['stats', 'saleWFilter']);
export const statsSaleWFGetLast = (state) => state.getIn(['stats', 'saleWFilterLast']);
export const statsSaleWFGetState = (state) => state.getIn(['stats', 'saleWFilterState']);

export const statsSaleWFFetch = (range, city, store, chains) => async (dispatch, getState) => {

    const state = getState();
    const token = tokensGetBearerToken(state);

    let args = [];
    if (range.startDate) {
        args.push('date_from=' + formatDate(range.startDate, 'YYYY-MM-DD'));
    }
    if (range.endDate) {
        args.push('date_to=' + formatDate(range.endDate, 'YYYY-MM-DD'));
    }
    if (city) {
        args.push('city_id=' + city.id);
    }
    if (store.length > 0) {
        store.map(s => {
            args.push('store_id=' + s.id);
        });
    }
    if (chains.length > 0) {
        chains.map(s => {
            args.push('chain_id=' + s.id);
        });
    }

    let url = '/v2/api/management/dashboard/statistics';

    if (args.length > 0) {
        url += '?' + args.join('&');
    }

    dispatch({type: "STATS_SALE_W_FILTER_LOADING"});

    try {
        const resp = await getWithToken(url, token, 'Bearer');

        dispatch({type: 'STATS_SALE_W_FILTER', payload: resp});
    } catch (err) {
        console.error(err);

        check401(err, dispatch)
    }
}

export const statsGraphWFGet = (state) => state.getIn(['stats', 'graphWFilter']);
export const statsGraphWFGetState = (state) => state.getIn(['stats', 'graphWFilterState']);

export const statsGraphWFFetch = (range, city, store, chains) => async (dispatch, getState) => {

    const state = getState();
    const token = tokensGetBearerToken(state);

    let args = [];
    if (range.startDate) {
        args.push('date_from=' + formatDate(range.startDate, 'YYYY-MM-DD'));
    }
    if (range.endDate) {
        args.push('date_to=' + formatDate(range.endDate, 'YYYY-MM-DD'));
    }
    if (city) {
        args.push('city_id=' + city.id);
    }
    if (store.length > 0) {
        store.map(s => {
            args.push('store_id=' + s.id);
        });
    }
    if (chains.length > 0) {
        chains.map(s => {
            args.push('chain_id=' + s.id);
        });
    }

    let url = '/v2/api/management/dashboard/graphics';

    if (args.length > 0) {
        url += '?' + args.join('&');
    }

    dispatch({type: "STATS_GRAPH_W_FILTER_LOADING"});

    try {
        const resp = await getWithToken(url, token, 'Bearer');
        dispatch({type: 'STATS_GRAPH_W_FILTER', payload: resp});
    } catch (err) {
        console.error(err);

        check401(err, dispatch)
    }
}
