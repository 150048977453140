import React from 'react';
import classNames from 'classnames';
import {makeStyles} from '@material-ui/core/styles';
import {Button, useMediaQuery, useTheme} from '@material-ui/core';
import {withRouter} from 'react-router-dom';
import LocalizedLink from '../../localization/LocalizedLink';

const styles = theme => ({
    root: {
        padding: 16,
        width: "100%",

        "&:not(:first-child)": {
            borderTopRightRadius: 0,
            borderTopLeftRadius: 0,
            borderTop: "1px solid " + theme.palette.border.main,
        }
    },
    wrap: {
        display: "grid",
        alignItems: "center",
        justifyContent: "flex-start",
        textAlign: "start",
        color: params => theme.palette[params.color],

        ".horizontal &": {
            gridTemplateColumns: "min-content minmax(auto, max-content) max-content",
            gridTemplateAreas: '"icon content date"',
        },

        ".vertical &": {
            gridTemplateColumns: "min-content 1fr",
            gridTemplateAreas: '"icon content" ". date"',
        }
    },
    wrap2: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        textAlign: "start",
        color: params => theme.palette[params.color],

        ".horizontal &": {
            gridTemplateColumns: "min-content minmax(auto, max-content) max-content",
            gridTemplateAreas: '"icon content date"',
        },

        ".vertical &": {
            gridTemplateColumns: "min-content 1fr",
            gridTemplateAreas: '"icon content" ". date"',
        }
    },
    icon: {
        gridArea: "icon",
        display: "inline-block",
        width: 24,
        height: 24,
        flex: "none",
        marginRight: 16,
    },
    content: {
        gridArea: "content",
        fontWeight: 500,
        fontSize: 12,
        lineHeight: 1.33,
        letterSpacing: "0.2px",
},
    date: {
        gridArea: "date",
        color: theme.palette.text.secondary,
        fontSize: 12,
        letterSpacing: "-0.363636px",

        ".horizontal &": {
            marginLeft: 16,
        },

        ".vertical &": {
            marginTop: 4,
        },
    },
});

const useStyles = makeStyles(styles);

/**
 * Renders a notification with an icon, date and some text
 */
function NotificationWidget({className, icon, color, children, date, link, isButton,
                                history, location, match, onClick, component, ...props}) {
    const classes = useStyles({color});

    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

    const alreadyHere = location.pathname === link;

    const Comp = isButton ? Button : LocalizedLink;

    return (
        <Comp
            onClick={onClick}
            component={Button}
            className={classNames(classes.root, className, isDesktop ? 'horizontal' : 'vertical', isButton ? null : classes.wrap2)}
            classes={{label: classes.wrap}}
            disabled={(!link && !isButton) || alreadyHere}
            to={link}
        >
            <div className={classes.icon}>{icon}</div>
            <div className={classes.content}>{children}</div>
            { date !== '-' &&
                <div className={classes.date}>{date}</div>
            }
        </Comp>
    );
}

export default NotificationWidget |> withRouter;
