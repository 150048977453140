import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import StatusIndicator from '../../../componets/StatusIndicator';
import {Typography} from '@material-ui/core';
import ProgressBar from '../../../componets/ProgressBar';
import {ReactComponent as PrinterIcon} from '../../../svg/printer.svg';
import {t} from '../../../i18n/utils.js';

function Printer({printerData, index, className, device, ...props}) {

    const ptr = printerData?.toJS();
    const notifications = device.get('notifications')?.toJS();

    const ptrNotifs = notifications.filter(n => n.module.type === ptr.type && n.module.id === ptr.id);
    const ptrStatus = ptrNotifs.reduce((acc, n) => {
        const warnLvl = n.type === 'Error' ? 3 :
                        n.type === 'Warn' ? 2 :
                            1;
        return warnLvl > acc ? warnLvl : acc;
    }, 1);

    const classes = useStyles(ptrStatus);

    const remained = ptr.labels_remained;
    const max = ptr.labels_max;
    const percentRemained = remained > 0 ? Math.round(remained / max * 100) : 0;

    return (
        <Paper component="section" {...props} className={[classes.root, className].join(' ')}>
            <StatusIndicator className={classes.indicator} lvl={ptrStatus} />
            <div className={classes.left}>
                <div className={classes.title}>
                    <PrinterIcon className={classes.icon}/>
                    <Typography variant="h2">{ptr.title}</Typography>
                </div>
                <div className={classes.pbar}>
                    <div className={classes.pbarLabel}>
                        {t('labelsAofB', {a: remained, b: max})}
                    </div>
                    <ProgressBar value={percentRemained} />
                </div>
            </div>
        </Paper>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        ".disconnected & *": {
            color: "#c1c1c1" + " !important",
        },
    },

    [theme.breakpoints.up('md')]: {
        root: {
            display: "flex",
            padding: "30px 140px 19px 45px",
            marginRight: 0,
        },
        kegs: {
            display: "flex",
        },
        title: {
            display: "flex",
            alignItems: "center",
            marginRight: 95,
            flexShrink: 0,
        },
        indicator: {
            position: "relative",
            top: 13,
            marginRight: 10,
        },
        icon: {
            flexShrink: 0,
            marginRight: 10,
        },
        left: {
            flex: 1,
        },
        lastWash: {
            fontSize: 13,
            lineHeight: "15px",
            fontWeight: 300,
        },
        pbarLabel: {
            textAlign: "right",
            marginBottom: 12,
        },
        pbar: {
            position: "relative",
            top: -8,
        }
    },


    [theme.breakpoints.down('sm')]: {
        icon: {
            flexShrink: 0,
            marginRight: 10,
        },
        pbarLabel: {
            display: "none",
        },
        indicator: {
            position: "relative",
            top: 13,
            marginRight: 10,
        },
        root: {
            display: "flex",
            marginRight: 0,
        },
        left: {
            display: "flex",
            flexDirection: "column",
            flexShrink: 0,
            flex: 1,
            paddingRight: 24,
        },
        title: {
            display: "flex",
            marginBottom: 18,
            alignItems: "center",
            flexShrink: 0,
        },
        kegs: {
            flex: 1,
            marginLeft: 50,
        },
        pbar: {
            position: "relative",
            top: -8,
            width: "100%",
        }
    }
}));

export default Printer;
