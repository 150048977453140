import React, {useState} from 'react';
import {Container, ThemeProvider} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import HomeIcon from '../../icons/HomeIcon';
import ChartIcon from '../../icons/ChartIcon';
import ManageIcon from '../../icons/ManageIcon';
import InfoIcon from '../../icons/InfoIcon';
import {useSelector} from 'react-redux';
import {userInfo} from '../../redux/reducers/user';
import {checkPermission, tokensGetAccessToken} from '../../redux/reducers/tokens';
import {getLoadedLanguage} from '../../redux/reducers/locale';
import {adminLink, prefixPath} from '../../app/utils';
import {t} from '../../i18n/utils.js';
import Sidebar, {sidebarWidth} from '../../pages/common/Sidebar.js';
import {theme} from '../../pages/Dashboard/theme.js';

const PageLayoutDesktop = props => {
    const classes = useStyles();
    const [profileMenu, setProfileMenu] = useState(false);
    const profileTabRef = React.useRef(null);

    const user = useSelector(userInfo);
    const username = user?.get('full_name')?.trim() || user?.get('username');

    const { children } = props;

    const currentDomain = window.location.hostname;
    const isProduction = currentDomain === 'sm.npmgroup.ru'; // TODO: change to compile option
    const supportMail = isProduction ? 'beerbox3@npmgroup.ru' : 'ivan.ryzhikov@npmgroup.ru'
    const supportLink = `mailto:${supportMail}?subject=${t('supportMailSubj')}`

    const token = useSelector(tokensGetAccessToken);

    const locale = useSelector(getLoadedLanguage);

    const menuItems = [
        {
            label: t('mainMenu_stores'),
            icon: <HomeIcon/>,
            disabled: false,
            href: prefixPath('/stores', locale),
        },
        {
            label: t('mainMenu_reports'),
            icon: <ChartIcon/>,
            disabled: !useSelector(checkPermission('section_reports')),
            href: prefixPath('/reports', locale),
        },
        {
            label: t('mainMenu_admin'),
            icon: <ManageIcon/>,
            disabled: !useSelector(checkPermission('section_service')),
            href: adminLink(token, '/admin/', locale),
        },
        {
            label: t('mainMenu_support'),
            icon: <InfoIcon/>,
            disabled: !useSelector(checkPermission('section_help')),
            href: supportLink,
        },
    ];

    const profileBtnLabel = username || t('mainMenu_profile');

    const tabClasses = {
        root: classes.tab,
        selected: classes.tabActive,
    };

    let activeTab = 0;
    if (props.url === 'reports') {
        activeTab = 1;
    }

    return (
        <Container disableGutters className={classes.container} maxWidth={props.noContainer ? false : 'xl'}>

            {/*
            <StackPaper component="header" id="header" className={classes.header}>
                <div className={classes.headerLogo}>
                    <Logo />
                </div>
                <div className={classes.headerMenu}>
                    <Tabs
                        classes={{
                            indicator: classes.tabIndicator,
                        }}
                        centered
                        value={activeTab}>
                        {menuItems.map((menuOpt, index) =>
                                !menuOpt.disabled &&
                                <Tab key={index} {...menuOpt} classes={tabClasses} />
                        )}
                    </Tabs>
                </div>
                <div className={classes.headerProfile}>
                    <IconButton frRef={profileTabRef} icon={<UserIcon/>}
                                onClick={() => {setProfileMenu(true)}}>
                        <span className={classes.profileBtnLabel}>{profileBtnLabel}</span>
                    </IconButton>
                </div>
                <ProfileMenu open={profileMenu} anchorRef={profileTabRef} onClose={() => {setProfileMenu(false);}} />
            </StackPaper>
            */}

            <main id="main" className={classes.main}>
                {children}
            </main>

            <ThemeProvider theme={theme}>
                <Sidebar />
            </ThemeProvider>
        </Container>
    );
}

export default PageLayoutDesktop;

const styles = theme => ({
    main: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
    },
    header: {
        zIndex: 50,
        display: "flex",
        padding: theme.spacing(2, 0, 0),
        height: 110,
    },
    headerLogo: {
        flex: 0,
        margin: "28px 20px",
    },
    headerMenu: {
        flex: 1,
    },
    headerProfile: {
        flex: 0,
        margin: "0 20px",
    },
    profileBtnLabel: {
        color: "#b2b2b2",
        textDecoration: "underline",
    },
    tabIndicator: {
        bottom: "unset",
        top: 0,
        backgroundColor: theme.palette.primary.main,
    },
    tab: {
        fontSize: 14,
        fontWeight: "normal",
        textTransform: "capitalize",
        opacity: 1,
        lineHeight: "16px",
        color: theme.palette.text.primary,
        minWidth: 98,
        margin: "0 8px",
    },
    tabActive: {
        color:  theme.palette.primary.main,
    },

    container: {
        paddingLeft: sidebarWidth,
    }
})

const useStyles = makeStyles(styles);
