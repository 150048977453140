import React, {useEffect, useState} from 'react';
import {IconButton, Paper, Typography} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DevicePageWrap from './DevicePageWrap';
import StatusIndicator from '../../../componets/StatusIndicator';
import {useDispatch, useSelector} from 'react-redux';
import {
    getActiveSections,
    getSectionsList,
    sectionDetailsFetch,
    sectionsFetchList, wsChange,
} from '../../../redux/reducers/sections';
import SectionsListWidget from './SectionsListWidget';
import ParamsDialog from './ParamsDialog';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import {checkPermission, tokensGetAccessToken, tokensGetBearerToken} from '../../../redux/reducers/tokens';
import {apiChangeItem} from '../../../app/api';
import ItemChangeDialog from './ItemChangeDialog';
import Item from './Item';
import classNames from 'classnames';
import TimeGroup from '../SettingsPage/TimeGroup';
import DoubleScrollbar from 'react-double-scrollbar';
import SettingsIcon from '@material-ui/icons/Settings';
import ServiceModeDialog from './ServiceModeDialog';
import ArrowNavigation from '../SettingsPage/ArrowNavigation';
import {t} from '../../../i18n/utils.js';
import {useParams} from 'react-router-dom';
import WebSocketWrap from '../../../componets/WebSocketWrap.js';
import {getWithToken} from '../../../app/rest.js';

function RenderGroup({group, device, secId, isDisconnected, ...props}) {
    const classes = useStyles();
    const [showGrpParams, setShowGrpParams] = useState(false);

    let grpColor = 1;

    console.log('GRP', group);

    const grouptitle = t('params');

    return (
        <Paper className={classes.group}>
            <div className={classes.groupHeader}>
                <StatusIndicator lvl={grpColor} className={classes.indic} />
                <div className={classes.groupTitle}>
                    {group?.params && group?.params?.length > 0  ? (
                    <a className={classes.groupLink} onClick={(ev) => {
                        ev.preventDefault();
                        setShowGrpParams(true);
                    }}>{grouptitle}</a>) : (
                        grouptitle
                    )}
                </div>
            </div>
            <div className={classes.items}>
                {Array.isArray(group.items) && group.items?.map((item, index) => {
                    return (
                        <>
                            <Item secId={secId} key={index} item={item} options={item.options} isDisconnected={isDisconnected} />
                        </>
                    );
                })}
                {!Array.isArray(group.items) && (
                    <div>{t('washDialogError')}</div>
                )}
            </div>
            <ParamsDialog device={device} open={showGrpParams} secId={secId} grp={group} isDisconnected={isDisconnected} onCloseFn={()=>{
                setShowGrpParams(false);
            }} />
        </Paper>
    );
}

export const SectionContext = React.createContext(null);

function RenderSection({section, device, isDisconnected, ...props}) {
    const classes = useStyles();

    console.log('SECT', section);

    const grp = section.data;

    return (
        <SectionContext.Provider value={section}>
            <div className={classes.section}>
                <div className={classes.sectionTitle}>
                    {section.name}
                </div>
                <div className={classes.groups}>
                    <RenderGroup device={device} group={grp} isDisconnected={isDisconnected} secId={section.id} />
                </div>
            </div>
        </SectionContext.Provider>
    );
}

function ElementsPage({device, pageList, currentPage, ...props}) {
    const classes = useStyles();

    const showServiceModeBtn = device.get('type') !== 'Smart';
    const serviceModeOn = showServiceModeBtn ? device.getIn(['serviceMode', 'enabled']) : true;

    const token = useSelector(tokensGetBearerToken);
    const dispatch = useDispatch();

    /*
    useEffect(() => {
        getWithToken(`/v2/api/groups/${deviceId}/service`, token, 'Bearer').then(r => {
            console.log('SERVICE MODE', r, deviceId);
            dispatch({type: 'DEVICE_DETAILS_SERVICE_MODE', enabled: r.enabled, enabledTo: r.enabled_to, id: deviceId});
        });
    }, []);
     */

    const [showServiceModeDialog, setShowServiceModeDialog] = useState(showServiceModeBtn && !serviceModeOn);

    const canSetTime = useSelector(checkPermission('access_to_change_working_time'));
    //const [activeSections, setActiveSections] = useState([]);

    const activeSectionsImm = useSelector(getActiveSections);
    const activeSections = activeSectionsImm?.toJS() || [];
    const setActiveSections = (val) => {dispatch({type: 'SET_ACTIVE_SECTIONS', val: val})};

    const sectionsImmutable = useSelector(getSectionsList);
    let sections = sectionsImmutable?.toJS() || [];

    sections = sections.sort((a, b) => {
        const aTap = a.name?.startsWith('Tap') || a.name?.startsWith('Кран');
        const bTap = b.name?.startsWith('Tap') || b.name?.startsWith('Кран');

        if (aTap && bTap) {
            return 0;
        }

        if (!aTap && !bTap) {
            return 0;
        }

        if (aTap && !bTap) {
            return -1;
        }

        if (!aTap && bTap) {
            return 1;
        }

        return 0;
    })

    const elementsWriteAccess = useSelector(checkPermission('access_to_write_control_panel'));

    const indicators = device.get('indicators');
    const connection = indicators?.find(i => i.get('type') === 'connection');
    let isDisconnected = !connection?.get('value');

    if (!elementsWriteAccess) {
        isDisconnected = true;
    }

    const deviceId = device.get('id');

    const params = useParams();
    const storeId = params.storeId;
    const groupId = params.deviceId;
    //console.log(params);

    useEffect(()=>{
        dispatch(sectionsFetchList(storeId, groupId));
    }, []);

    useEffect(()=>{
        // filter active sections and not loaded
        sections.filter(sec => activeSections?.includes(sec.id) && !sec.data).map(
            actsec => {
                // load it
                dispatch(sectionDetailsFetch(groupId, actsec.id));
            }
        );
    }, [sections, activeSections]);

    if(!sections) {
        return (
            <DevicePageWrap device={device}>
                <Paper className={classes.root}>
                    Loading...
                </Paper>
            </DevicePageWrap>
        );
    }

    //console.log(sections);
    const prevLink = `/stores/${deviceId}/settings/`;
    const nextLink = `/stores/${deviceId}/log/`;


    const serviceModeBtnText = !serviceModeOn ? t('serviceMode_btnTitle1') : t('serviceMode_btnTitle2');

    function onMessage(msg) {


        const m = JSON.parse(msg.data);

        if (m.key) {
            console.log('WS', m.key, m.value);
            dispatch(wsChange(m.key, m.value));
        }

        if (m.enabled_to || m.hasOwnProperty('enabled_to')) {
            dispatch({type: 'DEVICE_DETAILS_SERVICE_MODE', enabled: m.enabled, enabledTo: m.enabled_to, id: deviceId});
        }
    }

    return (
        <DevicePageWrap device={device}>
        <WebSocketWrap  url={`/v2/ws/groups/${groupId}`} onMessage={onMessage}>
            <Paper className={classNames([classes.root, {"disabled": isDisconnected}])}>
                <div className={classes.header}>
                    <Typography variant={"h1"}>{t('elementsHeading')}</Typography>
                    <ArrowNavigation pageList={pageList} currentPage={currentPage} />
                    <div className={classes.spacer}></div>
                    <div>
                        { showServiceModeBtn &&
                            <div title={serviceModeBtnText}>
                                <IconButton disabled={serviceModeOn} onClick={()=>{
                                    setShowServiceModeDialog(true);
                                }}>
                                    <SettingsIcon/>
                                </IconButton>
                            </div>
                        }
                    </div>
                </div>

                <ServiceModeDialog open={showServiceModeDialog} onClose={()=>{
                    setShowServiceModeDialog(false);
                }} device={device}/>

                <SectionsListWidget sections={sections} activeSections={activeSections} setActiveSections={setActiveSections}  />

                <>
                    <div className={classes.sectionsWidgets}>
                            {sections.map((sec, index) => {
                                if (activeSections?.includes(sec.id) && sec.data) {
                                        return (
                                            <RenderSection device={device} key={index} section={sec}
                                                           isDisconnected={isDisconnected || !serviceModeOn}/>
                                        );
                                }
                            })}
                    </div>
                </>
            </Paper>
        </WebSocketWrap>
        </DevicePageWrap>
    );
}

export default ElementsPage;

const useStyles = makeStyles(theme => ({
    spacer: {
        flex: 1,
    },
    root: {
        margin: 8,
        padding: 25,
    },
    section: {
        padding: 4,
        //marginLeft: -4,
    },
    sectionTitle: {
        fontSize: '21px',
        marginBottom: 16,
    },
    groups: {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
    },
    group: {
        display: "inline-block",
        //background: "#6a9d80",
        padding: 16,
        //color: "#fff",
        borderRadius: 8,
        maxWidth: 350,
        minWidth: 270,
        width: "100%",
    },
    header: {
        display: "flex",
        alignItems: "center",
        gap: "16px",
        flexWrap: 'wrap',
    },
    groupLink: {
        textDecoration: 'underline',
        cursor: "pointer",
        padding: "4px",

        "&:hover": {
            color: "#fff",
            background: 'rgba(0,0,0,.26)!important',
            borderRadius: "8px",
        },
    },
    groupTitle: {

    },
    groupHeader: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        fontSize: '16px',
        marginBottom: '1em',
    },
    item: {
        display: 'flex',
        gap: '8px',
        marginBottom: "4px",
        padding: "4px",
        alignItems: "center",

        "&:nth-child(2n)": {
            background: "rgba(255,255,255,0.1)",
        }
    },
    itemTitle: {
        flex: 1,
    },
    itemWidget: {
        flex: 1,
        textAlign: "center",
    },
    sectionsWidgets: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '16px',
        //overflow: "auto",
    },
    itemEditable: {
        border: 0,
        background: "transparent",
        color: "#646464",
        cursor: "pointer",

        "&:hover": {
            color: "#fff",
            background: "#646464",
            borderRadius: "8px",
        },
    },
    items: {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px 0',
    },
    indic: {
        //boxShadow: '0px 0px 6px 1px #0c7715',
        borderRadius: '100%',
    },
}));
