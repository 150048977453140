import React, {useContext, useEffect, useState} from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {tokensGetAccessToken, tokensGetBearerToken} from '../../../../redux/reducers/tokens';
import {connect, useDispatch, useSelector} from 'react-redux';
import {postWithToken} from '../../../../app/rest';
import {DatePicker} from '@material-ui/pickers';
import {getProducersList, producersFetchList} from '../../../../redux/reducers/producers';
import {brandsFetchList, getBrandsList} from '../../../../redux/reducers/brands';
import {formatDate} from '../../../../app/utils';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import {getLoadedLanguage as getSavedLocale} from '../../../../redux/reducers/locale';
import {t} from '../../../../i18n/utils.js';
import {LocaleContext} from '../../../../i18n/LocaleContext.js';

function KegReplaceDialog({open2, device, onClose, altUrl, only_change, ...props}) {
    const classes = useStyles();
    const localeObj = useContext(LocaleContext);
    const locale = useSelector(getSavedLocale);



    const dispatch = useDispatch();

    const keg = props.keg?.keg;
    const isNew = props.new;

    const tapData = props.tapData;
    console.log(tapData);
    const kegsData = tapData?.info?.kegs;
    const kegData = kegsData?.find(k => k.id === keg?.get('id'));
    console.log(kegData);
    const brandId = kegData?.brand_id ? Number(kegData?.brand_id) : null;
    const producerId = kegData?.producer_id ? Number(kegData?.producer_id) : null;

    const [valProd, setVProd] = useState(null);
    const [kegVol, setKegVol] = useState(30);
    const [remainVol, setRemainVol] = useState(30);
    const [notValid, setNotValid] = useState(false);

    const token = useSelector(tokensGetAccessToken);


    const isSmart = isNew && props.isSmart;
    //console.log(props);

    const producerOptions = useSelector(getProducersList)?.toJS();
    const producersFetching  = useSelector(state => state.getIn(['producers', 'isFetching']));

    const brandsOptions = useSelector(getBrandsList)?.toJS();
    const brandsFetching  = useSelector(state => state.getIn(['brands', 'isFetching']));

    const badClean = keg.get('bad_clean') === true;
    //console.log('BCC', keg.get('bad_clean'));

    /*
    useEffect(() => {
        setV(brandsOptions?.find(b => b.id === brandId));
    }, [brandsOptions, brandId]);*/

    /*
    useEffect(() => {
        setVProd(producerOptions?.find(b => b.id === producerId));
    }, [producerOptions, producerId]);*/

    const fetchProducers = () => {
        dispatch(producersFetchList());
    }

    const fetchBrands = () => {
        dispatch(brandsFetchList());
    }

    useEffect(()=>{
        if (open2) {
            fetchBrands();
            fetchProducers();
        }
    }, [open2]);


    const [val, setV] = useState(null);
    const [selectedDate, setSelectedDate] = React.useState(null);

    function onClose2(v) {
        setV(null);
        setVProd(null);
        setSelectedDate(null);
        onClose(v);
    }

    const prevVolItem = keg.getIn(['statistics', 'keg_volume']);
    const units = prevVolItem?.get('unit');
    const prevVol = prevVolItem?.get('value');

    let sendCoef  = units === 'liters' ? 1000 : 1;

    const optionsLiter = [
        {
            val: 10,
            text: t('litres', {litres: 10}),
        },
        {
            val: 20,
            text: t('litres', {litres: 20}),
        },
        {
            val: 30,
            text: t('litres', {litres: 30}),
            isDef: true,
        },
        {
            val: 40,
            text: t('litres', {litres: 40}),
        },
    ];
    const optionsOz = [
        {
            val: 661,
            text: t('oz', {litres: 661}),
        },
        {
            val: 992,
            text: t('oz', {litres: 992}),
            isDef: true,
        },
        {
            val: 1984,
            text: t('oz', {litres: 1984}),
        },
    ];

    const options = units === 'liters' ? optionsLiter : optionsOz;

    useEffect(()=>{
        // определяем дефолтный вариант
        let defOption = 0;
        const markedDef = options.find(o => o.isDef)?.val;
        if (markedDef) {
            defOption = markedDef;
        }

        if (prevVol) {
            const contains = options.filter(o => o.val === prevVol);
            if (contains) {
                defOption = prevVol;
            }
        }

        setKegVol(defOption);
        setRemainVol(defOption);
    }, []);

    function handleSubmit() {
        const devId = device.get('id');
        const kegId = keg.get('id');
        const producerId = valProd.id;
        const brandId = val.id;

        const prodDate = formatDate(selectedDate, 'DD.MM.yyyy');
        const token = props.token;

        const endpoint = altUrl ? altUrl : '/v2/api/devices/replace_keg';

        const body =             {
                //device: devId,
                keg_id: kegId,
                produce_date: prodDate,
                producer_id: producerId,
                brand_id: brandId,
                only_change_drink_info: only_change,
            };

        if (isSmart) {
            body['volume_keg'] = kegVol * sendCoef;
            body['new_remained_volume'] = parseFloat(remainVol) * sendCoef;
        }

        postWithToken(endpoint,
            body,
            'PUT', token, 'Bearer').then(resp=>resp === 'error'? Promise.reject(resp) : resp).then(
              resp => {
                    onClose2(true);
                },
                err => {
                     alert(t('kegReplaceDialogError'));
               });

    }

    // const adminlink = (
    //     <a className={classes.adminlink} href={adminLink(token, '/admin/brands/brand/add/', locale)}>
    //         {t('addNewDrink')}
    //     </a>
    // );

    const adminlink = (
        <div className={classes.adminlink}>
        </div>
    );

    //const addStoreLink = `/${locale.code}/adm/sellers/store/add/`;

    let brandsOptionsDropdown;
    if (brandsOptions && !brandsFetching) {
        brandsOptionsDropdown = brandsOptions;

        /*
        if (valProd) {
            brandsOptionsDropdown = brandsOptions.filter(b => b.producers.includes(valProd.id));
        }*/
    }
     else {
        brandsOptionsDropdown = [];
    }

    let producersOptionsDropdown;
    if (producerOptions) {
        producersOptionsDropdown = producerOptions;
    } else {
        producersOptionsDropdown = [];
    }

    if (val) {
        producersOptionsDropdown = val.producers_list || [];
        //console.log(val);

        if(producersOptionsDropdown.length === 1 && !valProd) {
            setVProd(producersOptionsDropdown[0]);
        }
    }

    useEffect(() => {
        if (!val && brandsOptions?.length > 0) {
            const defSelection = brandsOptions?.find(b => b.id === brandId);

            //alert('fsdefgdfg');
            console.log('BBQ1', brandsOptions, brandId, defSelection);
            setV(defSelection);
        }
    }, [brandsOptions, brandId]);

    console.log('preBBQ2', producersOptionsDropdown, producerId, valProd);
    useEffect(() => {
        if (!valProd) {
            const defSelection = producersOptionsDropdown?.find(b => b.id === producerId);
            //console.log('BBQ2', producersOptionsDropdown, producerId, defSelection);
            setVProd(defSelection);
        }
    }, [producersOptionsDropdown, producerId]);

    const validVolume = isSmart ? remainVol && remainVol >= 0 && remainVol <= kegVol : true;

    const maxDecimal = units === 'liters' ? 2 : 0;

    const dateFormat = localeObj.dateFormatForPicker;

    return (
        <Dialog open={open2} onClose={()=>{onClose2(false)}} maxWidth={"md"} classes={{paper: classes.paper}}>
            <Typography variant="h1" className={classes.heading}>{t('kegReplaceDialogHeading', {keg: keg.get('title')})}</Typography>
            {badClean && <div className={classes.stripe}>{t('bad_clean')}</div>}
            <DialogContent className={classes.cont}>
                <div className={classes.label}>{t('selectDrink')}</div>
                <Autocomplete
                    options={brandsOptionsDropdown}
                    getOptionLabel={(options) => options?.name}
                    getOptionSelected={(option, value) => option?.id === value?.id}
                    renderInput={params =>
                        <TextField {...params} variant="outlined" />
                    }
                    value={val}
                    onChange={(e,v)=>{
                        setV(v);

                        if (!v || v.id !== val?.id) {
                            setVProd(null);
                            setSelectedDate(null);
                        }
                    }}
                    noOptionsText={t('noDataFilter')}
                    loadingText={t('loadingFilter')}
                    loading={brandsOptions === null || brandsFetching}
                />
                {adminlink}

                <div className={classes.label}>{t('selectProducer')}</div>
                <Autocomplete
                    options={producersOptionsDropdown}
                    getOptionLabel={(options) => options?.name}
                    getOptionSelected={(option, value) => option?.id === value?.id}
                    renderInput={params =>
                        <TextField {...params} variant="outlined" />
                    }
                    value={valProd || null}
                    onChange={(e,v)=>{
                        console.log(v);
                        setVProd(v);
                    }}
                    loading={producerOptions === null || producersFetching}
                    noOptionsText={t('noDataFilter')}
                    loadingText={t('loadingFilter')}
                />
                <div className={classes.sep} />
                <div>
                    <div className={classes.label}>{t('selectCreationDate')}</div>
                    <DatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        format={dateFormat}
                        value={selectedDate}
                        onChange={(event)=>{
                            setSelectedDate(event);
                        }}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        maxDate={new Date()}
                        okLabel={t('applyBtn')}
                        cancelLabel={t('cancelBtn')}
                        InputProps={{
                            endAdornment: (
                                <CalendarTodayIcon />
                            ),
                        }}
                    />
                </div>

                {isSmart ?
                    <React.Fragment>
                        <div className={classes.sep}/>
                        <div className={classes.row}>
                            <div>
                                <div className={classes.label}>{t('kegVol')}</div>
                                <Select value={kegVol} variant="outlined" fullWidth onChange={(ev)=>{
                                    const val = ev.target.value;
                                    setKegVol(val);
                                    setRemainVol(val);
                                }
                                }>
                                    {options.map(o => {
                                        return (
                                            <MenuItem value={o.val}>{o.text}</MenuItem>
                                        );
                                    })}
                                </Select>
                            </div>
                            <div>
                                <div className={classes.label}>{t('volRemain')}</div>
                                <TextField error={notValid} type="number" variant="outlined" value={remainVol} fullWidth onChange={(ev) =>{
                                    const valStr = ev.target.value;

                                    if (valStr === '') {
                                        setRemainVol(valStr);
                                        setNotValid(true);
                                    }

                                    // validating isnumber, min, max
                                    let val = parseFloat(valStr);
                                    if (isNaN(val) || val < 0 || val > kegVol) {
                                        setNotValid(true);
                                        return;
                                    }

                                    // validation decimal
                                    val = parseFloat(val.toFixed(maxDecimal));

                                    setNotValid(false);
                                    setRemainVol(val);
                                }} InputProps={{ inputProps: { min: 0, max: kegVol, step: (1/10**maxDecimal) } }}/>
                            </div>
                        </div>
                    </React.Fragment>
                    : null
                }
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={()=>{onClose2(false)}}>{t('cancelBtn')}</Button>
                <Button color="primary" variant="contained" disabled={!valProd || !val || !selectedDate || !validVolume } onClick={handleSubmit} id={"acceptKegReplace"}>
                    {t('selectBtn')}
                </Button>

            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles(theme => ({
    heading: {
        textAlign: "left",
        margin: "24px 0",
        minWidth: 320,
        fontSize: 19,
        lineHeight: '22px',
        fontWeight: 500,
    },
    adminlink: {
        display: "inline-block",
        color: "#176BFB",
        textDecoration: "underline",
        marginTop: 16,
    },
    stripe: {
        background: "rgba(253, 148, 38, 0.1)",
        padding: "16px 32px",
        marginLeft: -24,
        marginRight: -24,
        marginBottom: 32,
        minWidth: 320,

        fontWeight: 500,
        fontSize: "13px",
        lineHeight: "16px", /* identical to box height, or 123% */
        letterSpacing: "0.2px",
        color: "#FD9426",
    },
    label: {
        fontSize: "12px",
        lineHeight: "15px",/* identical to box height */
        letterSpacing: "0.00039375px",
        marginBottom: 8,
    },
    cont: {
        //marginBottom: 32,
    },
    paper: {
        width: 470,
    },
    sep: {
        marginBottom: 32,
    },
    row: {
        display: "flex",
        gap: "16px",

        "& > *": {
            flex: 1,
        }
    }
}));

export default KegReplaceDialog |> connect(mapStateToProps, mapDispatchToProps);

function mapStateToProps(state) {
    return {
        token: tokensGetBearerToken(state),
        producers: getProducersList(state),
        brands: [],
    };
}

function mapDispatchToProps(dispatch) {
    return {
        producersFetch: () => dispatch(producersFetchList()),
    };
}
