import React from 'react';
import {Paper, Typography} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {useDispatch, useSelector} from 'react-redux';
import {getActiveSections} from '../../../redux/reducers/sections';
import SectionsListWidget from './SectionsListWidget';
import {checkPermission} from '../../../redux/reducers/tokens';
import classNames from 'classnames';
import TimeGroup from '../SettingsPage/TimeGroup';
import DoubleScrollbar from 'react-double-scrollbar';

import DevicePageWrap from '../ElementsPage/DevicePageWrap';
import ArrowNavigation from './ArrowNavigation';
import {t} from '../../../i18n/utils.js';
import BackupGroup from './BackupGroup.js';

export const SectionContext = React.createContext(null);

function RenderSection({section, device, isDisconnected,  pageList, currentPage,...props}) {
    const classes = useStyles();

    //console.log('SECT', section);

    return (
        <SectionContext.Provider value={section}>
            <div className={classes.section}>
                <div className={classes.sectionTitle}>
                    {section.title}
                </div>
                <div className={classes.groups}>
                    {(section.custom && section.content) ?
                        section.content(isDisconnected) : null
                    }
                </div>
            </div>
        </SectionContext.Provider>
    );
}

function SettingsPage({device, pageList, currentPage, ...props}) {
    const classes = useStyles();

    const dispatch = useDispatch();
    const canSetTime = useSelector(checkPermission('access_to_change_working_time'));
    //const [activeSections, setActiveSections] = useState([]);

    const activeSections = useSelector(getActiveSections)?.toJS() || [];
    //const activeSections = activeSectionsImm?.toJS() || [];

    console.log('ACTIVESESSION', useSelector(getActiveSections)?.toJS());

    const setActiveSections = (val) => {dispatch({type: 'SET_ACTIVE_SECTIONS', val: val})};

    const elementsWriteAccess = useSelector(checkPermission('access_to_write_control_panel'));

    const pouringSettingsSec = {
        id: 'pour_settings',
        name: t('pourSettings'),
        data: {
            title: t('pourSettings'),
            custom: true,
            content: (isDisabled) => (<TimeGroup device={device} isDisconnected={isDisabled || !canSetTime}/>),
        }
    };

    let sections = [pouringSettingsSec];

    const isBeerbox = device.get('type') !== 'smart' && device.get('type') !== 'Smart';

    if (isBeerbox) {
        const copyParamsSec = {
            id: 'copy_settings',
            name: t('copySettings'),
            data: {
                title: t('copySettings'),
                custom: true,
                content: (isDisabled) => (<BackupGroup device={device} isDisconnected={isDisabled || !canSetTime}/>),
            }
        };

        sections.push(copyParamsSec);
    }

    const indicators = device.get('indicators');
    const connection = indicators?.find(i => i.get('type') === 'connection');
    let isDisconnected = !connection?.get('value');

    if (!elementsWriteAccess) {
        isDisconnected = true;
    }

    const deviceId = device.get('id');

    if(!sections) {
        return (
            <DevicePageWrap device={device}>
                <Paper className={classes.root}>
                    Loading...
                </Paper>
            </DevicePageWrap>
        );
    }

    //console.log(sections);
    const prevLink = null;
    const nextLink = `/stores/${deviceId}/elements/`;

    return (
        <DevicePageWrap device={device}>
            <Paper className={classNames([classes.root, {"disabled": isDisconnected}])}>
                <div className={classes.header}>
                    <Typography variant={"h1"}>{t('settingsHeading')}</Typography>
                    <ArrowNavigation pageList={pageList} currentPage={currentPage} />
                </div>

                <SectionsListWidget sections={sections} activeSections={activeSections} setActiveSections={setActiveSections}  />

                <DoubleScrollbar>
                    <div className={classes.sectionsWidgets}>
                        {sections.map((sec, index) => {
                            if (activeSections?.includes(sec.id) && sec.data) {
                                return (
                                    <RenderSection device={device} key={index} section={sec.data}
                                                   isDisconnected={isDisconnected}/>
                                );
                            }
                        })}
                    </div>
                </DoubleScrollbar>
            </Paper>
        </DevicePageWrap>
    );
}

export default SettingsPage;

const useStyles = makeStyles(theme => ({
    root: {
        margin: 8,
        padding: 25,
    },
    section: {
        padding: 4,
        //marginLeft: -4,
    },
    sectionTitle: {
        fontSize: '21px',
        marginBottom: 16,
    },
    groups: {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
    },
    group: {
        display: "inline-block",
        //background: "#6a9d80",
        padding: 16,
        //color: "#fff",
        borderRadius: 8,
        maxWidth: 350,
        minWidth: 270,
        width: "100%",
    },
    header: {
        display: "flex",
        alignItems: "center",
        gap: "16px",
    },
    groupLink: {
        textDecoration: 'underline',
        cursor: "pointer",
        padding: "4px",

        "&:hover": {
            color: "#fff",
            background: 'rgba(0,0,0,.26)!important',
            borderRadius: "8px",
        },
    },
    groupTitle: {

    },
    groupHeader: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        fontSize: '16px',
        marginBottom: '1em',
    },
    item: {
        display: 'flex',
        gap: '8px',
        marginBottom: "4px",
        padding: "4px",
        alignItems: "center",

        "&:nth-child(2n)": {
            background: "rgba(255,255,255,0.1)",
        }
    },
    itemTitle: {
        flex: 1,
    },
    itemWidget: {
        flex: 1,
        textAlign: "center",
    },
    sectionsWidgets: {
        display: 'flex',
        gap: '16px',
        //overflow: "auto",
    },
    itemEditable: {
        border: 0,
        background: "transparent",
        color: "#646464",
        cursor: "pointer",

        "&:hover": {
            color: "#fff",
            background: "#646464",
            borderRadius: "8px",
        },
    },
    items: {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px 0',
    },
    indic: {
        //boxShadow: '0px 0px 6px 1px #0c7715',
        borderRadius: '100%',
    },
}));
