import React, {useContext, useState} from 'react';
import classNames from 'classnames';
import NotificationWidget from './NotificationWidget';
import {makeStyles} from '@material-ui/core/styles';
import NotificationsCounter from './NotificationsCounter';
import {formatDate, getIcon, getMostBadNotification} from '../../app/utils';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Notification from './Notification';
import {t} from '../../i18n/utils.js';
import {LocaleContext} from '../../i18n/LocaleContext.js';

const styles = theme => ({
    root: {},
    spoiler: {
        paddingLeft: 24,
        overflow: "hidden",

        ".closed &": {
            display: "none",
        },
    },
    spoilerBtnText: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    dropDownIcon: {
        marginLeft: 24,
        color: "#000000",

        ".opened &": {
            transform: "rotate(180deg)",
        }
    },
});

const useStyles = makeStyles(styles);

/**
 * Spoiler for multiple notifications
 */
function NotificationsSpoiler({className, list, ...props}) {
    const [open, setOpen] = useState(false);
    const classes = useStyles();

    const [mdn, state] = getMostBadNotification(list);
    const icon = getIcon(state);

    const lastNotificationDateSrc = list?.reduce((acc, n) => {
        const nDate = n.get('date');

        //console.log(nDate, acc);

        return !acc || nDate > acc ? nDate : acc;
    }, null);

    const localeObj = useContext(LocaleContext);
    const lastNotiDateStr = formatDate(lastNotificationDateSrc, localeObj.dateTimeFormat);

    const sorted = list.sort(function (a, b) {
        return a.getIn(['module', 'id']) - b.getIn(['module', 'id']);
    });

    //console.log(list.toJS());

    return (
        <div {...props} className={classNames(classes.root, {"closed": !open, "opened": open}, className)}>
            <NotificationWidget
                icon={icon}
                date={!open ? t('lastNotification', {lastDate: lastNotiDateStr}) : null}
                color={state === "Error" ? "red" : "orange"}
                isButton={true}
                onClick={() => {setOpen(!open)}}
            >
                <div className={classes.spoilerBtnText}>
                    <NotificationsCounter notifs={list} />
                    <ArrowDropDownIcon className={classes.dropDownIcon}/>
                </div>
            </NotificationWidget>
            <div className={classes.spoiler}>
                {sorted.map((n,index) => <Notification key={index} noti={n}/> )}
            </div>
        </div>
    );
}
export default NotificationsSpoiler;
