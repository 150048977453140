import Immutable from 'immutable';
import {initI18next, switchHtmlLang} from '../../i18n/utils.js';

export const reducerName = 'locale';

const INITIAL_STATE = Immutable.Map({
    //savedLocale: null,
    loadedLanguage: null,
    isFetching: false,
    error: null,
});

export default (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case 'LANGUAGE_FETCH_REQUEST':
            return state.set('isError', null).set('isFetching', true);

        case 'LANGUAGE_FETCH_SUCCESS':
            return state.set('isError', null).set('isFetching', false).set('loadedLanguage', action.lang);

        case 'LANGUAGE_FETCH_ERROR':
            return state.set('isError', action.error).set('isFetching', false);

        default:
            return state
    }
};

export const getLoadedLanguage = state => {
    return state.getIn([reducerName, 'loadedLanguage']);
};

export const isLanguageFetching = state => {
    return state.getIn([reducerName, 'isFetching']);
};

export const getLanguageError = state => {
    return state.getIn([reducerName, 'error']);
};

export const loadLanguage = lang => (dispatch, getState) => {
    dispatch({type: 'LANGUAGE_FETCH_REQUEST'});

    initI18next(lang).then(
        success => {
            switchHtmlLang(lang);
            dispatch({type: 'LANGUAGE_FETCH_SUCCESS', lang});
            dispatch({type: 'USER_SET_LANG', lang});
        },
        error => {
            console.error(error);
            dispatch({type: 'LANGUAGE_FETCH_ERROR', error});
            console.error(error);
        },
    );
}
