import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PageLayout from '../../../componets/PageLayout/PageLayout';
import DetailHeader from '../header/DetailHeader';
import {useParams} from 'react-router-dom';

/**
 * Отображает шапку и различные элементы сайта для внутренних страниц аппарата
 * @param device
 * @param children
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function DevicePageWrap({device, children, ...props}) {
    const devId = device.get('id');
    const params = useParams();
    const storeId = params?.storeId;

    return (
        <PageLayout url="stores">
            <section>
                <DetailHeader device={device} linkBack={`/stores/${storeId}/`} hideSec={false}/>

                <div>
                    {children}
                </div>
            </section>
        </PageLayout>
    );
}

export default DevicePageWrap;

const useStyles = makeStyles(theme => ({

}));
