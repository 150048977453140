import React, {useContext, useRef, useState} from 'react';
import {makeStyles, ThemeProvider} from '@material-ui/styles';
import {theme} from '../StoresListPage/theme.js';
import PageLayout from '../../componets/PageLayout/PageLayout.js';
import Topbar from './Topbar.js';
import {useSelector} from 'react-redux';
import {Button, Paper, Typography} from '@material-ui/core';
import {t} from '../../i18n/utils.js';
import ProgressBar from './ProgressBar.js';
import {DeviceContext} from '../../componets/DeviceLoader.js';
import NotifDate from '../StorePage/components/NotifDate.js';
import classNames from 'classnames';
import {checkPermission, tokensGetBearerToken} from '../../redux/reducers/tokens.js';
import LabelsReplaceDialog from '../DevicePage/ModulePage/printer/LabelsReplaceDialog.js';
import MobileTopbar from './MobileTopbar.js';
import {LocaleContext} from '../../i18n/LocaleContext.js';
import {useParams} from 'react-router-dom';
import {setTitle} from '../StoresListPage/StoresPage.js';
import PrinterMenu from './PrinterMenu.js';
import {ifnn} from '../StoresListPage/DailyStats.js';

function PrinterPage({store}) {
    const classes = useStyles();

    //g('STORE', store);

    const params = useParams();

    //console.log('PARAMS', params);
    const groupId = parseInt(params.deviceId, 10);
    const deviceId = parseInt(params.moduleId, 10);

    const grp = store.groups.find(g => g.id === groupId);
    //console.log(grp, groupId);
    const printer = grp.devices.find(d => d.device_id === deviceId);
    //console.log(printer);

    const ref = useRef(null);
    const [open, setOpen] = useState(false);
    const [openMenu, setOpenMenu] = useState(false);

    const cur = printer.info.labels_num;
    const max = printer.info.labels_num_full;
    let percents = Math.round(cur / max * 100);
    if (percents > 100) {
        percents = 100;
    }

    const device = useContext(DeviceContext).toJS();

    const storeName = device.device_number;

    setTitle(storeName);

    //const deviceId = device.id;

    const notifs = [...printer?.notifications?.errors, ...printer?.notifications?.warnings];
    const thisNotifs = notifs?.filter(n => n.module.id === printer.device_id);

    const hasErrors = thisNotifs.filter(n => n.type === 'Error').length > 0;
    const hasWarns = thisNotifs.filter(n => n.type === 'Warn').length > 0;

    const canChangeLabels = useSelector(checkPermission('access_to_change_sticker'));
    const canTestPrint = useSelector(checkPermission('access_to_test_printing'));

    const token = useSelector(tokensGetBearerToken);

    const locale = useContext(LocaleContext);

    const storeId = params.storeId;

    const backUrl = `/${locale.code}/stores/${storeId}/`;

    const address = store?.address;

    return (
        <ThemeProvider theme={theme}>
            <PageLayout noContainer={true} customMobileTopBar={<MobileTopbar backUrl={backUrl}/>}>
                <Topbar name={printer.name} hasErrors={hasErrors} hasWarns={hasWarns} backUrl={backUrl} address={address}/>
                <div className={classes.container}>
                    <Paper className={classes.paper}>
                        <div className={classes.mGrp}>
                            <div>
                                <div className={classes.row}>
                                    <Typography className={classes.c17}>{t('roll')}</Typography>
                                    <Typography className={classes.c15}>{ifnn(cur)} / {ifnn(max)} {t('labels')}</Typography>
                                </div>
                                <div>
                                    <ProgressBar value={percents} className={classes.pb}/>
                                </div>
                            </div>

                            <div className={classes.ngrp}>
                                {thisNotifs.map(n => {
                                    const color = n.type;

                                    return (
                                        <div className={classNames(classes.notif)}>
                                            <Typography className={classNames(classes.notifText,
                                                {[classes.Warn]: color === 'Warn' || color === 'warning'},
                                                {[classes.Error]: color === 'Error' || color === 'error'},
                                            )}>{n.text}</Typography>
                                            <NotifDate notif={n}/>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className={classes.btnsGrp}>
                            <Button variant={'outlined'} className={classes.btn}
                                    onClick={() => {
                                        setOpenMenu(true);
                                    }} disabled={!canTestPrint} ref={ref}
                            >{t('testPrint')}</Button>
                            <PrinterMenu open={openMenu} anchorRef={ref} onClose={() => {
                                setOpenMenu(false);
                            }} deviceId={printer.device_id} device={device}/>
                            <Button variant={'outlined'} className={classes.btn}
                                    onClick={() => {
                                        setOpen(true);
                                    }} disabled={!canChangeLabels}
                            >{t('replaceLabelsBtn')}</Button>
                        </div>
                    </Paper>
                </div>
                <LabelsReplaceDialog device={device} ptr={module} open={open} onClose={() => {
                    setOpen(false);
                }}/>

            </PageLayout>
        </ThemeProvider>
    );
}

export default PrinterPage;


const styles = () => ({
    container: {
        padding: '0 24px 24px',

        [theme.breakpoints.down('sm')]: {
            padding: 0,
        },
    },
    paper: {
        boxShadow: '0px 3px 12px rgba(12, 37, 86, 0.06)',
        padding: '20px 24px',
        display: 'flex',
        gap: 16,
        maxWidth: 1246,

        [theme.breakpoints.down('md')]: {
            gap: '26px 24px',
        },

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            borderRadius: 0,
        },
    },
    btn: {
        border: '1px solid rgba(223, 227, 233, 1)',
        alignSelf: 'flex-start',
        borderRadius: 8,
    },
    btnsGrp: {
        display: 'flex',
        gap: '16px',

        [theme.breakpoints.down('sm')]: {
            justifyContent: 'flex-end',
            borderTop: '1px solid ' + theme.colors.lightGrey.c100,
            paddingTop: 16,
        },
    },
    mGrp: {
        flex: 1,
        display: 'flex',
        gap: '0px 42px',
        flexWrap: 'wrap',

        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },

        [theme.breakpoints.down('sm')]: {
            gap: '16px',
        },
    },
    ngrp: {
        fontSize: 13,
        lineHeight: '16px',
        fontWeight: 500,
    },
    pb: {
        width: 348,
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },

    },
    row: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 14,
    },
    c17: {
        fontSize: 17,
    },
    c15: {
        fontSize: 15,
    },
    notif: {
        fontSize: 13,
        fontWeight: 500,
        color: '#666666',
        display: 'flex',
        gap: '0 8px',
        flexWrap: 'wrap',
        alignItems: 'baseline',
    },
    notifText: {
        fontSize: 13,
        fontWeight: 500,
        color: '#666666',
    },

    Warn: {
        color: theme.colors.colors.warning,
    },
    Error: {
        color: theme.colors.colors.error,
    },
});

const useStyles = makeStyles(styles);
