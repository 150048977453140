import React from 'react';
import PageLayout from '../componets/PageLayout/PageLayout.js';
import {adminLink} from '../app/utils.js';
import {useSelector} from 'react-redux';
import {getLoadedLanguage} from '../redux/reducers/locale.js';
import {makeStyles} from '@material-ui/core/styles';
import {tokensGetAccessToken} from '../redux/reducers/tokens.js';
import {useParams, withRouter} from 'react-router-dom';

function AdminPage(props) {
    const classes = useStyles();
    const token = useSelector(tokensGetAccessToken);
    const locale = useSelector(getLoadedLanguage);

    const params = useParams();

    const locHandler = (ev) => {
        const iframeUrl = ev.target.contentWindow.location;
    }

    let localeUrl = locale;
    let url = '/admin/';
    if (params[0]) {
        url += params[0];
    }

    const adminUrl = adminLink(token, url, localeUrl);

    return (
        <PageLayout noContainer={true}>
            <iframe id="adminFrame" className={classes.adminFrame} src={adminUrl}
                    onLoad={locHandler}
            />
        </PageLayout>
    );
}
export default withRouter(AdminPage);

const styles = (theme) => ({
    adminFrame: {
        width: '100%',
        height: '100%',
        flex: 1,
        border: 'none',
    },
    "#main": {
        display: 'flex',
    }
});
const useStyles = makeStyles(styles);
