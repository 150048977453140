import React, {useContext, useEffect, useRef, useState} from 'react';
import {CircularProgress, IconButton, MenuItem, Paper, Select} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {ReactComponent as DownloadIcon} from './DownloadIcon.svg';
import {getWithToken} from '../../app/rest.js';
import {APIPREFIX} from './Metrics.js';
import {formatDate} from '../../app/utils.js';
import {useSelector} from 'react-redux';
import {tokensGetBearerToken} from '../../redux/reducers/tokens.js';
import Badge from '../Dashboard/Badge.js';
import {LocaleContext} from '../../i18n/LocaleContext.js';
import {useIntersection} from './useIntersection.js';
import classNames from 'classnames';
import {ReactComponent as ArrowDownward} from './mdi_arrow_downward.svg';
import {ReactComponent as ArrowUpward} from './mdi_arrow_upward.svg';
import {theme} from '../Dashboard/theme.js';
import {Spoiler, TableHeader} from './Table.js';
import {styles} from './Table.js';
import {t} from '../../i18n/utils.js';

const Table = ({
    dateFrom,
    dateTo,
                   selCities, selChains, selStores,
    ...props
}) => {
    const classes = useStyles();

    const [data, setData] = useState(null);
    const token = useSelector(tokensGetBearerToken);

    const [inType, setInType] = useState('time');

    const locale = useContext(LocaleContext);
    const lang = locale?.code;

    const ref = useRef();
    const inViewport = useIntersection(ref, '0px');

    const [offset, setOffset] = useState(0);
    const pageSize = 10;
    const [total, setTotal] = useState(0);

    const [hovered, setHovered] = useState(null);

    const showed = offset + pageSize;

    const [error, setError] = useState(null);

    const [sorting, setSorting] = useState('-total_idle');
    const [update, setUpdate] = useState(true);
    const [force, setForce] = useState(true);

    const df = formatDate(dateFrom, 'YYYY-MM-DD');
    const dt = formatDate(dateTo, 'YYYY-MM-DD');
    let query = `?date_from=${df}&date_to=${dt}&limit=${pageSize}&offset=${offset}&sort_field=${sorting}` +
        `&lang=${lang}`;

    const cityIds = selCities.map(c => c.id);
    let temp;
    temp = cityIds?.map(c => {
        query += `&cities_ids=${c}`;
    });
    const chainIds = selChains.map(c => c.id);
    temp = chainIds?.map(c => {
        query += `&chains_ids=${c}`;
    });
    const storeIds = selStores.map(c => c.id);
    temp = storeIds?.map(c => {
        query += `&stores_ids=${c}`;
    });

    useEffect(() => {
        console.log(inViewport);
        if (inViewport && showed < total) {
            setOffset(offset + pageSize);
        }
    }, [inViewport]);

    useEffect(() => {
        setOffset(0);
        setUpdate(true);
    }, [sorting, dateFrom, dateTo, selCities, selChains, selStores]);

    useEffect(() => {
        setUpdate(true);
    }, [offset]);

    async function saveFile() {
        const resp = await fetch(APIPREFIX + '/reports/idles/table' + query + '&download=true',{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        });

        console.log(resp);

        if (resp.ok) {
            const blob = await resp.blob();
            //blob.type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;';

            var link = document.getElementById("linkFile");
            link.href = window.URL.createObjectURL(blob);
            link.click();
        }
    }

    async function fetchTableData(dateFrom, dateTo) {
        const forceUpdate = offset === 0;

        try {
            const resp = await getWithToken(APIPREFIX + '/reports/idles/table' + query + '&download=false', token, 'Bearer');
            console.log(resp);
            setError(null);

            if (resp.items !== null && resp.items !== undefined) {
                let oldData = data ? data : [];
                if (forceUpdate) {
                    oldData = [];
                }
                setData([...oldData, ...resp.items]); // append results
            }
            setTotal(resp?.total);
        } catch (e) {
                setError(e);
            }
    }

    useEffect(() => {
        if (update) {
            fetchTableData(df, dt);

            setUpdate(false);
        }
    }, [update]);

    function val(src) {
        if (src === null || src === undefined) {
            return '-';
        }

        const num = Number(src);
        if (isNaN(num)) {
            return '-';
        }

        const localizedStr = num.toLocaleString(lang, {maximumFractionDigits: 2});
        return localizedStr;
    }

    function SortingBtn({tag, sorting, setSorting}) {
        const dir = sorting[0] === '-' ? '-' : '';
        const sTag = dir === '-' ? sorting.substr(1) : sorting;

        return (
            <button className={classNames([classes.sortBtn, {active: sTag === tag}])} onClick={(e) => {
                const newDir = dir === '-' ? '' : '-';
                setSorting(newDir + tag);
            }}>
                {dir === '-' ?
                    <ArrowDownward className={classes.sortBtnIcon}/>
                    :
                    <ArrowUpward className={classes.sortBtnIcon}/>
                }
            </button>
        );
    }

    const [isScrolling, setIsScrolling] = useState(false);

    const onScroll = (ev) => {
       const st = ev.target.scrollTop;

       if (!isScrolling && st > 0) {
           setIsScrolling(true);
       } else if (isScrolling && st === 0) {
           setIsScrolling(false);
        }
    }

    if (error) {
        return (
            <Paper className={classes.root}>
                Произошла ошибка
            </Paper>
            );
    }

    const reasons = [
        'no_beer',
        'gas_is_out',
        'too_dirty',
        'no_label',
        'label_stuck',
        'cooler_low_temperature',
        'printer_power_off',
        'printer_head_is_open',
        'printer_error',
        'drain_full',
        'relief_timeout',
        'pour_timeout',
        'auto',
        'manually',
        'processor_temp',
        'no_data'
    ];

    function formatTime(timeStr) {
        if (timeStr === '0') {
            return '0 м';
        }

        if (!timeStr) {
            return '-'
        }

        const split = timeStr.split(':');

        let i = 0;


        let hh = 0;
        if (split.length >= 3) {
            hh = split[0];
            i += 1;
        }
        let mm = 0;
        if (split.length >= 2) {
            mm = split[1];
            i += 1;
        }

        if (hh > 0)
            return `${hh} ч ${mm} м`;
        else
            return `${mm} м`;
    }

    return (
        <Paper className={classes.root}>
            <div className={classes.header}>
                <div className={classes.headerTitle}>
                    Простои по магазинам

                    <Select value={inType} onChange={(e) => {
                        const val = e.target.value;
                        setInType(val);
                    }} variant={'outlined'} className={classes.select}>
                        <MenuItem value={'time'}>В часах</MenuItem>
                        <MenuItem value={'percentage'}>В процентах</MenuItem>
                    </Select>
                </div>

                <a href="#" download={'report.xlsx'} id="linkFile" className={classes.link} />
                <a className={classes.downloadBtn} href="#" onClick={(e)=> {
                    e.preventDefault();
                    saveFile()
                }}>
                    <div className={classes.downloadBtnIcon}>
                        <DownloadIcon width={24} height={24} />
                    </div>
                    <div className={classes.downloadBtnLabel}>Скачать excel</div>
                </a>
            </div>

            <div className={classes.tableWrap} onScroll={onScroll}>

                <div className={classNames([classes.tableSeparateHeader, {'isScrolling': isScrolling}])}>
                    <table className={classes.table}>
                        <colgroup>
                            <col span="1" style={{width: 144}} />
                            <col span="1" style={{width: 224}} />
                            <col span="1" style={{width: 264}} />
                            <col span="1" style={{width: 184}} />
                            <col span="1" style={{width: 124}} />
                            <col span="1" style={{width: 184}} />
                            <col span="1" style={{width: 184}} />

                            {reasons.map(r => (
                                <col span="1" style={{width: 164}} />
                            ))}

                            <col span="1" style={{width: 124}} />
                            <col span="1" style={{width: 164}} />
                        </colgroup>
                        <thead>
                        <tr className={'headRow'}>
                            <TableHeader title="Город" tag="city" hover={()=>{setHovered(1)}} unhover={()=>{setHovered(null)}} sorting={sorting} setSorting={setSorting} />
                            <TableHeader title="Магазин" tag="name" hover={()=>{setHovered(2)}} unhover={()=>{setHovered(null)}}  sorting={sorting} setSorting={setSorting}/>
                            <TableHeader title="Адрес" tag="address" hover={()=>{setHovered(3)}} unhover={()=>{setHovered(null)}} sorting={sorting} setSorting={setSorting} />
                            <TableHeader title="Общее время работы" tag="total_work_time" right hover={()=>{setHovered(4)}} unhover={()=>{setHovered(null)}} sorting={sorting} setSorting={setSorting} />
                            <TableHeader title="Простой всего" tag="total_idle" right hover={()=>{setHovered(5)}} unhover={()=>{setHovered(null)}} sorting={sorting} setSorting={setSorting} />
                            <TableHeader title="Простой от общего времени работы, %" tag="idle_diff" right hover={()=>{setHovered(6)}} unhover={()=>{setHovered(null)}} sorting={sorting} setSorting={setSorting} />
                            <TableHeader title="Среднее время простоя в день" tag="avg_idles" right hover={()=>{setHovered(7)}} unhover={()=>{setHovered(null)}} sorting={sorting} setSorting={setSorting} />

                            {reasons.map((r, i) => {
                                return (
                                    <TableHeader title={t(r)} tag={r} right hover={()=>{setHovered(10 + i)}} unhover={()=>{setHovered(null)}} sorting={sorting} setSorting={setSorting} />
                                );
                            })}

                            <TableHeader title="Номер аппарата" tag="group_names" right hover={()=>{setHovered(8)}} unhover={()=>{setHovered(null)}} sorting={sorting} setSorting={setSorting} />
                            <TableHeader title="Инвентарный номер"  tag="inventory_numbers" right hover={()=>{setHovered(9)}} unhover={()=>{setHovered(null)}} sorting={sorting} setSorting={setSorting} />
                        </tr>
                        </thead>
                    </table>
                </div>

                <table className={classes.table}>
                    <colgroup>
                        <col span="1" style={{width: 144}} />
                        <col span="1" style={{width: 224}} />
                        <col span="1" style={{width: 264}} />
                        <col span="1" style={{width: 184}} />
                        <col span="1" style={{width: 124}} />
                        <col span="1" style={{width: 184}} />
                        <col span="1" style={{width: 184}} />

                        {reasons.map(r => (
                            <col span="1" style={{width: 164}} />
                        ))}

                        <col span="1" style={{width: 124}} />
                        <col span="1" style={{width: 164}} />
                    </colgroup>
                    <thead>
                    <tr className={'headRow'} style={{display: 'none'}}>
                        <TableHeader title="Город" tag="city" hover={()=>{setHovered(1)}} unhover={()=>{setHovered(null)}} sorting={sorting} setSorting={setSorting} />
                        <TableHeader title="Магазин" tag="name" hover={()=>{setHovered(2)}} unhover={()=>{setHovered(null)}} sorting={sorting} setSorting={setSorting} />
                        <TableHeader title="Адрес" tag="address" hover={()=>{setHovered(3)}} unhover={()=>{setHovered(null)}} sorting={sorting} setSorting={setSorting} />
                        <TableHeader title="Общее время работы" tag="total_work_time" right hover={()=>{setHovered(4)}} unhover={()=>{setHovered(null)}} sorting={sorting} setSorting={setSorting} />
                        <TableHeader title="Простой всего" tag="total_idle" right hover={()=>{setHovered(5)}} unhover={()=>{setHovered(null)}} sorting={sorting} setSorting={setSorting} />
                        <TableHeader title="Простой от общего времени работы, %" tag="idle_diff" right hover={()=>{setHovered(6)}} unhover={()=>{setHovered(null)}} sorting={sorting} setSorting={setSorting} />
                        <TableHeader title="Среднее время простоя в день" tag="avg_idles" right hover={()=>{setHovered(7)}} unhover={()=>{setHovered(null)}} sorting={sorting} setSorting={setSorting} />

                        {reasons.map((r, i) => {
                            return (
                                <TableHeader title={t(r)} tag={r} right hover={()=>{setHovered(10 + i)}} unhover={()=>{setHovered(null)}} sorting={sorting} setSorting={setSorting} />
                            );
                        })}

                        <TableHeader title="Номер аппарата" tag="group_names" right hover={()=>{setHovered(8)}} unhover={()=>{setHovered(null)}} sorting={sorting} setSorting={setSorting} />
                        <TableHeader title="Инвентарный номер" tag="inventory_numbers" right hover={()=>{setHovered(9)}} unhover={()=>{setHovered(null)}} sorting={sorting} setSorting={setSorting} />
                    </tr>
                    </thead>
                    <tbody>
                    {data?.map((r, index) => {
                        /*
                        address: "Максима Горького 40"
                        average_daily_bottles_count: 0
                        average_daily_bottles_count_diff: 0
                        average_daily_pouring_volume: 0
                        average_daily_pouring_volume_diff: 0
                        bottles_count: 0
                        city: "Череповец"
                        group_names: ['B100005']
                        inventory_numbers: ['0704000619-005']
                        name: "Макси"
                        pouring_volume: 0
                        pouring_volume_diff: 0
                         */
                        return (
                            <tr key={index}>
                                <td className={classes.td}>
                                    <div className={classNames(classes.tdInner, {'hover': hovered === 1})}>
                                    {r.city}
                                    </div></td>
                                <td className={classes.td}>
                                    <div className={classNames(classes.tdInner, {'hover': hovered === 2})}>
                                        {r.name}</div></td>
                                <td className={classes.td}>
                                    <div className={classNames(classes.tdInner, {'hover': hovered === 3})}>
                                    {r.address}
                                    </div></td>
                                <td className={classes.td}>
                                    <div className={classNames(classes.tdInner, {'hover': hovered === 4})}>
                                    {formatTime(r.total_work_time)}
                                    </div></td>
                                <td className={classes.td}>
                                    <div className={classNames(classes.tdInner, {'hover': hovered === 5})}>
                                        {formatTime(r.total_idle)}</div></td>
                                <td className={classes.td}><div className={classNames(classes.tdInner, {'hover': hovered === 6})}>
                                    <Badge v={val(r.idle_diff) + '%'} t={r.idle_diff}  reverse={true} />
                                </div></td>
                                <td className={classes.td}>
                                    <div className={classNames(classes.tdInner, {'hover': hovered === 7})}>
                                        {formatTime(r.avg_idles)}</div></td>


                                {reasons.map((rea, ind) => {
                                    const rrea = r[rea];

                                    return (
                                        <td className={classes.td}>
                                            <div className={classNames(classes.tdInner, {'hover': hovered === 10 + ind})} style={{textAlign: 'right'}}>
                                                {(rea === 'no_data' && inType !== 'time') || !rrea ?
                                                    '-' :
                                                inType === 'time' ?
                                                formatTime(rrea?.time)
                                                    :
                                                    `${Number(rrea?.percentage).toLocaleString('ru', {maximumFractionDigits: 2})}%`
                                                }
                                            </div>
                                        </td>
                                    );
                                })}

                                <td className={classes.td}><div className={classNames(classes.tdInner, classes.right, {'hover': hovered === 8})}>

                                    <Spoiler list={r.group_names?.filter(a=>a)} /></div></td>
                                <td className={classes.td}>
                                    <div className={classNames(classes.tdInner,  classes.right, {'hover': hovered === 9})}>
                                        <Spoiler list={r.inventory_numbers?.filter(a=>a)} />
                                    </div></td>
                            </tr>
                        );
                    })}
                    <tr className={classNames([classes.moreRow, {'hidden': showed >= total}])}>
                        <td colSpan={8}>
                            <div className={classes.loading} ref={ref}>
                                <CircularProgress size={24} />
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </Paper>
    )
};

export default Table;

const useStyles = makeStyles(styles);
