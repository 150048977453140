import React from 'react';
import {Button, Typography} from '@material-ui/core';
import * as colors from '../uiKit/colors.js';
import {ArrowBackIos} from '../uiKit/icons';
import {makeStyles} from '@material-ui/core/styles';
import classNames from 'classnames';
import {t} from '../../../i18n/utils.js';

const styles = ({
    button: {
        background: colors.lightgrey40,
        color: colors.grey100,
        borderRadius: 8,
    },
    icon: {
        width: 16,
        height: 16,
    },
    label: {
        color: colors.grey100,
        fontSize: 12,
        lineHeight: '16px',
    },
});
const useStyles = makeStyles(styles);

export default function BackButton({className, ...props}) {
    const classes = useStyles();

    return (
        <Button className={classNames(classes.button, className)} {...props}>
            <ArrowBackIos className={classes.icon} />
            <Typography component="span" className={classes.label}>{t('Back')}</Typography>
        </Button>
    );
}
