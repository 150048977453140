import {FormControlLabel, makeStyles, Switch, Typography} from '@material-ui/core';
import React, {useEffect, useRef, useState} from 'react';
import Select from './Select.js';
import {useDispatch, useSelector} from 'react-redux';
import {citiesFetchList, getCitiesList, getCitiesListState} from '../../redux/reducers/cities.v2.js';
import {getWithToken} from '../../app/rest.js';
import {tokensGetBearerToken} from '../../redux/reducers/tokens.js';
import {t} from '../../i18n/utils.js';
import classNames from 'classnames';
import {DEVICE_LIST_UPDATE_FILTERS} from '../../redux/reducers/deviceList.js';

function Filters({query, setQuery, ...props}) {
    const classes = useStyles();

    const dispatch = useDispatch();

    const filters = useSelector(state => state.getIn(['deviceList', 'filters']));


    const {offset, limit, city, chain, withProblems} = filters;

    const cities = useSelector(getCitiesList)?.toJS();
    const token = useSelector(tokensGetBearerToken);
    const citiesLoading = useSelector(getCitiesListState);

    const [storesLoaded, setStoresLoaded] = useState(false);

    const cityId = props.city?.id;

    const loadStores = async () => {

            let endpoint = `/v2/api/chains?limit=9999`;
            if (cityId) {
                endpoint += `&city_id=${cityId}`;
            }

            setStoresLoaded(false);
            const resp = await getWithToken(endpoint, token, 'Bearer');

            const total = resp?.total || 0;
            const loadedItems = resp?.items || [];


            setStores(loadedItems);
            setStoresLoaded(true);
    };

    const [stores, setStores] = useState([]);
    const storesSel = useRef([]);

    useEffect(() => {
        dispatch(citiesFetchList());

        //console.log('useEffect', props, cityId);

        loadStores();
    }, [cityId]);

    function updateFilter() {
        props.setStore(storesSel.current);
        setStores([...storesSel.current, ...stores.filter(s => !storesSel.current.includes(s))]);
    }


    const errorTypes = [
        "keg_empty",
            "tap_is_empty",
            "bad_position",
            "label_stuck",
            "tape_low",
            "tape_empty",
            "pour_timeout",
            "temperature",
            "relief_timeout",
            "pressure",
            "gas_low",
            "gas_out",
            "too_dirty",
            "drain",
            "no_connection",
    ].map(it => ({
        id: it,
        name: t('error_types.' + it),
    }));

    const deviceTypes = [
        "tap",
        "keg",
        "manager",
        "co2",
        "temperature",
        "printer",
    ].map(it => ({
        id: it,
        name: t('device_types.' + it),
    }));

    return (
        <div className={classNames(classes.root, {'visible': props.fil})}>
            <div className={classes.filters}>
                {/*<FormControlLabel control={<Switch color={"primary"} checked={props.withProblems} onChange={(e) => {
                    const v = e.target.checked;
                    //props.setWithProblems(v);
                    dispatch({type: DEVICE_LIST_UPDATE_FILTERS, filters: {withProblems: v, offset: 0}})
                }}/>} label={t('searchAmongProblem')} />*/}
                <Select
                    options={cities || []}
                    placeholder={t('citiesDropdown')}
                    value={props.city}
                    onChange={(e, v)=>{
                        console.log('NEWVAL', v);
                        const newVal = v?.map(vv => typeof vv === 'object' ? vv.id : vv);
                        dispatch({type: DEVICE_LIST_UPDATE_FILTERS, filters: {offset: 0, city: newVal}})


                        //props.setCity(newVal);
                    }}
                    loading={citiesLoading}
                    multiple={true}
                />
                <Select
                    options={stores || []}
                    placeholder={t('chainsDropdown')}
                    value={props.store}
                    onChange={(e, v)=>{
                        console.log(v);

                        const newStore = v?.map(vv => typeof vv === 'object' ? vv.id : vv)
                        dispatch({type: DEVICE_LIST_UPDATE_FILTERS, filters: {offset: 0, store: newStore}})
                        //props.setStore(newStore);
                    }}
                    multiple={true}
                    loading={!storesLoaded}
                />

                <Select
                    options={deviceTypes}
                    placeholder={t('device_types_title')}
                    value={props.deviceTypes}
                    onChange={(e, v)=>{
                        console.log(v);

                        const newStore = v?.map(vv => typeof vv === 'object' ? vv.id : vv)
                        dispatch({type: DEVICE_LIST_UPDATE_FILTERS, filters: {offset: 0, devices: newStore}})
                        //props.setStore(newStore);
                    }}
                    multiple={true}
                    loading={!storesLoaded}
                />

                <Select
                    options={errorTypes}
                    placeholder={t('error_types_title')}
                    value={props.errorTypes}
                    onChange={(e, v)=>{
                        console.log(v);

                        const newStore = v?.map(vv => typeof vv === 'object' ? vv.id : vv)
                        dispatch({type: DEVICE_LIST_UPDATE_FILTERS, filters: {offset: 0, errors: newStore}})
                        //props.setStore(newStore);
                    }}
                    multiple={true}
                />

                <a href="#" onClick={(e) => {
                    e.preventDefault();
                    //props.setStore([]);
                    //props.setCity([]);
                    //props.setWithProblems(false);

                    dispatch({type: DEVICE_LIST_UPDATE_FILTERS, filters: {offset: 0, query: '', city: [], store: [], withProblems: false}})
                    //setQuery('');
                }}><Typography variant="subtitle1" className={classes.resetLink}>{t('resetFilters')}</Typography></a>
                <div className={classes.sep} />
            </div>


        </div>
    );
}
export default Filters;

const styles = theme => ({
    root: {
        marginBottom: theme.spacing(7),

        [theme.breakpoints.down('sm')]: {
            marginBottom: 16,
        },

        [theme.breakpoints.down('md')]: {
            display: 'none',
        },

        '&.visible': {
            display: 'block',
        }
    },

    resetLink: {
        color: '#007AFF',

        [theme.breakpoints.up('lg')]: {
            display: 'none',
        },
    },

    sep: {
        background: theme.colors.lightGrey.c100,
        height: 1,
        width: '100%',
        marginBottom: 24,
        marginTop: 16,

        [theme.breakpoints.up('lg')]: {
            display: 'none',
        },

        [theme.breakpoints.down('sm')]: {
            marginBottom: 16,
        },
    },

    filters: {
        display: 'flex',
        flexWrap: 'wrap',
        margin: theme.spacing(-2),
        alignItems: 'center',
        //padding: '16px 0 24px 0',

        '& > *': {
            margin: theme.spacing(1.5, 2, 1.5, 2),
        },

        [theme.breakpoints.down('sm')]: {
            '& > *': {
                width: '100%',
            },


        }
    }
});
const useStyles = makeStyles(styles);
