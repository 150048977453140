import React from 'react';
import {Redirect, Route, useLocation} from 'react-router-dom';

/**
 * Нормализует урлы с несколькими слешами подряд
 * Например: //one//two/three///?four#five -> /one/two/three/?four#five
 *
 * TODO
 * Trailing slash
 *
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
function SlashNormalizer({children}) {
    const location = useLocation();
    const {pathname, search, hash} = location;

    const multipleSlashesRegex = /\/{2,}/g;

    if (pathname.match(multipleSlashesRegex)) {
        // has multiple slashes
        const normalizedPathname = pathname.replace(multipleSlashesRegex, '/');
        const normalizedUrl = normalizedPathname + search + hash;

        return (
            <Route render={()=>{
                return (
                    <Redirect to={normalizedUrl} />
                );
            }} />
        );
    } else {
        // normal url
        return children;
    }
}

export default SlashNormalizer;