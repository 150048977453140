import React, {useEffect, useState} from 'react';
import {Button, debounce, Dialog, DialogActions, DialogContent, Paper, TextField, Typography} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {apiChangeParam} from '../../../app/api';
import {useSelector} from 'react-redux';
import {tokensGetAccessToken, tokensGetBearerToken} from '../../../redux/reducers/tokens';
import {t} from '../../../i18n/utils.js';

function ParamValue({device, secId, param, allParams, isDisconnected, onChange, parent = null}) {
    const classes = useStyles();

    const [val, setVal] = useState(param.value);

    if (!param) {
        return null;
    }

    let paramTitle = param?.title || '-';


    if (param?.parent_param_type_id != parent) {
        return null;
    }

    if (param?.is_value_group) {
        const subparams = allParams.filter(p => p.parent_param_type_id === param.param_type_id);

        return (
            <Paper>
                <div>{paramTitle}</div>

                {subparams.map((p, index) => (
                    <ParamValue  device={device} key={index} param={p} isDisconnected={isDisconnected}
                                 secId={secId} allParams={allParams} parent={param.param_type_id}
                    onChange={onChange}/>
                ))}
            </Paper>
        );
    }

    if (param?.value_type === null || param?.value_type === 7 ) {
        return (
            <div>{paramTitle}</div>
        );
    }

    let desc = param?.description;

    return (
        <div className={classes.paramSec}>
            <TextField label={paramTitle} value={val} fullWidth className={classes.paramEdit}
                       onChange={(ev)=>{
                           //console.log(ev.target.value);
                           setVal(ev.target.value);
                           onChange(param.id, ev.target.value);
                       }}
                       disabled={isDisconnected}
                       helperText={desc}
            />
        </div>
    );
}

function ParamsDialog({open, onCloseFn, grp, device, secId, isDisconnected, ...props}) {
    const classes = useStyles();

    const tokenv1 = useSelector(tokensGetAccessToken);
    const token = useSelector(tokensGetBearerToken);

    const [changed, setChanged] = useState({});

    function onClose() {
        setChanged({}); // reset changed list

        onCloseFn();
    }

    function handleSubmit() {
        console.log(changed);

        // apply changed one by one
        const keys = Object.keys(changed);

        keys.map( key => {
            apiChangeParam(token, tokenv1, secId, key, changed[key]).then(r => {})
        });

        onClose();
    }

    // prepeare
    let error = false;
    let params = [];

    if (!Array.isArray(grp?.params)) {
        error = true;
    } else {
        params = grp?.params;
    }

    //isDisconnected = false;

    return (
        <Dialog open={open} onClose={onClose} maxWidth="lg">
            <Typography variant="h1" className={classes.heading}>{t('paramsHeading')}: {grp.title}</Typography>

            <DialogContent className={classes.cont}>
                {!error ? params.map((param, index) => {
                    //console.log(param);
                    return (
                        <ParamValue device={device} key={index} param={param} isDisconnected={isDisconnected}
                            secId={secId} allParams={params} onChange={(id, val) => {
                                let newChanged = Object.assign({}, changed);
                                newChanged[id] = val
                                setChanged(newChanged);
                        }}
                        />
                    );
                }) : (
                    <div>{t('washDialogError')}</div>
                )}
            </DialogContent>
            <DialogActions>
                <Button color="primary" variant="outlined" onClick={handleSubmit} disabled={isDisconnected || Object.keys(changed).length === 0}>{t('applyBtn')}</Button>
                <Button variant="outlined" onClick={onClose}>{t('cancelBtn')}</Button>
            </DialogActions>
        </Dialog>
    );
}

export default ParamsDialog;


const useStyles = makeStyles(theme => ({
    heading: {
        textAlign: "center",
        margin: "24px 0",
        minWidth: 320,
        maxWidth: 580,
        width: "100vw",
    },
    cont: {
        marginBottom: 32,
    },

    paramSec: {
        padding: "8px 0",
    }
}));
