import React, {useContext, useEffect, useRef, useState} from 'react';
import {CircularProgress, IconButton, Paper} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {ReactComponent as DownloadIcon} from './DownloadIcon.svg';
import {getWithToken} from '../../app/rest.js';
import {APIPREFIX} from './Metrics.js';
import {formatDate, plural} from '../../app/utils.js';
import {useSelector} from 'react-redux';
import {tokensGetBearerToken} from '../../redux/reducers/tokens.js';
import Badge from '../Dashboard/Badge.js';
import {LocaleContext} from '../../i18n/LocaleContext.js';
import {useIntersection} from './useIntersection.js';
import classNames from 'classnames';
import {ReactComponent as ArrowDownward} from './mdi_arrow_downward.svg';
import {ReactComponent as ArrowUpward} from './mdi_arrow_upward.svg';
import {theme} from '../Dashboard/theme.js';
import * as PropTypes from 'prop-types';

function SortingBtn({tag, sorting, setSorting}) {
    const [dir, setDir] = useState('-');
    const sTag = sorting && sorting[0] === '-' ? sorting.substr(1) : sorting;
    const classes = useStyles();

    return (
        <button className={classNames([classes.sortBtn, {active: sTag === tag}])} onClick={(e) => {
            const newDir = dir === '-' ? '' : '-';

            setDir(newDir);

            setSorting(newDir + tag);
        }}>
            {dir === '-' ?
                <ArrowDownward className={classes.sortBtnIcon}/>
                :
                <ArrowUpward className={classes.sortBtnIcon}/>
            }
        </button>
    );
}

export function TableHeader({title, tag, right, sorting, setSorting, hover, unhover}) {
    const classes = useStyles();
    return (
        <th className={classes.th}>
            <div className={classNames([classes.thInner, {'right': right},])} onMouseEnter={()=> {
                hover && hover();
            }} onMouseLeave={()=> unhover && unhover()}>
                <span>{title}</span>
                <SortingBtn tag={tag} sorting={sorting} setSorting={setSorting} />
            </div>
        </th>
    )
}

export function Spoiler(props) {
    const {list} = props;
    const head = list[0];
    const tail = list.slice(1);

    const [open, setOpen] = useState(false);

    const classes = useStyles();

    let rest = '';
    if (tail.length > 0) {
        rest = ` + ${tail.length} ` + plural(tail.length, 'аппарат', 'аппарата', 'аппаратов');
    }


    return (
        <div>
            {open || tail.length === 0 ? (
                <div>
                    {head}
                </div>
            ) : (
                <>
                    <div>
                        {head}
                    </div>
                    <a href={'#'} className={classes.splLink}
                    onClick={(ev) => {
                        ev.preventDefault();
                        setOpen(true);
                    }}>{rest}</a>
                </>
            )}
            {open &&
            <div>
                {tail?.map((item, index) => {
                    return (
                        <div key={index}>
                            {item}
                        </div>
                    )
                })}

                <a href={'#'} className={classes.splLink}
                   onClick={(ev) => {
                       ev.preventDefault();
                       setOpen(false);
                   }}>скрыть</a>
            </div>
            }
        </div>
    );
}

Spoiler.propTypes = {list: PropTypes.any};
const Table = ({
    dateFrom,
    dateTo,
                   selCities, selChains, selStores,
    ...props
}) => {
    const classes = useStyles();

    const [data, setData] = useState(null);
    const token = useSelector(tokensGetBearerToken);

    const locale = useContext(LocaleContext);
    const lang = locale?.code;

    const ref = useRef();
    const inViewport = useIntersection(ref, '0px');

    const [offset, setOffset] = useState(0);
    const pageSize = 10;
    const [total, setTotal] = useState(0);

    const showed = offset + pageSize;

    const [sorting, setSorting] = useState('-pouring_volume');
    const [update, setUpdate] = useState(true);
    const [force, setForce] = useState(true);

    const [hovered, setHovered] = useState(null);

    const df = formatDate(dateFrom, 'YYYY-MM-DD');
    const dt = formatDate(dateTo, 'YYYY-MM-DD');
    let query = `?date_from=${df}&date_to=${dt}&offset=${offset}&limit=${pageSize}&sorting=${sorting}` +
        `&lang=${lang}`;

    const cityIds = selCities.map(c => c.id);
    let t;
    t = cityIds?.map(c => {
        query += `&city_ids=${c}`;
    });
    const chainIds = selChains.map(c => c.id);
    t = chainIds?.map(c => {
        query += `&chain_ids=${c}`;
    });
    const storeIds = selStores.map(c => c.id);
    t = storeIds?.map(c => {
        query += `&store_ids=${c}`;
    });

    useEffect(() => {
        console.log(inViewport);
        if (inViewport && showed < total) {
            setOffset(offset + pageSize);
        }
    }, [inViewport]);

    useEffect(() => {
        setOffset(0);
        setUpdate(true);
    }, [sorting, dateFrom, dateTo, selCities, selChains, selStores]);

    useEffect(() => {
        setUpdate(true);
    }, [offset]);

    async function saveFile() {
        const resp = await fetch(APIPREFIX + '/reports/dispensing/table' + query + '&download_file=true',{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        });
        console.log(resp);

        if (resp.ok) {
            const blob = await resp.blob();
            //blob.type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;';

            var link = document.getElementById("linkFile");
            link.href = window.URL.createObjectURL(blob);
            link.click();
        }
    }

    async function fetchTableData(dateFrom, dateTo) {
        const forceUpdate = offset === 0;

        const resp = await getWithToken(APIPREFIX + '/reports/dispensing/table' + query + '&download_file=false', token, 'Bearer');
        console.log(resp);

        if (resp.items !== null && resp.items !== undefined) {
            let oldData = data ? data : [];
            if (forceUpdate) {
                oldData = [];
            }
            setData([...oldData, ...resp.items]); // append results
        }
        setTotal(resp?.total);
    }

    useEffect(() => {
        if (update) {
            fetchTableData(df, dt);

            setUpdate(false);
        }
    }, [update]);

    function val(src) {
        if (src === null || src === undefined) {
            return '-';
        }

        const num = Number(src);
        if (isNaN(num)) {
            return '-';
        }

        const localizedStr = num.toLocaleString(lang, {maximumFractionDigits: 2});
        return localizedStr;
    }

    const [isScrolling, setIsScrolling] = useState(false);

    const onScroll = (ev) => {
       const st = ev.target.scrollTop;

       if (!isScrolling && st > 0) {
           setIsScrolling(true);
       } else if (isScrolling && st === 0) {
           setIsScrolling(false);
        }
    }

    const colgroup = (
        <colgroup>
            <col span="1" style={{width: 144}} />
            <col span="1" style={{width: 224}} />
            <col span="1" style={{width: 264}} />
            <col span="1" style={{width: 124}} />
            <col span="1" style={{width: 144}} />
            <col span="1" style={{width: 164}} />
            <col span="1" style={{width: 124}} />
            <col span="1" style={{width: 164}} />
            <col span="1" style={{width: 164}} />
            <col span="1" style={{width: 184}} />
        </colgroup>
    );

    return (
        <Paper className={classes.root}>
            <div className={classes.header}>
                <div className={classes.headerTitle}>Наливы по магазинам</div>

                <a href="#" download={'report.xlsx'} id="linkFile" className={classes.link} />
                <a className={classes.downloadBtn} href="#" onClick={(e)=> {
                    e.preventDefault();
                    saveFile()
                }}>
                    <div className={classes.downloadBtnIcon}>
                        <DownloadIcon width={24} height={24} />
                    </div>
                    <div className={classes.downloadBtnLabel}>Скачать excel</div>
                </a>
            </div>

            <div className={classes.tableWrap} onScroll={onScroll}>
                    <div className={classNames([classes.tableSeparateHeader, {'isScrolling': isScrolling}])}>
                        <table className={classes.table}>
                            {colgroup}
                            <thead>
                            <tr className={'headRow'}>
                                <TableHeader title="Город" tag="city" sorting={sorting} setSorting={setSorting} hover={()=>{setHovered(1)}} unhover={()=>{setHovered(null)}}/>
                                <TableHeader title="Магазин" tag="name" sorting={sorting} setSorting={setSorting} hover={()=>{setHovered(2)}} unhover={()=>{setHovered(null)}} />
                                <TableHeader title="Адрес" tag="address" sorting={sorting} setSorting={setSorting} hover={()=>{setHovered(3)}} unhover={()=>{setHovered(null)}}  />
                                <TableHeader title="Объём,&nbsp;л" tag="pouring_volume" right sorting={sorting} setSorting={setSorting} hover={()=>{setHovered(4)}} unhover={()=>{setHovered(null)}}  />
                                <TableHeader title="Динамика" tag="pouring_volume_diff" right sorting={sorting} setSorting={setSorting} hover={()=>{setHovered(5)}} unhover={()=>{setHovered(null)}}  />
                                <TableHeader title="Среднедневной объем" tag="average_daily_pouring_volume" right sorting={sorting} setSorting={setSorting} hover={()=>{setHovered(6)}} unhover={()=>{setHovered(null)}}  />
                                <TableHeader title="Кол-во бутылок" tag="bottles_count" right sorting={sorting} setSorting={setSorting} hover={()=>{setHovered(7)}} unhover={()=>{setHovered(null)}}  />
                                <TableHeader title="Среднедневное кол-во бутылок" tag="average_daily_bottles_count" right sorting={sorting} setSorting={setSorting} hover={()=>{setHovered(8)}} unhover={()=>{setHovered(null)}}  />

                                <TableHeader title="Номер аппарата" tag="group_names" right sorting={sorting} setSorting={setSorting} hover={()=>{setHovered(9)}} unhover={()=>{setHovered(null)}}  />
                                <TableHeader title="Инвентарный номер" tag="inventory_numbers" right sorting={sorting} setSorting={setSorting} hover={()=>{setHovered(10)}} unhover={()=>{setHovered(null)}}  />
                            </tr>
                            </thead>
                        </table>
                    </div>

                <table className={classes.table}>
                    {colgroup}

                    <thead style={{display: 'none'}}>
                    <tr className={'headRow'} >
                        <TableHeader title="Город" tag="city" />
                        <TableHeader title="Магазин" tag="name" />
                        <TableHeader title="Адрес" tag="address" />
                        <TableHeader title="Объём,&nbsp;л" tag="pouring_volume" right />
                        <TableHeader title="Динамика" tag="pouring_volume_diff" right />
                        <TableHeader title="Среднедневной объем" tag="average_daily_pouring_volume" right />
                        <TableHeader title="Кол-во бутылок" tag="bottles_count" right />
                        <TableHeader title="Среднедневное кол-во бутылок" tag="average_daily_bottles_count" right />

                        <TableHeader title="Номер аппарата" tag="group_names" right />
                        <TableHeader title="Инвентарный номер" tag="inventory_numbers" right />
                    </tr>
                    </thead>

                    <tbody>
                    {data?.map((r, index) => {

                        return (
                            <tr key={index}>
                                <td className={classes.td}>
                                    <div className={classNames(classes.tdInner, {'hover': hovered === 1})}>
                                        {r.city}
                                    </div>
                                </td>
                                <td className={classes.td}>
                                    <div className={classNames(classes.tdInner, {'hover': hovered === 2})}>
                                    {r.name}
                                    </div></td>
                                <td className={classes.td}>
                                    <div className={classNames(classes.tdInner, {'hover': hovered === 3})}>
                                        {r.address}</div></td>
                                <td><div className={classNames(classes.tdInner, {'hover': hovered === 4})}>
                                    {r.pouring_volume}
                                </div></td>
                                <td className={classes.td}>
                                    <div className={classNames(classes.tdInner, {'hover': hovered === 5})}>
                                    <Badge v={val(r.pouring_volume_diff) + '%'} t={r.pouring_volume_diff} />
                                    </div>
                                    </td>
                                <td className={classes.td}>
                                    <div className={classNames(classes.tdInner, {'hover': hovered === 6})}>
                                    {r.average_daily_pouring_volume}
                                    </div></td>
                                <td className={classes.td}>
                                    <div className={classNames(classes.tdInner, {'hover': hovered === 7})}>
                                    {r.bottles_count}
                                    </div></td>
                                <td className={classes.td}>
                                    <div className={classNames(classes.tdInner, {'hover': hovered === 8})}>
                                    {r.average_daily_bottles_count}
                                    </div></td>
                                <td className={classes.td}>
                                    <div className={classNames(classes.tdInner, {'hover': hovered === 9})}>
                                        <Spoiler list={r.group_names?.filter(a=>a)} />
                                    </div></td>
                                <td className={classes.td}>
                                    <div className={classNames(classes.tdInner, {'hover': hovered === 10})}>
                                        <Spoiler list={r.inventory_numbers?.filter(a=>a)} />
                                    </div>
                                    </td>
                            </tr>
                        );
                    })}
                    <tr className={classNames([classes.moreRow, {'hidden': showed >= total}])}>
                        <td colSpan={8}>
                            <div className={classes.loading} ref={ref}>
                                <CircularProgress size={24} />
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </Paper>
    )
};

export default Table;

export const styles = {
    root: {
        //
    },
    cover: {
        position: 'relative',
        width: '100%',
        background: '#fff',
        height: 14,
        marginBottom: -14,
    },
    downloadBtnIcon: {
        width: 24,
        height: 24,
    },
    tableSeparateHeader: {
        position: 'sticky',
        top: 0,
        borderBottom: 0 + ' !important',

        '&.isScrolling table': {
            boxShadow: '0px 3px 12px rgba(12, 37, 86, 0.16)',
        }
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 12,
        fontSize: 15,
    },
    headerTitle: {
        fontSize: 16,
        letterSpacing: '0.002em',
    },
    downloadBtn: {
        color: '#666',
        display: 'flex',
        gap: 4,
        cursor: 'pointer',

        '&:hover': {
            textDecoration: 'underline',
        }
    },
    tableWrap: {
        width: 'calc(100% + 40px)',
        maxHeight: 'calc(100vh - 160px)',
        overflow: 'auto',
        margin: '0px -20px',
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
        tableLayout: 'fixed',

        '& tr': {
            background: '#fff',
            borderBottom: '1px solid #E9ECF0',
            height: '100%',

            "&:not(.headRow):hover": {
                background: '#F7F8FA',
            },
        },

        '& th': {
            fontWeight: 500,
            textAlign: 'left',
            verticalAlign: 'bottom',
            padding: 0,
            width: 124,

            '&:nth-child(1)': {
                width: 144,
            },
            '&:nth-child(2)': {
                width: 224,
            },
            '&:nth-child(3)': {
                width: 264,
            },

            '&:first-child': {
                paddingLeft: 20,
            },
            '&:last-child': {
                paddingRight: 20,
            },
        },
        '& td': {
            textAlign: 'left',
            verticalAlign: 'middle',
            padding: '0',
            wordBreak: 'break-word',
            fontSize: 13,
            lineHeight: '16px',
            height: 1,

            '&:first-child': {
                paddingLeft: 20,
            },
            '&:last-child': {
                paddingRight: 20,
            },

            '&:nth-child(4)': {
                textAlign: 'right',
            },
            '&:nth-child(5)': {
                textAlign: 'right',
            },
            '&:nth-child(6)': {
                textAlign: 'right',
            },
            '&:nth-child(7)': {
                textAlign: 'right',
            },
            '&:nth-child(8)': {
                textAlign: 'right',
            },
            '&:nth-child(9)': {
                textAlign: 'right',
            },
            '&:nth-child(10)': {
                textAlign: 'right',
            },
        }
    },
    loading: {
        textAlign: 'center',
    },
    moreRow: {
        '&.hidden': {
            display: 'none',
        }
    },
    sortBtn: {
        border: 0,
        padding: 0,
        margin: '0px 4px',
        background: 'transparent',
        display: 'inline-block',
        verticalAlign: 'middle',
        cursor: 'pointer',
        color:  '#ADAFB3',

        '&:hover': {
            color:  '#666',
        },

        '&.active': {
            color: '#1d1d1d',
        }
    },
    sortBtnIcon: {
        height: 16,
    },

    th: {
        height: 1,
    },
    right: {
      textAlign: 'right',
    },
    tdInner: {

        padding: '10px 16px',
        height: '100%',

        '&.hover': {
            background: "#F7F8FA",
        }
    },
    select: {
        marginLeft: 24,
    },
    thInner: {
        display: 'flex',
        gap: 4,
        alignItems: 'flex-end',
        wordBreak: 'break-word',
        height: '100%',
        borderRadius: '6px 6px 0px 0px',
        //background: '#f00',
        padding: '4px 6px 4px 16px',
        fontSize: 13,
        lineHeight: '16px',
        position: 'relative',

        '&:hover': {
            background: '#F2F4F6',


        },

        '&.right': {
            textAlign: 'right',
            justifyContent: 'flex-end',
        }
    },
    link: {
        position: 'fixed',
        top: -9999,
        left: -9999,
        fontSize: 0,
    },

    "@supports ( -moz-appearance:none )" :{
        /* Add firefox CSS code here */
        td: {
            height: "100% !important",
        },
        th: {
            height: "100% !important",
        }
    },
    splLink: {
        borderBottom: '1px dashed',
    }
};
const useStyles = makeStyles(styles);
