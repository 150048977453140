import React from 'react';
import {Typography} from '@material-ui/core';
import {ReactComponent as DotStatus} from '../svg/dot-status.svg';
import {ReactComponent as KegIcon} from '../svg/keg.svg';
import ProgressBar from './ProgressBar';
import {makeStyles} from '@material-ui/core/styles';
import {STATE_ERROR, STATE_OK, STATE_WARN} from '../app/constants';
import {emptyVol, formatVolume, getUnits, getVolume} from '../app/utils';
import {t} from '../i18n/utils.js';

const styles = theme => ({
    header: {
        display: "inline-flex",
        width: "100%",
        marginBottom: 16,
        paddingLeft: 4,
        paddingRight: 31,
        boxSizing: "border-box",
    },
    titleCol: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        flexShrink: 1,
    },
    titleRow: {
        display: "flex",
        marginBottom: 4,
        alignItems: "center",
    },
    title: {
        flexGrow: 1,
        flexShrink: 1,
        height: 28,
        lineHeight: "28px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        color: params => !params.isActive ? theme.palette.disabled.main : "inherit",
    },
    progressBarLabel: {
        height: 20,
        display: "inline-block",
        lineHeight: "20px",
        textAlign: "right",
        flexGrow: 0,
        flexShrink: 0,
        marginLeft: theme.spacing(1),
        color: params => params.isEmpty ? theme.palette.disabled.main : "inherit",
    },
    iconWrap: {
        width: 32,
        height: 32,
        flexGrow: 0,
        flexShrink: 0,
        overflow: "hidden",
        marginRight: 16,
        marginTop: 3,
    },
    icon: {
        color: params => !params.isActive ? theme.palette.disabled.main : "inherit",
    },
    statusWrap: {
        width: 20,
        height: 20,
        flexGrow: 0,
        flexShrink: 0,
        overflow: "hidden",
        marginTop: 9,
    },
    beerTitle: {
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: 12,
        lineHeight: "15px",
        letterSpacing: "0.00039375px",
        maxHeight: 30,
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        "-webkit-line-clamp": 2,
        "-webkit-box-orient": "vertical",
    },
    status: {
        color: params => params.state === STATE_ERROR ? theme.palette.red :
            params.state === STATE_WARN ? theme.palette.orange :
                params.state === STATE_OK && params.isActive ? theme.palette.green :
                    theme.palette.disabled.main,
    },
    progressBar: {
        marginBottom: 8,
    },
});

const useStyles = makeStyles(styles);

/**
 * Отображает заголовок кега
 *
 * @component
 */
function KegHeader(props) {
    const {title, beerTitle, isActive, isEmpty, beerRemained, kegVolume, notifications} = props;

    const mostBadStatus = !notifications ? STATE_OK : notifications.reduce((acc, cur) => {
        const curType = cur.get("type");
        const curStatus = curType === 'Warn' ? STATE_WARN : STATE_ERROR;

        return curStatus > acc ? curStatus : acc;
    }, STATE_OK);

    const classes = useStyles({
        isActive,
        isEmpty,
        state: mostBadStatus,
    });

    const kegVolumeJs = kegVolume?.toJS();
    const beerRemainedJs = beerRemained?.toJS();
    const units = getUnits(kegVolumeJs);

    const beerRemainedV = isEmpty ? emptyVol : getVolume(beerRemainedJs);
    const kegVolumeV = getVolume(kegVolumeJs);
    //console.log (beerRemainedV, kegVolumeV, units);

    let beerRemainedStr = t('AofB', {a: beerRemainedV, b: kegVolumeV});
    beerRemainedStr = formatVolume(beerRemainedStr, units);

    return (
        <header className={classes.header}>
            <div className={classes.statusWrap}>
                <DotStatus className={classes.status}/>
            </div>
            <div className={classes.iconWrap}>
                <KegIcon className={classes.icon}/>
            </div>
            <div className={classes.titleCol}>
                <div className={classes.titleRow}>
                    <Typography variant="h2" className={classes.title}>{title}</Typography>
                    <Typography className={classes.progressBarLabel + ' beerRemained'}>
                        {beerRemainedStr}
                    </Typography>
                </div>
                <ProgressBar disabled={!isActive} value={!isEmpty ? beerRemainedV / kegVolumeV * 100 : 0}
                             className={classes.progressBar}/>
                <Typography className={classes.beerTitle}>{beerTitle}</Typography>
            </div>
        </header>
    );
}

export default KegHeader;
