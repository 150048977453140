import {createTheme} from '@material-ui/core';
import createPalette from '@material-ui/core/styles/createPalette';

const blue = "#176BFB";
const white = "#FFFFFF";


const palette = createPalette({
    type: "light",
    primary: {
        main: blue,
    },
    background: {
        default: "#F0F2F4",
        paper: white,
        progressBar: "#EAEEF4",
    },
    text: {
        primary: "#646464",
        secondary: "#C1C1C1",
        note: "#C1C1C1",
        heading: "#21293A",
    },

    disabled: {
        main: "#C1C1C1",
    },
    border: {
        main : "#c1c1c1"
    },

    red: "#FF3B30",
    orange: "#fd9426",
    green: "#30B402",
});

export const theme = createTheme({
    palette: palette,
    spacing: 8,
    breakpoints: {
        values: {
            xs: 0,
            sm: 360,
            md: 640,
            lg: 960,
            xl: 1300,
        },
    },
    typography: {
        fontFamily: ["SMSans", "sans-serif"].join(", "),
        fontSize: 14,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 700,
    },
});

theme.overrides = {
    MuiPaper: {
        root: {
            margin: 0,
            padding: theme.spacing(1),
        },
        elevation1: {
            boxShadow: "0px 1px 4px rgba(0,0,0,0.25)",
        },
        rounded: {
            borderRadius: 16,
        }
    },
    MuiTypography: {
        h1: {
            fontSize: 23,
            fontWeight: "bold",
            color: palette.text.heading,
            letterSpacing: 0,
        },
        h2: {
            fontSize: 16,
            fontWeight: "bold",
            color: palette.text.primary,
            letterSpacing: 0.2,
            margin: "4px 0",
            lineHeight: "28px",
        },
        h3: {
            fontSize: 14,
            fontWeight: theme.typography.fontWeightBold,
            color: palette.text.primary,
            letterSpacing: 0,
        },
        h4: {
            fontWeight: "bold",
            fontSize: "14px",
            lineHeight: "28px",
            color: palette.text.primary,
        },
    },
    MuiFilledInput: {
        root: {
            height: "100%",
            borderTopLeftRadius: 7,
            borderTopRightRadius: 7,
            borderRadius: 7,
            overflow: "hidden",
        },
        input: {
            padding: "7px 12px",
            "&::-webkit-input-placeholder": {
                opacity: 1,
            },
            "&::-moz-placeholder": {
                opacity: 1,
            },
            "&:-ms-input-placeholder": {
                opacity: 1,
            },
            "&:-moz-placeholder": {
                opacity: 1,
            },
            "&::placeholder": {
                opacity: 1,
            },
        },
        underline: {
            "&:before": {
                display: "none",
            },
        },
    },

    PrivateNotchedOutline: {
        legendLabelled: {
            // // Make it visible the hard way for accessibility - https://webaim.org/techniques/css/invisiblecontent/
            visibility: "inherit !important",
            position: "absolute", // This adds more padding to the outlined text field :(
            left: "-10000px",
            top: "auto",
            width: "1px",
            height: "1px",
            overflow: "hidden",
            color: "black",
        },
    },

    MuiInputLabel: {
        shrink: {
            transform: 'translate(14px, 2px) scale(0.75) !important',
        }
    },

    MuiOutlinedInput: {
        input: {
            ".MuiInputLabel-shrink + .MuiOutlinedInput-root &": {
                padding: '22px 14px 15px 14px !important',
            }
        }
    },

    MuiAutocomplete: {
        inputRoot: {
            "&&[class*=\"MuiOutlinedInput-root\"]": {
                "&&&  .MuiAutocomplete-input": {
                    padding: '13px 0px 4px 5px !important',
                },
            },

            "&&[class*=\"MuiFilledInput-root\"]": {
                paddingLeft: "12px",
                paddingTop: "7px",
                paddingBottom: "7px",

                "&&& .MuiAutocomplete-input": {
                    padding: 0,
                    fontSize: "13px",
                    lineHeight: "16px",
                },
            },
        },
        paper: {
            minWidth: 220,
            borderRadius: 4,
        },
    },

    MuiButton: {
        root: {
            textTransform: "none",
        },
        outlined: {
            boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.5)",
            borderRadius: "8px",
            fontWeight: "bold",
            fontSize: "14px",
            border: "none !important",
        },
        outlinedPrimary: {
            backgroundColor: theme.palette.primary.main,
            color: "#ffffff",

            "&:hover": {
                backgroundColor: theme.palette.primary.dark + " !important",
            },
            "&:disabled": {
                backgroundColor: theme.palette.disabled.main + " !important",
                color: '#fff !important',
            }
        },
    },
    MuiDialog: {
            paper: {
                [theme.breakpoints.down('sm')]: {
                    marginBottom: 0,
                    borderBottomRightRadius: 0,
                    borderBottomLeftRadius: 0,
                    width: "100vw",
                    margin: 0,
                },
            },
            scrollPaper: {
                [theme.breakpoints.down('sm')]: {
                    alignItems: "flex-end",
                },
            },
    },
    MuiDialogActions: {
        root: {
            "& > *": {
                flex: 1,
            }
        }
    },
};

/*
theme.overrides = {
    MuiPaper: {
        root: {
            margin: 0,
            padding: theme.spacing(1),
        },
        elevation1: {
            boxShadow: "0px 1px 4px rgba(0,0,0,0.25)",
        },
        rounded: {
            borderRadius: 16,
        }
    },
    MuiTypography: {
        h1: {
            fontSize: 23,
            fontWeight: "bold",
            color: palette.heading,
            letterSpacing: 0,
        },
        h2: {
            fontSize: 16,
            fontWeight: "bold",
            color: palette.text.primary,
            letterSpacing: 0.2,
            margin: "4px 0",
            lineHeight: "28px",
        },
        h4: {
            fontWeight: "bold",
            fontSize: "14px",
            lineHeight: "28px",
            color: palette.text.primary,
        },
    },
    MuiButton: {
        root: {
            textTransform: "none",
        },
        outlined: {
            boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.5)",
            borderRadius: "8px",
            fontWeight: "bold",
            fontSize: "14px",
            border: "none",
        },
        outlinedPrimary: {
            backgroundColor: theme.palette.primary.main,
            color: "#ffffff",

            "&:hover": {
                backgroundColor: theme.palette.primary.dark,
            }
        },
    },
    MuiDialog: {
        paper: {
            borderRadius: 8,
            boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.5)",
        },
    },
    MuiFilledInput: {
        root: {
            height: 32,
            borderTopLeftRadius: 7,
            borderTopRightRadius: 7,
            borderRadius: 7,
            overflow: "hidden",
        },
        input: {
            padding: "7px 12px",
            "&::-webkit-input-placeholder": {
                opacity: 1,
            },
            "&::-moz-placeholder": {
                opacity: 1,
            },
            "&:-ms-input-placeholder": {
                opacity: 1,
            },
            "&:-moz-placeholder": {
                opacity: 1,
            },
            "&::placeholder": {
                opacity: 1,
            },
        },
        underline: {
            "&:before": {
                display: "none",
            },
        },
    },
    MuiAutocomplete: {
        inputRoot: {
            "&&[class*=\"MuiFilledInput-root\"]": {
                padding: "7px 12px",

                "&&& .MuiAutocomplete-input": {
                    padding: 0,
                    fontSize: "13px",
                    lineHeight: "16px",
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                },
            },
        },
        paper: {
            minWidth: 220,
            borderRadius: 4,
        },
    },
};*/
