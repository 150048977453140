import React, {useContext, useEffect, useRef, useState} from 'react';
import {Button, ClickAwayListener, IconButton, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

import {ReactComponent as SearchIcon} from './svg/search.svg';
import {ReactComponent as SettingsIcon} from './svg/settings.svg';

import classNames from 'classnames';
import {useSelector} from 'react-redux';
import {statsSummaryGet} from '../../redux/reducers/stats.js';
import {LocaleContext} from '../../i18n/LocaleContext.js';
import {t} from '../../i18n/utils.js';
import SearchField from './SearchField.js';
import SettingsMenu from './SettingsMenu.js';
import {checkPermission, tokensGetAccessToken} from '../../redux/reducers/tokens.js';
import TopBarIcons from './components/TopBarIcons.js';

function TopBar({search, setSearch, query, setQuery, ...props}) {
    const classes = useStyles();

    const summary = useSelector(statsSummaryGet)?.toJS();

    const SmallBtn = ({icon, text}) => (
        <div className={classes.toplineBtn}>
            <div className={classes.toplineBtnIcon}>
                {icon}
            </div>
            <Typography className={classes.smallBtnText}>{text}</Typography>
        </div>
    );

    const BigBtn = ({icon}) => (
        <div className={classes.toplineBigBtn}>{icon}</div>
    );

    const locale = useContext(LocaleContext);
    const token = useSelector(tokensGetAccessToken);
    const addStoreLink = `/${locale.code}/adm/sellers/store/add/`;//adminLink(token, `/admin/sellers/store/add/`, locale.code);

    const [settings, setSettings] = useState(false);
    const ref = useRef(null);

    const handleClickAway2 = () => {
        setSearch(false);
    };

    const addBtnRef = useRef(null);

    const [rightOffset, setRightOffset] = useState(0);

    const hasAccessToAdmin = useSelector(checkPermission('section_service'));

    useEffect(() => {
        if (addBtnRef.current) {
            const rect = addBtnRef.current.getBoundingClientRect();
            //setRightOffset(window.innerWidth - addBtnRef.current.offsetLeft - 154);
            setRightOffset(window.innerWidth - rect.x - 58);
        }
    }, [addBtnRef.current]);

    return (
        <div className={classes.topbar}>
            <div className={classes.pageTitle}>
                <Typography variant="h1">{t('pageTitle')}</Typography>
            </div>
            <div className={classes.topbarButtons}>
                <TopBarIcons summary={summary} />
            </div>


                <div className={classNames(classes.topbarButtons, classes.bigButtons)}>
                    {search &&
                    <SearchField query={query} setQuery={setQuery}
                                 className={classNames(classes.searchField, {'visible': search})}
                                 setSearch={setSearch}
                                 style={{right: rightOffset}}
                    />
                    }
                    <IconButton className={classNames(classes.toplineBigBtn2, {'pressed':  search}, 'search')}
                                onClick={() => {
                                    setSearch(true);
                                }} ref={addBtnRef}
                    ><SearchIcon /></IconButton>

                    <ClickAwayListener onClickAway={handleClickAway2}>
                        <>
                            {hasAccessToAdmin &&
                            <Button className={classes.toplineBtn} variant="contained" color="primary" href={addStoreLink}>
                                {t('addStore')}</Button>}
                            <IconButton className={classNames(classes.toplineBigBtn2, {'pressed':  settings}, 'lastChild')}
                                        onClick={() => {
                                            setSettings(true);
                                        }}  ref={ref}
                            ><SettingsIcon/></IconButton>
                            <SettingsMenu open={settings} anchorRef={ref} {...props} onClose={()=>setSettings(false)} />
                        </>
                    </ClickAwayListener>
                </div>

        </div>
    );
}

export default TopBar;

const styles = (theme) => ({
    searchField: {
        position: "relative",
        zIndex: 800,
        background: "#fff",
        top: 0,
        left: -8, // 220
        borderRadius: 16,
        display: 'none',
        width: 250,

        '&.visible': {
            display: 'inherit',
        },
    },
    topbar: {
        display: 'flex',
        marginBottom: 18,
        position: 'relative',
        zIndex: 800,

        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    pageTitle: {
        flex: 1,
    },
    topbarButtons: {
        display: 'flex',
        alignItems: 'center',
    },

    toplineBtn: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: theme.spacing(3),
        marginLeft: theme.spacing(0),
    },
    toplineBtnIcon: {
        width: 24,
        height: 24,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        marginRight: theme.spacing(2),
    },
    smallBtnText: {
        color: theme.colors.grey.c100,
    },

    bigButtons: {
        marginLeft: theme.spacing(6),
    },
    toplineBigBtn: {
        width: 24,
        height: 24,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        marginRight: theme.spacing(4),
    },

    toplineBigBtn2: {
        width: 36,
        height: 36,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        marginRight: theme.spacing(2),
        '&.lastChild': {
            marginRight: 0,
        },
        padding: 0,

        '&.pressed': {
            background: '#007AFF1A',
            color: '#007AFF',

            '&.search': {
                display: 'none',
            }
        }
    },

});
const useStyles = makeStyles(styles);
