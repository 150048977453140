import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {Typography} from '@material-ui/core';
import {t} from '../../../i18n/utils.js';

function DeviceNumber({number, className, component = "h1", ...props}) {
    const classes = useStyles();

    return (
        <div {...props} className={[classes.root, className].join(' ')}>
            <small className={classes.label}>{t('deviceNumber')}</small>
            <Typography variant="h1" component={component} className={classes.deviceNumber}>{number}</Typography>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        position: "relative",
        top: -12,
        maxWidth: "100%",
    },
    label: {
        fontSize: 10,
        position: "relative",
        top: 2,
    },
    deviceNumber: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        maxWidth: '100%',
    },
}));

export default DeviceNumber;