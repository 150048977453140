import React, {useContext, useState} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import StackPaper from '../../../componets/StackPaper';
import BackBtn from '../BackBtn';
import DeviceNumber from './DeviceNumber';
import Indicators from '../Indicators';
import {useHistory} from 'react-router-dom';
import NotificationsArea from '../../../componets/Notifications/NotificationsArea';
import {IconButton} from '@material-ui/core';
import LocalizedLink from '../../../localization/LocalizedLink';
import RefreshIcon from '@material-ui/icons/Refresh';
import RebootDialog from './RebootDialog';
import {useSelector} from 'react-redux';
import {checkPermission, userIsSuper} from '../../../redux/reducers/tokens';
import DevActions from './DevActions';
import CreateIcon from '@material-ui/icons/Create';
import {t} from '../../../i18n/utils.js';
import moment from 'moment';
import {LocaleContext} from '../../../i18n/LocaleContext.js';

function DetailHeaderMobile({device, className, linkBack, hideSec, ...props}) {
    const classes = useStyles();
    const [reboot, setReboot] = useState(false);

    const canReboot = useSelector(checkPermission('access_to_reload_device'));
    const canChangeStore = useSelector(checkPermission('access_to_change_store'));

    const store = device.get('store');
    const storeName = store?.get('name');
    const storeAddress = store?.get('address')?.concat(', ');

    //console.log(store?.get('work_hours'));
    let storeHours = ''
    if (store?.get('work_hours')?.toJS instanceof Function) {
        const wh = store?.get('work_hours')?.toJS();
        storeHours = wh?.start && wh?.end ? `${wh.start} – ${wh.end}` : '-';
    } else {
        storeHours = store?.get('work_hours') || '-';
    }

    const storeCity = store?.get('city');
    const storeCityName = storeCity?.get('name')?.concat(', ');
    const chainName = store?.get('chain')?.get('name')?.concat(', ');

    const notiAll = device.get('notifications');
    const notiWarns = notiAll.filter(n => n.get('type') === 'Warn');
    const notiErrors = notiAll.filter(n => n.get('type') === 'Error');

    let history = useHistory()

    const isSuper = useSelector(userIsSuper);

    const storeStr = device.get('store') ? `${storeCityName} ${storeAddress} ${storeHours}` : t('undefinedStore');

    const locale = useContext(LocaleContext);

    const dateWarrancy = moment(device.get('warranty_date'));
    const dateWarrancyP1 = dateWarrancy.add(1, 'day');
    const now = moment();

    const hasWarrancy = dateWarrancy?.isValid() === true;
    console.log(dateWarrancy.isValid());

    const onWarrancy = dateWarrancy.diff(now) > 0;

    return (
        <div {...props} className={[classes.root, className].join(' ')}>
            <StackPaper className={classes.header}>
                <div className={classes.grid}>
                    <div className={[classes.g, classes.g1].join(' ')}>
                        <LocalizedLink to={linkBack}>
                            <BackBtn/>
                        </LocalizedLink>
                    </div>
                    <div className={[classes.g, classes.g2].join(' ')}>
                        {storeStr}
                        {canChangeStore &&
                        <IconButton title={t('changeStore')} onClick={() => {
                            props.setStoreChange(true);
                        }} disabled={!canChangeStore}>
                            <CreateIcon style={{width: 21}}/>
                        </IconButton>
                        }
                    </div>

                    {hasWarrancy &&
                    <div className={[classes.g, classes.g3].join(' ')}>
                        {
                            onWarrancy ? (
                                <div>
                                    {t('onWarrant', {date: dateWarrancy.format(locale.dateFormat)})}
                                </div>
                            ) : (
                                <div>
                                    {t('offWarrant', {date: dateWarrancyP1.format(locale.dateFormat)})}
                                </div>
                            )
                        }
                    </div>
                    }
                </div>
            </StackPaper>

            { !hideSec &&
            <StackPaper className={classes.header2}>
                <div className={classes.col}>
                    <div className={classes.deviceNumber}>
                        <div className={classes.dnum}>
                            <DeviceNumber number={device.get('device_number')}/>
                        </div>
                        <div className={classes.devActions}>
                            {canReboot &&
                            <IconButton onClick={() => {
                                setReboot(true);
                            }} title={t('rebootTitle')} disabled={!canReboot} id={'device-reboot-btn'}>
                                <RefreshIcon/>
                            </IconButton>
                            }
                            <RebootDialog open={reboot} onClose={()=>{
                                setReboot(false);
                            }} />

                            <DevActions device={device} />
                        </div>
                    </div>
                    <div className={[classes.indicators].join(' ')}>
                        <Indicators data={device.get('indicators')} />
                    </div>
                    <div className={[classes.notifications].join(' ')}>
                        <NotificationsArea list={notiAll} superArea={true}/>
                    </div>
                </div>
            </StackPaper>
            }
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    header: {
        marginLeft: 0,
        marginRight: 0,
        paddingRight: 32,
        paddingTop: 40,
        position: "relative",
        zIndex: 50,
    },
    grid: {
        display: "grid",
        width: "100%",
        gridTemplateColumns: "min-content 1fr",
        gridTemplateAreas: '"g1 g2" "g1 g3"',
    },
    separator: {
        display: "inline-block",
        width: 1,
        alignSelf: "stretch",
        background: theme.palette.border.main,
    },

    warranty: {

    },
    g: {
        display: "flex",
        alignItems: "center",
    },
    g1: {
        gridArea: "g1",
        marginRight: 18,
    },
    g2: { gridArea: "g2",
        fontSize: 14,
        lineHeight: "14px",
    },
    g3: {
        gridArea: "g3",
    },
    g4: { gridArea: "g4",
    },
    g5: { gridArea: "g5",
        display: "none",
    },

    gmarginStart: {
        marginRight: 20,
    },
    gmargin: {
        margin: "0 20px",
    },
    gmarginEnd: {
        marginLeft: 20,
    },

    storeName: {
        fontWeight: 500,
        lineHeight: "16px",
    },
    storeAddressHours: {
        display: "flex",
        fontSize: 12,
        flexDirection: "column",
    },
    storeHours: {
        fontSize: 10,
        fontWeight: 500,
        lineHeight: "14px",
    },
    storeCity: {
        fontSize: 10,
        fontWeight: 500,
        lineHeight: "14px",
    },

    notifications: {
        borderTop: "1px solid",
        borderColor: theme.palette.border.main,
        marginTop: 12,
    },
    header2: {
        marginLeft: 0,
        marginRight: 0,
        padding: 0,
        paddingTop: 48,
        zIndex: 20,
        position: "relative",
    },
    col: {
        display: "flex",
        flexDirection: "column",
        paddingLeft: 24 - 8,
        paddingRight: 24 - 8,
    },
    deviceNumber: {
        paddingLeft: 18,
        paddingRight: 18,
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
    },
    dnum: {
        flex: 1,
        maxWidth: "100%",
    },
    indicators: {
        paddingLeft: 18,
        paddingRight: 18,
    }
}));

export default DetailHeaderMobile;
