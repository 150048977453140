import React, {useState} from 'react';
import Box from '@material-ui/core/Box';
import DetailHeaderMobile from './DetailHeaderMobile';
import DetailHeaderDesktop from './DetailHeaderDesktop';
import ChangeStoreDialog from './ChangeStoreDialog';

function DetailHeader({device, linkBack, hideSec}) {
    const [storeChange, setStoreChange] = useState(false);

    return (
        <header>
            <Box display={{ xs: "block", lg: "none" }}>
                <DetailHeaderMobile device={device} linkBack={linkBack} hideSec={hideSec} setStoreChange={setStoreChange}/>
            </Box>
            <Box display={{ xs: "none", lg: "block" }}>
                <DetailHeaderDesktop device={device} linkBack={linkBack} setStoreChange={setStoreChange}/>
            </Box>

            {storeChange &&
            <ChangeStoreDialog open={storeChange} onClose={() => {
                setStoreChange(false);
            }}/>
            }
        </header>
    );
}

export default DetailHeader;
