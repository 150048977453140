import {makeStyles} from '@material-ui/core';
import React, {useEffect, useRef, useState} from 'react';
import Select from './Select.js';
import {useDispatch, useSelector} from 'react-redux';
import {citiesFetchList, getCitiesList, getCitiesListState} from '../../redux/reducers/cities.v2.js';
import {getWithToken} from '../../app/rest.js';
import {tokensGetBearerToken} from '../../redux/reducers/tokens.js';

function Filters({...props}) {
    const classes = useStyles();

    const dispatch = useDispatch();
    const cities = useSelector(getCitiesList)?.toJS();
    const token = useSelector(tokensGetBearerToken);
    const citiesLoading = useSelector(getCitiesListState);

    const [storesLoaded, setStoresLoaded] = useState(false);
    const [chainsLoaded, setChainsLoaded] = useState(false);


    const cityId = props.city?.id;

    const loadStores = async () => {
        const limit = 9999;

        let fullyLoaded = false;
        const result = [];
        let offset = 0;
        let cycle = 0;

        console.log('LOAD STORES');

        while(!fullyLoaded) {

            let endpoint = `/v2/api/stores?limit=${limit}&offset=${offset}`;
            if (cityId) {
                endpoint += `&city_id=${cityId}`;
            }

            setStoresLoaded(false);
            const resp = await getWithToken(endpoint, token, 'Bearer');

            const total = resp?.total || 0;
            const loadedItems = resp.items;

            if (!loadedItems) {
                break;
            }

            const newLen = result?.length + loadedItems?.length;
            offset += newLen;
            //console.log(cycle, offset, newLen)

            result.push(...loadedItems.map(s => ({id: s.id, name: s.address})).sort((a, b) => {
                if (a.name > b.name) {
                    return 1;
                }
                if (a.name < b.name) {
                    return -1;
                }
                return 0;
            }));

            //console.log(newLen, total);

            cycle += 1;

            if (offset >= total) {
                fullyLoaded = true;
            }
        }

        setStores(result);
        setStoresLoaded(true);
    };

    const loadChains = async () => {
        const limit = 9999;

        let fullyLoaded = false;
        const result = [];
        let offset = 0;
        let cycle = 0;

        console.log('LOAD CHAINS');

        while(!fullyLoaded) {

            let endpoint = `/v2/api/chains?limit=${limit}&offset=${offset}`;
            if (cityId) {
                endpoint += `&city_id=${cityId}`;
            }

            setStoresLoaded(false);
            const resp = await getWithToken(endpoint, token, 'Bearer');

            const total = resp?.total || 0;
            const loadedItems = resp.items;

            if (!loadedItems) {
                break;
            }

            const newLen = result?.length + loadedItems?.length;
            offset += newLen;
            //console.log(cycle, offset, newLen)

            result.push(...loadedItems.sort((a, b) => {
                if (a.name > b.name) {
                    return 1;
                }
                if (a.name < b.name) {
                    return -1;
                }
                return 0;
            }));

            //console.log(newLen, total);

            cycle += 1;

            if (offset >= total) {
                fullyLoaded = true;
            }
        }

        setChains(result);
        setChainsLoaded(true);
    };

    const [stores, setStores] = useState([]);
    const [chains, setChains] = useState([]);
    const storesSel = useRef([]);
    const chainsSel = useRef([]);

    useEffect(() => {
        dispatch(citiesFetchList());

        console.log('useEffect', props, cityId);

        loadStores();
        loadChains();
    }, [cityId]);

    function updateFilter() {
        props.setStore(storesSel.current);
        setStores([...storesSel.current, ...stores.filter(s => !storesSel.current.includes(s))]);

        props.setChains(chainsSel.current);
        setChains([...chainsSel.current, ...chains.filter(s => !chainsSel.current.includes(s))]);
    }

    return (
        <div className={classes.root}>
            <div className={classes.filters}>
                <Select
                    options={cities}
                    placeholder="Город"
                    value={props.city}
                    onChange={(e, v)=>{
                        console.log(v);
                        props.setCity(v);
                    }}
                    loading={citiesLoading}
                />
                <Select
                    options={chains}
                    placeholder="Торговая сеть"
                    //value={props.store}
                    onChange={(e, v, reason)=>{
                        chainsSel.current = v;
                        //props.setStore(v);
                        //console.log(storesSel);
                        //setStores([...storesSel.current, ...stores.filter(s => !storesSel.current.includes(s))]);

                        if (reason === 'clear') {
                            updateFilter();
                        }
                    }}
                    onClose={updateFilter}
                    multiple
                    loading={!chainsLoaded}
                />
                <Select
                    options={stores}
                    placeholder="Магазин"
                    //value={props.store}
                    onChange={(e, v, reason)=>{
                        storesSel.current = v;
                        //props.setStore(v);
                        //console.log(storesSel);
                        //setStores([...storesSel.current, ...stores.filter(s => !storesSel.current.includes(s))]);

                        if (reason === 'clear') {
                            updateFilter();
                        }
                    }}
                    onClose={updateFilter}
                    multiple
                    loading={!storesLoaded}
                />
            </div>
        </div>
    );
}
export default Filters;

const styles = theme => ({
    root: {
        marginBottom: theme.spacing(7),
    },

    filters: {
        display: 'flex',
        flexWrap: 'wrap',
        margin: theme.spacing(-2),
        //padding: '16px 0 24px 0',

        '& > *': {
            margin: theme.spacing(1.5, 2, 1.5, 2),
        },

        [theme.breakpoints.down('sm')]: {
            '& > *': {
                flex: 1,
            },
        }
    }
});
const useStyles = makeStyles(styles);
