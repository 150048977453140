export const STATE_OK = 1;
export const STATE_WARN = 2;
export const STATE_ERROR = 3;

export const DATE_FORMAT = "DD.MM.YYYY";

export const red = "#FF3B30";
export const orange = "#fd9426";
export const transparentOrange = "rgb(253,148,38,0.1)"; /* stripe bg */
export const green = "#30B402";
export const blue = "#176BFB";
export const white = "#FFFFFF";
export const black = "#000000";

export const gray0 = "#F0F2F4"; /* bg */
export const gray1 = "#EAEEF4"; /* progressbar bg */
export const gray2 = "#E1E3E6"; /* filled controls */
export const gray3 = "#C1C1C1"; /* disabled or border */
export const gray5 = "#646464"; /* text color */
export const gray6 = "#21293A"; /* headings */
